import { Flex, Headline, spacing } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React, { useCallback, useMemo, useState } from 'react';
import type {
  WwwLinkReferenceType as WwwLinkReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from '../../../types/referenceTypes';
import ClassOptions from './ClassOptions';
import DisciplineTilesNav from './DisciplineTilesNav';
import PreviewVideo from './PreviewVideo';
import { themes } from './theme';
import type { Discipline as DisciplineType } from './types';
import { useClassData } from './useClassData';

export type DisciplineTilesWithModalProps = {
  headline?: string;
  disciplines?: DisciplineType[];
  modalCta?: {
    text: string;
    link: WwwLinkReference | ExternalLinkReference;
  };
  theme?: 'White' | 'Grey 90';
};

/**
 *
 * The DisciplineTilesWithModal component is a container for a set of discipline tiles that
 * can be navigated through to view more examples of classes for each discipline.
 *
 * This component is composed of data from the Contentful `Component: JSON` form and the classes API.
 * All of the class examples for each discipline are fetched from the classes API, along with most of the data rendered in the modal that
 * pops up after clicking on one of these class examples.
 *
 * There are also three microcopy strings that are used in this component:
 * - `seeMoreClasses`: See more classes
 * - `errorLoadingClasses`: Error loading classes
 * - `app.classModalLabel.contentLabel`: Peloton App
 *
 * The three fields that can be controlled by the Contentful `Component: JSON` form are:
 * - `headline`: A an optional string that will be displayed as the headline for the component.
 * - `disciplines`: An optional array of Discipline objects that represent the different disciplines that are featured in the component.
 *  1. Each Discipline object contains the following fields:
 *  1. - `id`: A string that represents the unique identifier for the discipline.
 *  1. - `ctaTile`: An optional object that contains the icon and text that will be displayed on the tile for the discipline.
 *  1. 2. - The `icon` is an optional string that represents the icon name that will be displayed on the tile.
 *  1. 2. - The `text` is an optional string that represents the text that will be displayed on the tile.
 *  1. `previewVideo`: A MediaReference object that represents the video that will be displayed as a preview for the discipline.
 *  1. `previewClassesHeadline`: A string that represents the headline that will be displayed for the preview classes.
 *  1. `seeMoreLink`: An optional object that contains the link that will be used to navigate to the full list of classes for the discipline.
 * - `modalCta`: An optional object that contains the text and link for the CTA that will be displayed in the modal (that renders after clicking on one of the class examples).
 * 2. - The `text` is a string that represents the text that will be displayed on the CTA.
 * 2. - The `link` is an object that contains the link that will be used to navigate to the full list of classes for the discipline.
 * - `theme`: An optional string that represents the colors that will be used for the component. This currently supports White and Grey 90.
 * 1. - The default theme is Grey 90.
 */

export const DisciplineTilesWithModal: React.FC<
  React.PropsWithChildren<DisciplineTilesWithModalProps>
> = ({ disciplines = [], headline = '', modalCta, theme = 'Grey 90' }) => {
  const [activeDisciplineId, setActiveDisciplineId] = useState(disciplines[0]?.id || '');
  const activeDiscipline = useMemo(
    () => disciplines.find(({ id }) => id === activeDisciplineId) || disciplines[0],
    [disciplines, activeDisciplineId],
  );
  const { classData, loadingStates, fetchDisciplineData } = useClassData(disciplines);

  const changeDiscipline = useCallback(
    (disciplineId: string) => {
      fetchDisciplineData([disciplineId]);
      setActiveDisciplineId(disciplineId);
    },
    [classData],
  );

  const { backgroundColor, primaryTextColor } = themes[theme];

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      data-test-id="discipline-tiles-with-modal-container"
      verticalPadding={{
        mobile: spacing[48],
        tablet: spacing[80],
      }}
    >
      <Flex
        flexDirection="column"
        gap={{ mobile: spacing[16], tablet: spacing[24] }}
        style={{ width: '100%' }}
      >
        <Headline size="small" textColor={primaryTextColor}>
          {headline}
        </Headline>
        <DisciplineTilesNav
          activeDisciplineId={activeDisciplineId}
          changeDiscipline={changeDiscipline}
          disciplines={disciplines}
          theme={theme}
        />
        {activeDiscipline && (
          <Flex
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            gap={{ mobile: spacing[24], tablet: spacing[56] }}
          >
            <PreviewVideo media={activeDiscipline.previewVideo} />
            <ClassOptions
              activeDiscipline={activeDiscipline}
              classes={classData[activeDisciplineId]}
              isLoadingClassData={loadingStates[activeDisciplineId]}
              modalCta={modalCta}
              theme={theme}
            />
          </Flex>
        )}
      </Flex>
    </OuterContainer>
  );
};
