import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';
import { scrollToSection } from '../../../utils/linkUtils';
import TOCItem from './TOCItem';
import type { TOCItemLinkProps, TOCItemsProps } from './types';

const styleMap = {
  Original: {
    styles: {
      horizontalMargin: `auto ${spacing[24]}`,
      horizontalPadding: 0,
    },
  },
  'Mobile Carousel': {
    styles: {
      horizontalMargin: `${spacing[24]} auto`,
      horizontalPadding: `0 ${spacing[24]}`,
    },
  },
};

const TOCItems: React.FC<React.PropsWithChildren<TOCItemsProps>> = ({
  ctas,
  parentName,
  headbandRef,
  activeId,
  theme,
  treatment = 'Original',
}) => {
  const { trackEvent } = useTracking();

  if (!ctas?.length) {
    return null;
  }

  const handleJumpScroll = (
    event: React.MouseEvent<Element, MouseEvent>,
    cta: TOCItemLinkProps,
    sectionId: string = '',
  ) => {
    event.preventDefault();

    scrollToSection(sectionId, headbandRef);

    trackEvent({
      event: TrackingEvent.ClickedSubnavMenu,
      properties: {
        parentType: 'TableOfContents (CHAMP)',
        parent: parentName,
        unitName: `TableOfContents (CHAMP) ${cta.text} CTA`,
        linkName: cta.text,
        linkTo: sectionId,
      },
    });
  };

  const allCtasAreOutlineVariant = ctas.every(cta => cta.variant === 'outline');
  const gap = allCtasAreOutlineVariant
    ? spacing[8]
    : {
        mobile: spacing[24],
        tablet: spacing[16],
        desktop: spacing[24],
      };

  const treatmentStyles = styleMap[treatment]['styles'];

  return (
    <Flex gap={gap} flexShrink={1} {...treatmentStyles} data-test-id="toc-items">
      {ctas.map(cta => (
        <TOCItem
          key={cta.text}
          cta={cta}
          handleClick={handleJumpScroll}
          activeId={activeId}
          theme={theme}
        />
      ))}
    </Flex>
  );
};

export default TOCItems;
