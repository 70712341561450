import { useCallback, useEffect, useState } from 'react';
import { useLocale } from '@peloton/internationalize';
import { fetchClassListingData } from '@ecomm/classes/utils/fetchClassListingData';
import type { ListingData } from '@ecomm/classes/utils/fetchDisciplineDataApi';
import type {
  Discipline as DisciplineType,
  Classes as ClassesType,
  ParsedClass as ParsedClassType,
} from './types';

const MAX_CLASSES = 4;

const getInitialLoadState = (disciplines: DisciplineType[]) =>
  disciplines.reduce((acc, disc) => {
    acc[disc.id] = true;
    return acc;
  }, {});

export const useClassData = (disciplines: DisciplineType[]) => {
  const [classData, setClassData] = useState<Record<string, ParsedClassType[]>>({});
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    getInitialLoadState(disciplines),
  );

  const locale = useLocale();

  const fetchClassesForDiscipline = useCallback(
    (disciplineId: string): Promise<ListingData> => {
      setLoadingStates(prevState => ({
        ...prevState,
        [disciplineId]: true,
      }));

      return fetchClassListingData({
        slug: disciplineId,
        locale,
        query: { page: '1' },
      }).finally(() => {
        setLoadingStates(prevState => ({
          ...prevState,
          [disciplineId]: false,
        }));
      });
    },
    [classData, locale],
  );

  const fetchDisciplineData = useCallback(
    (disciplineIds: string[]): void => {
      (async () => {
        // do not re-fetch when we already have data for the discipline
        const filteredIds = disciplineIds.filter(id => !classData[id]);
        const disciplineFetchers: Promise<ListingData>[] = filteredIds.map(
          fetchClassesForDiscipline,
        );

        const disciplineResponses = await Promise.allSettled(disciplineFetchers);
        const newState = {};

        disciplineResponses.forEach((response, idx) => {
          if (response.status === 'fulfilled') {
            const classes = response?.value?.classes || [];
            const disciplineId = filteredIds[idx];
            const cacheMiss = !(disciplineId && classData[disciplineId]);

            if (classes.length && cacheMiss) {
              newState[disciplineId] = parseClassData(classes.slice(0, MAX_CLASSES));
            }
          }
        });

        setClassData(prevClassData => ({
          ...prevClassData,
          ...newState,
        }));
      })();
    },
    [classData, loadingStates],
  );

  useEffect(() => {
    const firstDisciplineId = disciplines?.[0]?.id;
    if (firstDisciplineId) {
      fetchDisciplineData([firstDisciplineId]);
    }
  }, []);

  return {
    classData,
    loadingStates,
    fetchDisciplineData,
  };
};

const parseClassData = (classes: ClassesType) =>
  classes.map(
    ({
      id,
      instructorName,
      fitnessDisciplineDisplayName,
      imageUrl,
      title,
      difficultyLevel,
      originalAirTime,
      description,
      playlist,
      segments,
      equipmentTags,
      muscleGroupPercentages,
    }) => ({
      id,
      instructorName,
      fitnessDisciplineDisplayName,
      imageUrl,
      title,
      difficultyLevel,
      originalAirTime,
      description,
      playlist,
      segments,
      equipmentTags,
      muscleGroupPercentages,
    }),
  );
