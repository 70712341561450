import useCfuPromo from '@content/client/hooks/useCfuPromo';
import { isProductCFU } from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { BundleType } from '@ecomm/shop/models';

const useFinancingTextForProduct = (product: Product) => {
  const { data: promo } = useCfuPromo(
    isProductCFU(product) ? product.bundleType : BundleType.Accessories,
  );
  const promoFinancingText = promo?.fields.financingMessaging;

  return product.financingText || promoFinancingText;
};

export default useFinancingTextForProduct;
