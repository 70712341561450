import type { Entry } from 'contentful';
import type {
  TypeQuiz,
  TypeQuizFields,
  TypeQuizQuestionFields,
} from '@page-builder/lib/types';
import type { Progress } from '@page-builder/modules/Quiz/models/progress';

/*
  Example ParsedQuizData:
  {
    quizSpecificMetadata: {
      bucketSlugMap: {
        1: '/some-results-page-slug'
      }
    },
    '/some-quiz-question-slug': {
      question: contentfulQuizQuestion,
      quizSpecificMetadata: { weight: 5 },
      valueMap: {
        someStoreValue: {
          displayValue: 'Some Display Value',
          storeValue: 'someStoreValue',
          buckets: '1,2',
          blacklistedBuckets: '3,4'
        },
        ...otherStoreValues
      },
    },
    ...otherSlugs,
  },
*/

type ParsedQuizData = {
  quizSpecificMetadata: TypeQuizFields['quizSpecificMetadata'];
  [questionSlug: string]: {
    question: Entry<TypeQuizQuestionFields>;
    quizSpecificMetadata: TypeQuizQuestionFields['quizSpecificMetadata'];
    valueMap: {
      [answerStoreValue: string]: {
        displayValue: string;
        storeValue: string;
        buckets: string;
        blacklistedBuckets: string;
      };
    };
  };
};

type SlugBucketValues = {
  blacklistedBuckets: {
    [bucketNum: string]: boolean;
  };
  values: {
    [bucketNum: string]: number;
  };
};

const parseQuizData = (quiz: TypeQuiz): ParsedQuizData => {
  const parsedQuizData: ParsedQuizData = {
    quizSpecificMetadata: quiz.fields.quizSpecificMetadata,
  };

  // For each quiz question...
  quiz.fields.questions.forEach(question => {
    parsedQuizData[question.fields.slug] = {
      question,
      quizSpecificMetadata: question.fields.quizSpecificMetadata,
      valueMap: {},
    };

    const answerOptions = question.fields.question.fields.formFields;
    // ...for each answer option in that question...
    answerOptions.forEach(answerOption => {
      // ... track the important info for each possible answer, so we can easily look it up in a hash map by question.slug
      answerOption.fields.items?.forEach(answerText => {
        const [
          displayValue,
          storeValue,
          buckets = '0',
          blacklistedBuckets = '0',
        ] = answerText.split(':');
        parsedQuizData[question.fields.slug].valueMap[storeValue] = {
          displayValue,
          storeValue,
          buckets,
          blacklistedBuckets,
        };
      });
    });
  });

  return parsedQuizData;
};

const getWinningBucketNum = (slugBucketValues: SlugBucketValues) => {
  const { blacklistedBuckets, values } = slugBucketValues;

  return Object.entries(values).reduce(
    (largestSoFar: string | null, [bucketNum, value]) => {
      const isNotBlacklisted = !blacklistedBuckets[bucketNum];
      const isLarger = !largestSoFar || value > values[largestSoFar];

      return isLarger && isNotBlacklisted ? bucketNum : largestSoFar;
    },
    null,
  );
};

const getWinningSlug = (progress: Progress, quiz: TypeQuiz): string | undefined => {
  const slugBucketValues: SlugBucketValues = {
    blacklistedBuckets: {},
    values: {},
  };

  const parsedQuiz = parseQuizData(quiz);

  const { bucketSlugMap } = parsedQuiz.quizSpecificMetadata;

  if (!bucketSlugMap) {
    return;
  }

  const trackSlugAnswers = (slugAnswersPair: [string, string[]]) => {
    const [slug, answers] = slugAnswersPair;
    const targetQuestion = parsedQuiz[slug];

    // Slug saved in session state not found in contentful quiz, or has no answers applied in contentful
    if (!targetQuestion) {
      return;
    }
    const quizQuestionWeight = targetQuestion.quizSpecificMetadata.weight;

    // Iterate through answers in session storage for a given slug
    answers.forEach(answer => {
      const storeValue = answer.split(':')[0];
      const targetAnswer = targetQuestion.valueMap[storeValue];

      if (!targetAnswer) {
        return;
      }

      // Track bucket values
      targetAnswer.buckets.split(',').forEach(bucket => {
        const bucketInt = parseInt(bucket);

        // 0s indicate an "empty" value
        if (!bucketInt) {
          return;
        }

        slugBucketValues.values[bucketInt] =
          (slugBucketValues.values[bucketInt] || 0) + quizQuestionWeight;
      });

      // Track blacklisted buckets
      targetAnswer.blacklistedBuckets.split(',').forEach(bucket => {
        const bucketInt = parseInt(bucket);

        // 0s indicate an "empty" value
        if (!bucketInt) {
          return;
        }

        slugBucketValues.blacklistedBuckets[bucketInt] = true;
      });
    });
  };

  Object.entries(progress || {}).forEach(trackSlugAnswers);

  // In the map we built, find the slug with highest value that hasn't been blacklisted
  const winningBucketNum = getWinningBucketNum(slugBucketValues);

  if (!winningBucketNum) {
    return;
  }

  return parsedQuiz.quizSpecificMetadata.bucketSlugMap[winningBucketNum];
};

export default getWinningSlug;
