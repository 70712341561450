import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import dynamic from 'next/dynamic';
import React from 'react';
import toCohortFromGenericList from '@ecomm/product-recommendations/utils/toCohortFromGenericList';

const ProductRecommendationsGridV2 = dynamic(
  () => import('@ecomm/product-recommendations/Views/ProductRecommendationsGridV2'),
);

const ProductRecommendationsProductGrid: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = props => {
  const cohort = toCohortFromGenericList(props);

  return <ProductRecommendationsGridV2 cohort={cohort} themeName={props.theme} />;
};

export default ProductRecommendationsProductGrid;
