import { Icon, Media, spacing, Badge, white, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';

type Props = {
  image: string;
};

const FreeClassCardImageResponsive: React.FC<React.PropsWithChildren<Props>> = ({
  image,
}) => {
  const freeClassBadge = useMicroCopy('freeClassBadge');

  return (
    <Wrapper>
      <CardImage
        width="100%"
        objectFit="cover"
        media={{
          alt: '',
          mobile: image,
          loading: 'eager',
          type: 'image',
        }}
      />
      <StyledBadge variant="horizontal" theme="accent">
        {freeClassBadge}
      </StyledBadge>
      <StyledIcon primaryColor={white} secondaryColor={black} name="play" />
    </Wrapper>
  );
};

export default FreeClassCardImageResponsive;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 12px;
  left: 12px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CardImage = styled(Media)`
  border-radius: ${spacing[4]} ${spacing[4]} 0 0;
  height: 175px;
  min-width: 100%;
  overflow: hidden;

  picture img {
    object-fit: cover;
    min-height: 100%;
    max-width: 100%;
    width: 100vw;
  }

  ${media.desktop`
    height: 192px;
  `}
`;
