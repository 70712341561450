import type Slider from '@dior/react-slick';
import {
  grey,
  spacing,
  Badge,
  Container,
  Flex,
  Label,
} from '@pelotoncycle/design-system';
import React, { useState, useRef } from 'react';
import type { FC } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { media } from '@peloton/styles/breakpoints';
import { CarouselGradient } from './CarouselGradient';
import { debounceTimer } from './carouselUtils';
import PaginationButtons from './PaginationButtons';
import PlayPauseToggle from './PlayPauseToggle';
import type { VideoCard as VideoCardProps } from './types';
import VideoCarousel from './VideoCarousel';

const CLICKED_CLASSES_CARD_EVENT_TYPE = 'Clicked Classes Card';

const DesktopTabletHeroCarousel: FC<{
  videoCards: VideoCardProps[];
}> = ({ videoCards }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const totalNumberOfSlides = videoCards.length;

  const goToSlide = (index: number, animate = true) =>
    sliderRef.current?.slickGoTo(index, !animate);

  const beforeSlideChange = (_: number, nextSlide: number) => {
    setActiveSlide(nextSlide);
  };

  const handleNextSlide = () => {
    if (activeSlide === totalNumberOfSlides - 1) {
      goToSlide(activeSlide + 1);
      setActiveSlide(0);
    } else {
      goToSlide(activeSlide + 1);
      setActiveSlide(activeSlide + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (activeSlide === 0) {
      goToSlide(activeSlide - 1);
      setActiveSlide(totalNumberOfSlides - 1);
    } else {
      goToSlide(activeSlide - 1);
      setActiveSlide(activeSlide - 1);
    }
  };

  const debouncedHandleNextSlide = debounceTimer(handleNextSlide);
  const debouncedHanldePreviousSlide = debounceTimer(handlePreviousSlide);

  const { trackEvent } = useTracking();

  const trackClickedClassesCard = (videoCard: VideoCardProps) => {
    trackEvent({
      event: CLICKED_CLASSES_CARD_EVENT_TYPE,
      properties: {
        parent: 'Prospect Classes Page',
        linkTo: `${videoCard.classLandingPageUrl}`,
        linkName: `${videoCard.badgeText ?? ''}`,
        unitName: 'HeroBanner',
        parentType: 'Page',
      },
    });
  };

  return (
    <Container position="relative" margin={`${spacing[2]} 0 0 0`}>
      <PaginationButtons
        activeSlide={activeSlide}
        totalNumberOfSlides={totalNumberOfSlides}
        handleNextSlide={debouncedHandleNextSlide}
        handlePreviousSlide={debouncedHanldePreviousSlide}
      />
      <VideoCarousel beforeChange={beforeSlideChange} slider={sliderRef}>
        {videoCards.map((videoCard, index) => {
          return (
            <Container position="relative" margin={`0 ${spacing[16]} 0 0`} key={index}>
              <Link
                to={videoCard.classLandingPageUrl}
                href={videoCard.classLandingPageUrl}
                hasUnderline={false}
                onClick={() => trackClickedClassesCard(videoCard)}
              >
                <CarouselVideo videoCard={videoCard} isPlaying={isPlaying} />
                <BreakpointSwitchCSS
                  breakpoints={{
                    tabletXLarge: (
                      <TabletFocusState
                        activeSlide={activeSlide}
                        index={index}
                        videoCard={videoCard}
                      />
                    ),
                    desktopLarge: (
                      <DesktopHoverState
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        hoveredIndex={hoveredIndex}
                        index={index}
                        videoCard={videoCard}
                      />
                    ),
                  }}
                />
              </Link>
            </Container>
          );
        })}
      </VideoCarousel>
      <PlayPauseToggle onClick={() => setIsPlaying(!isPlaying)} playing={isPlaying} />
      <CarouselGradient />
    </Container>
  );
};

const DesktopHoverState: FC<
  React.PropsWithChildren<{
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    hoveredIndex: number | null;
    index: number;
    videoCard: VideoCardProps;
  }>
> = ({ onMouseEnter, onMouseLeave, hoveredIndex, index, videoCard }) => (
  <VideoOverlay
    position="absolute"
    style={{ top: 0, left: 0, height: '100%', cursor: 'pointer' }}
    width={{ tablet: '300px', desktop: '300px' }}
    borderRadius={spacing[8]}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    data-test-id={`video-overlay-${index}`}
  >
    {hoveredIndex === index ? (
      <>
        {!!videoCard.badgeText ? (
          <Container margin={`${spacing[16]} 0 0 ${spacing[16]}`}>
            <Badge theme="accent" variant="horizontal">
              {videoCard.badgeText}
            </Badge>
          </Container>
        ) : null}
        <Container
          margin={`0 0 ${spacing[16]} ${spacing[16]}`}
          position="absolute"
          style={{ bottom: 0 }}
        >
          <Flex flexDirection="column" gap={spacing[4]}>
            <Label textColor={grey[30]}>{videoCard.classTitle}</Label>
            <Label textColor={grey[50]}>{videoCard.fitnessDiscipline}</Label>
          </Flex>
        </Container>
      </>
    ) : null}
  </VideoOverlay>
);

const TabletFocusState: FC<
  React.PropsWithChildren<{
    activeSlide: number;
    index: number;
    videoCard: VideoCardProps;
  }>
> = ({ activeSlide, index, videoCard }) => (
  <VideoOverlay
    position="absolute"
    style={{ top: 0, left: 0, height: '100%', cursor: 'pointer' }}
    width={{ tablet: '300px', desktop: '300px' }}
    borderRadius={spacing[8]}
    brighten={activeSlide === index}
    data-test-id={`video-overlay-${index}`}
  >
    {activeSlide === index ? (
      <>
        {!!videoCard.badgeText ? (
          <Container margin={`${spacing[16]} 0 0 ${spacing[16]}`}>
            <Badge theme="accent" variant="horizontal">
              {videoCard.badgeText}
            </Badge>
          </Container>
        ) : null}
        <Container
          margin={`0 0 ${spacing[16]} ${spacing[16]}`}
          position="absolute"
          style={{ bottom: 0 }}
        >
          <Flex flexDirection="column" gap={spacing[4]}>
            <Label textColor={grey[30]}>{videoCard.classTitle}</Label>
            <Label textColor={grey[50]}>{videoCard.fitnessDiscipline}</Label>
          </Flex>
        </Container>
      </>
    ) : null}
  </VideoOverlay>
);

const CarouselVideo: FC<
  React.PropsWithChildren<{
    videoCard: VideoCardProps;
    isPlaying: boolean;
  }>
> = ({ videoCard, isPlaying }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  isPlaying ? videoRef.current?.play() : videoRef.current?.pause();

  return (
    <StyledVideo
      ref={videoRef}
      src={videoCard.videoUrl ?? ''}
      poster={videoCard.posterImage?.mobile ?? ''}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
    />
  );
};

const VideoOverlay = styled(Container)<{
  brighten?: boolean;
}>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);

  ${media.desktopLarge`
    &:hover {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.56%, rgba(0, 0, 0, 0.7) 100%);
    }
  `};

  ${props =>
    props.brighten &&
    'background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.56%, rgba(0, 0, 0, 0.7) 100%)'}
`;

const StyledVideo = styled.video`
  display: block;
  object-fit: cover;
  border-radius: ${spacing[8]};
  height: 450px;
  width: 300px;
`;

export default DesktopTabletHeroCarousel;
