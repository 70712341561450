import {
  Body,
  Eyebrow,
  Flex,
  Grid,
  Icon,
  Media,
  white,
  spacing,
  black,
  grey,
  Container,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media as mediaQuery } from '@peloton/styles';
import type { MediaReferenceType as MediaReference } from '../../../types/referenceTypes';
import {
  borderColor,
  backgroundColor,
  inhaleBlue,
  hoverBackgroundColor,
} from './constants';
import type { OutcomesHeroCTAProps as OutcomesHeroCTA } from './types';

export type OutcomesHeroProps = {
  mediaHeadline?: MediaReference;
  subtext?: string;
  ctas?: OutcomesHeroCTA[];
};

/**
 * This is the OutcomesHero module that will be shown on the homepage behind the variation for the "Homepage outcomes" full-page experiment.
 * EPIC: https://pelotoncycle.atlassian.net/browse/CHAMP-1734
 * - The `mediaHeadline` is an *optional* field. It is intended to support an svg media asset that will be rendered as the headline.
 * - The `subtext` field is an *optional* field. It is the text that will be rendered below the optional svg headline.
 * - The `ctas` field is an *optional* field. It supports the list of ctas that will be displayed below the media and subtext (if they are defined).
 *
 * @param mediaHeadline the expected svg media asset that will be rendered as a headline in this component.
 * @param subtext the subtext that will be rendered below the headline.
 * @param ctas the list of ctas that will be displayed below the media and subtext (if they are defined).
 */
const OutcomesHero: React.FC<OutcomesHeroProps> = ({ mediaHeadline, subtext, ctas }) => {
  const { trackEvent } = useTracking();

  const handleOnClick = (cta: OutcomesHeroCTA, ctaPosition: number) => {
    trackEvent({
      event: 'Clicked Hero CTA on Homepage',
      properties: {
        linkName: cta.text,
        linkTo: cta.link.url,
        parentType: 'OutcomesHero (CHAMP)',
        parent: 'Homepage Hero',
        position: ctaPosition + 1,
        unitName: 'Homepage Outcomes Hero',
      },
    });
  };

  return (
    <OuterContainer
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: `152px ${spacing[80]}`,
      }}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[64],
        desktop: spacing[88],
      }}
      alignItems="center"
      backgroundColor={grey[90]}
    >
      <Flex
        maxWidth="1224px"
        flexDirection="column"
        alignItems="flex-start"
        style={{ width: '100%' }}
      >
        {mediaHeadline && (
          <Container verticalPadding={{ mobile: `0 ${spacing[16]}` }}>
            <Media media={mediaHeadline} />
          </Container>
        )}
        {subtext && (
          <Container
            verticalPadding={{ mobile: `0 ${spacing[16]}`, desktop: `0 ${spacing[32]}` }}
          >
            <Body textColor={white} size="medium">
              {subtext}
            </Body>
          </Container>
        )}
        <Grid
          columnCount={{ mobile: 1, tablet: 2, desktop: 3 }}
          gap={{ mobile: spacing[12], tablet: spacing[16] }}
          width="100%"
        >
          {ctas &&
            ctas.map((cta, index) => (
              <Link
                to={cta.link.url}
                href={cta.link.url}
                onClick={() => handleOnClick(cta, index)}
                hasUnderline={false}
                key={cta.text}
              >
                <CTA data-test-id="outcomes-hero-cta">
                  <IconContainer className="ctaIcon">
                    <StyledIcon name={cta?.ctaIcon as IconNames} height={20} />
                  </IconContainer>
                  <Eyebrow size="medium" textColor={white}>
                    {cta.text}
                  </Eyebrow>
                </CTA>
              </Link>
            ))}
        </Grid>
      </Flex>
    </OuterContainer>
  );
};

const StyledIcon = styled(Icon)`
  path {
    fill: ${white};
  }
`;

const IconContainer = styled.div`
  height: 42px;
  width: 42px;
  background: ${rgba(white, 0.1)};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CTA = styled(Flex)`
  width: 100%;
  height: 58px;
  border-radius: 128px;
  padding: 4px 8px;
  gap: 15px;
  align-items: center;
  backdrop-filter: blur(42px);
  justify-content: flex-start;
  background: ${backgroundColor};
  background-blend-mode: saturation, normal, normal;
  box-shadow: 0px 4px 12px 0px rgba(127, 102, 255, 0.12);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mask: linear-gradient(${black} 0 0) padding-box, linear-gradient(${black} 0 0);
    mask-composite: exclude;
    border-radius: 128px;
    border: 1.5px solid transparent;
    background: linear-gradient(270deg, ${borderColor}) border-box;
  }

  // disable hover styling on touch devices
  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 5px 32px 0px ${rgba(black, 0.24)};
      background: ${hoverBackgroundColor};
      transition: all 300ms ease-in-out;

      &::before {
        border: 2.5px solid transparent;
        transition: all 300ms ease-in-out;
      }

      .ctaIcon {
        background: ${rgba(inhaleBlue, 1)};
        transition: all 300ms ease-in-out;
      }
    }
  }

  ${mediaQuery.desktopLarge`
    height: 64px;
    `}
`;

export default OutcomesHero;
