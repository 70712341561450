import {
  spacing,
  Flex,
  Display,
  Body,
  Container,
  Support,
  Grid,
  Media,
  FlexChild,
} from '@pelotoncycle/design-system';
import { imageFieldsToMediaImageProps, WithMarkdown } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import ModalView from '@ecomm/modal/ModalView';
import { ScrollFromBottomModalStyles } from '@ecomm/modal/ScrollFromBottomModalStyles';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { toThemeValue } from '@ecomm/product-recommendations/utils/theme';
import useDetailsUrl from '@ecomm/product-recommendations/utils/useDetailsUrl';
import PriceDisplay from '@ecomm/product-recommendations/Views/PriceDisplay';
import ProductPills from '@ecomm/product-recommendations/Views/ProductPills';
import { media } from '@page-builder/utils/helpers';

type Props = {
  product: Product;
  isOpen: boolean;
  onClose: () => void;
};

const PackageContentsModal: React.FC<Props> = ({ product, isOpen, onClose }) => {
  const whatsIncluded = useMicroCopy('whatsIncluded');

  if ((product.packageContents || []).length === 0) {
    return null;
  }

  return (
    <>
      <ScrollFromBottomModalStyles />
      <StyledModal
        contentLabel={`${product.name} ${whatsIncluded}`}
        contentClassname="scroll-from-bottom-modal"
        className="quick-view-modal"
        isOpen={isOpen}
        closeHandler={onClose}
        width={960}
      >
        <Flex gap={spacing[16]} flexDirection="column">
          <Flex
            padding={{
              mobile: `${spacing[32]} ${spacing[16]} 0`,
              desktop: spacing[40],
            }}
            flexDirection="column"
            gap={{
              mobile: spacing[32],
              desktop: spacing[40],
            }}
          >
            <ModalHeader {...product} />
            <ModalContents {...product} />
          </Flex>
          <MobileStickyFooter
            display={{
              mobile: 'block',
              desktop: 'none',
            }}
            horizontalPadding={spacing[16]}
            verticalPadding={spacing[16]}
          >
            <ShopCta {...product} />
          </MobileStickyFooter>
        </Flex>
      </StyledModal>
    </>
  );
};

const ModalHeader: React.FC<Product> = product => {
  return (
    <Flex gap={spacing[8]} flexDirection="column">
      <ProductPills product={product} floating={false} />
      <Flex flexDirection="column" gap={spacing[16]}>
        <Flex
          flexDirection={{
            mobile: 'column',
            desktop: 'row',
          }}
          gap={spacing[8]}
          alignItems={{
            desktop: 'center',
          }}
          justifyContent="space-between"
        >
          <Display size="small">{product.name}</Display>
          <Flex gap={spacing[16]} alignItems="center">
            <Body size="medium">
              <PriceDisplay product={product} />
            </Body>
            <Container
              display={{
                mobile: 'none',
                desktop: 'block',
              }}
            >
              <ShopCta {...product} />
            </Container>
          </Flex>
        </Flex>
        {product.packageContentsDescription && (
          <Support size="large">{product.packageContentsDescription}</Support>
        )}
      </Flex>
    </Flex>
  );
};

const ModalContents: React.FC<Product> = product => {
  return (
    <ContentsGrid
      columnCount={{
        mobile: 1,
        desktop: 3,
      }}
    >
      {product.packageContents!.map(({ image, description }, idx) => {
        const mediaProps = imageFieldsToMediaImageProps(image);

        return (
          <Flex
            key={idx}
            flexDirection={{ mobile: 'row', desktop: 'column' }}
            gap={spacing[16]}
          >
            <FlexChild flex="3.5">
              <ContentsMedia media={mediaProps} />
            </FlexChild>
            <FlexChild flex="6.5">
              <Support size="small">
                <WithMarkdown text={description} />
              </Support>
            </FlexChild>
          </Flex>
        );
      })}
    </ContentsGrid>
  );
};

const ShopCta: React.FC<Product> = product => {
  const productUrl = useDetailsUrl(product);
  const label = useMicroCopy('shopNowCtaLabel');
  const { trackEvent } = useTracking();

  const onClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName: `${product.name} Modal`,
        linkName: label,
        linkTo: productUrl,
      },
    });
  };

  return (
    <LinkButton href={productUrl} onClick={onClick} size="small" width="adaptive">
      {label}
    </LinkButton>
  );
};

// This is currently using the legacy @ecomm/modal, should ideally use NextModal
const StyledModal = styled(ModalView)`
  background-color: ${toThemeValue('backgroundColor')};
  border-radius: ${spacing[12]} ${spacing[12]} 0px 0px;
  text-align: left;
  padding: 0;

  ${media.desktop`
    max-width: 960px;
    border-radius: ${spacing[4]};
  `}
`;

const ContentsGrid = styled(Grid)`
  gap: ${spacing[16]};

  ${media.desktop`
    row-gap: ${spacing[40]};
  `}
`;

const ContentsMedia = styled(Media)`
  aspect-ratio: 1;
  background: ${toThemeValue('imageBackground')};
  border-radius: ${spacing[4]};
  overflow: hidden;
`;

const MobileStickyFooter = styled(Container)`
  border-top: 1px solid ${toThemeValue('dividerColor')};
  position: sticky;
  bottom: 0;
  background-color: ${toThemeValue('backgroundColor')};
`;

export default PackageContentsModal;
