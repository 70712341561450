import { green } from '@pelotoncycle/design-system';
import type { TypeBundlePromo } from '@pelotoncycle/page-builder';
import React from 'react';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import { useGetCommercetoolsCFU } from '@ecomm/commercetools/hooks/useGetCommercetoolsCFU';
import { toDollars } from '@ecomm/models';
import useConcessionaryPricing from '@ecomm/pg-concessionary-pricing/useConcessionaryPricing';
import type { CfuAndBundlePromoProps, SpecialPricingOptions } from '../types';
import { toDisplayPrices } from '../utils';

type CommercetoolsPromoPriceProps = CfuAndBundlePromoProps & {
  bundlePromo?: TypeBundlePromo;
};

export const CommercetoolsPromoPrice: React.FC<
  React.PropsWithChildren<CommercetoolsPromoPriceProps>
> = ({ cfu, bundleSlug, bundlePromo }) => {
  const { data: productPackageData } = useGetCommercetoolsCFU(bundleSlug);
  const basePrice = productPackageData?.catalog.packageBySlug.price.amount;

  if (!basePrice) return null;

  if (bundlePromo) {
    const discount = bundlePromo.fields.couponDiscount;

    return <PromoPriceDisplay price={basePrice} discount={discount} />;
  }

  return <PromoPrice cfu={cfu} bundleSlug={bundleSlug} price={basePrice} />;
};

export type PromoPriceProps = CfuAndBundlePromoProps & {
  price: number;
  specialPricingOptions?: SpecialPricingOptions;
};

const PromoPrice: React.FC<React.PropsWithChildren<PromoPriceProps>> = ({
  cfu,
  bundleSlug,
  price,
  specialPricingOptions = undefined,
}) => {
  const { data } = useBundlePromo(cfu, bundleSlug);
  let discount = data?.fields.couponDiscount;

  const { toDiscountForCFU } = useConcessionaryPricing(
    specialPricingOptions?.requireVerification ?? false,
  );

  const specialPricingDiscount = Boolean(specialPricingOptions)
    ? toDiscountForCFU(cfu)
    : undefined;

  if (specialPricingOptions?.ignorePromo) {
    discount = 0;
  }

  return (
    <PromoPriceDisplay
      price={price}
      discount={discount}
      specialPricingDiscount={specialPricingDiscount}
    />
  );
};

type PriceDisplayProps = {
  price: number;
  discount?: number;
  specialPricingDiscount?: number;
};

export const PromoPriceDisplay = ({
  price: originalBasePrice,
  discount,
  specialPricingDiscount,
}: PriceDisplayProps) => {
  const toFormattedText = useGetTextFormatter();

  const formatPrice = (price: number) => {
    return toFormattedText('{price, number, currency}', {
      price: toDollars(price),
    });
  };

  if (!discount && !specialPricingDiscount) {
    return <span data-testid="base-price">{formatPrice(originalBasePrice)}</span>;
  }

  const { basePrice, discountPrice } = toDisplayPrices({
    basePrice: originalBasePrice,
    discount,
    specialPricingDiscount,
  });
  return (
    <>
      <span data-testid="discounted-price" style={{ color: green[50] }}>
        {formatPrice(discountPrice)}
      </span>{' '}
      <s data-testid="base-price">{formatPrice(basePrice)}</s>
    </>
  );
};

export default PromoPrice;
