import type { ToggleProps } from '@pelotoncycle/design-system';
import {
  Toggle,
  grey,
  black,
  spacing,
  white,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { PageBuilderTheme } from '@ecomm/product-recommendations/utils/theme';
import { DEFAULT_THEME_NAME } from '@ecomm/product-recommendations/utils/theme';
import { useFiltersContext } from '@ecomm/product-recommendations/Views/FiltersContext';

type Props = {
  cohortKeyName: string;
  themeName: PageBuilderTheme;
};

const toggleThemes = {
  ['White']: {
    backgroundColor: grey[30],
    textColor: grey[90],
    toggleTheme: 'light',
  },
  ['Grey 90']: {
    backgroundColor: grey[90],
    textColor: white,
    toggleTheme: 'dark',
  },
};

const ToggleFilters: React.FC<Props> = ({ cohortKeyName, themeName }) => {
  const {
    hasAnyFilterGroups,
    filterGroups,
    enabledFilterTags,
    setEnabledFilterTags,
  } = useFiltersContext();
  const { trackEvent } = useTracking();

  if (!hasAnyFilterGroups) {
    return null;
  }

  const [{ title, filters }] = filterGroups;
  const [leftButton, rightButton] = filters;

  const { backgroundColor, textColor, toggleTheme } =
    toggleThemes[themeName] || toggleThemes[DEFAULT_THEME_NAME];

  const onToggleChange: ToggleProps['onChange'] = isLeftButtonActive => {
    const activeFilter = isLeftButtonActive ? leftButton : rightButton;
    setEnabledFilterTags([activeFilter.tag]);

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'ProductGrid (CHAMP)',
        parent: cohortKeyName,
        unitName: title,
        linkName: activeFilter.displayName || activeFilter.tag,
      },
    });
  };

  return (
    <StyledToggle
      aria-label={title}
      backgroundColor={backgroundColor}
      inactiveEyebrowColor={textColor}
      inactiveLabelColor={grey[70]}
      leftButtonActive={enabledFilterTags.includes(leftButton.tag)}
      leftButton={{
        eyebrowText: leftButton.displayName || leftButton.tag,
      }}
      rightButton={{
        eyebrowText: rightButton.displayName || rightButton.tag,
      }}
      onChange={onToggleChange}
      theme={toggleTheme}
    />
  );
};

const StyledToggle = styled(Toggle)`
  div {
    padding: 0 ${spacing[32]};
    height: ${spacing[64]};
    justify-content: center;

    :not(:active):hover {
      p {
        color: ${grey[70]} !important;
        -webkit-transition: color 0.1s ease-in-out 0.1s;
        transition: color 0.1s ease-in-out 0.1s;
      }
    }
  }
  :after {
    border: 2px solid ${black};
    border-radius: 3.125rem;
    box-shadow: unset;
  }

  align-self: flex-start;
  border-radius: 3.125rem;
  width: 100%;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    width: auto;
  }
`;

export default ToggleFilters;
