import type { NextRouter } from 'next/router';
import type useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import { LegacyEquipmentType } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import { BundleType } from '@ecomm/shop/models/BundleType';

export const isProductInCTCart = (
  cart: GetShopCartJupiterQuery['shopCart'],
  productType: LegacyEquipmentType,
) => {
  return cart?.lineItems.some(
    item => item.productVariant.legacyEquipmentType === productType,
  );
};

export const useShouldShowSubnav = (
  router: NextRouter,
  ctCart?: GetShopCartJupiterQuery['shopCart'],
  legacyData?: ReturnType<typeof useCartWithVariables>['data'],
) => {
  const { isReady, query } = router;

  if (!isReady) {
    return false;
  }
  const { cart: legacyCart } = legacyData || {};

  const cfuInCartMap = {
    [BundleType.RefurbishedBike]: {
      buy:
        legacyCart?.hasRefurbBike ||
        isProductInCTCart(ctCart, LegacyEquipmentType.RefurbBike),
      rent: false,
    },
    [BundleType.RefurbishedBikePlus]: {
      buy:
        legacyCart?.hasRefurbBikePlus ||
        isProductInCTCart(ctCart, LegacyEquipmentType.RefurbBikePlus),
      rent: false,
    },
    [BundleType.Bike]: {
      buy: legacyCart?.hasBike || isProductInCTCart(ctCart, LegacyEquipmentType.Bike),
      //   legacyCart doesn't have a hasBikeLease property so we check for both an equipemnt least and a bike
      rent: legacyCart?.hasEquipmentLease && legacyCart?.hasBike,
    },
    [BundleType.BikePlus]: {
      buy:
        legacyCart?.hasBikePlus ||
        isProductInCTCart(ctCart, LegacyEquipmentType.BikePlus),
      rent: legacyCart?.hasBikePlusLease,
    },
    [BundleType.Tread]: {
      buy: legacyCart?.hasTread || isProductInCTCart(ctCart, LegacyEquipmentType.Tread),
      rent: false,
    },
    [BundleType.TreadPlus]: {
      buy:
        legacyCart?.hasTreadPlus ||
        isProductInCTCart(ctCart, LegacyEquipmentType.TreadPlus),
      rent: false,
    },
    [BundleType.Row]: {
      buy: legacyCart?.hasRow || isProductInCTCart(ctCart, LegacyEquipmentType.Row),
      rent: false,
    },
    [BundleType.RainforestCafe]: {
      buy: legacyCart?.hasGuide || isProductInCTCart(ctCart, LegacyEquipmentType.Guide),
      rent: false,
    },
  };

  //   route pattern of upsell interstitial pages: /shop/[cfu]/[rent or nothing]/complete-cart
  const [, cfu, potentialRentIndicator] = query.slugs as [
    string,
    keyof typeof cfuInCartMap,
    'rent' | 'complete-cart',
  ];
  const purchaseMethod: 'buy' | 'rent' =
    !potentialRentIndicator || potentialRentIndicator === 'complete-cart'
      ? 'buy'
      : potentialRentIndicator;

  return Boolean(cfuInCartMap[cfu]?.[purchaseMethod]);
};
