import type { ParsedUrlQuery } from 'querystring';
import React from 'react';
import type { TypeQuizQuestion } from '@page-builder/lib/types';
import { InstructorsContext } from '../components/InstructorsProvider';
import { ProgressContext } from '../components/ProgressProvider';

const GROUP_ANSWER_SEPARATOR = ':';

export const useFormProgress = () => React.useContext(ProgressContext);

export const useInstructors = () => React.useContext(InstructorsContext);

const getSlugsFromQuestions = (questions: TypeQuizQuestion[]) => {
  return questions.map(question => {
    const slugArray = question.fields.slug.split('/');
    return slugArray[slugArray.length - 1];
  });
};

const buildBaseSlug = (slug: string) => {
  return '/' + slug + '/';
};

export const getQueryParams = (questions: TypeQuizQuestion[], query: ParsedUrlQuery) => {
  const params = {};
  const slugs: string[] = getSlugsFromQuestions(questions);

  if (query && query.slugs) {
    for (const queryParam in query) {
      if (slugs.includes(queryParam)) {
        params[buildBaseSlug(query.slugs[0]) + queryParam] = String(
          query[queryParam],
        ).split(',');
      }
    }
  }

  return params;
};

export const useAnswersForQuestion = (slug: any) => {
  const { progress, setProgress } = useFormProgress();

  const saveAnswers = React.useCallback(
    (answers: string[]) => {
      setProgress(prevProgress => ({
        ...prevProgress,
        [slug]: answers,
      }));
    },
    [slug, setProgress],
  );

  const saveGroupAnswer = React.useCallback(
    (group: string, answer: string) => {
      setProgress(prevProgress => {
        const prevAnswers: string[] = prevProgress[slug] || [];

        const newAnswers = prevAnswers
          .filter(a => !a.startsWith(group))
          .concat(toGroupAnswer(group, answer));

        return {
          ...prevProgress,
          [slug]: newAnswers,
        };
      });
    },
    [slug, setProgress],
  );

  const toggleAnswer = React.useCallback(
    (
      answer: string,
      options: { resetOtherQuestion?: string; exclusiveAnswer?: string } = {},
    ) => {
      setProgress(prevProgress => {
        const { resetOtherQuestion, exclusiveAnswer } = options;
        const prevAnswers: string[] = prevProgress[slug] || [];

        let newAnswers = prevAnswers.includes(answer)
          ? prevAnswers.filter(a => a !== answer)
          : prevAnswers.concat(answer);

        if (exclusiveAnswer) {
          // If the user has toggled *ON* the exclusive answer, we filter anything *except* the exclusive answer
          if (answer === exclusiveAnswer) {
            newAnswers = newAnswers.filter(a => a === exclusiveAnswer);
          } else {
            // Otherwise, the user has selected a different answer, and we remove *only* the exclusive answer
            newAnswers = newAnswers.filter(a => a !== exclusiveAnswer);
          }
        }

        const resetOtherQuestionProgress = resetOtherQuestion
          ? {
              [resetOtherQuestion]: [],
            }
          : {};

        return {
          ...prevProgress,
          ...resetOtherQuestionProgress,
          [slug]: newAnswers,
        };
      });
    },
    [slug, setProgress],
  );

  const answers = React.useMemo(() => {
    return progress[slug] || [];
  }, [progress, slug]);

  const groupAnswers = React.useMemo(() => {
    return toAnswerGroups(answers);
  }, [answers]);

  return { answers, groupAnswers, saveAnswers, saveGroupAnswer, toggleAnswer };
};

export const toGroupAnswer = (group: string, answer: string) => {
  return [group, answer].join(GROUP_ANSWER_SEPARATOR);
};

export const toAnswerGroups = (answers: string[]) => {
  const groups: Record<string, string[]> = {};

  for (const answer of answers) {
    if (answer.includes(GROUP_ANSWER_SEPARATOR)) {
      const [group, groupedAnswer] = answer.split(GROUP_ANSWER_SEPARATOR);

      const existingGroupAnswers = groups[group] || [];

      groups[group] = [...existingGroupAnswers, groupedAnswer];
    }
  }

  return groups;
};
