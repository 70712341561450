import { BreakpointWidths, white, spacing } from '@pelotoncycle/design-system';
import styled, { css } from 'styled-components';
import { themes } from './theme';
import type { Direction } from './types';

const CAROUSEL_ITEM_HEIGHT = 68;
const CAROUSEL_ITEM_BORDER = 1;
const CAROUSEL_ARROW_HEIGHT = CAROUSEL_ITEM_HEIGHT + 2 * CAROUSEL_ITEM_BORDER;

export const CarouselContainer = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 500px;
  padding: ${spacing[16]} 0;
  position: relative;
  width: 100%;

  .carousel {
    display: flex;
    justify-content: center;

    // rendered by react-carousel
    [data-testid='sliderList'] {
      gap: ${spacing[8]};
      display: grid;
      grid-auto-flow: column;

      @media (min-width: ${BreakpointWidths.desktop}px) {
        gap: ${spacing[16]};
      }
    }
  }
`;

export const Category = styled.button<{ active: boolean; theme: 'White' | 'Grey 90' }>`
  border-radius: 10px;
  border: ${CAROUSEL_ITEM_BORDER}px solid transparent;
  color: ${white};
  display: flex;
  background: ${({ theme }) => themes[theme].categoryBackgroundColor};

  &:hover {
    background: ${({ theme }) => themes[theme].categoryHoverColor};
  }

  ${({ active, theme }) =>
    active &&
    `border: solid ${CAROUSEL_ITEM_BORDER}px ${themes[theme].categoryBorderColor}; color: ${themes[theme].categoryBorderColor};`};

  svg {
    margin-bottom: ${spacing[8]};

    path {
      fill: ${({ theme }) => themes[theme].iconColor};
      color: ${({ theme }) => themes[theme].iconColor};
    }
  }
`;

export const CategoryContent = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  width: 106px;
  color: ${white};
  height: ${CAROUSEL_ITEM_HEIGHT}px;

  @media (max-width: ${BreakpointWidths.tablet}px) {
    width: 88px;
  }
`;

export const Arrow = styled.button<{ direction: Direction; theme: 'White' | 'Grey 90' }>`
  align-items: center;
  display: flex;
  height: ${CAROUSEL_ARROW_HEIGHT}px;
  position: absolute;
  background-color: ${({ theme }) => themes[theme].backgroundColor};
  z-index: 1;
  ${({ direction, theme }) =>
    `${
      direction === 'left'
        ? `left: 0px; padding-right: ${spacing[12]}; border-right: solid 1px ${themes[theme].arrowBorderColor};`
        : `right: 0px; padding-left: ${spacing[12]}; border-left: solid 1px ${themes[theme].arrowBorderColor};`
    }`};

  path {
    width: ${spacing[8]};
    height: 16px;
    ${({ direction }) =>
      `${
        direction === 'left' ? `transform: 0` : `transform: scaleX(-1) translateX(-22px)`
      }`};
  }
`;

export const classImageStyles = css`
  height: 72px;
  width: 112px;
  border-radius: 8px;
`;

export const skeletonAnimationStyles = ({ theme }: { theme: 'White' | 'Grey 90' }) => css`
  animation: loading 0.75s ease-in-out alternate infinite;

  @keyframes loading {
    0% {
      background-color: ${themes[theme].loadingColor0};
    }
    100% {
      background-color: ${themes[theme].loadingColor100};
    }
  }
`;
