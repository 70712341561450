import useIsToggleActive from './hooks/useIsToggleActive';
import useToggleVariation from './hooks/useToggleVariation';

const X_SELL_INTERSTITIAL_TEST_FLAG = 'cross_sell_interstitial';

export const useCrossSellInterstitialToggle = () => {
  const isExperimentActive = useIsToggleActive()(X_SELL_INTERSTITIAL_TEST_FLAG);
  const activeVariation = useToggleVariation(X_SELL_INTERSTITIAL_TEST_FLAG);

  return {
    isExperimentActive,
    activeVariation,
  };
};
