import { Tabs } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { TypeComponentTabs } from '@page-builder/lib/types';

const TabsContainer: React.FC<React.PropsWithChildren<TypeComponentTabs>> = props => {
  const { trackEvent } = useTracking();
  const { header, subHeader } = React.useContext(GlobalReferenceContext);
  const headerOrSubheaderTop = subHeader?.height || header?.height;

  const onClick = (index: number) => {
    const {
      fields: { name: componentName },
    } = props;
    const {
      fields: { name: tabName, tabHeader },
    } = props.fields.tabItem[index];

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Tabs',
        parent: componentName,
        unitName: `${componentName} - Tab ${index + 1}`,
        linkName: tabName,
        linkTo: tabHeader,
      },
    });
  };

  return <Tabs topOverride={headerOrSubheaderTop} onClick={onClick} {...props} />;
};

export default TabsContainer;
