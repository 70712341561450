import {
  Grid,
  Eyebrow,
  Headline,
  Container,
  Button,
  Flex,
  Body,
  spacing,
  white,
  BreakpointWidths,
  grey,
  black,
} from '@pelotoncycle/design-system';
import { ctaExtractData, OuterContainer } from '@pelotoncycle/page-builder';
import React, { useContext, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { PrevArrowIcon, NextArrowIcon } from '@ecomm/themed-modules/VideoGallery/icons';
import PageContext from '@page-builder/layout/context/PageContext';
import LinkRenderer from '@page-builder/layout/MarkdownPage/renderers/LinkRenderer';
import type {
  TypeComponentGenericListFields,
  TypeComponentVideo,
} from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toLightboxVideoProps } from '@page-builder/utils/helpers/lightbox';
import { imageFieldsToMediaImageProps } from '@page-builder/utils/helpers/media';
import TrackedVideo from '@page-builder/utils/TrackingVideoElement';
import CarouselCard from '../CarouselCard';
import VideoModal from '../VideoModal';

const defaults = {
  theme: 'Grey 90',
} as const;

const ClassesTeasers: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text, ctas, theme = defaults.theme, treatment }) => {
  const { textColor, backgroundColor, headlineColor } = themes[theme];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const { trackEvent } = useTracking();
  const { additionalTrackingData } = useContext(PageContext);
  const nextLabel = useMicroCopy('nextButtonLabel');
  const previousLabel = useMicroCopy('previousButtonLabel');
  const { eyebrow, headline } = getTextFieldsWithRequiredKeys(['headline'], text);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);

  const trailers = items.map(item => {
    const video = item.fields.media?.fields.media as TypeComponentVideo;
    const poster = imageFieldsToMediaImageProps(video.fields.poster.fields);
    const contentId = item.fields.name;
    const { label, url, tracks, campaignName } = toLightboxVideoProps(video);
    const { headline: title } = getTextFieldsWithRequiredKeys(
      ['headline'],
      item.fields.text,
    );

    return {
      title,
      label,
      url,
      poster,
      tracks,
      videoMetadata: {
        properties: {
          campaignName: campaignName || '',
          contentId: contentId,
          title: title,
          contentPosition: `video${videoIndex + 1}`,
          ...additionalTrackingData,
        },
      },
    };
  });

  const mobileGridColumnCount = trailers.length < 4 ? 1 : 2;
  const desktopGridColumnCount = treatment === 'Classes Teasers Columns' ? 3 : 4;

  const openNextModal = () => {
    const nextIndex = videoIndex + 1;
    if (nextIndex < trailers.length) {
      setVideoIndex(nextIndex);
    } else {
      setVideoIndex(0);
    }
  };

  const openPreviousModal = () => {
    const nextIndex = videoIndex - 1;
    if (nextIndex >= 0) {
      setVideoIndex(nextIndex);
    } else {
      setVideoIndex(trailers.length - 1);
    }
  };

  const ArrowIcon: React.FC<React.PropsWithChildren<{ direction: 'prev' | 'next' }>> = ({
    direction,
  }) => {
    return direction === 'prev' ? (
      <button
        onClick={openPreviousModal}
        data-test-id={`prev-button-${videoIndex}`}
        aria-label={previousLabel}
      >
        <PrevArrowIcon height={32} />
      </button>
    ) : (
      <button
        onClick={openNextModal}
        data-test-id={`next-button-${videoIndex}`}
        aria-label={nextLabel}
      >
        <NextArrowIcon height={32} />
      </button>
    );
  };

  return (
    <OuterContainer
      aria-live="polite"
      id="classesTeasersSection"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor={backgroundColor}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[64],
        desktop: spacing[40],
      }}
      theme={theme}
    >
      {eyebrow && (
        <Eyebrow marginBottom={spacing[16]} textColor={textColor}>
          {eyebrow}
        </Eyebrow>
      )}
      <StyledHeadline size="small" textColor={headlineColor} textAlign="center">
        {headline}
      </StyledHeadline>
      <StyledGrid
        columnCount={{
          mobile: mobileGridColumnCount,
          tablet: mobileGridColumnCount,
          desktop: Math.min(trailers.length, desktopGridColumnCount),
        }}
        margin={{
          mobile: `${spacing[32]} 0 0`,
          tablet: `${spacing[40]} 0 0`,
          desktop: `${spacing[56]} 0 0`,
        }}
        gap={{
          mobile: spacing[16],
          tablet: spacing[24],
        }}
      >
        {trailers.map(({ title, poster }, index) => {
          const openModal = () => {
            setVideoIndex(index);
            setIsModalOpen(true);
            trackEvent({
              event: 'Video Content Opened',
              ...trailers[videoIndex].videoMetadata,
            });
          };

          return (
            <button
              key={`trailerTile_${index}`}
              onClick={openModal}
              data-test-id={`videoModalButton_${index}`}
            >
              <StyledCard
                title={title}
                image={poster}
                index={index}
                shadowColor={theme === 'Black' ? grey[70] : black}
              />
            </button>
          );
        })}
      </StyledGrid>
      <Container
        margin={{
          mobile: `${spacing[48]} auto 0`,
          desktop: `${spacing[64]} auto 0`,
        }}
        textAlign="center"
      >
        {primaryCta && primaryCta.url && (
          <LinkRenderer href={primaryCta.url}>
            <Button
              variant={primaryCta.variant}
              color={primaryCta.color}
              data-test-id={`classTeasers_cta`}
            >
              {primaryCta.text}
            </Button>
          </LinkRenderer>
        )}
      </Container>
      <VideoModal
        closeHandler={() => {
          setIsModalOpen(false);
          trackEvent({
            event: 'Video Content Closed',
            properties: {
              ...trailers[videoIndex].videoMetadata.properties,
              length: {},
              currentTime: {},
            },
          });
        }}
        isOpen={isModalOpen}
        darkMode={true}
        contentLabel={trailers[videoIndex].label}
        withContent
      >
        <Flex
          id="classesTeasersContainer"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding={{
            desktop: `0 ${spacing[40]}`,
          }}
        >
          <VideoControls
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            justifyContent="center"
            alignItems="center"
            gap={{ desktop: spacing[24] }}
          >
            <ArrowIcon direction="prev" />
            <div>
              <StyledTrackedVideo
                src={trailers[videoIndex].url}
                tracks={trailers[videoIndex].tracks}
                autoPlay
                controls
                eventProps={{
                  ...trailers[videoIndex].videoMetadata,
                }}
              />
              <DesktopVideoInfoContainer>
                <DesktopVideoInfoContent>
                  <StyledHeadlineDesktop size="medium">
                    {trailers[videoIndex].title}
                  </StyledHeadlineDesktop>
                  <Body size="small">{`${videoIndex + 1} of ${trailers.length}`}</Body>
                </DesktopVideoInfoContent>
              </DesktopVideoInfoContainer>
            </div>
            <ArrowIcon direction="next" />
            <MobileControls>
              <ArrowIcon direction="prev" />
              <VideoInfo>
                <StyledBody>{trailers[videoIndex].title}</StyledBody>
                <Eyebrow size="small">
                  {`${videoIndex + 1} of ${trailers.length}`}
                </Eyebrow>
              </VideoInfo>
              <ArrowIcon direction="next" />
            </MobileControls>
          </VideoControls>
        </Flex>
      </VideoModal>
    </OuterContainer>
  );
};

export default ClassesTeasers;

const StyledGrid = styled(Grid)`
  width: 100%;
  max-width: 1224px;
`;

const VideoControls = styled(Flex)`
  > button {
    display: none;
  }

  @media (min-width: ${BreakpointWidths.desktop}px) {
    > button {
      display: inline-flex;
    }
  }
`;

const StyledHeadline = styled(Headline)`
  @media (min-width: ${BreakpointWidths.desktop}px) {
    max-width: 680px;
  }
`;

const StyledHeadlineDesktop = styled(Headline)`
  padding-bottom: ${spacing[16]};
`;

const StyledTrackedVideo = styled(TrackedVideo)`
  width: 100%;
  max-width: 1280px;
`;

const StyledCard = styled(CarouselCard)`
  margin: 0;
  overflow: hidden;
  max-width: unset;
`;

const MobileControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing[32]} ${spacing[32]} 0 ${spacing[32]};
  width: 100%;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    display: none;
  }
`;

const DesktopVideoInfoContainer = styled.div`
  display: none;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledBody = styled(Body)`
  padding-bottom: ${spacing[16]};
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${white};
`;

const DesktopVideoInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${white};
`;
