import { Flex, spacing, brand } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';

export const TextContainer = styled(Flex)`
  @media (max-width: ${BreakpointWidth.desktopLarge}px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const HeadbandContainer = styled(Flex)`
  align-items: center;
  white-space: pre;

  @media (max-width: ${BreakpointWidth.desktopLarge}px) {
    white-space: normal;
  }
  h2 {
    height: 100%;
  }
`;

export const Container = styled(Flex)<{ darkMode?: boolean }>`
  align-items: center;
  white-space: pre;
  margin-bottom: ${({ darkMode }) => (darkMode ? 0 : spacing[8])};

  @media (max-width: ${BreakpointWidth.desktopLarge}px) {
    background: ${({ darkMode }) => (darkMode ? 'rgba(0, 0, 0, 0.6)' : 'none')};
    flex-flow: wrap;
    border-radius: 25px;
    justify-content: center;
    margin-bottom: ${({ darkMode }) => (darkMode ? spacing[24] : spacing[8])};
    padding: ${({ darkMode }) => (darkMode ? spacing[8] : 0)};
    white-space: pre;
    width: 100%;
  }
  h2 {
    height: ${({ darkMode }) => (darkMode ? '100%' : 'auto')};
  }
`;

export const MemberAvatar = styled.div<{
  avatarUrl: string;
  border?: boolean;
  darkMode?: boolean;
}>`
  background: center no-repeat url(${({ avatarUrl }) => avatarUrl});
  background-size: contain;
  border: 1px solid ${({ border }) => (border ? brand.accent : 'none')};
  border-radius: 50%;
  height: 33px;
  margin-right: ${({ darkMode }) => (darkMode ? spacing[16] : spacing[8])};
  width: 33px;
`;
