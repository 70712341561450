/**
 * @param basePrice - the base price (expected to be in cents)
 * @param discount - the discount (expected to be in dollars)
 * @param specialPricingDiscount - the special pricing discount (expected to be in dollars)
 * @returns { basePrice: number, discountPrice: number}
 */
export const toDisplayPrices = ({
  basePrice,
  discount = 0,
  specialPricingDiscount = 0,
}: {
  basePrice: number;
  discount?: number;
  specialPricingDiscount?: number;
}) => {
  const discountCents = discount * 100;
  const specialPricingDiscountInCents = specialPricingDiscount * 100;

  const isPriceDrop = discountCents < 0;

  /*
    When the discount is negative, the promo is a price drop and the basePrice is actually the discounted price.
    The discount can be used to calculate the actual base price.
    */
  if (isPriceDrop) {
    // Calculate the postive value of the discount to compare it to the special pricing discount.
    const postitiveDiscountCents = Math.abs(discountCents);
    const calculatedBasePrice = basePrice - discountCents;

    // If the price drop is a greater value than the special pricing discount, return the calculated base price, and the actual discount.
    if (postitiveDiscountCents > specialPricingDiscountInCents) {
      return {
        basePrice: calculatedBasePrice,
        discountPrice: basePrice,
      };
    } else {
      /* 
        If the value of special pricing discount is greater than the price drop, return the calculated base price, 
        and the discount price as the calculated base price minus the special pricing discount.
       */
      return {
        basePrice: calculatedBasePrice,
        discountPrice: calculatedBasePrice - specialPricingDiscountInCents,
      };
    }
  }

  const greaterDiscountCents = toActiveDiscount({
    discount: discountCents,
    specialPricingDiscount: specialPricingDiscountInCents,
  });

  return {
    basePrice,
    discountPrice: basePrice - greaterDiscountCents,
  };
};

export const toActiveDiscount = ({
  discount,
  specialPricingDiscount,
}: {
  discount: number;
  specialPricingDiscount: number;
}) => {
  /*
    https://pelotoncycle.atlassian.net/browse/EGE-1071

    Always return the greater of the two values between the promo and special pricing.
    */
  return Math.max(Math.abs(discount), specialPricingDiscount);
};
