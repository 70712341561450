import type {
  LightboxEventListener,
  LinkClickListener,
  LeadGenSubmitListener,
} from '@pelotoncycle/page-builder';
import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { useErrorReporter } from '@peloton/error-reporting';
import { toCountry } from '@peloton/internationalize';
import { TrackingEvent } from '@ecomm/analytics/models';
import LeadGenInputError from '@page-builder/utils/errors/LeadGenInputError';

/**
 * Use WWW specific Page Builder listeners for tracking
 * @returns {object} onLinkClick, onLightboxEvent, onLeadGenSubmit handlers
 */
const usePageBuilderListeners = () => {
  const { trackEvent } = useTracking();
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const onLinkClick: LinkClickListener = useCallback(
    ({ event, cta = {}, config = {}, additionalProps = {} }) => {
      const { url, text, name } = cta;
      const { parentName, parentId } = config;
      trackEvent({
        event: event ?? 'Clicked Link',
        properties: {
          linkTo: url,
          parent: parentName,
          parentType: parentId,
          unitName: name,
          linkName: text ?? '',
          ...additionalProps,
        },
      });
    },
    [trackEvent],
  );

  const onModalEvent: LinkClickListener = useCallback(
    ({ event, cta = {}, config = {} }) => {
      const { text, name } = cta;
      const { parentName, parentId, unitName, unitPlacement, modalTrigger } = config;
      trackEvent({
        event: event ?? 'Clicked Link',
        properties: {
          parent: parentName,
          parentType: parentId,
          unitName: unitName ?? name,
          linkName: text,
          unitPlacement,
          modalTrigger,
        },
      });
    },
    [trackEvent],
  );

  const onLightboxEvent: LightboxEventListener = useCallback(
    ({ event, video = {}, cta = {}, index = 0, additionalProps }) => {
      const { name } = cta;
      const { name: title, campaignName } = video;
      trackEvent({
        event,
        properties: {
          contentId: name,
          title,
          contentPosition: `video${index + 1}`,
          campaignName,
          ...additionalProps,
        },
      });
    },
    [trackEvent],
  );

  const onLeadGenSubmit: LeadGenSubmitListener = useCallback(
    ({ event, pathname, metadata = {}, onError, additionalProps = {}, callback }) => {
      const {
        campaignId,
        campaignName,
        marketingConsent,
        productInterest,
        unitName,
        unitPlacement,
      } = metadata;

      // Win the Gym (WTG) is a special case where we need to track a different event
      // CHAMP-1675
      if (campaignId === '701Uk00000HQ8wDIAT') {
        event = TrackingEvent.SubmittedEmailForWTG;
      }
      if (onError) {
        reportError(new LeadGenInputError());
        return;
      }

      trackEvent({
        event: event ?? 'Submitted Email for Marketing',
        properties: {
          campaignId,
          campaignName,
          country: toCountry(),
          marketingConsent,
          pageName: pathname,
          productInterest: productInterest ?? '',
          propertyType: 'Web',
          unitName,
          unitPlacement: unitPlacement ?? 'in-line',
          ...additionalProps,
        },
        callback,
      });
    },
    [trackEvent, reportError],
  );

  return { onLinkClick, onLightboxEvent, onLeadGenSubmit, onModalEvent };
};

export default usePageBuilderListeners;
