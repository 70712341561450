import { Eyebrow, Flex, Headline, spacing } from '@pelotoncycle/design-system';
import type {
  TypeComponentGenericListFields,
  TypeComponentCtaFields,
} from '@pelotoncycle/page-builder';
import { getGenericTextNodes, OuterContainer } from '@pelotoncycle/page-builder';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import { themes } from '@page-builder/themes';
import { scrollToSection } from '../../../../apps/freeform/utils/linkUtils';
import DesktopHighlightReel from './DesktopHighlightReel';
import MobileHighlightReel from './MobileHighlightReel';

const HighlightReel: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ name, items: rawItems, text, theme = 'Grey 80' }) => {
  const items = rawItems.filter(item => Boolean(item.fields.media));
  const { backgroundColor, headlineColor } = themes[theme];

  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );
  const [inViewRef, isInView] = useInView({ triggerOnce: true });
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { trackEvent } = useTracking();
  const { Track } = useTracking({
    properties: {
      parent: 'Highlight Reel',
      parentName: name,
    },
  });

  const onVideoEnded = () => {
    setActiveIndex(currentIndex => (currentIndex + 1) % items.length);
  };

  const { eyebrow, headline } = getGenericTextNodes(text);
  const showText = Boolean(eyebrow || headline);

  const handleJumpScroll = (
    event: React.MouseEvent<Element, MouseEvent>,
    cta: TypeComponentCtaFields,
    sectionId: string = '',
  ) => {
    event.preventDefault();

    scrollToSection(sectionId, headbandRef);

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Generic Text With Media',
        parent: 'Highlight Reel',
        unitName: cta.name,
        linkName: cta.text,
        linkTo: sectionId,
      },
    });
  };

  const deviceSpecificProps = {
    items,
    activeIndex,
    setActiveIndex,
    onVideoEnded,
    isInView,
    handleJumpScroll,
    headlineColor,
  };

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[64],
        desktop: spacing[40],
      }}
      ref={inViewRef}
    >
      <Flex
        minWidth="100%"
        flexDirection="column"
        gap={{
          mobile: spacing[16],
          desktop: spacing[24],
        }}
      >
        {showText && (
          <Flex flexDirection="column" gap={spacing[8]}>
            {eyebrow && (
              <Eyebrow textColor={headlineColor} size="small">
                {eyebrow}
              </Eyebrow>
            )}
            {headline && (
              <Headline textColor={headlineColor} size="small">
                {headline}
              </Headline>
            )}
          </Flex>
        )}
        <Track>
          <BreakpointSwitchCSS
            breakpoints={{
              mobile: <MobileHighlightReel {...deviceSpecificProps} />,
              desktopLarge: <DesktopHighlightReel {...deviceSpecificProps} />,
            }}
          />
        </Track>
      </Flex>
    </OuterContainer>
  );
};

export default HighlightReel;
