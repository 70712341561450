import type { TypeComponentForm, TypeQuiz } from '@page-builder/lib/types';
import type { AlgorithmInstructor } from '@page-builder/modules/MWYIQuiz/models/AlgorithmInstructor';
import type { MatchedInstructor } from '@page-builder/modules/MWYIQuiz/models/MatchedInstructor';
import type { ProcessedQuiz } from '@page-builder/modules/MWYIQuiz/models/ProcessedQuiz';
import type { ProcessedQuizQuestion } from '@page-builder/modules/MWYIQuiz/models/ProcessedQuizQuestion';
import type { QuestionMetadata } from '@page-builder/modules/MWYIQuiz/models/QuestionMetadata';
import { Slug } from '@page-builder/modules/MWYIQuiz/models/Slug';
import type { Progress } from '@page-builder/modules/Quiz/models/progress';

const defaultMetadata: QuestionMetadata = {
  weight: 0.33,
  exponent: 1,
  coefficient: 1,
};

const DEFAULT_PRIORITY_WEIGHT = 0;
const DEFAULT_CUTOFF_PERCENT = 0.5;

const INTENSITY_SLUG = '/instructor-match/intensity';

const average = (values: number[]) => {
  if (values.length === 0) {
    return 0;
  }
  return values.reduce((sum, value) => sum + value, 0) / values.length;
};

export const processIntensityQuestion = (
  userSelections: string[],
  question: TypeComponentForm,
) => {
  const [formElement] = question.fields.formFields;
  const rawOptions = formElement?.fields.items || [];

  const values: string[] = [];
  const options: string[] = [];

  for (const rawOption of rawOptions) {
    const [option, value] = rawOption.split(':');

    values.push(value);
    options.push(option);
  }

  if (userSelections.length === 0) {
    return {
      options,
    };
  }

  const intensityValues = userSelections.map(selection => values.indexOf(selection));
  const averageUserSelection = average(intensityValues);

  const averageIntensityAnswer = options[Math.round(averageUserSelection)];

  return {
    options,
    averageIntensityAnswer,
  };
};

export const processQuiz = (progress: Progress, quiz: TypeQuiz): ProcessedQuiz => {
  const processedQuizQuestions = [];

  for (const {
    fields: { slug, question, quizSpecificMetadata = {} },
  } of quiz.fields.questions) {
    const isIntensityQuestion = slug === INTENSITY_SLUG;

    const userSelections = (progress[slug] || []).map(selection => {
      const [display, value] = selection.split(':');
      return isIntensityQuestion ? value : display;
    });

    const processedAnswers: string[] = [];
    const options: string[] = [];

    if (isIntensityQuestion) {
      const {
        averageIntensityAnswer,
        options: intensityOptions,
      } = processIntensityQuestion(userSelections, question);

      if (averageIntensityAnswer) {
        processedAnswers.push(averageIntensityAnswer);
      }

      options.push(...intensityOptions);
    } else {
      for (const {
        fields: { items },
      } of question.fields.formFields) {
        if (items) {
          const [rawOption] = items;
          const value = rawOption.split(':')[1];

          options.push(value);

          if (userSelections.includes(value)) {
            processedAnswers.push(value);
          }
        }
      }
    }

    const questionMetadata = {
      ...defaultMetadata,
      ...(quizSpecificMetadata as QuestionMetadata),
    };

    processedQuizQuestions.push({
      slug,
      answers: processedAnswers,
      options,
      ...questionMetadata,
    });
  }

  const { quizSpecificMetadata } = quiz.fields;

  const priorityWeight = quizSpecificMetadata?.priorityWeight || DEFAULT_PRIORITY_WEIGHT;
  const cutoffPercent = quizSpecificMetadata?.cutoffPercent || DEFAULT_CUTOFF_PERCENT;

  return {
    questions: processedQuizQuestions,
    priorityWeight,
    cutoffPercent,
  };
};

export const processInstructorAnswers = (instructor: AlgorithmInstructor): string[][] => {
  return [
    instructor.algorithmFitnessDisciplines,
    [instructor.intensity],
    instructor.musicGenres.map(genre => genre.split(':')[0]),
    instructor.languages,
    [instructor.coaching],
  ];
};

export const getScoreForQuestion = (
  question: ProcessedQuizQuestion,
  instructorAnswers: string[],
) => {
  const { answers: userAnswers, options, coefficient, exponent, weight } = question;
  const exactMatchingAnswers = [];

  const totalScores = [];

  for (const answer of userAnswers) {
    if (!instructorAnswers.includes(answer)) {
      let score = 0;
      const userAnswerIndex = options.indexOf(answer);
      for (const instructorAnswer of instructorAnswers) {
        if (!userAnswers.includes(instructorAnswer)) {
          const instructorAnswerIndex = options.indexOf(instructorAnswer);
          const distanceFromUserAnswer = Math.abs(
            userAnswerIndex - instructorAnswerIndex,
          );

          const matchValue = 1 - coefficient * Math.pow(distanceFromUserAnswer, exponent);

          score = Math.max(score, matchValue);
        }
      }

      totalScores.push(score);
    } else {
      totalScores.push(1);

      exactMatchingAnswers.push(answer);
    }
  }

  const totalScore = average(totalScores) * weight;

  return { totalScore, exactMatchingAnswers };
};

const getScoreForQuiz = (
  instructorAnswers: string[][],
  questions: ProcessedQuizQuestion[],
) => {
  let score = 0;
  const exactMatchingAnswerMap = {};

  for (let i = 0; i < instructorAnswers.length; i++) {
    const { totalScore: scoreForQuestion, exactMatchingAnswers } = getScoreForQuestion(
      questions[i],
      instructorAnswers[i],
    );

    exactMatchingAnswerMap[questions[i].slug] = exactMatchingAnswers;

    score += scoreForQuestion;
  }
  return { score, exactMatchingAnswerMap };
};

const shuffle = (array: MatchedInstructor[]) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

const assignBestMatchFlag = (matchedInstructors: MatchedInstructor[]) => {
  return matchedInstructors.length
    ? [{ ...matchedInstructors[0], bestMatch: true }, ...matchedInstructors.slice(1)]
    : matchedInstructors;
};

const MATCH_REASON_PRIORITY = [Slug.Discipline, Slug.Music, Slug.Coaching, Slug.Language];

const EXCLUDED_MATCH_REASONS: Partial<Record<Slug, string[]>> = {
  [Slug.Language]: ['English'],
};

export const assignMatchReasons = (
  matchedInstructors: MatchedInstructor[],
): MatchedInstructor[] => {
  const modifiedMatchedInstructors: MatchedInstructor[] = [];
  const usedAnswerMap: Partial<Record<Slug, string[]>> = {};
  let startSearchIndex = 0;

  for (const matchedInstructor of matchedInstructors) {
    let currentSearchIndex = startSearchIndex;

    const { exactMatchingAnswerMap } = matchedInstructor;

    // Loop forever until we find a match for the instructor or give up
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const questionSlug = MATCH_REASON_PRIORITY[currentSearchIndex];

      const exactMatchAnswers = exactMatchingAnswerMap[questionSlug] || [];

      const usedAnswers = usedAnswerMap[questionSlug] || [];
      const unusedAnswer = exactMatchAnswers.find(
        answer => !usedAnswers.includes(answer),
      );

      // If the instructor didn't have an answer we haven't already shown, just use their first answer if it exists
      const answerForInstructor = unusedAnswer || exactMatchAnswers[0];

      // The instructor hasn't matched on this question, or has an excluded answer, so we should go to the next question
      if (
        !answerForInstructor ||
        EXCLUDED_MATCH_REASONS[questionSlug]?.includes(answerForInstructor)
      ) {
        currentSearchIndex = (currentSearchIndex + 1) % MATCH_REASON_PRIORITY.length;
        if (currentSearchIndex === startSearchIndex) {
          // we looped through all the questions and didn't find any exact answers for this instructor,
          // so we just add them to the return array and go to the next instructor
          modifiedMatchedInstructors.push(matchedInstructor);
          break;
        } else {
          continue;
        }
      }

      usedAnswers.push(answerForInstructor);
      usedAnswerMap[questionSlug] = usedAnswers;

      modifiedMatchedInstructors.push({
        ...matchedInstructor,
        matchReason: [questionSlug, answerForInstructor],
      });

      // The next instructor should start searching at the next question in the priority order
      startSearchIndex = (startSearchIndex + 1) % MATCH_REASON_PRIORITY.length;

      break;
    }
  }

  return modifiedMatchedInstructors;
};

export const getIntructorMatches = (
  progress: Progress,
  quiz: TypeQuiz,
  instructors: AlgorithmInstructor[],
) => {
  const { questions, priorityWeight, cutoffPercent } = processQuiz(progress, quiz);

  const totalQuestionsScore = questions.reduce((score, currentValue) => {
    return score + currentValue.weight;
  }, 0);

  const maxScore = totalQuestionsScore + priorityWeight;
  const cutoffScore = maxScore * cutoffPercent;

  const matchedInstructors: MatchedInstructor[] = [];
  for (const instructor of instructors) {
    const instructorAnswers = processInstructorAnswers(instructor);

    const { score, exactMatchingAnswerMap } = getScoreForQuiz(
      instructorAnswers,
      questions,
    );
    const priorityScore = instructor.priority === 1 ? priorityWeight : 0;

    const totalScore = score + priorityScore;

    const percentMatch = Math.min(Math.round((totalScore / maxScore) * 100), 100);

    if (totalScore >= cutoffScore) {
      matchedInstructors.push({
        bestMatch: false,
        instructor,
        percentMatch,
        exactMatchingAnswerMap,
      });
    }
  }

  shuffle(matchedInstructors);

  matchedInstructors.sort((a, b) => b.percentMatch - a.percentMatch);

  return {
    matchedInstructors: assignBestMatchFlag(matchedInstructors),
  };
};
