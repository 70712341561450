import React from 'react';
import DownloadRedirect from '@ecomm/app-download/DownloadRedirect';
import type { BlockProps } from '@page-builder/lib/pages';
import type { TypeComponentPlaceholder } from '@page-builder/lib/types';
import {
  HideChatAlways,
  HideChatMobile,
  ShowChatOnPageScrollMobile,
} from './Views/HideDriftChat';
import { HideNav } from './Views/HideNav';

type Props = TypeComponentPlaceholder & BlockProps;

const NextPlaceholder: React.FC<React.PropsWithChildren<Props>> = ({
  fields: { name },
}) => {
  switch (name) {
    /**
     * CHAMP Placeholders
     */
    case 'Hide Drift Chat':
      return <HideChatAlways />;
    case 'Hide Drift Chat Mobile':
      return <HideChatMobile />;
    case 'Show Chat On Page Scroll Mobile':
      return <ShowChatOnPageScrollMobile />;
    case 'App Download Redirect':
      return <DownloadRedirect />;
    case 'Hide Nav':
      return <HideNav />;
    default:
      return null;
  }
};

export default NextPlaceholder;
