import type {
  CardProps,
  MediaIFrameProps,
  MediaImageProps,
  MediaVideoProps,
  ResponsiveImageProps,
} from '@pelotoncycle/design-system';
import { BreakpointWidths } from '@pelotoncycle/design-system';
import React from 'react';
import { css } from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type {
  TypeComponentEmbedFields,
  TypeComponentImageFields,
  TypeComponentMedia,
  TypeComponentVideoFields,
  TypeComponentImageCard,
  TypeComponentImageCardGroup,
} from '@page-builder/lib/types';
import type { ResponsiveVideoSrc } from '@page-builder/modules/Video/useResponsiveVideoSrc';
import WithMarkdown from '@page-builder/modules/WithMarkdown';
import type { ImageCloudinaryOptions } from './cloudinary';
import { regexValidation } from './regex';

const MOBILE_BREAKPOINT_WIDTH = 600;

export const imageFieldsToMediaImageProps = (
  data: TypeComponentImageFields,
  skipCloudinary?: boolean,
  imageCloudinaryOptions: ImageCloudinaryOptions = {},
): MediaImageProps => {
  const props: MediaImageProps = {
    mobile: '',
    tablet: '',
    desktop: '',
    alt: '',
    type: 'image',
  };

  const imageWidths = {
    ...BreakpointWidths,
    mobile: MOBILE_BREAKPOINT_WIDTH,
  };

  for (const key in data) {
    const breakpointData = data[key];
    switch (key) {
      case 'mobile':
      case 'tablet':
      case 'desktop':
        if (breakpointData) {
          props[key] = skipCloudinary
            ? breakpointData.fields.file.url
            : toCloudinarySrc({
                src: breakpointData.fields.file.url,
                width: imageWidths[key],
                ...imageCloudinaryOptions[key],
              });
        } else {
          props[key] = '';
        }
        break;
      case 'alt':
        props[key] = (data[key] as string) || data.name;
        break;
    }
  }

  return props;
};

export const toImageProps = (
  data: TypeComponentMedia | undefined,
  skipCloudinary?: boolean,
) => {
  const mediaFields = data?.fields.media.fields;
  if (mediaFields && 'alt' in mediaFields) {
    return imageFieldsToMediaImageProps(mediaFields, skipCloudinary);
  }
  return undefined;
};

type VideoBreakpoints = {
  responsiveVideoSrc?: ResponsiveVideoSrc;
};
type VideoThumbnailProps = { thumbnail?: ResponsiveImageProps };

type ResponsiveVideoProps = MediaVideoProps & VideoBreakpoints & VideoThumbnailProps;

export const videoFieldsToMediaVideoProps = (
  data: TypeComponentVideoFields,
  overrides?: Partial<MediaVideoProps>,
): ResponsiveVideoProps => {
  const props: ResponsiveVideoProps = {
    src: '',
    responsiveVideoSrc: undefined,
    autoplay: data.hasOwnProperty('autoplay') ? data.autoplay : true,
    loop: data.hasOwnProperty('loop') ? data.loop : true,
    playPauseButtonPosition: data.playPauseButtonPosition || 'right',
    type: 'video',
    theme: data.theme || 'light',
    thumbnail: undefined,
    muteButtonPosition: data.showMuteButton ? 'right' : 'hide',
    ...overrides,
  };

  if (data.poster) {
    props.poster = data.poster.fields.mobile?.fields.file.url;
    props.thumbnail = imageFieldsToMediaImageProps(data.poster.fields);
  }

  if (data.video) {
    props.src = data.video.fields.file.url;
  }

  if (data.mobile) {
    props.responsiveVideoSrc = {
      mobile: data.mobile.fields.file.url,
      tablet: data.tablet?.fields.file.url,
      desktop: data.desktop?.fields.file.url,
    };
  }

  return props;
};

export const toMediaProps = (
  data: TypeComponentMedia,
  skipCloudinary?: boolean,
  imageCloudinaryOptions?: ImageCloudinaryOptions,
): ResponsiveVideoProps | MediaImageProps => {
  const mediaFields = data.fields.media.fields;

  if ('video' in mediaFields) {
    return videoFieldsToMediaVideoProps(mediaFields);
  }

  if ('code' in mediaFields) {
    throw new Error(`import useIFrameProps instead`);
  }

  return imageFieldsToMediaImageProps(
    mediaFields,
    skipCloudinary,
    imageCloudinaryOptions,
  );
};

export const toVideoProps = (
  data: TypeComponentMedia | undefined,
  overrides?: MediaVideoProps,
) => {
  const mediaFields = data?.fields.media.fields;
  if (mediaFields && 'video' in mediaFields) {
    return videoFieldsToMediaVideoProps(mediaFields, overrides);
  }
  return undefined;
};

export const embedFieldsToMediaIFrameProps = (
  data: TypeComponentEmbedFields,
  iframeHeight?: object,
): MediaIFrameProps => {
  const props: MediaIFrameProps = {
    title: '',
    src: '',
    allow: '',
    height: iframeHeight,
    type: 'iframe',
  };

  const code = regexValidation(data.code);
  const container = document.createElement('div');
  container.innerHTML = code;
  const iframe = container.firstElementChild;

  props.src = iframe?.getAttribute('src') ?? '';
  props.title = iframe?.getAttribute('title') ?? '';
  props.allow = iframe?.getAttribute('allow') ?? '';

  return props;
};

export const toImageCardProps = ({
  fields: { eyebrow, headline, body, bodyLongText, support, label, image },
}: TypeComponentImageCard): CardProps => {
  const bodyText = bodyLongText ?? body;
  const bodyMarkdown = bodyText ? <WithMarkdown text={bodyText} /> : undefined;
  const url = image?.fields?.file?.url;
  const alt = image?.fields?.title || '';
  const imageProps: CardProps = {
    //@ts-expect-error
    bodyText: bodyMarkdown,
    eyebrowText: eyebrow,
    headlineText: headline,
    supportText: support,
    labelText: label,
  };
  if (url) {
    imageProps.image = {
      mobile: url,
      tablet: url,
      desktop: url,
      alt,
    };
  }
  return imageProps;
};

export const toCloudinaryImageCards = (data: TypeComponentImageCardGroup) => {
  const imageCards = data.fields.imageCards;

  const cloudinaryImageCards = imageCards.map(card => {
    const currentImage = card.fields.image;
    const currentFile = currentImage?.fields.file;

    if (!currentImage || !currentFile) return card;

    const currentImageUrl = currentFile.url;
    const { height, width } = currentFile.details.image!;

    const newUrl = toCloudinarySrc({
      src: currentImageUrl,
      width: width,
      height: height,
    });

    const newFile = {
      ...currentFile,
      url: newUrl,
    };

    const newImage = {
      ...currentImage,
      fields: {
        ...currentImage.fields,
        file: newFile,
      },
    };

    const newCard = {
      ...card,
      fields: {
        ...card.fields,
        image: newImage,
      },
    };

    return newCard;
  });

  return cloudinaryImageCards;
};

export const DOUBLE_BREAKPOINT_WIDTH_OPTIONS: ImageCloudinaryOptions = {
  mobile: {
    width: MOBILE_BREAKPOINT_WIDTH * 2,
  },
  tablet: {
    width: BreakpointWidths.tablet * 2,
  },
  desktop: {
    width: BreakpointWidths.desktop * 2,
  },
};

const toBreakpointFunction = (width: number) => (...args: any[]) => css`
  @media (min-width: ${width}px) {
    ${css.call(this, ...args)}
  }
`;

export const media = {
  tablet: toBreakpointFunction(BreakpointWidths.tablet),
  desktop: toBreakpointFunction(BreakpointWidths.desktop),
};
