import type { TypeComponentJsonFields } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';

export const filterModulesByDiscipline = (
  modules: Entry<TypeComponentJsonFields>[],
  fitnessDiscipline: string,
) => {
  const disciplineMap: { [key: string]: string } = {
    strength: 'Strength',
    yoga: 'Yoga',
    cardio: 'Cardio',
  };

  const disciplineName = disciplineMap[fitnessDiscipline?.toLowerCase()];

  if (!disciplineName) {
    return [];
  }

  return modules?.filter(module => module?.fields?.name === disciplineName);
};
