import React from 'react';
import type {
  TypeComponentCta,
  TypeComponentGenericList,
  TypeComponentGenericTextWithMedia,
  TypeComponentGenericTextWithMediaFields,
} from '@page-builder/lib/types';
import MediaCard from './MediaCard';

export type GalleryData = {
  media?: TypeComponentGenericTextWithMedia[];
  unitName: string;
  modalTitle?: string;
  textItems?: TypeComponentGenericTextWithMedia[];
  ctas?: TypeComponentCta[];
};

export const toGalleryData = (gallery: TypeComponentGenericList) => {
  const { fields } = gallery;
  const items = gallery && separateItemsByTreatment(gallery, 'Specs View');

  return {
    media: items.defaultItems,
    unitName: fields.name,
    modalTitle: fields.text?.fields.eyebrow,
    textItems: items.itemsWithTreatment,
    ctas: fields.ctas,
  };
};
export const separateItemsByTreatment = (
  list: TypeComponentGenericList,
  treatment: TypeComponentGenericTextWithMediaFields['treatment'],
) => {
  const {
    fields: { items },
  } = list;

  const itemsWithTreatment = items.filter(item => item.fields.treatment === treatment);

  const defaultItems = items.filter(item => item.fields.treatment !== treatment);

  return { itemsWithTreatment, defaultItems };
};

export const applyMediaTreatment = (
  array: TypeComponentGenericTextWithMedia[],
  onImageClick?: (index: number) => void,
) => {
  return array.map((item, index) => {
    const {
      fields: { media, name },
    } = item;
    return (
      <MediaCard
        key={`${name}-${index}`}
        media={media}
        onClick={onImageClick ? () => onImageClick(index) : undefined}
      />
    );
  });
};
