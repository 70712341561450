import {
  grey,
  white,
  Badge,
  Flex,
  spacing,
  Display,
  Label,
  Media,
} from '@pelotoncycle/design-system';
import { toCloudinarySrc } from '@pelotoncycle/page-builder';
import type {
  WwwLinkReferenceType as WwwLinkReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from 'apps/freeform/types/referenceTypes';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toUtcTime } from '@peloton/time/time';
import { useMicroCopyBulk } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import {
  ClassDetailsFeaturedArtists,
  ClassDetailsEquipment,
  ClassDetailsClassPlan,
  ClassDetailsPlaylist,
  ClassDetailsMoreInfo,
  ClassDetailsBodyActivity,
} from '@page-builder/modules/ClassesGrid';
import ClassDifficultyRate from '@page-builder/modules/ClassesGrid/Modal/ClassDifficultyRate';
import type { Difficulty } from '@page-builder/modules/ClassesGrid/Modal/ClassDifficultyRate';
import { LiftedModal } from '@page-builder/modules/NextModal/LiftedModal/LiftedModal';
import type { LiftedModalProps } from '@page-builder/modules/NextModal/LiftedModal/LiftedModal';
import type { ParsedClass as ParsedClassType } from './types';

const DIFFICULTY_LABELS = {
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
};

const MODAL_LABELS_MICRO_COPY_KEYS = [
  'app.classModalLabel.originallyAiredLabel',
  'app.classModalLabel.featuringMusicByLabel',
  'app.classModalLabel.equipmentLabel',
  'app.classModalLabel.classPlanLabel',
  'app.classModalLabel.playlistLabel',
  'app.classModalLabel.playlistViewMoreLabel',
  'app.classModalLabel.playlistViewLessLabel',
  'app.classModalLabel.moreInfoLabel',
  'app.classModalLabel.muscleGroupLabel',
  'app.classModalLabel.otherGroupLabel',
  'app.classModalLabel.primaryMuscleGroupLabel',
  'app.classModalLabel.secondaryMuscleGroupLabel',
  'app.classModalLabel.tertiaryMuscleGroupLabel',
];

type ClassDetailsModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
  classDetails?: ParsedClassType;
  cta?: {
    text: string;
    link: WwwLinkReference | ExternalLinkReference;
  };
};

export const ClassDetailsModal: React.FC<LiftedModalProps & ClassDetailsModalProps> = ({
  isOpen,
  closeHandler,
  classDetails,
  contentLabel,
  cta,
}) => {
  const { trackEvent } = useTracking();
  const modalLabels = useMicroCopyBulk(MODAL_LABELS_MICRO_COPY_KEYS);

  if (!classDetails) {
    return null;
  }

  const {
    imageUrl,
    difficultyLevel,
    title,
    originalAirTime,
    instructorName,
    fitnessDisciplineDisplayName,
    description,
    segments,
    equipmentTags,
    playlist,
    muscleGroupPercentages,
  } = classDetails;
  const formattedAirTime = originalAirTime
    ? toUtcTime(originalAirTime).format('MM/DD/YYYY')
    : null;

  const hasBodyActivitySection = Boolean(
    muscleGroupPercentages && muscleGroupPercentages?.length > 0,
  );

  const handleOnCtaClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: 'DisciplineTilesWithModal (CHAMP)',
        parentType: 'Component: JSON',
        unitName: 'Class Details Modal CTA',
        linkTo: cta?.link?.url,
        linkName: cta?.text,
      },
    });
  };

  return (
    <StyledLiftedModal
      isOpen={isOpen}
      contentLabel={contentLabel}
      backgroundColor={grey[90]}
      textColor="white"
      closeHandler={closeHandler}
      maxWidth="600px"
      hasExtendedBottomMargin={false}
    >
      {imageUrl && (
        <Media
          objectFit="contain"
          media={{
            type: 'image',
            alt: title,
            mobile: toCloudinarySrc({ src: imageUrl, width: 400 }),
            tablet: toCloudinarySrc({ src: imageUrl, width: 600 }),
          }}
        />
      )}
      <Flex verticalPadding={spacing[32]} flexDirection="column">
        <ClassCardDetailsWrapper
          flexDirection="column"
          justifyContent="center"
          textAlign="left"
          horizontalMargin={{ mobile: spacing[16], desktop: spacing[32] }}
          gap={spacing[8]}
        >
          <ClassDifficultyRate
            difficultyRate={difficultyLevel as Difficulty}
            difficultyLabels={DIFFICULTY_LABELS}
          />
          <Display size="medium" textColor="white">
            {title}
          </Display>
          {formattedAirTime && (
            <Label size="large" textColor="white">
              {modalLabels['app.classModalLabel.originallyAiredLabel']} {formattedAirTime}
            </Label>
          )}
          <Flex gap={spacing[8]}>
            <ModalPill variant="horizontal" theme="grey">
              {instructorName}
            </ModalPill>
            <ModalPill variant="horizontal" theme="grey">
              {fitnessDisciplineDisplayName}
            </ModalPill>
          </Flex>
          {cta && (
            <Flex verticalPadding={`${spacing[24]} 0`}>
              <LinkButton
                data-test-id="class-modal-link"
                text={cta.text}
                href={cta.link.url}
                to={cta.link.url}
                onClick={handleOnCtaClick}
                variant="solid"
                color="primary"
                width="adaptive"
              />
            </Flex>
          )}
        </ClassCardDetailsWrapper>
        {equipmentTags && equipmentTags?.length > 0 && (
          <ClassDetailsEquipment
            equipmentTags={equipmentTags}
            equipmentLabel={modalLabels['app.classModalLabel.equipmentLabel']}
          />
        )}
        {playlist?.topArtists && playlist?.topArtists?.length > 0 && (
          <ClassDetailsFeaturedArtists
            playlist={playlist}
            featuringMusicByLabel={
              modalLabels['app.classModalLabel.featuringMusicByLabel']
            }
          />
        )}
        {playlist?.songs && playlist?.songs?.length > 0 && (
          <ClassDetailsPlaylist
            playlist={playlist}
            playlistLabel={modalLabels['app.classModalLabel.playlistLabel']}
            viewMoreLabel={modalLabels['app.classModalLabel.playlistViewMoreLabel']}
            viewLessLabel={modalLabels['app.classModalLabel.playlistViewLessLabel']}
          />
        )}
        {segments && segments?.length > 0 && (
          <ClassPlanSection flexDirection="column">
            <ClassDetailsClassPlan
              segments={segments}
              classPlanLabel={modalLabels['app.classModalLabel.classPlanLabel']}
            />
          </ClassPlanSection>
        )}
        {muscleGroupPercentages && muscleGroupPercentages?.length > 0 && (
          <BodyActivitySectionWrapper
            flexDirection="column"
            removeBottomPadding={!!description}
          >
            <ClassDetailsBodyActivity
              muscleGroupScore={muscleGroupPercentages}
              muscleGroupLabel={modalLabels['app.classModalLabel.muscleGroupLabel']}
              otherGroupLabel={modalLabels['app.classModalLabel.otherGroupLabel']}
              primaryMuscleGroupLabel={
                modalLabels['app.classModalLabel.primaryMuscleGroupLabel']
              }
              secondaryMuscleGroupLabel={
                modalLabels['app.classModalLabel.secondaryMuscleGroupLabel']
              }
              tertiaryMuscleGroupLabel={
                modalLabels['app.classModalLabel.tertiaryMuscleGroupLabel']
              }
            />
          </BodyActivitySectionWrapper>
        )}
        {!!description && (
          <ClassDetailsMoreInfo
            description={description}
            moreInfoLabel={modalLabels['app.classModalLabel.moreInfoLabel']}
            withBorder={!hasBodyActivitySection}
          />
        )}
      </Flex>
    </StyledLiftedModal>
  );
};

const StyledLiftedModal = styled(LiftedModal)`
  border-radius: ${spacing[12]};
`;

const ClassCardDetailsWrapper = styled(Flex)`
  border-radius: 0 0 ${spacing[4]} ${spacing[4]};
  color: ${white};
  padding: ${spacing[24]} 0;
`;

const ModalPill = styled(Badge)`
  background: rgba(101, 102, 106, 0.25);
  border-radius: ${spacing[4]};
  color: white;
  font-weight: 600;
`;

const ClassPlanSection = styled(Flex)`
  * > img {
    filter: invert(61%) sepia(11%) saturate(228%) hue-rotate(186deg) brightness(89%)
      contrast(85%);
  }
`;

// For removing the extra padding between More Info and Body Activity section
const BodyActivitySectionWrapper = styled(Flex)<{ removeBottomPadding: boolean }>`
  section {
    ${removeBottomPadding => (removeBottomPadding ? 'padding-bottom: 0' : '')}
  }
`;
