import { useHasVariation } from '@peloton/split-testing/hooks';

const REVIEWS_REMOVAL_EXPERIMENT = 'Reviews Removal';
const REVIEWS_REMOVAL_EXPERIMENT_VARIATION = 'Variation #1';

export const useIsReviewsRemovalExperimentActive = () => {
  return Boolean(
    useHasVariation(REVIEWS_REMOVAL_EXPERIMENT, REVIEWS_REMOVAL_EXPERIMENT_VARIATION),
  );
};
