import { grey, Flex, Icon } from '@pelotoncycle/design-system';
import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import getRootElement from '@ecomm/modal/getRootElement';
import ModalGlobalStyles from '@ecomm/modal/ModalGlobalStyles';
import { focusHighlight } from '@ecomm/styles';

type ModalContentWrapperProps = {
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
  contentLabel: string;
  children: React.ReactNode;
  maxWidth?: string;
};

// http://reactcommunity.org/react-modal/accessibility/#app-element
if (typeof window !== 'undefined') {
  const root = getRootElement();
  if (root) {
    ReactModal.setAppElement(root);
  }
}
export const ModalContentWrapper: React.FC<
  React.PropsWithChildren<ModalContentWrapperProps>
> = ({ isOpen, openHandler, closeHandler, contentLabel, children, maxWidth }) => {
  const [savedScrollPosition, setSavedScrollPosition] = useState<number | undefined>(
    typeof window !== 'undefined' ? window.scrollY : undefined,
  );

  const applySavedScrollPosition = useCallback(() => {
    requestAnimationFrame(() => window.scrollTo({ top: savedScrollPosition }));
    document.body.style.width = '';
    document.body.style.position = '';
    document.body.style.top = '';
  }, [savedScrollPosition]);

  const handleAfterOpen = () => {
    setSavedScrollPosition(window.scrollY);
    const modal = document.getElementsByClassName('ReactModalPortal')[0];
    modal.addEventListener('close', closeHandler);
    openHandler && openHandler();
  };

  const handleRequestClose = () => {
    const modal = document.getElementsByClassName('ReactModalPortal')[0];
    modal.removeEventListener('close', closeHandler);
    applySavedScrollPosition();
    if (closeHandler) closeHandler();
  };

  return (
    <StyledModal
      maxWidth={maxWidth}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel={contentLabel}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      closeTimeoutMS={400}
      role="dialog"
      aria={{ modal: 'true' } as ReactModal.Aria}
    >
      <ModalGlobalStyles /> {/* Taken from Legacy modal, leaving for stability */}
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        // Although DS Flex  accepts height and width props, they weren't being applied in the DOM
        style={{ height: '50px', width: '100%' }}
      >
        <CloseButton
          onClick={handleRequestClose}
          data-test-id="dismissModal"
          aria-label="Close"
        >
          <Icon name="close" height={27} primaryColor={grey[30]} />
        </CloseButton>
      </Flex>
      {children}
    </StyledModal>
  );
};

type WidthProp = {
  maxWidth: string | undefined;
};

const StyledModal = styled(ReactModal)<WidthProp>`
  margin: 0 20px 40px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  ${media.tablet`
    margin: 20px 40px 80px;
  `}
  ${media.desktop`
    margin: 30px 0 90px;
  `}
  text-align: left;
  color: ${grey[70]};
`;
export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding-right: 3px;
  ${focusHighlight}
  ${media.tablet`
    transition-property: opacity;
    transition-duration: 250ms;
    transition-timing-function: ease;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
  `}
`;
