import { Icon, Label } from '@pelotoncycle/design-system';
import { ScrollingCarousel as Carousel } from '@trendyol-js/react-carousel';
import React from 'react';
import { useTracking } from 'react-tracking';
import {
  Arrow as ArrowContainer,
  CarouselContainer,
  Category,
  CategoryContent,
} from './styles';
import { themes } from './theme';
import type { Direction, Discipline } from './types';

type Props = {
  activeDisciplineId: string;
  disciplines: Discipline[];
  changeDiscipline: (id: string) => void;
  theme?: 'White' | 'Grey 90';
};

const DisciplineTilesNav: React.FC<React.PropsWithChildren<Props>> = ({
  activeDisciplineId,
  changeDiscipline,
  disciplines,
  theme = 'Grey 90',
}) => {
  const { trackEvent } = useTracking();
  const onTileClick = (e: React.MouseEvent<Element>, id: string) => {
    changeDiscipline(id);

    e.currentTarget.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth',
    });

    trackEvent({
      event: 'Clicked Discipline Icon',
      properties: {
        parent: 'DisciplineTilesWithModal (CHAMP)',
        parentType: 'Component: JSON',
        unitName: 'Discipline Nav Tile',
        linkName: id,
        linkTo: '',
      },
    });
  };

  return (
    <CarouselContainer aria-label="Disciplines">
      <Carousel
        leftIcon={<Arrow direction="left" theme={theme} />}
        rightIcon={<Arrow direction="right" theme={theme} />}
        className="carousel"
      >
        {disciplines.map(discipline => (
          <DisciplineTile
            key={discipline.id}
            activeDisciplineId={activeDisciplineId}
            discipline={discipline}
            onTileClick={onTileClick}
            theme={theme}
          />
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

type DisciplineTileProps = {
  activeDisciplineId: string;
  discipline: Discipline;
  onTileClick: (e: React.MouseEvent<Element>, id: string) => void;
  theme?: 'White' | 'Grey 90';
};

const DisciplineTile: React.FC<React.PropsWithChildren<DisciplineTileProps>> = ({
  activeDisciplineId,
  discipline,
  onTileClick,
  theme = 'Grey 90',
}) => {
  const isActiveDiscipline = activeDisciplineId === discipline.id;
  const labelWeight = isActiveDiscipline ? 'medium' : 'regular';
  const { primaryTextColor } = themes[theme];

  return (
    <Category
      theme={theme}
      key={discipline.id}
      active={isActiveDiscipline}
      data-test-id={`${discipline.id}-discipline-tile`}
      onClick={e => {
        onTileClick(e, discipline.id);
      }}
      aria-current={isActiveDiscipline}
    >
      <CategoryContent className="link">
        {discipline.ctaTile.icon && (
          <Icon
            width={24}
            height={24}
            name={discipline.ctaTile.icon}
            aria-hidden="true"
          />
        )}
        <Label
          size="small"
          weight={labelWeight}
          textColor={primaryTextColor}
          data-test-id={`${discipline.id}-tile-label`}
        >
          {discipline.ctaTile.text}
        </Label>
      </CategoryContent>
    </Category>
  );
};

export const Arrow: React.FC<
  React.PropsWithChildren<{ direction: Direction; theme?: 'White' | 'Grey 90' }>
> = ({ direction, theme = 'Grey 90' }) => {
  return (
    <ArrowContainer
      direction={direction}
      theme={theme}
      data-test-id={`arrow-${direction}`}
    >
      <Icon width="8px" primaryColor={themes[theme].arrowColor} name="carouselLine" />
    </ArrowContainer>
  );
};

export default DisciplineTilesNav;
