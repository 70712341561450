import { useRouter } from 'next/router';
import React, { useState } from 'react';
import type { TypeQuiz } from '@page-builder/lib/types';
import Loading from '@page-builder/modules/Quiz/components/Loading';
import NavControls from '@page-builder/modules/Quiz/components/NavControls';
import QuizQuestion from '@page-builder/modules/Quiz/components/QuizQuestion';
import { useQuestions } from '@page-builder/modules/Quiz/utils/questions';
import { ThemeProvider } from '@page-builder/modules/Quiz/utils/theme';
import MatchingInstructorRedirect from '../../MWYIQuiz/Modules/MatchingInstructorRedirect';
import { getQueryParams, useFormProgress, useInstructors } from '../utils/progress';
import { writeProgressToSession } from '../utils/session';
import QuizLeadGen from './QuizLeadGen';
import QuizRedirect from './QuizRedirect';

type Props = {
  quiz: TypeQuiz;
};

const QuizContent: React.FC<React.PropsWithChildren<Props>> = ({ quiz }) => {
  const router = useRouter();
  const { progress, setProgress, quizSessionKey } = useFormProgress();
  const { algorithmInstructors, hasAlgorithmInstructors } = useInstructors();
  const [isLeadGenComplete, setIsLeadGenComplete] = useState(false);

  const {
    fields: { questions, quizSpecificMetadata, resultPageUrl, loadingPageText, leadGen },
  } = quiz;
  const showLeadGen = leadGen && !isLeadGenComplete;
  const shouldRedirectDevFree = !!quizSpecificMetadata?.bucketSlugMap && !showLeadGen;
  const shouldRedirectMWI = hasAlgorithmInstructors && !showLeadGen;

  const {
    getCurrentQuestion,
    canGoToPreviousQuestion,
    canGoToNextQuestion,
    isResultsPage,
    getCurrentQuestionIndex,
  } = useQuestions(questions, resultPageUrl, progress);
  const currentQuestion = getCurrentQuestion();

  React.useEffect(() => {
    // avoids double setting on results page (handled in redirect component)
    // and query params not hydrated on router for results page
    if (!isResultsPage() && router.isReady) {
      const queryParams = getQueryParams(questions, router.query);
      // if query params, overwrite
      // if not, use session storage
      if (Object.keys(queryParams).length !== 0) {
        setProgress(prevProgress => ({ ...prevProgress, ...queryParams }));
        writeProgressToSession(queryParams, quizSessionKey);
      }
    }
  }, [isResultsPage, router, questions, quizSessionKey, setProgress]);

  if (isResultsPage()) {
    return (
      <ThemeProvider theme={questions[questions.length - 1].fields.theme}>
        {showLeadGen ? (
          <QuizLeadGen
            leadGen={leadGen}
            onLeadGenSuccess={() => setIsLeadGenComplete(true)}
          />
        ) : (
          <Loading loadingPageText={loadingPageText} />
        )}
        {shouldRedirectDevFree && <QuizRedirect progress={progress} quiz={quiz} />}
        {shouldRedirectMWI && (
          <MatchingInstructorRedirect
            algorithmInstructors={algorithmInstructors}
            quiz={quiz}
          />
        )}
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={currentQuestion.fields.theme}>
      <QuizQuestion
        question={currentQuestion}
        currentQuestionIndex={getCurrentQuestionIndex()}
        quizSessionKey={quizSessionKey}
      />
      <NavControls
        questions={questions}
        question={currentQuestion}
        canGoBack={canGoToPreviousQuestion()}
        canGoForward={canGoToNextQuestion()}
        resultPageUrl={resultPageUrl}
        quizSessionKey={quizSessionKey}
      />
    </ThemeProvider>
  );
};

export default QuizContent;
