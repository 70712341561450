import { useContext, useRef } from 'react';
import { GlobalReferenceContext, GlobalRefElement } from '../GlobalReferenceProvider';
import useIsomorphicEffect from './useIsomorphicEffect';
import useResizeRefWatch from './useResizeRefWatch';

const useProductBlockReference = () => {
  const { setElement } = useContext(GlobalReferenceContext);
  const productBlockRef = useRef<HTMLDivElement | null>(null);
  useResizeRefWatch(productBlockRef, ref =>
    setElement(
      GlobalRefElement.APP_PLP_PRODUCT_BLOCK,
      ref?.getBoundingClientRect(),
      productBlockRef.current,
    ),
  );

  useIsomorphicEffect(() => {
    setElement(
      GlobalRefElement.APP_PLP_PRODUCT_BLOCK,
      productBlockRef.current?.getBoundingClientRect(),
      productBlockRef.current,
    );
  }, [productBlockRef.current]);

  return productBlockRef;
};

export default useProductBlockReference;
