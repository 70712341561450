import type { ProductVariant } from '@ecomm/graphql/types.generated';
import type { CommerceToolsCFU } from '@ecomm/product-recommendations/models/Product';

const toProductOptionIdsForUnconfigurableCfu = (product: CommerceToolsCFU): string[] => {
  const [primaryCfuVariant] = product.ctProduct.connectedFitnessUnit
    .variants as ProductVariant[];
  const [
    {
      options: [primaryWarrantyOption],
    },
  ] = product.ctProduct.warranty.attributes;

  const productOptionIds = [primaryWarrantyOption.id, primaryCfuVariant.id];

  return productOptionIds;
};

export default toProductOptionIdsForUnconfigurableCfu;
