import {
  Button,
  Flex,
  Media,
  spacing,
  Display,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductRecommendationsMarketingTileWithModal } from '@content/client/www/product-recommendations/models';
import { TrackingEvent } from '@ecomm/analytics/models';
import ProductComparisonModal from '@page-builder/modules/ProductComparisonCards/ProductComparisonModal/ProductComparisonModal';
import { imageFieldsToMediaImageProps } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  marketingTileWithModal: ProductRecommendationsMarketingTileWithModal;
};

const MarketingTileWithModal: React.FC<React.PropsWithChildren<Props>> = ({
  marketingTileWithModal,
}) => {
  const { trackEvent } = useTracking();

  const {
    backgroundImage,
    supportingImage,
    text,
    ctaText,
    modalBody,
    modalName,
    modalSupport,
  } = marketingTileWithModal;

  const createModalEventProps = (
    eventName: TrackingEvent.ModalOpened | TrackingEvent.ModalClosed,
    modalTrigger: string,
  ) => {
    return {
      event: eventName,
      properties: {
        productInterest: 'App',
        propertyType: 'Web',
        unitName: 'App Comparison Modal',
        unitPlacement: 'Modal',
        modalTrigger,
      },
    };
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
    trackEvent(
      createModalEventProps(
        TrackingEvent.ModalOpened,
        `User opened App Comparison modal`,
      ),
    );
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    trackEvent(
      createModalEventProps(
        TrackingEvent.ModalClosed,
        `User closed App Comparison modal`,
      ),
    );
  };

  return (
    <>
      <StyledBackground
        backgroundImage={imageFieldsToMediaImageProps(backgroundImage).mobile}
        borderRadius={spacing[4]}
        data-test-id="marketing-tile-with-modal-background"
      >
        <Flex
          flexDirection="column"
          gap={spacing[16]}
          margin={`${spacing[32]} ${spacing[16]} ${spacing[16]} ${spacing[16]}`}
        >
          <Media
            width={spacing[40]}
            height={spacing[40]}
            media={imageFieldsToMediaImageProps(supportingImage)}
          />
          <Display textColor={white} size="small" style={{ flexGrow: 1 }}>
            <Markdown content={text} />
          </Display>
          <Button
            color="light"
            onClick={onOpenModal}
            size="small"
            width="adaptive"
            variant="outline"
          >
            {ctaText}
          </Button>
          <ProductComparisonModal
            isOpen={isModalOpen}
            name={modalName}
            content={modalBody}
            membershipOptionsText={modalSupport}
            onClose={onCloseModal}
            productName="App"
          />
        </Flex>
      </StyledBackground>
    </>
  );
};

const StyledBackground = styled(Flex)`
  max-height: 360px;
  overflow: hidden;
  background-image: url('${(props: { backgroundImage: string }) =>
    props.backgroundImage}');
  background-size: cover;
`;

export default MarketingTileWithModal;
