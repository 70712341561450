import React from 'react';
import type { TypeComponentJson } from '@page-builder/lib/types';
import JsonComponentMap from './maps';

const ComponentJsonHandler: React.FC<React.PropsWithChildren<TypeComponentJson>> = ({
  fields,
}) => {
  const { name, component, payload } = fields;
  const Component = JsonComponentMap[component];

  if (!Component) {
    console.warn(`${component} cannot be handled`);
    return null;
  }

  return <Component key={`componentJson-${component}`} {...payload} name={name} />;
};

export default ComponentJsonHandler;
