import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@page-builder/utils/helpers';

type PillsWrapperProps = {
  children: React.ReactNode;
  floating?: boolean;
  dataTestId: string;
};

const PillsWrapper: React.FC<PillsWrapperProps> = ({
  children,
  floating = true,
  dataTestId,
}) => (
  <StyledPillsWrapper
    data-test-id={dataTestId}
    gap={{
      mobile: spacing[4],
      desktop: spacing[8],
    }}
    flexDirection={{
      mobile: 'column',
      desktop: 'row',
    }}
    alignItems={{
      mobile: 'flex-start',
      desktop: 'center',
    }}
    floating={floating}
  >
    {children}
  </StyledPillsWrapper>
);

const StyledPillsWrapper = styled(Flex)<{ floating: boolean }>`
  position: ${props => (props.floating ? 'absolute' : 'static')};
  z-index: 1;

  top: ${spacing[8]};
  left: ${spacing[8]};

  ${media.desktop`
    top: ${spacing[16]};
    left: ${spacing[16]};
  `}
`;

export default PillsWrapper;
