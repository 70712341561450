import { Button, Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { Link } from '@ecomm/internationalize-ui';
import type { CtaType } from '@ecomm/product-recommendations/models/TileComponentProps';
import type { ButtonDisplayLocation } from '@ecomm/product-recommendations/utils/toTileButtons';
import { toTileButtons } from '@ecomm/product-recommendations/utils/toTileButtons';

interface TileButtonsProps {
  ctaType: CtaType | undefined;
  breakpoint: 'mobile' | 'desktop';
  isMobile: boolean;
  handlers: {
    onQuickView: React.MouseEventHandler;
    onQuickAdd: React.MouseEventHandler;
    onSeeDetails: React.MouseEventHandler;
  };
  displayLocation: ButtonDisplayLocation;
  loading: boolean;
}

const GridTileCtas: React.FC<TileButtonsProps> = ({
  ctaType,
  breakpoint,
  isMobile,
  handlers,
  displayLocation,
  loading,
}) => {
  if (!ctaType) {
    return null;
  }

  const buttons = toTileButtons(ctaType, handlers);

  return (
    <Flex
      justifyContent="space-between"
      gap={spacing[16]}
      padding={{
        mobile:
          displayLocation === 'imageContainer' ? `0 ${spacing[8]} ${spacing[8]}` : `0`,
        desktop: `0 ${spacing[16]} ${spacing[16]}`,
      }}
      style={{
        zIndex: 2,
        position: 'relative',
      }}
    >
      {buttons
        .filter(button => button.displayLocations[breakpoint].includes(displayLocation))
        .map((button, index) =>
          displayLocation === 'belowDescription' ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              is="button"
              size="small"
              variant="body"
              data-test-id={`${button.id}-cta-${displayLocation}`}
              key={`${button.id}-${index}`}
              onClick={button.onClick}
            >
              {button.text}
            </Link>
          ) : (
            <Button
              key={`${button.id}-${index}`}
              size={isMobile ? 'small' : button.size}
              color="dark"
              width="adaptive"
              variant={button.variant}
              onClick={button.onClick}
              data-test-id={`${button.id}-cta-${displayLocation}`}
              isLoading={button.id === 'quick-add' && loading}
            >
              {button.text}
            </Button>
          ),
        )}
    </Flex>
  );
};

export default GridTileCtas;
