import { Container, Flex, Headline, grey, spacing } from '@pelotoncycle/design-system';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ModuleComponentProps } from '@ecomm/product-recommendations/models/ModuleComponentProps';
import {
  DEFAULT_THEME_NAME,
  toGridTheme,
} from '@ecomm/product-recommendations/utils/theme';
import {
  CAROUSEL_CLICK_DELAY,
  OBSERVER_HANDLER_DELAY,
} from '@page-builder/modules/VideoCarousel/constants';
import Pagination from '@page-builder/modules/VideoCarousel/Pagination';
import {
  getObserver,
  getScrollToLeftPosition,
  usePaginationPropValues,
} from '@page-builder/modules/VideoCarousel/utils';

export const MAX_CARDS_PER_SLIDE = {
  mobile: 2,
  desktop: 4,
};

const CARD_WIDTHS = {
  mobile: '144px',
  desktop: '288px',
};

const LAST_SLIDE_OFFSET = '82px';

const CarouselModuleComponent: React.FC<ModuleComponentProps> = ({
  cohort,
  children,
  options: { headerGap },
  themeName,
}) => {
  const productTiles = React.useMemo(() => {
    return React.Children.toArray(children) as React.ReactElement[];
  }, [children]);

  const { trackEvent } = useTracking();
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const { headline, eyebrow: carouselPaginationTextRaw } = cohort;
  const [carouselProductTileSlides, setCarouselProductTileSlides] = useState<
    React.ReactElement[]
  >([]);
  const { backgroundColor, textColor } = toGridTheme(themeName);

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktop}px)`);
  const isMobileOnly = useMedia(`(max-width: ${BreakpointWidth.tablet}px)`);
  const maxCardsPerSlide = isMobileOrTablet
    ? MAX_CARDS_PER_SLIDE.mobile
    : MAX_CARDS_PER_SLIDE.desktop;
  const totalSlideCount = Math.ceil(productTiles.length / maxCardsPerSlide);

  // Element Refs that help determine the scroll position of the carousel items.
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  // Intersection observers for mobile viewports to allow user to swipe through product tile slides.
  const timeoutIdsRef = useRef<Record<number, ReturnType<typeof setTimeout>>>({});
  const observerRefs = useRef<(IntersectionObserver | null)[]>([]);
  const activeSlideIndexRef = useRef(activeSlideIndex);

  useEffect(() => {
    // Handles the assignment and sets of the correct amount of product grid tiles per slide once the component mounts.
    if (productTiles.length > 0) {
      const groupedProductTiles = productTiles.reduce(
        (groupedTiles: React.ReactElement[][], tile, tileIndex) =>
          (tileIndex % maxCardsPerSlide
            ? groupedTiles[groupedTiles.length - 1].push(tile)
            : groupedTiles.push([tile])) && groupedTiles,
        [],
      ) as React.ReactElement[][];

      const carouselSlides = groupedProductTiles.map(
        (groupedTiles: React.JSX.Element[], slideIndex: number) => {
          return renderSlide(slideIndex, groupedTiles);
        },
      );

      setCarouselProductTileSlides(carouselSlides);
    }
  }, [productTiles, maxCardsPerSlide]);

  const sendCarouselTrackingEvent = useCallback(() => {
    trackEvent({
      event: TrackingEvent.ClickedCarouselSlide,
      properties: {
        parent: 'Component: Carousel Product Grid',
        parentType: 'Carousel Product Grid',
      },
    });
  }, [trackEvent]);

  const handleActiveItemOnMobileScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]; // each observer is observing a single element
      if (!entry) {
        return;
      }

      const isElementWithinRootBounds = entry.isIntersecting;
      const slideIndex = itemRefs.current.indexOf(entry.target as HTMLElement);
      if (isElementWithinRootBounds && activeSlideIndexRef.current !== slideIndex) {
        timeoutIdsRef.current[slideIndex] = setTimeout(() => {
          setActiveSlideIndex(slideIndex);
          sendCarouselTrackingEvent();
        }, OBSERVER_HANDLER_DELAY);
      } else {
        clearTimeout(timeoutIdsRef.current[slideIndex]);
      }
    },
    [sendCarouselTrackingEvent],
  );

  useEffect(() => {
    // Keeps track of the active index while the state is not up to date.
    activeSlideIndexRef.current = activeSlideIndex;
  }, [activeSlideIndex]);

  useEffect(() => {
    // Disconnect all current observers
    observerRefs.current.forEach(observer => observer?.disconnect());
    const observers = observerRefs.current;

    // Set up functionality for mobile viewports where users are able to set inactive cards via scroll.
    if (isMobileOnly) {
      for (const [slideIndex, cardElement] of itemRefs.current.entries()) {
        const totalCardsLength = itemRefs.current.length;

        if (scrollContainerRef.current && cardElement) {
          const newObserver = getObserver(
            scrollContainerRef,
            cardElement,
            slideIndex,
            totalCardsLength,
            handleActiveItemOnMobileScroll,
          );

          observers[slideIndex] = newObserver;
          observers[slideIndex]?.observe(cardElement);
        }
      }
    }

    return () => {
      observers.forEach(observer => observer?.disconnect());
    };
  }, [handleActiveItemOnMobileScroll, isMobileOnly, carouselProductTileSlides]);

  /**
   * Renders the product grid tiles in a wrapper for each slide and sets the ref on the slide for the carousel functionality.
   * @param slideIndex index of the slide being created. This is used to set the ref on the slide for the carousel to scroll to.
   * @param productTilesInSlide the product grid tile elements that will be displayed in the slide.
   */
  const renderSlide = (slideIndex: number, productTilesInSlide: React.JSX.Element[]) => {
    return (
      <Slide
        data-test-id="carousel-product-grid-slide"
        key={`carousel-product-grid-slide-${slideIndex}`}
        ref={(el: HTMLElement | null) => {
          itemRefs.current[slideIndex] = el;
        }}
      >
        {productTilesInSlide.map(productTile => {
          return (
            <CarouselTile key={`product-tile-${productTile.key}`}>
              {productTile}
            </CarouselTile>
          );
        })}
      </Slide>
    );
  };

  /**
   * Scrolls the new active card to the center position of the scroll container.
   * @param slideIndex index of the current slide.
   */
  const scrollToActiveItem = (slideIndex: number) => {
    const scrollReviewsContainer = scrollContainerRef.current;
    const activeSlide = itemRefs.current[slideIndex];

    if (activeSlide && scrollReviewsContainer) {
      const activeItemPosition = getScrollToLeftPosition(
        activeSlide,
        scrollReviewsContainer.offsetWidth,
        slideIndex,
        totalSlideCount,
      );

      setTimeout(
        () => scrollReviewsContainer.scrollTo({ left: activeItemPosition }),
        CAROUSEL_CLICK_DELAY,
      );
    }
  };

  const handleActiveItem = (slideIndex: number) => {
    setActiveSlideIndex(slideIndex);
    scrollToActiveItem(slideIndex);
    sendCarouselTrackingEvent();
  };

  // Values to pass into the Pagination component.
  const paginationPropValues = usePaginationPropValues(
    activeSlideIndex,
    totalSlideCount,
    carouselPaginationTextRaw ?? '',
    handleActiveItem,
  );

  return (
    <CarouselProductGridContainer
      data-test-id="carousel-product-grid"
      padding={{
        mobile: `0 0 0 ${spacing[16]}`,
        tablet: `0 ${spacing[40]}`,
      }}
    >
      <Flex
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        alignItems={{ mobile: 'flex-start', desktop: 'center' }}
        flexGrow={1}
        justifyContent={headline ? 'space-between' : 'flex-end'}
        gap={headerGap}
        verticalPadding={{ mobile: `0 ${spacing[16]}`, desktop: `0 ${spacing[24]}` }}
      >
        {headline && (
          <Headline size="small" is="h1">
            {headline}
          </Headline>
        )}
        {paginationPropValues && carouselProductTileSlides.length > 1 && (
          <Pagination
            textColorOverride={textColor}
            buttonOutlineColorOverride={textColor}
            backgroundColorOverride={backgroundColor}
            disabledStateColorOverride={
              themeName === DEFAULT_THEME_NAME ? grey[60] : undefined
            }
            theme={themeName}
            {...paginationPropValues}
          />
        )}
      </Flex>
      <ProductGridTilesContainer
        data-test-id="product-grid-tiles-container"
        flexDirection="row"
        ref={scrollContainerRef}
        isMobileOnly={isMobileOnly}
        isMobileOrTablet={isMobileOrTablet}
      >
        <SlideContainer
          data-test-id="product-grid-scroll-container"
          isMobileOrTablet={isMobileOrTablet}
        >
          {carouselProductTileSlides}
        </SlideContainer>
      </ProductGridTilesContainer>
    </CarouselProductGridContainer>
  );
};

export default CarouselModuleComponent;

const CarouselProductGridContainer = styled(Container)`
  max-width: 1224px;

  @media (min-width: ${BreakpointWidth.tablet}px) {
    width: 100%;
  }

  @media (min-width: ${BreakpointWidth.desktopXLarge}px) {
    padding: 0;
  }
`;

const ProductGridTilesContainer = styled(Flex)<{
  isMobileOnly: boolean;
  isMobileOrTablet: boolean;
}>`
  overflow: ${({ isMobileOnly }) => (isMobileOnly ? 'scroll' : 'hidden')};
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;

  // to hide the scrollbar
  box-sizing: content-box;

  // hide scrollbar on safari
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SlideContainer = styled.ul<{ isMobileOrTablet: boolean }>`
  display: grid;
  grid-gap: ${({ isMobileOrTablet }) => (isMobileOrTablet ? spacing[16] : spacing[24])};
  grid-auto-flow: column;
  list-style-type: none;
  margin: 0;
  padding: ${({ isMobileOrTablet }) => (isMobileOrTablet ? 0 : `0 ${spacing[16]}`)};
  position: relative;
  width: fit-content;

  // used to offset and correctly scroll snap the last slide to the left (on mobile)
  :last-child {
    padding-right: ${({ isMobileOrTablet }) =>
      isMobileOrTablet ? `${spacing[104]}` : 0};
  }

  @media (min-width: ${BreakpointWidth.desktopLarge}px) {
    padding: 0;
  }
`;

const Slide = styled.li`
  display: flex;
  gap: ${spacing[16]};
  scroll-snap-align: start;
  width: 304px;

  @media (min-width: ${BreakpointWidth.tablet}px) {
    gap: ${spacing[24]};
    position: relative;
    width: calc(100vw - ${LAST_SLIDE_OFFSET});
  }

  @media (min-width: ${BreakpointWidth.desktopXLarge}px) {
    width: 100vw;
    max-width: 1224px;
  }
`;

const CarouselTile = styled(Flex)`
  width: ${CARD_WIDTHS.mobile};

  a {
    max-width: 100%;
  }
  p {
    white-space: break-spaces;
  }

  @media (min-width: ${BreakpointWidth.tablet}px) {
    // calculate dynamic width using
    min-width: ${CARD_WIDTHS.mobile};
    width: calc((100vw - ${LAST_SLIDE_OFFSET}) / ${MAX_CARDS_PER_SLIDE.mobile});
  }

  @media (min-width: ${BreakpointWidth.desktop}px) {
    // calculate dynamic width using
    width: calc(
      (100vw - ${spacing[80]} - ${spacing[72]}) / ${MAX_CARDS_PER_SLIDE.desktop}
    );
  }

  @media (min-width: ${BreakpointWidth.desktopLarge}px) {
    max-width: ${CARD_WIDTHS.desktop};
  }
`;
