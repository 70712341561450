import { AccessoryBundleKeys } from '../constants';
import type { ProductCatalogType } from '../models/main';
import { getAttributeValueByName } from './getAttributeValueByName';

export const isCfuPackage = (product: ProductCatalogType) => {
  return Boolean(
    product.masterData.current &&
      getAttributeValueByName<AccessoryBundleKeys>(
        product.masterData.current.masterVariant.attributesRaw,
        AccessoryBundleKeys.LEGACY_EQUIPMENT_TYPE,
      ),
  );
};
