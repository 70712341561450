import { hasVariablePricing, toPriceRange } from '@ecomm/accessories/models';
import type { AccessoryCommerceTools } from '@ecomm/pg-shop-accessories-display/models';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { AccessoryFieldsFragment } from '@ecomm/product-recommendations/queries/AllProducts.generated';
import { toPriceRangeTuple } from '@ecomm/product-recommendations/utils/productUtils';

type Accessory = AccessoryCommerceTools | AccessoryFieldsFragment;

export const isAccessoryWarranty = (accessory: Accessory): boolean => {
  return accessory.slug.includes('warr');
};

export const toProductFromAccessory = (accessory: Accessory): Product => {
  const price = hasVariablePricing(accessory)
    ? toPriceRangeTuple(toPriceRange(accessory))
    : accessory.price.amount;

  return {
    productType: 'accessory',
    slug: accessory.slug,
    name: accessory.name,
    description: accessory.description,
    imageUrl: '',
    images: accessory.images.map(i => i.src),
    price,
    id: accessory.id,
    isConfigurable: accessory.attributes.length > 0 && !isAccessoryWarranty(accessory),
    attributes: !isAccessoryWarranty(accessory) ? accessory.attributes : [],
    variants: accessory.variants,
  };
};
