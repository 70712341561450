import {
  Body,
  Container,
  Eyebrow,
  Flex,
  Media,
  Support,
  spacing,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import { MarkdownWithDefaultRenderers, OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { BreakpointWidth } from '@peloton/styles';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import usePlatform, { Platform } from '@ecomm/app-download/usePlatform';
import {
  containedProps,
  FiftyFiftyContainer,
  MediaContainer,
} from '@page-builder/modules/FiftyFiftyMedia/FiftyFiftyMedia';
import { themes } from '@page-builder/themes';
import { nameToModuleId } from '@page-builder/utils/helpers';
import type {
  MediaReferenceType as MediaReference,
  Theme as ThemeReference,
} from '../../../types/referenceTypes';
import type { JSONComponentProps } from '../../../types/utilityTypes';
import { handleJumpLinks } from '../../../utils/linkUtils';
import type { CtaProps as CtaPropsType } from './types';

export type DeviceCtaHeroProps = {
  eyebrow?: string;
  headlineMedia?: MediaReference;
  body?: string;
  support?: string;
  theme?: ThemeReference;
  media?: MediaReference;
  desktopCtas?: CtaPropsType[];
  iOSCtas?: CtaPropsType[];
  androidCtas?: CtaPropsType[];
};

/**
 * The DeviceCtaHero module is a derivation of one of our many Hero components (and will replace the iOSCtaHero). The main difference is the CTA will support linking to a separate URL if the user is on iOS or on Android.
 *
 * This models a 50/50 layout, including:
 *
 * Header Section (Top left of module)
 * - Eyebrow
 * - Headline Media
 * - Body
 *
 * CTA Section (Bottom left of module)
 * - CTA (Desktop or iOS or Android)
 * - Support
 *
 * Media Section (Right side of module)
 * - Media
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __name*__  | Name of the module;    |
 * | __eyebrow__ | Eyebrow shown above the headline media     |
 * | __headlineMedia__    | Headline, supports SVG  |
 * | __body__    | Body shown below the headline media |
 * | __support__    | Support shown below the CTA |
 * | __media__  | Media shown in the right side of the module, Videos will autoplay and loop    |
 * | __theme__    | Defaults to Grey 90 (other themes not tested at time of creation) |
 * | __desktopCtas__    | CTA(s) that show up on desktop or if the iOSCta and androidCtas do not have ctas defined. This will replace the existing desktopCta field so that more than 1 cta can be supported and is considered the default field for ctas being rendered |
 * | __iOSCtas__    | CTA(s) that show up on the hero when the user is on an iOS device. This will replace the existing iOSCta field so that more than 1 cta can be supported |
 * | __androidCtas__    | CTA(s) that show up on the hero when the user is on an android device |
 *
 * CTAs include
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __text*__  | Text used inside the CTA    |
 * | __link*__ | WWW or External Link     |
 * | __icon__    | String of a valid icon name, or leave blank for no icon  |
 * | __color__    | 'dark' / 'light' / 'primary' / 'accent' , defaults to primary |
 * | __variant__    | 'solid' / 'outline', defaults to solid |
 *
 */
const DeviceCtaHero: React.FC<JSONComponentProps<DeviceCtaHeroProps>> = ({
  name,
  eyebrow,
  headlineMedia,
  body,
  support,
  theme = 'Grey 90',
  media,
  desktopCtas = [],
  iOSCtas = [],
  androidCtas = [],
}) => {
  const { trackEvent } = useTracking();
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );
  const { backgroundColor, textColor } = themes[theme];
  const platform = usePlatform();

  /**
   * Checks which platform the user device is on and returns the corresponding list of ctas that should be rendered.
   * If the returned ctas for the device's platform is empty (iOSCtas and/or androidCtas), it will default to use the desktopCtas.
   * @returns CtaPropsType[] | []
   */
  const ctasForDevice = () => {
    switch (platform) {
      case Platform.iOS:
        return iOSCtas.length > 0 ? iOSCtas : desktopCtas;
      case Platform.Android:
        return androidCtas.length > 0 ? androidCtas : desktopCtas;
      default:
        return desktopCtas;
    }
  };
  const ctas = ctasForDevice();

  /**
   * Maps the list of ctas to a StyledLinkButton element that will be rendered on the hero.
   * @returns React.JSX.Element[]
   */
  const renderCtas = () => {
    return ctas.map(heroCta => {
      return (
        <StyledLinkButton
          key={heroCta.text}
          href={heroCta.link.url}
          color={heroCta.color}
          variant={heroCta.variant}
          icon={heroCta.icon as IconNames}
          size="medium"
          onClick={e =>
            handleJumpLinks(e, headbandRef, heroCta.link, () =>
              handleTracking(heroCta.text, heroCta.link.url),
            )
          }
          style={{ width: '100%' }}
        >
          {heroCta.text}
        </StyledLinkButton>
      );
    });
  };

  const mediaProps = media && {
    ...media,
    autoplay: true,
    progressTracked: true,
    loop: true,
  };

  const handleTracking = (text: string, url: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: JSON',
        parent: 'DeviceCtaHero (CHAMP)',
        unitName: 'DeviceCtaHero CTA',
        linkName: text,
        linkTo: url,
      },
    });
  };

  return (
    <OuterContainer
      id={nameToModuleId(name)}
      backgroundColor={backgroundColor}
      theme={theme}
      verticalPadding={{ mobile: spacing[32], desktop: spacing[80] }}
      horizontalPadding={{ mobile: spacing[16], desktop: spacing[88] }}
    >
      <FiftyFiftyContainer
        backgroundColor={backgroundColor}
        maxWidth={containedProps.maxWidth}
        gap={{ mobile: spacing[16], desktop: spacing[24] }}
        removeOuterMargins
      >
        <Flex
          flexDirection="column"
          alignItems={{ mobile: 'center', desktop: 'flex-start' }}
          textAlign={{ mobile: 'center', desktop: 'left' }}
          style={{ gridArea: 'header' }}
          gap={spacing[24]}
          verticalPadding={{ desktop: `${spacing[48]} 0` }}
        >
          {eyebrow && (
            <Eyebrow size="small" textColor={textColor}>
              <MarkdownWithDefaultRenderers text={eyebrow ?? ''} />
            </Eyebrow>
          )}
          {headlineMedia && (
            <Container>
              <Media media={headlineMedia} />
            </Container>
          )}
          {body && (
            <Container maxWidth="432px">
              <Body size="medium" textColor={textColor}>
                <MarkdownWithDefaultRenderers text={body ?? ''} />
              </Body>
            </Container>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          textAlign={{ mobile: 'center', desktop: 'left' }}
          alignItems={{ mobile: 'center', desktop: 'flex-start' }}
          removeOuterMargins
          verticalPadding={{ desktop: `0 ${spacing[48]}` }}
          gap={spacing[16]}
          style={{ gridArea: 'cta', alignSelf: 'flex-end' }}
        >
          {ctas && ctas.length > 0 && (
            <CtaContainer
              flexDirection={{ mobile: 'column', desktop: 'row' }}
              gap={spacing[16]}
              desktopWidthStyling={ctas.length > 1 ? '100%' : 'auto'}
            >
              {renderCtas()}
            </CtaContainer>
          )}
          {support && (
            <Support size="medium" textColor={textColor}>
              <MarkdownWithDefaultRenderers text={support ?? ''} />
            </Support>
          )}
        </Flex>
        <MediaContainer style={{ gridArea: 'media' }}>
          {mediaProps && <Media media={mediaProps} />}
        </MediaContainer>
      </FiftyFiftyContainer>
    </OuterContainer>
  );
};

export default DeviceCtaHero;

const StyledLinkButton = styled(LinkButton)`
  svg {
    margin-bottom: ${spacing[2]};
  }
`;

const CtaContainer = styled(Flex)<{ desktopWidthStyling: string }>`
  @media (max-width: ${BreakpointWidth.desktopLarge}px) {
    width: 100%;
  }

  width: ${({ desktopWidthStyling }) => `${desktopWidthStyling};`};
`;
