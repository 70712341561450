import { useMemo } from 'react';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import useProductDataForPage from '@page-builder/utils/product-data/useProductDataForPage';

const useCanCohortBeBuildTimeRendered = (cohort: ProductRecommendationsCohort) => {
  const allProductData = useProductDataForPage();

  return useMemo(() => {
    return cohort.products.every(({ slug }) => {
      const productData =
        allProductData.cfuPackages[slug] ||
        allProductData.bundles[slug] ||
        allProductData.accessories[slug];

      return Boolean(productData?.basePrice);
    });
  }, [allProductData, cohort]);
};

export default useCanCohortBeBuildTimeRendered;
