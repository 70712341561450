import * as React from 'react';
import FormatText from '@ecomm/copy/FormatText';
import { toDollarsCeil } from '@ecomm/models';

export const SinglePrice: React.FC<React.PropsWithChildren<{ price: number }>> = ({
  price,
}) => (
  <FormatText
    content="{price, number, currency}"
    values={{ price: toDollarsCeil(price) }}
  />
);
export const PriceRange: React.FC<
  React.PropsWithChildren<{ range: Readonly<[number, number]> }>
> = ({ range: [low, high] }) => (
  <FormatText
    content="{low, number, currency} – {high, number, currency}"
    values={{ low: toDollarsCeil(low), high: toDollarsCeil(high) }}
  />
);
