import logAction from '@ecomm/logging/logAction';
import { ProductStates } from '@ecomm/product-states/models';
import useRequiresPostalCode from '@page-builder/modules/Overview/ProductAvailability/useRequiresPostalCode';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';

const useCanAddToCart = () => {
  const { isPostalCodeEligible, productBundleType, productPackage } = useShopContext();
  const doesRequirePostalCode = useRequiresPostalCode();

  if (!productBundleType) {
    // log error if productBundleType is not defined
    logAction('Add to Cart Button Error (useCanAddToCart)', {
      error: 'productBundleType is undefined in the Shop Context',
      productBundleType: productBundleType,
      productPackage: productPackage,
      isPostalCodeEligible: isPostalCodeEligible,
    });

    return false;
  }

  if (doesRequirePostalCode(productBundleType)) {
    return isPostalCodeEligible;
  }

  const availabilityState = productPackage?.availability.state;
  const isAvailable = !availabilityState || availabilityState === ProductStates.Available;

  if (!isAvailable) {
    // log error if the product is not available
    logAction(`Add to Cart Button Error (useCanAddToCart)`, {
      error: `productBundleType: ${productBundleType} does not have a valid availablity state in the Shop Context`,
      productBundleType: productBundleType,
      productPackage: productPackage,
      doesRequirePostalCode: doesRequirePostalCode(productBundleType),
      isPostalCodeEligible: isPostalCodeEligible,
      availabilityState: availabilityState,
      isAvailableProductState: availabilityState === ProductStates.Available,
    });
  }

  return isAvailable;
};

export default useCanAddToCart;
