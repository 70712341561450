import type { ButtonProps } from '@pelotoncycle/design-system';
import type { CtaType } from '@ecomm/product-recommendations/models/TileComponentProps';

export type ButtonDisplayLocation = 'imageContainer' | 'belowDescription';

type TileButtonProps = {
  id: string;
  text: string;
  size: ButtonProps['size'];
  variant: ButtonProps['variant'];
  onClick: React.MouseEventHandler;
  displayLocations: {
    desktop: ButtonDisplayLocation[];
    mobile: ButtonDisplayLocation[];
  };
};

export const toTileButtons = (
  ctaType: CtaType,
  callbacks: {
    onQuickView: React.MouseEventHandler;
    onQuickAdd: React.MouseEventHandler;
    onSeeDetails: React.MouseEventHandler;
  },
): TileButtonProps[] => {
  const buttonMap: Record<CtaType, TileButtonProps[]> = {
    quickView: [
      {
        id: 'quick-view',
        text: 'Quick view',
        size: 'small',
        variant: 'outline',
        onClick: callbacks.onQuickView,
        displayLocations: {
          desktop: ['imageContainer'],
          mobile: ['imageContainer'],
        },
      },
    ],
    upsellCtaGroup: [
      {
        id: 'quick-add',
        text: 'Quick add',
        size: 'medium',
        variant: 'solid',
        onClick: callbacks.onQuickAdd,
        displayLocations: {
          desktop: ['imageContainer'],
          mobile: ['imageContainer'],
        },
      },
      {
        id: 'see-details',
        text: 'See details',
        size: 'medium',
        variant: 'outline',
        onClick: callbacks.onSeeDetails,
        displayLocations: {
          desktop: ['imageContainer'],
          mobile: ['belowDescription'],
        },
      },
    ],
  };

  return buttonMap[ctaType] || [];
};
