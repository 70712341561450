import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import type {
  ExternalLinkReferenceType as ExternalLinkReference,
  WwwLinkReferenceType as WwwLinkReference,
} from '../../../types/referenceTypes';
import { handleJumpLinks } from '../../../utils/linkUtils';
import { findDeviceFromRoute } from './utils';

export type HeadbandCTAProps = {
  text: string;
  link?: WwwLinkReference | ExternalLinkReference;
  iosLink?: ExternalLinkReference;
};

export const CTA: React.FC<
  HeadbandCTAProps & {
    parentTitle?: string;
    behavior?: 'adaptive' | 'adjustable' | 'fixed';
  }
> = ({ text, link, parentTitle, behavior = 'adjustable' }) => {
  const { trackEvent } = useTracking();
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );
  const shrinkBehavior = behavior === 'adjustable' ? 0 : 'unset';

  const handleClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedSubnavATC,
      properties: {
        linkTo: link?.url,
        parent: parentTitle,
        parentType: 'Freeform Headband',
        unitName: `Freeform Headband ${text} CTA`,
        linkName: text,
        productName: findDeviceFromRoute(link?.url ?? ''),
      },
    });
  };

  return (
    <LinkButton
      href={link?.url}
      size="small"
      width={behavior}
      margin={`${spacing[8]} 0;`}
      onClick={e => handleJumpLinks(e, headbandRef, link, handleClick)}
      style={{ flexShrink: shrinkBehavior }}
    >
      {text}
    </LinkButton>
  );
};
