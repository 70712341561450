import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { truncateReference } from '@content/client/lib/truncateComponentJsonEntry';
import type {
  TypeComponentCta,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import type { TableOfContentsProps } from './TableOfContents';
import TOCItems from './TOCItems';
import TOCItemsDropdown from './TOCItemsDropdown';
import type { LegacyTOCItemsProps, TOCItemLinkProps, TOCItemsProps } from './types';

const ctaToTOCItemLink = (cta: TypeComponentCta): TOCItemLinkProps => {
  return {
    text: cta.fields.text,
    link: cta.fields.link ? truncateReference(cta.fields.link) : { url: '' },
    variant: cta.fields.variant,
  };
};

const StyledFlex = styled(Flex)`
  padding: 0 0 0 ${spacing[16]};
  overflow-x: auto;
  align-items: center;

  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;
`;

export const treatmentMap = {
  Original: {
    mobileComponent: TOCItemsDropdown,
    overflowContainer: React.Fragment,
  },
  'Mobile Carousel': {
    mobileComponent: TOCItems,
    overflowContainer: StyledFlex,
  },
};

export const toPbxTOCItemsProps = (props: LegacyTOCItemsProps): TOCItemsProps => {
  const transformedCtas = props.ctas
    ? props.ctas.map((cta: TypeComponentCta) => ctaToTOCItemLink(cta))
    : ([{ text: '', link: { url: '' }, variant: 'outline' }] as TOCItemLinkProps[]);

  return {
    ctas: transformedCtas,
    headbandRef: props.headbandRef,
    parentName: props.parentName,
    activeId: props.activeId,
  };
};

export const toPbxTOCProps = (
  props: TypeComponentGenericListFields,
): TableOfContentsProps => {
  const transformedCtas = props.ctas?.map((cta: TypeComponentCta) =>
    ctaToTOCItemLink(cta),
  );

  return {
    ctas: transformedCtas,
    title: getTextFieldsWithRequiredKeys(['eyebrow'], props.text).eyebrow,
  };
};
