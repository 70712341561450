import { Toggle, grey, spacing } from '@pelotoncycle/design-system';
import type { JSONComponentProps } from 'apps/freeform/types/utilityTypes';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { themes, ThemeValues } from '@page-builder/themes';
import type { Theme as TypeTheme } from '../../../types/referenceTypes';
import type { MediaCarouselProps as TypeMediaCarousel } from '../MediaCarousel/MediaCarousel';
import MediaCarousel from '../MediaCarousel/MediaCarousel';

export type ToggledCarouselsProps = {
  headline?: string;
  theme?: TypeTheme;
  carousels: (TypeMediaCarousel & { toggleTitle: string })[];
};

export const ToggledCarousels: React.FC<JSONComponentProps<ToggledCarouselsProps>> = ({
  name,
  headline,
  theme = 'Grey 90',
  carousels,
}) => {
  const { trackEvent } = useTracking();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { type: themeType, backgroundColor } = themes[theme];

  const handleToggleChange = () => {
    const updatedIndex = activeIndex === 0 ? 1 : 0;
    setActiveIndex(updatedIndex);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'ToggledCarousels (CHAMP)',
        parent: `${headline} ToggledCarousels`,
        unitName: `${headline} ToggledCarousels Toggle`,
        linkName: carousels[updatedIndex].toggleTitle,
      },
    });
  };

  return (
    <MediaCarousel
      name={name}
      {...carousels[activeIndex]}
      theme={theme}
      headline={headline}
      carouselIndex={activeIndex}
    >
      <StyledToggle
        leftButton={{ eyebrowText: carousels[0].toggleTitle }}
        rightButton={{ eyebrowText: carousels[1].toggleTitle }}
        onChange={handleToggleChange}
        theme={themeType as 'dark' | 'light'}
        variant={theme === ThemeValues.White ? 'bordered' : 'base'}
        backgroundColor={themeType === 'light' ? grey[30] : backgroundColor}
      />
    </MediaCarousel>
  );
};

const StyledToggle = styled(Toggle)`
  > div[role='radio'] {
    min-width: 140px;
    height: ${spacing[64]};
  }
`;
