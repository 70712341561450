import {
  BreakpointWidths,
  white,
  Flex,
  spacing,
  grey,
} from '@pelotoncycle/design-system';
import type { TypeComponentHeadbandFields } from '@pelotoncycle/page-builder';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useSubHeaderReference from '@acme-ui/global/hooks/useSubHeaderReference';
import { themes } from '@page-builder/themes';
import { HEADER_HEIGHT_DESKTOP_LARGE, HEADBAND_HEIGHT_MOBILE, zIndex } from './constants';

type HeadbandContainerProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'is'> & {
  stackHeadband?: boolean;
  theme?: TypeComponentHeadbandFields['theme'];
  treatment?: 'Original' | 'Mobile Carousel';
};

const mobileStyleMap = {
  Original: {
    mobile: `0 ${spacing[16]}`,
  },
  'Mobile Carousel': {
    mobile: 0,
  },
};

const HeadbandContainer: React.FC<React.PropsWithChildren<HeadbandContainerProps>> = ({
  stackHeadband,
  theme = 'Grey 90',
  treatment = 'Original',
  ...props
}) => {
  const headbandRef = useSubHeaderReference();
  const { backgroundColor } = themes[theme];

  return (
    <>
      {stackHeadband && <BackgroundContainer backgroundTheme={backgroundColor} />}
      <StyledContainer
        ref={headbandRef}
        backgroundTheme={backgroundColor}
        stackHeadband={stackHeadband}
        justifyContent="space-between"
        alignItems="center"
        padding={{
          ...mobileStyleMap[treatment],
          tablet: `0 ${spacing[24]}`,
          desktop: `0 ${spacing[32]}`,
        }}
        position="sticky"
        {...props}
      />
    </>
  );
};

export default HeadbandContainer;

const BackgroundContainer = styled(Flex)<{ backgroundTheme: string }>`
  position: absolute;
  ${props => `background-color: ${props.backgroundTheme};`}

  height: ${HEADBAND_HEIGHT_MOBILE}px;
  width: 100%;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    height: ${HEADER_HEIGHT_DESKTOP_LARGE}px;
  }
`;

export const StyledContainer = styled(Flex)<{
  backgroundTheme?: string;
  stackHeadband?: boolean;
}>`
  height: ${HEADBAND_HEIGHT_MOBILE}px;
  ${props =>
    props.backgroundTheme &&
    `background-color: ${transparentize(0.1, props.backgroundTheme)};`}

  // this prevents the backdrop-filter blur from being applied to the children
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(12px);
    z-index: -1;
  }

  top: 0;
  z-index: ${zIndex.HEADBAND};
  width: 100%;
  color: ${white};
  ${props => !props.stackHeadband && `margin-bottom: -${HEADBAND_HEIGHT_MOBILE}px;`}

  h2 + span {
    width: auto;
    min-width: 160px;
    max-height: 32px;
  }

  ${props => props.backgroundTheme === white && `border-bottom: 1px solid ${grey[40]};`}

  @media (min-width: ${BreakpointWidths.tablet}px) {
    height: ${HEADER_HEIGHT_DESKTOP_LARGE}px;
    h2 + span {
      max-height: none;
    }
    ${props =>
      !props.stackHeadband && `margin-bottom: -${HEADER_HEIGHT_DESKTOP_LARGE}px;`}
  }
`;
