import { spacing, Icon, grey, Flex, Label } from '@pelotoncycle/design-system';
import { useSelect } from 'downshift';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import { zIndex } from '@page-builder/modules/Headband/constants';
import { themes } from '@page-builder/themes';
import { scrollToSection } from '../../../utils/linkUtils';
import type { TOCItemLinkProps, TOCItemsProps } from './types';

const TOCItemsDropdown: React.FC<TOCItemsProps> = ({
  activeId,
  ctas,
  parentName,
  headbandRef,
  theme = 'Grey 90',
}) => {
  const { trackEvent } = useTracking();
  const { headlineColor } = themes[theme];

  const dropdownLabel = useMicroCopy('jumpTo');
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [displayedId, setDisplayedId] = useState<string | undefined>(activeId);
  const previousActiveId = useRef(activeId);

  useEffect(() => {
    if (previousActiveId.current !== activeId) {
      setIsAnimating(true);
      setDisplayedId(previousActiveId.current);
      const timer: NodeJS.Timeout = setTimeout(() => {
        setIsAnimating(false);
        setDisplayedId(activeId);
        previousActiveId.current = activeId;
      }, 150);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [activeId]);

  const expandDropdownText = useMemo(
    () => ctas.find(cta => cta.link.url === displayedId)?.text ?? dropdownLabel,
    [displayedId, dropdownLabel, ctas],
  );

  const onKeyDown = (
    cta: TOCItemLinkProps,
    e: React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      onSelectedItemChange(cta);
    }
  };

  // We cannot pass this directly to useSelect() because it will not trigger if user selects same option twice
  // We need to support use case of user choosing option A, scrolling to section B, and re-selecting option A
  const onSelectedItemChange = (cta: TOCItemLinkProps) => {
    if (cta) {
      const sectionId = cta.link.url;
      scrollToSection(sectionId, headbandRef);

      trackEvent({
        event: TrackingEvent.ClickedSubnavMenu,
        properties: {
          parentType: 'TableOfContents (CHAMP)',
          parent: parentName,
          unitName: `Table of Contents Dropdown ${cta.text} CTA`,
          linkName: cta.text,
        },
      });

      closeMenu();
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
    closeMenu,
  } = useSelect({
    items: ctas,
  });

  return (
    <Flex
      flexDirection="column"
      horizontalMargin="auto 0"
      data-test-id="toc-items-dropdown"
    >
      <SelectInteraction {...getToggleButtonProps()} className={isOpen && 'expanded'}>
        <StyledLabel
          {...getLabelProps()}
          isAnimating={isAnimating}
          textColor={headlineColor}
        >
          {expandDropdownText}
        </StyledLabel>
        <Icon
          name="chevron"
          height={8}
          rotate={isOpen ? 90 : -90}
          primaryColor={headlineColor}
        />
      </SelectInteraction>
      <Flex flexDirection="column" alignItems="flex-end">
        <SelectList
          {...getMenuProps()}
          flexDirection="column"
          className={isOpen && 'expanded'}
        >
          {ctas.map((cta, index) => (
            <SelectListItem
              key={cta.text}
              data-test-id="toc-item"
              {...getItemProps({ item: cta, index })}
              onClick={() => onSelectedItemChange(cta)}
              onKeyDown={e => onKeyDown(cta, e)}
            >
              <Label>{cta.text}</Label>
            </SelectListItem>
          ))}
        </SelectList>
      </Flex>
    </Flex>
  );
};

export default TOCItemsDropdown;

const DROPDOWN_WIDTH = 122;

const StyledLabel = styled(Label)<{ isAnimating: boolean }>`
  transition: opacity 0.15s ease-in-out;
  opacity: ${props => (props.isAnimating ? 0 : 1)};
`;

const SelectInteraction = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${spacing[40]};
  border-radius: 8px;
  border: ${grey[50]} solid 1px;
  padding: ${spacing[12]};
  min-width: ${DROPDOWN_WIDTH}px;
  gap: ${spacing[12]};

  &.expanded {
    border: white solid 2px;
    background-color: ${grey[70]}33;
    backdrop-filter: blur(50px);
    // account for border
    min-width: ${DROPDOWN_WIDTH + 2}px;
    transform: translateX(1px);
  }
`;

const SelectList = styled(Flex)<{ isOpen: boolean }>`
  z-index: ${zIndex.HEADER_DROPDOWN};
  position: absolute;
  margin-top: ${spacing[16]};
  border-radius: 8px;
  border: 1px solid ${grey[70]}99;
  padding: ${spacing[8]};
  background-color: ${grey[80]}99;
  backdrop-filter: blur(20px);
  box-shadow: 3px 10px 15px -5px rgba(0, 0, 0, 0.14);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 200ms ease;
  min-width: ${DROPDOWN_WIDTH}px;

  &.expanded {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
`;

const SelectListItem = styled.button`
  padding: ${spacing[8]};
  cursor: pointer;
`;
