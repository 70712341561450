import { Eyebrow, Icon, grey } from '@pelotoncycle/design-system';
import React from 'react';
import type ReactModal from 'react-modal';
import type { BaseModalProps } from '@ecomm/modal/BaseModal';
import getRootElement from '../getRootElement';
import ModalGlobalStyles from '../ModalGlobalStyles';
import setRootElement from '../setRootElement';
import { ModalContent, StickyHeader, StyledModal, CloseButton, Fixed } from './styles';

export type Props = BaseModalProps & {
  subnavText: string;
  selectedItem: number | undefined;
};

// Ported from @ecomm/plp-v2/FeatureCardModal
const ViewportModalWithHeader: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen = true,
  closeHandler,
  openHandler,
  contentLabel,
  subnavText,
  selectedItem,
  children,
}) => {
  const modalContentRef = React.useRef<HTMLDivElement>(null);

  const handleAfterOpen = () => {
    openHandler && openHandler();
    setTimeout(() => {
      if (modalContentRef !== null) {
        const el = modalContentRef.current?.querySelector(`.pos${selectedItem}`);
        const top = el?.getBoundingClientRect().top || 0;
        const modal = document.querySelector('.ReactModal__Overlay--after-open');
        if (modal) {
          modal.scrollTo({
            // 48 is the height of the mobile sticky header
            top: top - 48,
            left: 0,
            behavior: 'instant',
          });
        }
      }
    }, 1);
  };

  const handleRequestClose = () => {
    closeHandler && closeHandler();
  };
  if (isOpen) {
    // http://reactcommunity.org/react-modal/accessibility/#app-element
    const root = getRootElement();
    StyledModal.setAppElement(root as HTMLElement);
  }
  /*
   * NOTE: DO NOT WRAP THE StyledModal COMPONENT IN ANY DOM ELEMENTS!
   *
   * The StyledModal uses a portal to attach its elements to a different part of the DOM.
   * Wrapping the StyledModal in a DOM element here will leave the element in a spot in the DOM
   * that is likely different from where you are expecting it to go.
   */
  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel={contentLabel}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      role="dialog"
      aria={{ modal: 'true' } as ReactModal.Aria}
    >
      <ModalGlobalStyles /> {/* Taken from Legacy modal, leaving for stability */}
      <Fixed /> {/* input-cursor fix; see comment on component declaration */}
      {/* eslint-disable styled-components-a11y/no-autofocus */}
      {/* while autoFocus is generally a violation, in this case, it actually *fixes* an issue where the focus trap isn't set within the lightbox modal on iOS Safari */}
      <ModalContent ref={modalContentRef}>
        <StickyHeader>
          <Eyebrow size="small">{subnavText}</Eyebrow>
          <CloseButton
            onClick={handleRequestClose}
            data-test-id="dismissModal"
            aria-label="Close"
          >
            <Icon name="close" height={24} primaryColor={grey[60]}></Icon>
          </CloseButton>
        </StickyHeader>
        {children}
      </ModalContent>
    </StyledModal>
  );
};

export default ViewportModalWithHeader;

setRootElement();
