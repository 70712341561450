import {
  grey,
  spacing,
  Container,
  Flex,
  ResponsiveImage,
  Label,
} from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { debounceTimer } from './carouselUtils';
import PaginationButtons from './PaginationButtons';
import PlayPauseToggle from './PlayPauseToggle';
import type { VideoCard as VideoCardProps } from './types';

const CLICKED_CLASSES_CARD_EVENT_TYPE = 'Clicked Classes Card';

const ClassDetailsThumbnail: FC<
  React.PropsWithChildren<{
    classLandingPageUrl: string;
    slideIndex: number;
    setInViewSlideIndex: (slideIndex: number) => void;
    trackClickedClassesCard: () => void;
  }>
> = ({
  children,
  classLandingPageUrl,
  slideIndex,
  setInViewSlideIndex,
  trackClickedClassesCard,
}) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView === true) {
      setInViewSlideIndex(slideIndex);
    }
  }, [inView]);

  return (
    <Link
      to={classLandingPageUrl}
      href={classLandingPageUrl}
      hasUnderline={false}
      onClick={() => trackClickedClassesCard()}
    >
      <Flex
        ref={inViewRef}
        minWidth="326px"
        maxHeight={spacing[88]}
        flexDirection="row"
        padding={spacing[12]}
        gap={spacing[12]}
        margin={`0 ${spacing[12]} 0 0`}
        borderRadius={spacing[12]}
        backgroundColor="rgba(34, 37, 41, 0.80)"
        style={{
          backdropFilter: 'blur(2px)',
          scrollSnapAlign: 'start',
        }}
        id={`classDetails-thumbnail-${slideIndex}`}
      >
        {children}
      </Flex>
    </Link>
  );
};

const MobileHeroCarousel: FC<{
  videoCards: VideoCardProps[];
}> = ({ videoCards }) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const [inViewSlideIndex, setInViewSlideIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const totalNumberOfSlides = videoCards.length;
  // Hardcoded number of times to loop through the carousel to create the illusion of infinite scrolling.  Implementing real infinite scrolling results in performance issues.
  const numCarouselLoops = 4;

  const playEventListener = (event: Event) => {
    setShowPlayButton(true);
  };
  useEffect(() => {
    const videoRef = ref?.current;
    videoRef?.addEventListener('play', playEventListener);

    return () => {
      videoRef?.removeEventListener('play', playEventListener);
    };
  }, []);

  const { asPath } = useRouter();

  const { trackEvent } = useTracking();

  const trackClickedClassesCard = () => {
    trackEvent({
      event: CLICKED_CLASSES_CARD_EVENT_TYPE,
      properties: {
        page: asPath,
        parent: 'Prospect Classes Page',
        linkTo: `${
          videoCards[inViewSlideIndex % totalNumberOfSlides].classLandingPageUrl
        }`,
        linkName: `${videoCards[inViewSlideIndex % totalNumberOfSlides].badgeText ?? ''}`,
        unitName: 'HeroBanner',
        parentType: 'Page',
      },
    });
  };

  const handleNextSlide = () => {
    const nextSlide = document.getElementById(
      `classDetails-thumbnail-${inViewSlideIndex + 1}`,
    );
    nextSlide?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  };

  const handlePreviousSlide = () => {
    const previousSlide = document.getElementById(
      `classDetails-thumbnail-${inViewSlideIndex - 1}`,
    );
    previousSlide?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  };

  const togglePlayPause = () => {
    const isVideoPlaying = !ref?.current?.paused;

    if (isVideoPlaying) {
      ref?.current?.pause();
      ref?.current && setIsPlaying(false);
    } else {
      ref?.current?.play();
      ref?.current && setIsPlaying(true);
    }
  };

  const debouncedHandleNextSlide = debounceTimer(handleNextSlide);
  const debouncedHanldePreviousSlide = debounceTimer(handlePreviousSlide);

  return (
    <Container position="relative">
      <PaginationButtons
        activeSlide={inViewSlideIndex % totalNumberOfSlides}
        totalNumberOfSlides={totalNumberOfSlides}
        handleNextSlide={debouncedHandleNextSlide}
        handlePreviousSlide={debouncedHanldePreviousSlide}
        nextButtonDisabled={
          inViewSlideIndex === totalNumberOfSlides * numCarouselLoops - 1
        }
        prevButtonDisabled={inViewSlideIndex === 0}
      />
      <Container
        max-width="390px"
        margin={`${spacing[16]} 0 0 0`}
        style={{ top: '168px' }}
      >
        <StyledLink
          to={videoCards[inViewSlideIndex % totalNumberOfSlides].classLandingPageUrl}
          href={videoCards[inViewSlideIndex % totalNumberOfSlides].classLandingPageUrl}
          hasUnderline={false}
          onClick={() => trackClickedClassesCard()}
        >
          <StyledVideo
            ref={ref}
            src={videoCards[inViewSlideIndex % totalNumberOfSlides].videoUrl ?? ''}
            poster={
              videoCards[inViewSlideIndex % totalNumberOfSlides].posterImage?.mobile ?? ''
            }
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            preload="metadata"
          />
          <Container
            position="absolute"
            style={{
              top: 0,
              left: 0,
              height: '100%',
              cursor: 'pointer',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0) 55.56%, rgba(0, 0, 0, 0.7) 100%)',
            }}
          />
        </StyledLink>
      </Container>
      <Container
        position="absolute"
        padding={`0 0 0 ${spacing[16]}`}
        width="100%"
        style={{ bottom: spacing[16] }}
      >
        <Flex
          style={{
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            scrollBehavior: 'smooth',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
          }}
        >
          {Array(numCarouselLoops)
            .fill(videoCards)
            .flat()
            .map((videoCard, index) => {
              return (
                <ClassDetailsThumbnail
                  key={index}
                  classLandingPageUrl={videoCard.classLandingPageUrl}
                  slideIndex={index}
                  setInViewSlideIndex={setInViewSlideIndex}
                  trackClickedClassesCard={trackClickedClassesCard}
                >
                  <Container
                    border={spacing[8]}
                    maxWidth="114px"
                    height="100%"
                    width="114px"
                    style={{ overflow: 'hidden' }}
                  >
                    <StyledResponsiveImage
                      mobile={videoCard.mobileThumbnail.mobile}
                      alt="mobile-thumbnail"
                    />
                  </Container>
                  <Flex
                    flexDirection="column"
                    gap={spacing[4]}
                    height="100%"
                    justifyContent="center"
                    flexGrow={1}
                  >
                    <Label textColor={grey[30]}>{videoCard.classTitle}</Label>
                    <Label textColor={grey[50]}>{videoCard.fitnessDiscipline}</Label>
                  </Flex>
                </ClassDetailsThumbnail>
              );
            })}
        </Flex>
      </Container>
      {showPlayButton && (
        <PlayPauseToggle onClick={togglePlayPause} playing={isPlaying} />
      )}
    </Container>
  );
};

const StyledResponsiveImage = styled(ResponsiveImage)`
  img {
    max-width: 100%;
  }
`;

const StyledVideo = styled.video`
  display: block;
  object-fit: cover;
  height: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

export default MobileHeroCarousel;
