import {
  black,
  slate,
  brand,
  white,
  Eyebrow,
  Body,
  grey,
} from '@pelotoncycle/design-system';
import type { ValidTypographyHTMLTags } from '@pelotoncycle/design-system/types';
import { rgba } from 'polished';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import PageContext from '@page-builder/layout/context/PageContext';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { ctaLinkToUrlRequired } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { BlockPerformantImage } from './PerformantImage';

export type Props = TypeComponentGenericTextWithMedia & {
  cardIndex: number;
  headingTag?: ValidTypographyHTMLTags;
};

const BlogCard: React.FC<React.PropsWithChildren<Props>> = ({
  fields,
  cardIndex,
  headingTag,
}) => {
  const { name: page } = useContext(PageContext);
  const { name, text, media: cardMedia, ctas } = fields;
  const { eyebrow, body } = getGenericTextNodes(text, ['eyebrow', 'body']);

  const { trackEvent } = useTracking();

  const image = toImageProps(cardMedia, true);
  const link = ctas && ctaLinkToUrlRequired(ctas?.[0]);

  const handleClick = () => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        parent: 'Blog Module',
        unitName: 'Blog Article Link',
        linkTo: link,
      },
    });
  };

  return (
    <ZoomableBlogCard
      data-test-id={name}
      href={link && link}
      onClick={ctas && handleClick}
    >
      <StyledImageContainer>
        {image && (
          <StyledImage
            alt={image.alt}
            breakpointOptions={{
              mobile: {
                src: image.mobile,
                width: 380,
              },
              tablet: {
                src: image.tablet,
                width: 768,
              },
              desktop: {
                src: image.desktop,
                width: 256,
              },
            }}
            loading="lazy"
            src={image.mobile}
          />
        )}
      </StyledImageContainer>
      <FloatingCard>
        <Eyebrow
          as={headingTag}
          size="small"
          textColor={slate[50]}
          data-test-id={`${page}BlogCardTitle-${cardIndex}`}
        >
          {eyebrow}
        </Eyebrow>
        <StyledHr />
        <Body size="small" textColor={grey[70]}>
          {body}
        </Body>
      </FloatingCard>
    </ZoomableBlogCard>
  );
};

const FloatingCard = styled.div`
  background-color: ${white};
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0 8px 18px 2px ${rgba(black, 0.1)};
  margin: -36px 16px 0;
  transform: translateY(0);
  transition: all 500ms ease-in-out;

  ${media.desktop`
    padding: 40px 80px;
  `}

  ${media.desktopLarge`
    padding: 25px;
  `}

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ZoomableBlogCard = styled.a.attrs({ target: '_blank' })`
  display: flex;
  flex-direction: column;

  &:hover,
  &:focus {
    picture {
      transform: scale(1.03);
    }

    ${FloatingCard} {
      transform: translateY(-2%);
    }
  }

  text-align: center;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  ${media.desktop`
    margin-bottom: 40px;
  `}

  ${media.desktopLarge`
    margin: 0 12.5px;
    max-width: 384px;
  `}

  p {
    max-width: 14em;
    margin: 0 auto;
  }
`;

const StyledHr = styled.hr`
  margin: 16px auto;
  width: 40px;
  height: 1px;
  border: 0.5px solid ${brand.primary};
`;

const StyledImageContainer = styled.div`
  overflow: hidden;
`;

const StyledImage = styled(BlockPerformantImage)`
  img {
    width: 100%;
  }
`;

export default BlogCard;
