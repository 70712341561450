import { Flex, grey, spacing, white } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { transparentize } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import usePlatform, { Platform } from '@ecomm/app-download/usePlatform';
import AddToCartCta from '@page-builder/modules/Overview/ShopDrawers/AddToCartCta';
import { toCtaTrackingProperties } from '@page-builder/modules/Overview/utils';
import { themes } from '@page-builder/themes';
import type { Theme } from '../../../../freeform/types/referenceTypes';
import type { HeadbandCTAProps } from './CTA';
import { CTA } from './CTA';

export const MobileCTABanner: React.FC<{
  ctas?: HeadbandCTAProps[];
  title?: string;
  theme?: Theme;
  addToCartCta?: TypeComponentCta;
}> = ({ ctas, title, addToCartCta, theme = 'Grey 90' }) => {
  const { trackEvent } = useTracking();
  const platform = usePlatform();
  const { backgroundColor } = themes[theme];

  if (!ctas && !addToCartCta) {
    return null;
  }

  const trackLinkClicked = () => {
    if (addToCartCta) {
      trackEvent({
        event: TrackingEvent.ClickedSubnavATC,
        properties: toCtaTrackingProperties(addToCartCta.fields, 'Headband'),
      });
    }
  };

  const isIos = platform === Platform.iOS;

  return (
    <StickyContainer
      gap={spacing[16]}
      position="fixed"
      width="100%"
      justifyContent="center"
      padding={`${spacing[12]} ${spacing[16]}`}
      data-test-id="mobile-cta-banner"
      backgroundTheme={backgroundColor}
      alignItems="center"
    >
      {ctas &&
        ctas.map(cta => (
          <CTA
            key={cta.text}
            text={cta.text}
            link={isIos && cta.iosLink ? cta.iosLink : cta.link}
            parentTitle={title}
            behavior="adaptive"
          />
        ))}
      {addToCartCta && (
        <AddToCartCta
          cta={addToCartCta?.fields}
          size="medium"
          onClick={trackLinkClicked}
        />
      )}
    </StickyContainer>
  );
};

const StickyContainer = styled(Flex)<{ backgroundTheme: string }>`
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ backgroundTheme }) => transparentize(0.1, backgroundTheme)};
  border-top: ${({ backgroundTheme }) =>
    backgroundTheme === white && `1px solid ${grey[40]}`};
`;
