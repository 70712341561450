import React from 'react';
import type { TypeQuiz } from '@page-builder/lib/types';
import ProgressProvider from './components/ProgressProvider';
import QuizContent from './components/QuizContent';

const Quiz: React.FC<React.PropsWithChildren<TypeQuiz>> = quiz => {
  return (
    <ProgressProvider sessionKey={quiz.fields.quizName}>
      <QuizContent quiz={quiz} />
    </ProgressProvider>
  );
};

export default Quiz;
