import React from 'react';
import {
  isCommerceToolsAccessory,
  isCommerceToolsBundle,
  isCommerceToolsCFU,
  isCommerceToolsSparePart,
} from '@ecomm/product-recommendations/models/checkProductType';
import type {
  CommerceToolsAccessory,
  CommerceToolsBundle,
  CommerceToolsCFU,
  CommerceToolsSparePart,
  Product,
} from '@ecomm/product-recommendations/models/Product';
import {
  useQuickAddAccessoryMutationCommerceTools,
  useQuickAddBundleMutationCommerceTools,
  useQuickAddCfuPackageMutationCommerceTools,
  useQuickAddSparePartMutationCommerceTools,
} from '@ecomm/product-recommendations/utils/quick-add/quickAddMutationCommerceTools';
import type { QuickAddMutation } from '@ecomm/product-recommendations/utils/quick-add/types';

type Props<ProductType> = {
  product: ProductType;
  children: (addToCartMutation: QuickAddMutation) => React.ReactNode;
};

const AccessoryWrapper: React.FC<Props<CommerceToolsAccessory>> = ({
  product,
  children,
}) => children(useQuickAddAccessoryMutationCommerceTools(product));

const BundleWrapper: React.FC<Props<CommerceToolsBundle>> = ({ product, children }) =>
  children(useQuickAddBundleMutationCommerceTools(product));

const CFUWrapper: React.FC<Props<CommerceToolsCFU>> = ({ product, children }) =>
  children(useQuickAddCfuPackageMutationCommerceTools(product));

const SparePartWrapper: React.FC<Props<CommerceToolsSparePart>> = ({
  product,
  children,
}) => children(useQuickAddSparePartMutationCommerceTools(product));

const AddToCartMutationWrapper: React.FC<Props<Product>> = ({ product, children }) => {
  if (isCommerceToolsAccessory(product)) {
    return <AccessoryWrapper product={product}>{children}</AccessoryWrapper>;
  } else if (isCommerceToolsBundle(product)) {
    return <BundleWrapper product={product}>{children}</BundleWrapper>;
  } else if (isCommerceToolsCFU(product)) {
    return <CFUWrapper product={product}>{children}</CFUWrapper>;
  } else if (isCommerceToolsSparePart(product)) {
    return <SparePartWrapper product={product}>{children}</SparePartWrapper>;
  }

  return null;
};

export default AddToCartMutationWrapper;
