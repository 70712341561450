import {
  Container,
  Flex,
  Icon,
  Label,
  green,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import toFormattedText from '@peloton/copy/toFormattedText';
import { Locale, useLocale } from '@peloton/internationalize';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import shouldHideContent from '@ecomm/copy/helpers/shouldHideContent';
import { roundByFinancingPartner } from '@ecomm/financing/models/monthlyPaymentWithApr';
import { Partners } from '@ecomm/financing/models/Partners';
import { toBundleType } from '@ecomm/graphql-bridge/EquipmentType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { toDollars } from '@ecomm/models';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import { toEntryTags } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';
import toFinancingPartnerFromTags from '../Financing/toFinancingPartnerFromTags';
import type { DrawerFinancingData } from './../../DrawerContentContext';
import { useDrawerContentContext } from './../../DrawerContentContext';
import CtaHandler from './../CtaHandler';

const shouldShowFinancingSection = (drawerFinancingData?: DrawerFinancingData) => {
  if (!drawerFinancingData) return false;

  const hasFinancingText =
    drawerFinancingData.eyebrow && !shouldHideContent(drawerFinancingData.eyebrow);
  const hasCta = drawerFinancingData.cta;
  return Boolean(hasFinancingText && hasCta);
};

type Props = {
  productSlug: string;
  product: string;
  isDrawerOpen: boolean;
  index: number;
  parentName: string;
  isSoloDrawer?: boolean;
};

type ComponentProps = {
  drawerFinancingSectionContent: DrawerFinancingData;
  product: string;
  isDrawerOpen: boolean;
  parentName: string;
  equipmentType: string;
  slug: string;
  price: number;
  isSoloDrawer: boolean;
  packageName: string;
};

const DrawerFinancingComponent: React.FC<React.PropsWithChildren<ComponentProps>> = ({
  drawerFinancingSectionContent,
  product,
  isDrawerOpen,
  parentName,
  equipmentType,
  slug,
  price,
  isSoloDrawer,
  packageName,
}) => {
  const locale = useLocale();
  const packageBundleType = toBundleType(equipmentType as EquipmentType);
  const { data } = useBundlePromo(packageBundleType!, slug);
  const discount = data?.fields.couponDiscount || 0;
  const overridePriceWithDiscount = price - discount * 100;

  const tags = toEntryTags(drawerFinancingSectionContent.cta!);
  const partner = toFinancingPartnerFromTags(tags);

  // we want to support other term lengths, but 12mos is the easiest to calculate and tested right now
  const termLength = 12;
  // todo: from above, how can we support other term lengths?
  // we should double check what the APR values we support + prices are correct
  const apr = useApr(locale, packageBundleType!, termLength);
  const monthlyPrice = roundByFinancingPartner(
    overridePriceWithDiscount,
    termLength,
    apr,
    partner,
  );
  const textWithPromo = discount
    ? drawerFinancingSectionContent.eyebrow
    : drawerFinancingSectionContent.eyebrow!.replace(/_/g, '');

  const formattedPriceText = toFormattedText(textWithPromo, {
    price: toDollars(monthlyPrice),
  }).toString();

  return (
    <Container position="relative">
      <Flex
        backgroundColor={grey[30]}
        padding={`${spacing[4]} ${spacing[8]}`}
        verticalMargin={isSoloDrawer ? `0 ${spacing[8]}` : `${spacing[8]} 0`}
        borderRadius={spacing[4]}
        justifyContent="center"
        display={isSoloDrawer ? 'inline-flex' : 'flex'}
      >
        <Flex flexDirection="row" alignItems="center" gap={spacing[8]}>
          {partner === Partners.Klarna && (
            <Flex padding={spacing[2]}>
              <Icon name="klarnaBadge" height={20} />
            </Flex>
          )}
          {drawerFinancingSectionContent.eyebrow && (
            <StyledLabel>
              <Markdown
                content={formattedPriceText}
                markdown={{
                  renderers: {
                    emphasis: ({ node, ...props }) => (
                      <span style={{ color: green[50] }}>{props.children}</span>
                    ),
                  },
                }}
              />
            </StyledLabel>
          )}
          {drawerFinancingSectionContent.cta && (
            <Flex
              justifyContent="center"
              position="relative"
              minWidth={locale === Locale.GermanGermany ? '100px' : '75px'}
              minHeight="28px"
              style={{
                zIndex: 2,
              }}
            >
              <CtaHandler
                cta={drawerFinancingSectionContent.cta}
                product={product}
                drawerName={parentName}
                modalLayout="financing"
                isActive={isDrawerOpen}
                isDrawerFinancingSection={true}
                financingPriceOverride={overridePriceWithDiscount}
                packageNameOverride={packageName}
                key={drawerFinancingSectionContent.cta.fields.name}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

const DrawerFinancingSection: React.FC<React.PropsWithChildren<Props>> = ({
  productSlug,
  product,
  isDrawerOpen,
  index,
  parentName,
  isSoloDrawer = false,
}) => {
  const productBuildTimeData = useCFUProductDataForSlug(productSlug);

  const { drawerFinancingData } = useDrawerContentContext();
  const drawerFinancingSectionContent = drawerFinancingData.get(index);

  const shouldShowDrawerFinancingSection = shouldShowFinancingSection(
    drawerFinancingSectionContent,
  );

  if (!drawerFinancingSectionContent || !shouldShowDrawerFinancingSection) return null;

  if (productBuildTimeData) {
    const { equipmentType, slug, basePrice, name } = productBuildTimeData;

    return (
      <DrawerFinancingComponent
        drawerFinancingSectionContent={drawerFinancingSectionContent}
        product={product}
        isDrawerOpen={isDrawerOpen}
        parentName={parentName}
        equipmentType={equipmentType!}
        slug={slug}
        price={basePrice}
        isSoloDrawer={isSoloDrawer}
        packageName={name}
      />
    );
  }

  return null;
};

const StyledLabel = styled(Label)`
  padding-top: ${spacing[2]};
`;

export default DrawerFinancingSection;
