import { useHasVariation } from '@peloton/split-testing/hooks';

const MOVEMENT_LIBRARY_EXPERIMENT = 'Movement Library Test';
const MOVEMENT_LIBRARY_VARIATION = 'Variation #1';

const useIsMovementLibraryExperimentActive = () => {
  return useHasVariation(MOVEMENT_LIBRARY_EXPERIMENT, MOVEMENT_LIBRARY_VARIATION);
};

export default useIsMovementLibraryExperimentActive;
