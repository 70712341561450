import { Support, spacing, red, Badge } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import ListRenderer from '@page-builder/layout/MarkdownPage/renderers/ListRenderer';
import type { TypeComponentGenericTextFields } from '@page-builder/lib/types';
import { useUrgencyMessagingMarkdownValues } from '@page-builder/modules/Overview/utils';
import Markdown from '@page-builder/utils/Markdown';

type MarkdownProps = {
  content?: TypeComponentGenericTextFields['body'];
};

export const DrawerMarkdown: React.FC<React.PropsWithChildren<MarkdownProps>> = ({
  content,
}) => {
  const markdownValues = useUrgencyMessagingMarkdownValues();

  if (!content) return null;
  return (
    <Markdown
      content={content}
      values={markdownValues}
      markdown={{
        renderers: {
          list: ({ node, ...props }) => (
            <List ordered={false} children={props.children} />
          ),
          listItem: ({ node, ...props }) => (
            <Support size="large" as="li">
              {props.children}
            </Support>
          ),
          emphasis: ({ node, ...props }) => (
            <span style={{ color: red[50] }}>{props.children}</span>
          ),
          inlineCode: ({ node, ...props }) => (
            <Badge theme="accent" variant="horizontal">
              {props.children}
            </Badge>
          ),
          link: ({ node, ...props }) => (
            <StyledLink to={props.href} href={props.href} target="_blank">
              <Support size="large">{props.children}</Support>
            </StyledLink>
          ),
        },
      }}
    />
  );
};

const StyledLink = styled(Link)`
  transform: translateY(1px);

  ::after {
    transform: translateY(-6px);
  }
`;

const List = styled(ListRenderer)`
  // Overrides the DS list font size to match the DS support font size
  li {
    font-size: 14px;
  }
  padding-left: ${spacing[16]};
`;
