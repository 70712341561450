import type * as Contentful from 'contentful';
import type { TypePageFields } from '@page-builder/lib/types';
import { truncateEntry } from './truncateEntry';
import type { ContentfulEntry } from './types';

/**
 * Parses a compose page, trimming down all entries in `content`
 * to contain only `contentType` `sys` values and `fields`.
 * @param page full compose page response
 * @returns truncated compose page
 */
const truncateComposePage = (page: Contentful.Entry<TypePageFields>) => {
  return {
    sys: page.sys,
    fields: {
      ...page.fields,
      content: {
        ...truncateEntry(page.fields.content as ContentfulEntry<TypePageFields>),
      },
    },
  };
};

export default truncateComposePage;
