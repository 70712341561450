import {
  grey,
  black,
  spacing,
  white,
  Flex,
  Label,
  Headline,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { useFiltersContext } from '@ecomm/product-recommendations/Views/FiltersContext';

const ALL_FILTER = 'All';

type Props = {
  cohortKeyName: string;
};

const FilterButtons: React.FC<Props> = ({ cohortKeyName }) => {
  const {
    hasAnyFilterGroups,
    filterGroups,
    enabledFilterTags,
    setEnabledFilterTags,
  } = useFiltersContext();
  const { trackEvent } = useTracking();

  if (!hasAnyFilterGroups) {
    return null;
  }

  const [{ title, filters }] = filterGroups;

  const handleFilterChange = (filterTag: string) => {
    setEnabledFilterTags(filterTag === ALL_FILTER ? [] : [filterTag]);

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'ProductGrid (CHAMP)',
        parent: cohortKeyName,
        unitName: title,
        linkName: filterTag,
      },
    });
  };

  return (
    <Flex
      flexDirection={{ mobile: 'column', tablet: 'row' }}
      gap={spacing[16]}
      alignItems={{ mobile: 'center', tablet: 'row' }}
      justifyContent={{ mobile: 'center', tablet: 'space-between' }}
    >
      {title && <Headline size="extraSmall">{title}</Headline>}
      <Flex gap={spacing[8]} flexShrink={1}>
        {filters.map(filter => (
          <FilterButton
            key={filter.tag}
            isActive={
              filter.tag === ALL_FILTER
                ? enabledFilterTags.length === 0
                : enabledFilterTags.includes(filter.tag)
            }
            onClick={() => handleFilterChange(filter.tag)}
          >
            <Label as="span">{filter.displayName || filter.tag}</Label>
          </FilterButton>
        ))}
      </Flex>
    </Flex>
  );
};

export default FilterButtons;

const FilterButton = styled.button<{ isActive: boolean }>`
  box-shadow: 0 0 0 1px ${grey[50]};
  border-radius: ${spacing[8]};
  padding: ${spacing[8]} ${spacing[16]};
  height: ${spacing[40]};
  display: flex;
  align-items: center;
  background: ${white};
  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 1px ${black};
      transition: box-shadow 500ms ease-in-out;
      cursor: pointer;
    }
  }
  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${black};
    & span {
      color: ${white};
    }
  `}
  transition: all 300ms ease-in-out;
`;
