import { ApolloError } from '@apollo/client';
import type {
  AccessoryBundle,
  BundleAccessoryType,
  ChildItemForBundle,
} from '@ecomm/pg-shop-accessories/models';
import type { ProductSelection } from '@ecomm/shop/models';

export const getAccessoryProductOptions = (
  accessory: AccessoryBundle | BundleAccessoryType,
  productSelections: ProductSelection[],
): string[] => {
  return productSelections.flatMap((selection: ProductSelection) => {
    const products: ChildItemForBundle[] = accessory.products as ChildItemForBundle[];
    const product = products.find(
      (p: ChildItemForBundle) => p.slug === selection.product,
    );

    if (!product) {
      throw new ApolloError({
        errorMessage: `Can't find ${selection.product} in bundle ${accessory.slug}`,
      });
    }

    if (selection.selections.length == 0 && product.variants.length > 0) {
      return product.variants[0].legacyOptionId;
    }

    return selection.selections.map(subSelection => {
      const configuration = product.variants.reduce((result, variant) => {
        if (variant.configurations.length === 0) {
          return { legacyOptionId: variant.legacyOptionId };
        }
        return (
          result ||
          variant.configurations.find(
            config =>
              config.attribute === subSelection.attribute &&
              config.option === subSelection.option,
          )
        );
      }, undefined);

      return configuration?.legacyOptionId || '';
    });
  });
};
