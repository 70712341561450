import React from 'react';
import CommercetoolsCFUProvider from '@page-builder/modules/Overview/CommercetoolsCFUContext';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import { toPrimaryDrawerCta } from '@page-builder/modules/Overview/utils';
import { CFUPackageContext } from './useCFUPackageContext';

const CFUPackageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { activeShopDrawer } = useDrawerSelectionContext();
  const addToCartCta = activeShopDrawer && toPrimaryDrawerCta(activeShopDrawer);
  const productSlug = addToCartCta?.fields.productSlug || '';

  const value = {
    shouldUseCTProvider: true,
  };

  return (
    <CFUPackageContext.Provider value={value}>
      <CommercetoolsCFUProvider slug={productSlug}>{children}</CommercetoolsCFUProvider>
    </CFUPackageContext.Provider>
  );
};

export default CFUPackageProvider;
