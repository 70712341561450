import { useTracking } from 'react-tracking';
import type { Cart } from '@ecomm/cart/models';
import { toDollars } from '@ecomm/models';
import type { AccessoryBundle } from '@ecomm/pg-shop-accessories-display/models';
import stripDashesFromId from '../models/stripDashesFromId';

export type BundleAnalyticsProperties = Pick<AccessoryBundle, 'id' | 'name' | 'price'>;

export const useAddBundleToCartAnalytics = () => {
  const { trackEvent } = useTracking();

  const trackAddBundleToCart = (
    analyticsProperties: BundleAnalyticsProperties,
    cart: Cart,
  ) => {
    trackEvent({
      event: 'Added Bundle',
      properties: {
        cartId: cart.id,
        graphql: true,
        id: stripDashesFromId(analyticsProperties.id),
        name: analyticsProperties.name,
        price: toDollars(analyticsProperties.price.amount),
        propertyType: 'Web',
      },
    });
  };

  return {
    trackAddBundleToCart,
  };
};
