import { Flex, Icon } from '@pelotoncycle/design-system';
import React from 'react';

// This component is largely ported from Grid/LoadingGrid
const GridLoadingComponent: React.FC = () => {
  return (
    <Flex
      minHeight={{
        mobile: '900px',
        tablet: '650px',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        name="spinner"
        height={32}
        data-test-id="spinner"
        primaryColor="currentColor"
      />
    </Flex>
  );
};

export default GridLoadingComponent;
