import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import useBannerReference from '@acme-ui/global/hooks/useBannerReference';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import { a11yOutline } from '@ecomm/buttons/buttons';
import EcommBanner from '@ecomm/cms-promo-banner/EcommBanner';
import Markdown from '@page-builder/utils/Markdown';
import type { WwwLinkReferenceType } from '../../../../apps/freeform/types/referenceTypes';
import { handleJumpLinks } from '../../../../apps/freeform/utils/linkUtils';
import BannerContainer from './components/BannerContainer';
import type { HybridBanner } from './utils';

const DualCtaBanner: React.FC<React.PropsWithChildren<HybridBanner>> = ({
  links,
  name,
  text,
  theme = 'white',
}) => {
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );
  const bannerRef = useBannerReference();
  const { trackLinkClick } = useTrackLinkClick();

  if (!links || links.length <= 1) {
    return null;
  }

  const { url, text: ctaText, name: ctaName } = links[0];
  const { url: url2, text: ctaText2, name: ctaName2 } = links[1];

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => (
    <StyledLabel size="responsive" weight="medium" data-test-id={`${name}Text`}>
      {children}
    </StyledLabel>
  );

  const BannerLink: React.FC<
    React.PropsWithChildren<{ href: string; index: number }>
  > = ({ children, href, index }) => {
    const linkTracking = () => {
      trackLinkClick({
        href,
        parent: 'hurricane banner',
        additionalProps: {
          parentType: 'componentBanner',
          linkName: index === 0 ? ctaText : ctaText2,
          unitName: index === 0 ? ctaName : ctaName2,
        },
      });
    };
    return (
      <Link
        href={href}
        data-test-id={`${name}Cta${index + 1}`}
        onClick={e =>
          handleJumpLinks(
            e,
            headbandRef,
            { url: href } as WwwLinkReferenceType,
            linkTracking,
          )
        }
      >
        {children}
      </Link>
    );
  };

  if (!url || !url2) {
    throw new Error('Please provide a valid url');
  }

  return (
    <BannerContainer theme={theme}>
      <EcommBanner ref={bannerRef} theme={theme}>
        <Markdown
          content={text}
          values={{
            link1: `[${ctaText}](${url})`,
            link2: `[${ctaText2}](${url2})`,
          }}
          markdown={{
            renderers: {
              paragraph: TextWithDataTestId,
              // eslint-disable-next-line react/display-name
              link: ((i = 0) => (props: any) => <BannerLink {...props} index={i++} />)(),
            },
          }}
        />
      </EcommBanner>
    </BannerContainer>
  );
};

export default DualCtaBanner;

const StyledLabel = styled(Label)`
  ${a11yOutline}

  a {
    font-size: inherit !important;
    font-weight: inherit !important;
  }
`;
