import {
  Tooltip,
  white,
  Eyebrow,
  Button,
  spacing,
  Body,
  Flex,
  Icon,
  grey,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system/icons/icons';
import type { GenericCtaData } from '@pelotoncycle/page-builder';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import {
  generateMembersClassUrl,
  generateAccountUrl,
} from '@peloton/members-urls/membersUrlUtils';
import { Link } from '@peloton/next/components/Link';
import { LinkButton } from '@peloton/next/components/LinkButton';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { media } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';

type Props = {
  tooltipData: GenericCtaData;
  className?: string;
  classId: string;
  discipline?: string;
  loginUrl: string;
};

export const getAnalyticsEventNameForCta = (ctaName: string | undefined) => {
  switch (ctaName) {
    case 'bookmarkCta':
      return TrackingEvent.ClickedBookmark;
    case 'scheduleCta':
      return TrackingEvent.ClickedSchedule;
    case 'stackCta':
      return TrackingEvent.ClickedStack;
    default:
      return TrackingEvent.ClickedLink;
  }
};

export const MembershipSectionTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipData,
  className,
  classId,
  discipline,
  loginUrl,
}) => {
  const { trackEvent } = useTracking();
  const toolTipText = tooltipData.modal?.text?.fields;

  const toolTipCTAs = tooltipData.modal?.ctas?.flatMap(modalCta => {
    const modalCtaData = ctaExtractData(modalCta);
    const isMembersClassUrl = modalCtaData.url?.includes('members.onepeloton');

    return modalCtaData.url
      ? {
          ...modalCtaData,
          url: isMembersClassUrl
            ? generateMembersClassUrl(modalCtaData.url, classId)
            : generateAccountUrl(modalCtaData.url, classId),
        }
      : [];
  });

  if (!toolTipText) {
    throw new Error('Missing modal text');
  }

  const trackClick = (link?: string, buttonText?: string, buttonName?: string) => {
    trackEvent({
      event: getAnalyticsEventNameForCta(buttonName),
      properties: {
        parent: 'Prospect Classes Page',
        className: className,
        discipline: discipline,
        unitName: buttonName,
        linkTo: link,
        parentType: 'Page',
      },
    });
  };

  return (
    <StyledTooltip
      trigger="click"
      position="bottom"
      thin={true}
      backgroundColor={white}
      maxWidth="400px"
      offsetX={-8}
      customTrigger={() => <CustomTrigger cta={tooltipData} clickHandler={trackClick} />}
    >
      <TooltipBody
        tooltipText={toolTipText}
        ctas={toolTipCTAs}
        loginUrl={loginUrl}
        clickHandler={trackClick}
      />
    </StyledTooltip>
  );
};

type CustomTriggerProps = {
  cta: GenericCtaData;
  clickHandler: (
    link?: string,
    buttonText?: string | undefined,
    buttonName?: string | undefined,
  ) => void;
};

const CustomTrigger: React.FC<React.PropsWithChildren<CustomTriggerProps>> = ({
  cta,
  clickHandler,
}) => {
  return (
    <BreakpointSwitchCSS
      breakpoints={{
        mobile: (
          <Flex
            padding={spacing[8]}
            onClick={() => clickHandler(cta.url, cta.text, cta.name)}
          >
            <Icon
              name={cta.icon as IconNames}
              primaryColor={white}
              height={32}
              width={32}
            />
          </Flex>
        ),
        tablet: (
          <StyledButton
            is="div"
            width="adaptive"
            color="dark"
            variant="solid"
            size="medium"
            icon={cta.icon as IconNames}
            data-test-id={cta.name}
            onClick={() => clickHandler(cta.url, cta.text, cta.name)}
          >
            {cta.text}
          </StyledButton>
        ),
      }}
    />
  );
};

type TooltipBodyProps = {
  tooltipText: any;
  ctas?: GenericCtaData[];
  loginUrl: string;
  clickHandler: (
    link?: string,
    buttonText?: string | undefined,
    buttonName?: string | undefined,
  ) => void;
};

const TooltipBody: React.FC<React.PropsWithChildren<TooltipBodyProps>> = ({
  tooltipText,
  ctas,
  loginUrl,
  clickHandler,
}) => {
  return (
    <TooltipContent>
      <Eyebrow size="small" marginBottom={spacing[16]}>
        {tooltipText.headline}
      </Eyebrow>
      <Body size="small" marginBottom={spacing[16]}>
        {tooltipText.body}
      </Body>
      {ctas && ctas.length > 0 && (
        <Flex flexDirection="row" gap={spacing[16]} alignItems="center">
          <LinkButton
            variant="solid"
            color="dark"
            size="small"
            width="adjustable"
            data-test-id="sign-up"
            onClick={() => clickHandler(ctas[0].url, ctas[0].text, ctas[0].name)}
            href={ctas[0].url}
            text={ctas[0].text}
          />
          {ctas.length > 1 && (
            <Link
              size="small"
              href={loginUrl}
              onClick={() => clickHandler(loginUrl, ctas[1].text, ctas[1].name)}
            >
              {ctas[1].text}
            </Link>
          )}
        </Flex>
      )}
    </TooltipContent>
  );
};

const StyledTooltip = styled(Tooltip)`
  height: 100%;
  margin-bottom: ${spacing[24]};
  ${media.tabletLarge`
      width: 100%;
  `}
  ${media.desktopLarge`
      margin-bottom: 0;
  `}

  span,
  button {
    ${media.tabletLarge`
      flex: 1;
  `}
  }
`;

const TooltipContent = styled.div`
  padding: ${spacing[24]};
  max-width: 300px;
`;

const StyledButton = styled(Button)`
  background-color: ${rgba(grey[70], 0.25)} !important;
  border-color: transparent !important;

  &:hover {
    background-color: ${rgba(grey[70], 0.75)} !important;
  }
`;
