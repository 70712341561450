import type { BadgeProps } from '@pelotoncycle/design-system';
import { Badge as Pill } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React from 'react';
import { SPECIAL_PRICING_PRODUCT_TAG } from '@peloton/concessionary-pricing/constants';
import toFormattedText from '@peloton/copy/toFormattedText';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import useConcessionaryPricing from '@ecomm/pg-concessionary-pricing/useConcessionaryPricing';
import type { PromoProps, CfuPromoProps, CfuAndBundlePromoProps } from '../types';
import { toActiveDiscount } from '../utils';

type ThemeProps = { theme?: BadgeProps['theme'] };

type PromoPillProps = ThemeProps & PromoProps;

const PromoPill: React.FC<React.PropsWithChildren<PromoPillProps>> = ({
  cfu,
  bundleSlug,
  theme,
}) => {
  if (cfu && !bundleSlug) {
    return <CfuPromoPill cfu={cfu} theme={theme} />;
  }

  if (cfu && bundleSlug) {
    return <CfuAndBundlePromoPill cfu={cfu} bundleSlug={bundleSlug} theme={theme} />;
  }

  return null;
};

export default PromoPill;

const CfuPromoPill: React.FC<React.PropsWithChildren<CfuPromoProps & ThemeProps>> = ({
  cfu,
  theme,
}) => {
  const { data } = useCfuPromo(cfu);
  const pillMessaging = data?.fields.pillMessaging;
  if (!pillMessaging) return null;
  return <PillComponent text={pillMessaging} theme={theme} />;
};

const CfuAndBundlePromoPill: React.FC<
  React.PropsWithChildren<CfuAndBundlePromoProps & ThemeProps>
> = ({ cfu, bundleSlug, theme }) => {
  const { data } = useBundlePromo(cfu, bundleSlug);
  const pillMessaging = data?.fields.pillMessaging;
  const couponDiscount = data?.fields.couponDiscount;

  const { asPath } = useRouter();
  const isSpecialPricingRoute = asPath.includes(SPECIAL_PRICING_PRODUCT_TAG);

  const { toDiscountForCFU } = useConcessionaryPricing(!isSpecialPricingRoute);
  const specialPricingDiscount = toDiscountForCFU(cfu);

  const activeDiscount = toActiveDiscount({
    discount: couponDiscount ?? 0,
    specialPricingDiscount,
  });

  if (isSpecialPricingRoute && activeDiscount && pillMessaging) {
    const updatedPillMessagingWithDiscount = pillMessaging.replace(
      /\$\d+/,
      `$${activeDiscount}`,
    );
    return <PillComponent text={updatedPillMessagingWithDiscount} theme={theme} />;
  }

  if (isSpecialPricingRoute && !(activeDiscount && pillMessaging)) {
    const updatedPillMessagingWithDiscount = toFormattedText('${number} off', {
      number: activeDiscount,
    }) as string;
    return <PillComponent text={updatedPillMessagingWithDiscount} theme={theme} />;
  }

  if (!isSpecialPricingRoute && pillMessaging) {
    return <PillComponent text={pillMessaging} theme={theme} />;
  }

  return null;
};

export const PillComponent: React.FC<
  React.PropsWithChildren<{ text: string } & ThemeProps>
> = ({ text, theme = 'accent' }) => (
  <Pill variant="horizontal" theme={theme}>
    {text}
  </Pill>
);
