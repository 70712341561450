import { Container, spacing, DataTable } from '@pelotoncycle/design-system';
import { createMarkdownArrayTableSync } from 'parse-markdown-table';
import React, { useState, useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media as mediaBreakpoints } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import ModalView from '@ecomm/modal/ModalView';
import { ScrollFromBottomModalStyles } from '@ecomm/modal/ScrollFromBottomModalStyles';
import Markdown from '@page-builder/utils/Markdown';
import type { MarkdownChildrenType } from '@page-builder/utils/Markdown';
import { useTheme } from '../themes';
import type { ThemeProps } from '../themes';
import { BodyCell, HeaderCell } from './ProductComparisonModalTable';

type Props = {
  isOpen: boolean;
  content: string;
  membershipOptionsText: string;
  name: string;
  onClose: () => void;
  productName: string;
};

const ProductComparisonModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  content,
  membershipOptionsText,
  name,
  onClose,
  productName,
}) => {
  const theme = useTheme();
  const [appElement, setAppElement] = useState<HTMLElement | undefined>(undefined);

  const { headers, rows } = useMemo(() => {
    const parsedTable = createMarkdownArrayTableSync(content);

    return {
      headers: parsedTable.headers,
      rows: [...parsedTable.rows], // Converts an iterable to an array
    };
  }, [content]);

  useEffect(() => {
    const root = document.getElementById('root') || document.getElementById('__next');
    if (root) {
      setAppElement(root);
    }
  }, []);

  const { trackEvent } = useTracking();

  const trackModalEvent = (event: string, additionalProperties = {}) => {
    trackEvent({
      event,
      properties: {
        parentType: 'Component: Product Block',
        parent: `${productName} Compare Modal`,
        propertyType: 'Web',
        unitName: name,
        unitPlacement: 'Modal',
        ...additionalProperties,
      },
    });
  };

  const onModalOpened = () => {
    trackModalEvent('Modal Opened', { modalTrigger: 'click' });
  };

  const onModalClosed = () => {
    trackModalEvent('Modal Closed');
  };

  return (
    <>
      <ScrollFromBottomModalStyles />
      <WiderModalStyle name={name} />
      <StyledModalView
        isOpen={isOpen}
        contentLabel={name}
        closeHandler={onClose}
        modalOpened={onModalOpened}
        modalClosed={onModalClosed}
        padded={false}
        className="quick-view-modal" // This class is needed so that the mobile slide up styles apply
        contentClassname="scroll-from-bottom-modal"
        appElement={appElement}
        modalBackgroundColor={theme.modalBackgroundColor}
      >
        <Container
          verticalPadding={{ mobile: spacing[24], tablet: spacing[48] }}
          horizontalPadding={{ mobile: spacing[16], tablet: spacing[64] }}
        >
          <CompareTable
            modalTextColor={theme.modalTextColor}
            borderColor={theme.modalDividerColor}
            tableCellBorder={{ top: true }}
            verticalAlign="middle"
            paddingBlock={spacing[12]}
            paddingInline={spacing[16]}
            headings={headers.map((header, column) => (
              <HeaderCell column={column} key={column}>
                {header}
              </HeaderCell>
            ))}
            rows={rows.map(cells =>
              cells.map((cell, column) => (
                <BodyCell column={column} key={column}>
                  {cell}
                </BodyCell>
              )),
            )}
          />
          <Container
            verticalPadding={{ mobile: `${spacing[24]} 0`, tablet: `${spacing[32]} 0` }}
            textAlign="left"
          >
            <Markdown
              content={membershipOptionsText}
              markdown={{
                renderers: {
                  link: ({ node, ...props }) => (
                    <MembershipOptionsLink
                      href={props.href}
                      modalTextColor={theme.modalTextColor}
                    >
                      {props.children}
                    </MembershipOptionsLink>
                  ),
                },
              }}
            />
          </Container>
        </Container>
      </StyledModalView>
    </>
  );
};

type LinkRendererProps = {
  children: MarkdownChildrenType;
  href: string;
  modalTextColor: string;
};

const MembershipOptionsLink: React.FC<React.PropsWithChildren<LinkRendererProps>> = ({
  children,
  href,
  modalTextColor,
}) => {
  const { trackEvent } = useTracking();

  return (
    <StyledLink
      variant="body"
      size="small"
      href={href}
      to={href}
      children={children}
      target="_blank"
      modalTextColor={modalTextColor}
      onClick={() =>
        trackEvent({
          event: TrackingEvent.ClickedLink,
          properties: {
            linkTo: href,
            linkName: children[0]?.props.children,
            unitName: `Membership Options Link: ${children[0]?.props.children}`,
            parent: 'Product Comparison Modal',
            parentType: 'Component: Generic Text',
          },
        })
      }
    />
  );
};

const StyledLink = styled(Link)<{ modalTextColor: string }>`
  ${props => `color: ${props.modalTextColor}`};
`;

const StyledModalView = styled(ModalView)<Pick<ThemeProps, 'modalBackgroundColor'>>`
  ${props => `background-color: ${props.modalBackgroundColor}`} !important;

  border-radius: ${spacing[12]} ${spacing[12]} 0 0;

  ${mediaBreakpoints.desktopLarge`
    border-radius: 5px;
  `}
`;

const CompareTable = styled(DataTable)<
  Pick<ThemeProps, 'modalTextColor'> & { borderColor: ThemeProps['modalDividerColor'] }
>`
  ${props => `color: ${props.modalTextColor}`};

  td {
    height: ${spacing[72]};
  }

  th {
    border-right: none;
    padding-block: ${spacing[24]};
  }

  tr:last-of-type {
    border-bottom: 1px solid ${props => `${props.borderColor}`};
  }

  td:first-of-type,
  th:first-of-type {
    width: 50%;
  }
`;

const WiderModalStyle = createGlobalStyle<{ name: string }>`
  .ReactModal__Content[aria-label="${props => props.name}"] {
    max-width: 967px;
  }
`;

export default ProductComparisonModal;
