import { PageBuilderProvider } from '@pelotoncycle/page-builder';
import type { FC } from 'react';
import React from 'react';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { useNextLinkWrapperProviderProps } from '@peloton/next/hooks/useIsNextHooks';
import usePageBuilderListeners from '@acme-ui/global/hooks/usePageBuilderListeners';
import useMarkdownValues from '@page-builder/hooks/useMarkdownValues';
import { placeholderMatcher } from './maps/placeholderTypeMap';

/**
 * Allows PB modules access to WWW markdown values and react-tracking
 */
const BlockProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const values = useMarkdownValues();
  const {
    onLightboxEvent,
    onLinkClick,
    onLeadGenSubmit,
    onModalEvent,
  } = usePageBuilderListeners();
  const toFormattedText = useGetTextFormatter();

  return (
    <PageBuilderProvider
      tracking={{ onLightboxEvent, onLinkClick, onLeadGenSubmit, onModalEvent }}
      markdown={{ values, formatter: toFormattedText }}
      placeholder={placeholderMatcher}
      linkWrapper={useNextLinkWrapperProviderProps()}
    >
      {children}
    </PageBuilderProvider>
  );
};

export default BlockProvider;
