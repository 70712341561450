import type { Product } from '@ecomm/product-recommendations/models/Product';
import { useProductRecommendationsCopyKey } from '@ecomm/product-recommendations/utils/useProductRecommendationsCopyKey';

type Options = {
  isInCart: boolean;
  product: Product;
};

const DEFAULT_PILL_THEME = 'accent';

const usePill = ({ isInCart, product }: Options) => {
  const { pill, pillTheme } = product;

  const inYourCartText = useProductRecommendationsCopyKey('inYourCart');

  if (!pill && !isInCart) {
    return null;
  }

  const notInCartPillTheme = pillTheme || DEFAULT_PILL_THEME;

  const text = isInCart ? inYourCartText : pill;
  const theme = isInCart ? 'white' : notInCartPillTheme;

  return {
    text,
    theme,
  } as const;
};

export default usePill;
