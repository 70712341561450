import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import {
  classTypesByDiscipline,
  durationBuckets,
} from '../../../../apps/prospects/scripts/manualTypes';

export enum AppDiscipline {
  CIRCUIT = 'circuit',
  BIKE_BOOTCAMP = 'bike_bootcamp',
  CAESAR = 'caesar',
  CAESAR_BOOTCAMP = 'caesar_bootcamp',
  ROW_BOOTCAMP = 'row_bootcamp',
  TREAD_BOOTCAMP = 'tread_bootcamp',
}

export type Discipline = ProspectsDiscipline | AppDiscipline;

export const PROSPECTS_DISCIPLINE_SLUGS: Record<ProspectsDiscipline, string> = {
  strength: 'strength',
  cardio: 'cardio',
  yoga: 'yoga',
  running: 'running',
  stretching: 'stretching',
  outdoor: 'outdoor-workout',
  rowing: 'rowing',
  meditation: 'meditation',
  cycling: 'cycling',
  walking: 'walking',
};

export const APP_DISCIPLINE_SLUGS: Record<AppDiscipline, string> = {
  circuit: 'circuit',
  bike_bootcamp: 'bike-bootcamp',
  caesar: 'caesar',
  caesar_bootcamp: 'caesar-bootcamp',
  row_bootcamp: 'row-bootcamp',
  tread_bootcamp: 'tread-bootcamp',
};

export const DISCIPLINE_SLUGS: Record<Discipline, string> = {
  ...PROSPECTS_DISCIPLINE_SLUGS,
  ...APP_DISCIPLINE_SLUGS,
};

export const DISCIPLINE_VARIANTS: Partial<Record<ProspectsDiscipline, string>> = {
  [ProspectsDiscipline.CYCLING]: 'cycling-bike',
  [ProspectsDiscipline.RUNNING]: 'running-tread',
};

export const CLASS_TYPES: string[] = [
  ...new Set(
    Object.values(classTypesByDiscipline)
      .map(classTypes => Object.keys(classTypes))
      .flat(),
  ),
];

export const DISCIPLINE_SLUG_RULE = `(${Object.values(PROSPECTS_DISCIPLINE_SLUGS).join(
  '|',
)})`;
export const DURATION_SLUG_RULE = `(${Object.keys(durationBuckets).join('|')})`;
export const CLASS_TYPE_SLUG_RULE = `(${CLASS_TYPES.join('|')})`;

/**
 * Determines whether a slug matches a classId path like /classes/1e4203126618478e8bfb56769d7c4032
 * @param slug to match against
 * @returns a boolean value indicating whether the slug matches a classId path
 */
export const isClassIdSlug = (slug: string): boolean => {
  const splitPath = slug.split('/');

  return (
    splitPath[splitPath.length - 1].length === CLASS_ID_LENGTH &&
    slug.includes('-') === false
  );
};

// eslint-disable-next-line no-useless-escape
const CLASS_DISCIPLINE_PAGES_RULE = `classes\/(${DISCIPLINE_SLUG_RULE})(\/${DURATION_SLUG_RULE})?(\/${CLASS_TYPE_SLUG_RULE})?(\/?\\d*)`;
// eslint-disable-next-line no-useless-escape
const CLASS_LANDING_PAGES_RULE = `classes\/[\\w-]+(\/?\\d*)`;

export const CLASS_DISCIPLINE_PAGES_REGEX = new RegExp(
  `^(${CLASS_DISCIPLINE_PAGES_RULE})$`,
);

export const CLASS_PAGES_REGEX = new RegExp(/\/?(?:classes)\/[^/]+\/[^/]+$/);

export const FREE_CLASS_VIDEO_PAGES_REGEX = new RegExp(
  /\/?(?:classes)\/(?:video)\/[^/]+$/,
);

export const CLASS_LISTING_PAGES_REGEX = new RegExp(
  `^((${CLASS_DISCIPLINE_PAGES_RULE})|(${CLASS_LANDING_PAGES_RULE}))$`,
);

export const CLASS_ID_PAGES_REGEX = /(classes)\/[a-z0-9]{32}/;

export const CLASS_LISTING_LIMIT = 24;
export const CLASS_ID_LENGTH = 32;
