import { useMemo, useState } from 'react';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useCrossSellInterstitialToggle } from '@ecomm/feature-toggle/CrossSellInterstitial';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { getItemInCart } from './cartUtils';

export const useCartResponse = () => {
  const { errorReporter } = useErrorReporter();
  const { CTCartEnabled } = useMigrationStatus();
  const { isExperimentActive, activeVariation } = useCrossSellInterstitialToggle();
  const ctCartResponse = useGetCartItems();
  const legacyCartResponse = useCartWithVariables({
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const isCrossSellInterstitialEnabled = isExperimentActive && activeVariation === 'on';
  const ctCart = ctCartResponse?.data;
  const legacyCart = legacyCartResponse?.data?.cart;
  const isCTCart = CTCartEnabled && !Boolean(legacyCart?.numberOfItems);

  return {
    isValidCartResponse: isCrossSellInterstitialEnabled && Boolean(ctCart || legacyCart),
    cart: isCTCart ? ctCart : legacyCart,
    isCTCart,
  };
};

export const useCartFilter = (products: Product[]) => {
  const [renderedProducts, setRenderedProducts] = useState<Product[]>(products);
  const { cart, isCTCart } = useCartResponse();
  const cartExists = Boolean(cart);
  const filteredProducts = products.filter(
    product =>
      product.altInCartDescription || !getItemInCart({ product, cart, isCTCart }),
  );
  // Once we know the cart exists, re-set the rendered products.
  // This ensures that we only show products that are not in the cart.
  // We only want `cartExists` & `products` as dependencies to avoid updating the grid
  // as the cart changes (we only want the initial state of the cart)
  useMemo(() => {
    cartExists && setRenderedProducts(filteredProducts);
  }, [cartExists, products]);

  return { renderedProducts };
};
