import type { IconNames } from '@pelotoncycle/design-system';
import React from 'react';
import { LinkButton } from '@ecomm/internationalize-ui';
import type { CtaProps } from './types';
import WaysToAccessModal from './WaysToAccess/WaysToAccessModal';

type ViewportLinkButtonProps = {
  isMobile: boolean;
  cta: CtaProps;
  handleLinkClick: (text: string, linkTo?: string) => void;
  handleWaysToAccessModalLinkClick: (desktopText: string) => void;
  isWaysToAccessModalOpen: boolean;
  setIsWaysToAccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewportLinkButton: React.FC<ViewportLinkButtonProps> = ({
  isMobile,
  cta,
  handleLinkClick,
  handleWaysToAccessModalLinkClick,
  isWaysToAccessModalOpen,
  setIsWaysToAccessModalOpen,
}) => {
  const { icon, link, modal, desktopText, mobileText, variant } = cta;

  const sharedCtaProps = {
    icon: icon as IconNames,
    variant,
  };

  // render CTA that links on mobile/tablet
  if (isMobile) {
    return (
      <LinkButton
        key={mobileText}
        color="light"
        size="medium"
        href={link?.url}
        to={link?.url}
        onClick={() => handleLinkClick(mobileText, link?.url)}
        {...sharedCtaProps}
      >
        {mobileText}
      </LinkButton>
    );
  } else {
    // render CTA that opens modal on desktop
    return (
      <>
        <LinkButton
          key={`${desktopText}-ways-to-take-classes`}
          color="light"
          size="medium"
          onClick={() => handleWaysToAccessModalLinkClick(desktopText)}
          {...sharedCtaProps}
        >
          {desktopText}
        </LinkButton>

        <WaysToAccessModal
          isOpen={isWaysToAccessModalOpen}
          modal={modal}
          closeHandler={() => setIsWaysToAccessModalOpen(false)}
          handleLinkClick={handleLinkClick}
        />
      </>
    );
  }
};

export default ViewportLinkButton;
