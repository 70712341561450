import useProductDataForPage from '@page-builder/utils/product-data/useProductDataForPage';

const useBuildTimeDataForSlug = (slug: string) => {
  const allProductData = useProductDataForPage();

  const productData =
    allProductData.cfuPackages[slug] ||
    allProductData.bundles[slug] ||
    allProductData.accessories[slug];

  return productData;
};

export default useBuildTimeDataForSlug;
