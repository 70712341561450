import { Flex, Media, Support } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { ScreenReaderText } from '@peloton/accessibility';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import { defaultTransition } from '@peloton/styles';
import { DescriptionHighlightCopy } from '@ecomm/product-recommendations/Views/MarkdownFormatters';
import Markdown from '@page-builder/utils/Markdown';

export const ImageContainer = styled(Flex)`
  overflow: hidden;
  background: ${(props: { background: string }) => props.background};
`;

export const TileMedia = styled(Media)`
  aspect-ratio: 0.8;
  overflow: hidden;
  max-height: 360px;
`;

export const TileWrapper = styled.div.attrs({ role: 'group' })`
  cursor: pointer;
  outline: none;
  position: relative;
  text-align: left;

  a {
    outline: none;
  }

  [data-whatintent='keyboard'] &:focus,
  [data-whatintent='keyboard'] & a:focus {
    outline-offset: 1px;

    /* Set outline for non-webkit browsers */
    outline: Highlight 2px solid !important;

    /* Sets outline to default for webkit browsers */
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline: -webkit-focus-ring-color auto 5px !important;
    }
  }

  [data-whatintent='mouse'] & {
    img {
      ${defaultTransition('transform')}
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
`;

const tileCoverStyles = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const TileCoverLink = withI18n(styled.a`
  ${tileCoverStyles};
`);

const TileCoverButton = styled.button`
  ${tileCoverStyles};
`;

// The TileLink and TileButton components both render an interactive element that covers the entire tile and
// render its children (usually the name of the product) only to screen readers, so that users using a screen reader
// get a link with the product name, while not affecting the visual appearance of the tile.
export const TileLink: React.FC<
  React.PropsWithChildren<{ href?: string; onClick: React.MouseEventHandler }>
> = ({ href, onClick, children }) => {
  return (
    <TileCoverLink href={href} onClick={onClick}>
      <ScreenReaderText>{children}</ScreenReaderText>
    </TileCoverLink>
  );
};

export const TileButton: React.FC<
  React.PropsWithChildren<{ onClick: React.MouseEventHandler }>
> = ({ onClick, children }) => {
  return (
    <TileCoverButton onClick={onClick}>
      <ScreenReaderText>{children}</ScreenReaderText>
    </TileCoverButton>
  );
};

const DescriptionSupportText = styled(Support)<{ truncated?: boolean }>`
  ${({ truncated }) =>
    truncated &&
    css`
      // The below is to support two-line truncation with ellipses
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    `}
`;

export const TileDescription: React.FC<{ truncated?: boolean; children?: string }> = ({
  truncated,
  children,
}) => {
  if (!children) {
    return null;
  }

  return (
    <DescriptionSupportText size="small" is="span" truncated={truncated}>
      <Markdown
        content={children}
        markdown={{
          renderers: {
            strong: DescriptionHighlightCopy,
          },
        }}
      />
    </DescriptionSupportText>
  );
};
