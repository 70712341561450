import { black, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback, useContext, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '@peloton/styles';
import { Swipeable } from '@peloton/swipeable';
import { GlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import { white } from '@ecomm/colors';
import { ChevronLight, Orientation } from '@ecomm/icons';
import useCarousel from '@page-builder/hooks/useCarousel';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import { toMediaProps } from '@page-builder/utils/helpers';
import VideoToggle from '../Video/VideoToggle';
import HeroCarouselSlide from './HeroCarouselSlide';

const DEFAULT_INTERVAL = 5000;

type Props = TypeComponentGenericListFields;

const HeroCarousel: React.FC<React.PropsWithChildren<Props>> = ({ items }) => {
  const { reduceMotion } = useContext(GlobalUiStateContext);
  const preview = useIsPreviewCopyEnabled();

  const {
    handleStart,
    handlePause,
    activeSlide,
    handleNext,
    handlePrevious,
    handleClickIndicator,
    isPlaying,
  } = useCarousel(items.length, DEFAULT_INTERVAL);

  useEffect(() => {
    if (!reduceMotion) {
      handleStart();
      // if (activeVideo.current?.paused) activeVideo?.current?.play();
    } else {
      handlePause();
      // if (activeVideo.current) resetActiveMedia();
    }
    return () => {
      handlePause();
    };
  }, [reduceMotion]);

  const onMediaToggleClick = useCallback(() => {
    if (isPlaying) {
      handlePause();
    } else {
      handleStart();
    }
  }, [isPlaying, handlePause, handleStart]);

  const initialHeroProgressiveRenderMap = { [0]: true };

  const [heroProgressiveRenderMap, setHeroProgressiveRenderMap] = React.useState(
    initialHeroProgressiveRenderMap,
  );

  const multipleSlides = items.length > 1;

  /*
   * If there's only 1 slide and it's a video OR
   * If there's multiple slides - show play/pause button
   * If there's one slide and it's an image - DO NOT show play/pause button
   * */
  const mediaFields = items[0]?.fields?.media
    ? toMediaProps(items[0].fields.media)
    : undefined;
  const isVideo = mediaFields?.type === 'video';
  const showPlayPauseButton = (multipleSlides || isVideo) && !reduceMotion;

  useEffect(() => {
    const updatedProgressiveRenderMap = {
      ...heroProgressiveRenderMap,
      [activeSlide]: true,
    };

    let timeout: NodeJS.Timeout;
    if (multipleSlides) {
      setHeroProgressiveRenderMap(updatedProgressiveRenderMap);
      timeout = setTimeout(() => {
        setHeroProgressiveRenderMap({
          ...updatedProgressiveRenderMap,
          [activeSlide + 1]: true,
        });
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [activeSlide]);

  return (
    <Container>
      <CarouselContainer
        data-test-id="carousel-container"
        onSwipeLeft={handleNext}
        onSwipeRight={handlePrevious}
      >
        {items.map((item, i) => {
          const heroToRender = (
            <HeroContainer
              data-test-id="cmsHeroContainer"
              className={i === activeSlide ? 'is-active' : ''}
              key={item.sys.id}
            >
              <HeroCarouselSlide
                {...item.fields}
                index={i}
                active={i === activeSlide}
                isPlaying={isPlaying}
                preview={!!preview}
              />
            </HeroContainer>
          );

          if (heroProgressiveRenderMap[i]) {
            return heroToRender;
          }

          return null;
        })}
      </CarouselContainer>
      {multipleSlides && (
        <>
          <BarsContainer>
            {items.map((_, index) => {
              const className =
                activeSlide === index && !reduceMotion && isPlaying ? 'active' : '';
              const complete =
                activeSlide > index || (reduceMotion && activeSlide === index);
              return (
                <IndicatorButton
                  key={`indicator-${index}`}
                  aria-label={`slide ${index + 1} button`}
                  onClick={() => handleClickIndicator(index)}
                >
                  <ProgressBar
                    className={className}
                    playState={isPlaying ? 'running' : 'paused'}
                    complete={complete}
                  />
                </IndicatorButton>
              );
            })}
          </BarsContainer>
          <Previous onClick={handlePrevious} aria-label="Previous">
            <ChevronLight width="70px" orientation={Orientation.Left} />
          </Previous>
          <Next onClick={handleNext} aria-label="Next">
            <ChevronLight width="70px" orientation={Orientation.Right} />
          </Next>
        </>
      )}
      {showPlayPauseButton && (
        <VideoToggle
          playing={isPlaying}
          bearing="left"
          onClick={onMediaToggleClick}
          theme="light"
        />
      )}
    </Container>
  );
};

export default HeroCarousel;

const Container = styled.section`
  position: relative;
  background-color: ${black};
`;

const HeroContainer = styled.div``;

const CarouselContainer = styled(Swipeable)`
  display: grid;
  transition: opacity 0.15s ease-in;
  transition-delay: 150ms; /* allow image or video poster in first slide to download before fading in */
  position: relative;

  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    visibility: hidden;

    /* overlay that fades out as each slide comes in */
    &:after {
      content: '';
      position: absolute;
      pointer-events: none; /* ensures click events still 'pass-through' the overlay */
      background-color: black;
      transition: all 0.2s ease-in;
      opacity: 0.2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.is-active {
      visibility: visible;
      z-index: 1;

      &:after {
        opacity: 0;
      }
    }
  }
`;

const NavButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: none;
  height: 80px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 40px);
  width: 100px;
  z-index: 2;

  @media (min-width: 940px) {
    display: flex;
  }

  svg path {
    stroke: ${grey[50]} !important;
    transition: all 150ms ease;
  }

  &:hover svg path {
    box-shadow: ${rgba(black, 0.2)} 5px 3px 50px 0px;
    stroke: ${white} !important;
  }
`;

const Previous = styled(NavButton)`
  left: 0;
  right: auto;
`;

const Next = styled(NavButton)`
  left: auto;
  right: 0;
`;

const progressBarAnimation = keyframes`
  0% { width: 0 }
  100% { width: 100% }
`;

const BarsContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 2;
  margin: 0 3rem;

  ${media.tabletXLarge`
    bottom: 8px;
  `}
`;

type ProgressBarProps = {
  playState: 'paused' | 'running';
  complete: boolean;
};

const IndicatorButton = styled.button`
  background: transparent;
  border: none;
  padding: 16px 4px;
  width: 84px;
  position: relative;
`;

const ProgressBar = styled.span<ProgressBarProps>`
  height: 2px;
  width: 100%;
  box-shadow: 0 0 20px 5px ${rgba(black, 0.2)};
  background-color: ${rgba(white, 0.25)};
  display: block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => (props.complete ? '100%' : '0')};
    height: 100%;
    background-color: ${white};
    z-index: 3;
  }

  &.active::after {
    animation: ${progressBarAnimation} ${DEFAULT_INTERVAL}ms linear forwards;
    animation-play-state: ${props => props.playState};
  }
`;
