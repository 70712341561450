import {
  Container,
  Eyebrow,
  Flex,
  Headline,
  Media,
  Support,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles/breakpoints';
import { LinkButton } from '@ecomm/internationalize-ui';
import ModalView from '@ecomm/modal/ModalView';
import type { ModalWidth } from '@ecomm/modal/ModalView';
import Markdown from '@page-builder/utils/Markdown';
import type { ViewportHeroProps } from '../ViewportHero';
import { ViewportHeroModalStyles } from '../ViewportHeroModalStyles';

type WaysToAccessModalProps = {
  isOpen: boolean;
  modal: ViewportHeroProps['ctas'][0]['modal'];
  closeHandler: () => void;
  handleLinkClick: (text: string, linkTo?: string) => void;
};

const WaysToAccessModal: React.FC<WaysToAccessModalProps> = ({
  isOpen,
  modal,
  closeHandler,
  handleLinkClick,
}) => {
  if (!modal) {
    return null;
  }

  const { headline, modalSections: items } = modal;

  return (
    <ModalView
      contentLabel={headline}
      closeHandler={closeHandler}
      padded={false}
      isOpen={isOpen}
      width={900 as ModalWidth}
      data-test-id="ways-to-access-modal"
    >
      <ViewportHeroModalStyles />
      <Flex flexDirection="column" padding={spacing[40]} backgroundColor={grey[90]}>
        <Container textAlign="center">
          <Headline textColor={white} size="small">
            {headline}
          </Headline>
        </Container>

        <Flex
          flexWrap="wrap"
          gap={{ mobile: spacing[16], tablet: spacing[24] }}
          verticalMargin={`${spacing[32]} 0`}
        >
          {items.map(({ media, headline: sectionTitle, body, text: ctaText, link }) => {
            return (
              <StyledSection key={sectionTitle}>
                <StyledMedia media={media} width="100%" height="auto" />

                <Flex flexDirection="column" gap={spacing[8]}>
                  <Eyebrow as="h3">
                    <Markdown content={sectionTitle} />
                  </Eyebrow>

                  <Support as="p" size="large">
                    <Markdown content={body} />
                  </Support>
                </Flex>

                <BottomContainer flexDirection="column">
                  <LinkButton
                    variant="solid"
                    href={link.url}
                    to={link.url}
                    size="small"
                    width="adjustable"
                    onClick={() => handleLinkClick(ctaText, link.url)}
                  >
                    {ctaText}
                  </LinkButton>
                </BottomContainer>
              </StyledSection>
            );
          })}
        </Flex>
      </Flex>
    </ModalView>
  );
};

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${spacing[32]};
  background-color: ${grey[80]};
  border-radius: ${spacing[8]};
  color: ${white};
  gap: ${spacing[32]};

  text-align: center;
  align-items: center;

  ${mediaBreakpoints.tablet`
    width: calc(50% - ${12 / 16}rem);
  `}
`;

const StyledMedia = styled(Media)`
  aspect-ratio: 1.5;

  ${mediaBreakpoints.desktop`
    aspect-ratio: 1.66;
  `}
`;

const BottomContainer = styled(Flex)`
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export default WaysToAccessModal;
