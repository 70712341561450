import type { BadgeProps } from '@pelotoncycle/design-system';
import type { TypeComponentImageFields } from '@pelotoncycle/page-builder';
import type { SupportedTreatmentType } from '@ecomm/product-recommendations/models/treatment';

export type ProductRecommendationsCopyKey =
  | 'addedToCart'
  | 'inYourCart'
  | 'quickAdd'
  | 'deliveryAndSetup'
  | 'loadingHeadline'
  | 'delivery'
  | 'freeReturns'
  | 'addToCart'
  | 'factoidHeading'
  | 'productDetails'
  | 'defaultError'
  | 'selectAttribute'
  | 'isRequired'
  | 'dropdownOptionSelected';

export type ProductRecommendations = {
  copy: Record<ProductRecommendationsCopyKey, string>;
  cohorts: Record<string, ProductRecommendationsCohort>;
};

export type ProductRecommendationsCohort = {
  cohortKeyName: string;
  headline?: string;
  subHeadline?: string;
  eyebrow?: string;
  position: 'top' | 'bottom';
  products: ProductRecommendationsTile[];
  marketingTiles: ProductRecommendationsMarketingTile[];
  marketingTilesWithModal: ProductRecommendationsMarketingTileWithModal[];
  treatment: SupportedTreatmentType;
};

export const PillThemes = ['dark', 'primary', 'accent'] as const;

export type ProductRecommendationsPillTheme = Exclude<BadgeProps['theme'], undefined>;

export type Taggable = {
  tags?: string[];
};

export type ProductRecommendationsTile = {
  slug: string;
  factoid?: string;
  altInCartDescription?: string;
  disclaimerMessaging?: string;
  factoidIcon?: {
    fields: {
      file: {
        url: string;
      };
    };
  };
  pill?: string;
  pillTheme?: ProductRecommendationsPillTheme;
  priceDisplay?: string;
  factoidEyebrow?: string;
  financingText?: string;
  overrideImageUrl?: string;
  overrideName?: string;
  packageContentsDescription?: string;
  packageContents?: {
    image: TypeComponentImageFields;
    description: string;
  }[];
} & Taggable;

export type ProductRecommendationsMarketingTile = {
  name: string;
  url: string;
  image: TypeComponentImageFields;
  title: string;
  body?: string;
  priceDisplay?: string;
  pills?: { text: string; theme: ProductRecommendationsPillTheme }[];
} & Taggable;

export type ProductRecommendationsMarketingTileWithModal = {
  backgroundImage: TypeComponentImageFields;
  supportingImage: TypeComponentImageFields;
  text: string;
  ctaText: string;
  modalBody: string;
  modalName: string;
  modalSupport: string;
} & Taggable;
