import type { Locale } from '@peloton/internationalize';
import { mapProductToSparePart } from '@ecomm/commercetools/mappers/mapProductToSparePart';
import type { SparePartsListCommerceTools } from '@page-builder/modules/SpareParts/models';
import type { ProductProjectionType } from '../models/main';
import type { ProductCatalogData } from '../types.generated';

export const mapProductsToSparePartsList = (
  result: ProductProjectionType[],
  locale: Locale,
): SparePartsListCommerceTools => {
  return result.map(sparePart => {
    const productsForMap = {
      key: sparePart.key,
      id: sparePart.id,
      productType: sparePart.productType,
      masterData: ({
        current: sparePart,
      } as unknown) as ProductCatalogData,
    };

    const { images, ...rest } = mapProductToSparePart(productsForMap, locale);
    const [firstImage] = images;
    const categories = sparePart.categories.map(category => String(category.key));
    return {
      ...rest,
      image: firstImage,
      images,
      categories,
    };
  });
};
