import type { GenericCtaData } from '@pelotoncycle/page-builder';
import { theUserIsInTheUnitedStates } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models';
import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import type { Class, FreeClass } from '@peloton/models/PelotonClass';
import { mapCFUClassCardPropsFromContentful } from './mapContentfulProps';

export type ExtraCardType = 'CFU' | 'FreeClass'; // Added 'Free Class' type for future use in RAM-2661

export type ExtraCFUCardType = {
  imageUrl: string;
  headline: string;
  support: string;
  cta: GenericCtaData;
  extraCardType: ExtraCardType;
};

export type ClassOrExtraCard = Class | ExtraCFUCardType | FreeClass; //Add 'Free Class' type onto this

const disciplineToCFUCardKeyMap = {
  [ProspectsDiscipline.CYCLING]: 'Cycling Bike+',
  [ProspectsDiscipline.ROWING]: 'Row',
  [ProspectsDiscipline.RUNNING]: 'Running Tread',
  [ProspectsDiscipline.WALKING]: 'Running Tread',
  [ProspectsDiscipline.STRENGTH]: 'Guide',
  [ProspectsDiscipline.CARDIO]: 'Cardio Bike+',
  [ProspectsDiscipline.YOGA]: 'Yoga Tread',
};

export type InsertExtraCFUCardArgs = {
  classes: Class[];
  freeClass?: FreeClass;
  items: any;
  isDiscipline: boolean;
  isFiltered: boolean;
  lowestLoadedPage: number;
  isProductAvailable: boolean;
  fitnessDiscipline: ProspectsDiscipline;
  locale: Locale;
};

export const insertExtraCFUCard = ({
  classes,
  freeClass,
  items,
  isDiscipline,
  isFiltered,
  lowestLoadedPage,
  isProductAvailable,
  fitnessDiscipline,
  locale,
}: InsertExtraCFUCardArgs) => {
  const shouldInsertExtraCFUCard =
    isProductAvailable && !isFiltered && lowestLoadedPage === 1;

  if (!shouldInsertExtraCFUCard) {
    return classes;
  }

  const classCardKey = disciplineToCFUCardKeyMap[fitnessDiscipline];
  const CFUClassCard = mapCFUClassCardPropsFromContentful(items, classCardKey);
  const updatedClasses: ClassOrExtraCard[] = [...classes];

  if (CFUClassCard && isDiscipline) {
    const extraCard: ExtraCFUCardType = {
      imageUrl: CFUClassCard.media ?? '',
      headline: CFUClassCard.headline ?? '',
      support: CFUClassCard.support ?? '',
      cta: CFUClassCard.cta ?? {},
      extraCardType: 'CFU',
    };
    updatedClasses.splice(3, 1, extraCard);
  }
  if (freeClass && theUserIsInTheUnitedStates(locale)) {
    updatedClasses.splice(0, 1, freeClass);
  }

  return updatedClasses;
};
