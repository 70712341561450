import React from 'react';
import type { Locale } from '@peloton/internationalize/models/locale';
import {
  fetchBuilderInstructorData,
  filterInstructorsListFields,
} from '@content/client/lib/instructors';
import { truncateEntry } from '@content/client/lib/truncateEntry';
import type { TypeQuiz, TypeInstructor } from '@page-builder/lib/types';
import type { AlgorithmInstructor } from '@page-builder/modules/MWYIQuiz/models/AlgorithmInstructor';
import type { MatchedInstructor } from '@page-builder/modules/MWYIQuiz/models/MatchedInstructor';
import {
  assignMatchReasons,
  getIntructorMatches,
} from '@page-builder/modules/MWYIQuiz/utils/matchingAlgorithm';
import { useFormProgress } from '@page-builder/modules/Quiz/utils/progress';

const MAX_INSTRUCTOR_MATCHES_SHOWN = 5;

export const getInstructorDataForQuiz = async (
  locale: Locale,
): Promise<AlgorithmInstructor[]> => {
  const instructorData = await fetchBuilderInstructorData(locale);
  const instructorFields = filterInstructorsListFields(
    instructorData,
    'name',
    'slug',
    'instructorId',
    'headshot',
    'algorithmFitnessDisciplines',
    'coaching',
    'intensity',
    'languages',
    'musicGenres',
    'priority',
  );

  return instructorFields;
};

export const parseInstructorSlug = (segment: string | string[] | undefined): string => {
  if (!segment) {
    throw new Error('No instructor segment was provided');
  }
  return Array.isArray(segment) ? segment[0] : segment; // only need the intructor name and not the subsequent segments
};

export const getInstructorSlugs = async (locale: Locale): Promise<string[]> => {
  const instructorData = await fetchBuilderInstructorData(locale);
  const instructorFields = filterInstructorsListFields(instructorData, 'slug');

  return instructorFields.map(i => i.slug);
};

export const filterInstructorData = (data: TypeInstructor[], slug: string) => {
  const targetInstructor = data.find(i => {
    return i.fields.slug === slug;
  });
  try {
    if (targetInstructor && targetInstructor.fields['collectionsModule']) {
      const { collectionsModule } = targetInstructor.fields;
      (targetInstructor.fields['collectionsModule'] as any) = truncateEntry(
        collectionsModule,
      );
    }
  } catch (err) {
    console.warn(`Failed to parse collectionsModule field for ${slug}`);
  }
  return targetInstructor;
};

export const getSingleInstructor = async (
  slug: string,
  locale: Locale,
): Promise<TypeInstructor | undefined> => {
  const instructorData = await fetchBuilderInstructorData(locale);
  const instructor = filterInstructorData(instructorData, slug);

  return instructor;
};

export const toResultsPageUrl = ({ slug }: AlgorithmInstructor) => {
  return `/instructor-match/results/${slug}`;
};

export const useMatchedInstructors = (
  algorithmInstructors: AlgorithmInstructor[],
  quiz: TypeQuiz,
) => {
  const progressContext = useFormProgress();

  return React.useMemo(() => {
    const { progress } = progressContext;

    return getIntructorMatches(progress, quiz, algorithmInstructors);
  }, [progressContext, quiz, algorithmInstructors]);
};

export const getCurrentAndOtherInstructorMatches = (
  matchedInstructors: MatchedInstructor[],
  instructor: TypeInstructor,
) => {
  const currentInstructor = matchedInstructors.find(
    ({ instructor: { slug } }) => slug === instructor.fields.slug,
  );
  const otherInstructorMatches = assignMatchReasons(
    matchedInstructors
      .filter(({ instructor: { slug } }) => slug !== instructor.fields.slug)
      .slice(0, MAX_INSTRUCTOR_MATCHES_SHOWN - 1),
  );

  return {
    currentInstructor,
    otherInstructorMatches,
  };
};
