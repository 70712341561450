import { Badge } from '@pelotoncycle/design-system';
import React from 'react';
import type { ProductRecommendationsPillTheme } from '@content/client/www/product-recommendations/models';
import PillsWrapper from './PillsWrapper';

type Props = {
  pills: { text: string; theme: ProductRecommendationsPillTheme }[];
};

const ContentPills: React.FC<Props> = ({ pills }) => {
  return (
    <PillsWrapper floating={true} dataTestId="grid-product-content-tile-pill">
      {pills.map(pill => (
        <Badge key={`${pill.text}_${pill.theme}`} variant="horizontal" theme={pill.theme}>
          {pill.text}
        </Badge>
      ))}
    </PillsWrapper>
  );
};

export default ContentPills;
