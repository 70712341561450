import {
  Container,
  Grid,
  Body,
  Flex,
  Headline,
  spacing,
  Eyebrow,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useMedia } from 'react-use';
import { BreakpointWidth } from '@peloton/styles';
import { FiltersStyle } from '@ecomm/product-recommendations/models/FiltersStyle';
import type { ModuleComponentProps } from '@ecomm/product-recommendations/models/ModuleComponentProps';
import { toGridWidth } from '@ecomm/product-recommendations/utils/toGridWidth';
import useFilteredItems from '@ecomm/product-recommendations/utils/useFilteredItems';
import FilterButtons from '@ecomm/product-recommendations/Views/Filters/FilterButtons';
import ToggleFilters from '@ecomm/product-recommendations/Views/Filters/ToggleFilters';
import { useFiltersContext } from '@ecomm/product-recommendations/Views/FiltersContext';
import MarketingTile from '@ecomm/product-recommendations/Views/Grid/MarketingTile';
import MarketingTileWithModal from '@ecomm/product-recommendations/Views/Grid/MarketingTileWithModal';
import { Divider } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import Markdown from '@page-builder/utils/Markdown';

const GridModuleComponent: React.FC<ModuleComponentProps> = ({
  cohort,
  options,
  themeName,
  children,
}) => {
  const {
    cohortKeyName,
    headline,
    subHeadline,
    eyebrow,
    marketingTiles: allMarketingTiles,
    marketingTilesWithModal: allMarketingTilesWithModal,
  } = cohort;
  const hasHeading = Boolean(headline || subHeadline || eyebrow);
  const { enabledFilterTags } = useFiltersContext();

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);
  const marketingTiles = useFilteredItems(allMarketingTiles, enabledFilterTags);
  const marketingTilesWithModal = useFilteredItems(
    allMarketingTilesWithModal,
    enabledFilterTags,
  );

  const {
    columnCount,
    headerAlignment,
    showHeaderDivider,
    headerGap,
    showSectionDivider,
    headerPadding,
    eyebrowHeadingSize,
    filtersStyle,
  } = options;

  return (
    <Container>
      <Flex
        flexDirection="column"
        alignItems={headerAlignment}
        maxWidth={toGridWidth(12)}
        gap={headerGap}
        centered
      >
        {showSectionDivider && <Divider topPadding={0} />}
        {hasHeading && (
          <Flex
            flexDirection="column"
            gap={spacing[16]}
            maxWidth={{
              tablet: '440px',
              desktop: '680px',
            }}
            textAlign={headerAlignment}
            verticalPadding={headerPadding}
          >
            {eyebrow && (
              <Eyebrow
                size={
                  isMobileOrTablet
                    ? eyebrowHeadingSize.mobile
                    : eyebrowHeadingSize.desktop
                }
              >
                {eyebrow}
              </Eyebrow>
            )}
            {headline && (
              <Headline size="small" is="h1">
                {headline}
              </Headline>
            )}
            {subHeadline && (
              <Body size="small" is="h2">
                <Markdown content={subHeadline} />
              </Body>
            )}
          </Flex>
        )}
        {showHeaderDivider && <Divider topPadding={0} />}
        {filtersStyle === FiltersStyle.Toggle && (
          <ToggleFilters themeName={themeName} cohortKeyName={cohortKeyName} />
        )}
        {filtersStyle === FiltersStyle.Buttons && (
          <FilterButtons cohortKeyName={cohortKeyName} />
        )}
        <Grid
          gap={{
            mobile: `${spacing[48]} ${spacing[16]}`,
            tablet: `${spacing[48]} ${spacing[24]}`,
          }}
          columnCount={columnCount}
          minWidth="100%"
        >
          {children}
          {marketingTilesWithModal.map(marketingTileWithModal => (
            <MarketingTileWithModal
              key={marketingTileWithModal.text}
              marketingTileWithModal={marketingTileWithModal}
            />
          ))}
          {marketingTiles.map(marketingTile => (
            <MarketingTile
              key={marketingTile.title}
              marketingTile={marketingTile}
              themeName={themeName}
            />
          ))}
        </Grid>
      </Flex>
    </Container>
  );
};

export default GridModuleComponent;
