import { useEffect, useState } from 'react';
import { generateIdMeAuthorizationLink } from '@peloton/concessionary-pricing';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import { toWWWLink } from '@peloton/external-links/models';
import { shopAccessories } from '@peloton/links/www';
import { BundleType } from '@ecomm/shop/models';
import {
  isProductAccessory,
  isProductBundle,
  isProductSparePart,
} from '../models/checkProductType';
import type { Product } from '../models/Product';
import { isSpecialPricingRedirect, toRefurbishedPath, toShopPath } from './productUtils';

const useDetailsUrl = (product: Product) => {
  const externalLinks = useExtLinkEnv();

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    // builds link used when clicking on product card
    if (isProductAccessory(product)) {
      setUrl(toShopPath(shopAccessories, product));
    } else if (isProductBundle(product)) {
      setUrl(toShopPath(toWWWLink(shopAccessories.path + '/set/'), product));
    } else if (isProductSparePart(product)) {
      setUrl('');
    } else if (isSpecialPricingRedirect(product) && externalLinks) {
      setUrl(generateIdMeAuthorizationLink(externalLinks, product.slug));
    } else {
      if (product.bundleType === BundleType.RefurbishedBike) {
        setUrl(toRefurbishedPath(BundleType.Bike));
      } else if (product.bundleType === BundleType.RefurbishedBikePlus) {
        setUrl(toRefurbishedPath(BundleType.BikePlus));
      } else {
        setUrl(toShopPath(toWWWLink(`/shop/${product.bundleType}`), product));
      }
    }
  }, [externalLinks, product]);

  return url;
};

export default useDetailsUrl;
