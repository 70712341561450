import { Flex, Icon, spacing } from '@pelotoncycle/design-system';
import React from 'react';

// This component is largely ported from Grid/LoadingGrid
const NestedGridLoadingComponent: React.FC = () => {
  return (
    <Flex minHeight={spacing[64]} justifyContent="center" alignItems="center">
      <Icon
        name="spinner"
        height={32}
        data-test-id="spinner"
        primaryColor="currentColor"
      />
    </Flex>
  );
};

export default NestedGridLoadingComponent;
