import type { Locale } from '@peloton/internationalize';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import type { ProductCatalogType, ProductProjectionType } from '../models/main';
import type { ProductCatalogData } from '../types.generated';
import isBundleConfigurable from '../utils/getBundleConfigurableInfo';
import { mapCTBundleToAccessoryBundle } from './mapCTBundleToAccessoryBundle';

export const makeCtBundleFromProductProjectionItem = (
  ctBundle: ProductProjectionType,
): ProductCatalogType => ({
  key: ctBundle.key,
  id: ctBundle.id,
  productType: ctBundle.productType,
  masterData: ({
    current: ctBundle,
  } as unknown) as ProductCatalogData,
});

export const mapBundlesToAccessoriesList = (
  result: ProductProjectionType[],
  locale: Locale,
): BundleAccessoryType[] =>
  result.map(ctBundle => {
    const categories = ctBundle.categories.map(category => String(category.key));

    const isConfigurable = isBundleConfigurable(ctBundle);

    const bundleProduct = makeCtBundleFromProductProjectionItem(ctBundle);
    const accessoryBundle = mapCTBundleToAccessoryBundle(bundleProduct, locale);

    return {
      ...accessoryBundle,
      configurable: isConfigurable,
      image: accessoryBundle.images[0],
      categories,
    };
  });
