import { Container, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useDrawerContentContext } from '../../DrawerContentContext';
import { DrawerMarkdown } from './DrawerMarkdown';
import { Divider } from './UpsellContent/SharedComponents';

type DrawerContentProps = {
  isOpen: boolean;
};

const DrawerContent: React.FC<React.PropsWithChildren<DrawerContentProps>> = ({
  isOpen,
}) => {
  const { pageBuilderData, isMultiShopOptions } = useDrawerContentContext();
  const { drawerBody } = pageBuilderData;

  const shouldRender = isMultiShopOptions && drawerBody;

  if (!shouldRender) {
    return null;
  }

  return (
    <Container
      style={{
        height: isOpen ? 'auto' : 0,
        margin: isOpen ? `${spacing[12]} 0 0 0` : '0',
        zIndex: isOpen ? 2 : 0,
      }}
      data-test-id="overview-drawer-content"
      position="relative"
    >
      {isOpen && <Divider topPadding={0} />}
      {isMultiShopOptions && drawerBody && (
        <MarkdownContainer>
          <DrawerMarkdown content={drawerBody} />
        </MarkdownContainer>
      )}
    </Container>
  );
};

export default DrawerContent;

const MarkdownContainer = styled(Container)`
  padding-top: ${spacing[12]};
`;
