import { Flex, grey, spacing, white } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import HeroTile from './HeroTile/HeroTile';
import type { HeroTileProps as TypeHeroTile } from './types';

export type HeroTilesProps = {
  heroTiles: TypeHeroTile[];
  theme?: 'White' | 'Grey 90';
  stackOnMobile?: boolean;
};

/**
 * This is the `HeroTiles` module which is expected to show 1-3 tiles.
 * - The `heroTiles` field is required and must contain *at least one* tile.
 * - The `theme` field is optional and defaults to White.
 * - The `stackOnMobile` field is optional and defaults to false.
 *
 * *For more information on the `HeroTile` props, see the `HeroTile` component.*
 * @param heroTiles list of props for each HeroTile that will be rendered inside this module.
 * @param theme the passed in theme for this module.
 * @param stackOnMobile whether to stack the tiles vertically on mobile.
 */

const HeroTiles: React.FC<HeroTilesProps> = ({
  heroTiles,
  theme = 'White',
  stackOnMobile = false,
}) => {
  const underThreeTiles = heroTiles.length < 3;
  const tileElements = heroTiles.map(heroTileProps => {
    return (
      <HeroTile
        key={heroTileProps.subhead}
        {...heroTileProps}
        underThreeTiles={underThreeTiles}
      />
    );
  });

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={theme === 'White' ? white : grey[90]}
      verticalPadding={{
        mobile: spacing[32],
        tablet: `${spacing[32]} ${spacing[16]}`,
        desktop: spacing[40],
      }}
    >
      <Flex
        data-test-id="hero-tiles-container"
        gap={{ mobile: spacing[8], desktop: spacing[16] }}
        minWidth="100%"
        flexDirection={{
          mobile: stackOnMobile ? 'column' : 'row',
          tablet: 'row',
        }}
      >
        {tileElements}
      </Flex>
    </OuterContainer>
  );
};

export default HeroTiles;
