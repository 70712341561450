import type { Locale } from '@peloton/internationalize/models/locale';
import { toLocaleWithFallback } from '@peloton/internationalize/models/locale';
import { NextNotFoundError } from '@peloton/next/errors/NextNotFoundError';
import { getPageConfigs } from '@content/client/lib/pageConfig';
import { getClient } from '@content/client/pageBuilderClient';
import { fetchClassContent } from '@ecomm/classes/utils/fetchClassContent';
import { transformLocale } from '@ecomm/classes/utils/transformLocale';
import type { FetcherOpts, Fetcher } from '@page-builder/hooks/useCustomPageData';
import type { TypePageConfig } from '@page-builder/lib/types';

const FREE_CLASSES_PAGE_CONFIG_SLUG = 'classes/*';

type FreeClassPageConfig = {
  youtubeUrl: string;
  classType: string[];
  metrics: string[];
  title: string;
  metaDesc: string;
  h1: string;
  description: string;
  videoUrl: string;
  imgUrl: string;
};

export type FreeClassList = Record<string, FreeClassPageConfig>;

export const fetchFreeClassListingFromPageConfig = async (
  preview: boolean,
  locale: Locale,
): Promise<FreeClassList> => {
  const client = getClient(preview);

  const pageConfigsFreeClasses: TypePageConfig[] = await getPageConfigs(client, {
    slugs: [FREE_CLASSES_PAGE_CONFIG_SLUG],
    locale: locale,
  });

  return pageConfigsFreeClasses[0]?.fields?.config?.freeClasses;
};

export const fetchFreeClassDataApi: Fetcher = async ({
  locale,
  preview,
  slug,
}: FetcherOpts) => {
  const localeApi = transformLocale(locale!);
  const fallbackLocale = toLocaleWithFallback(localeApi);
  const urlArray = slug.split('/');
  const classSlug = urlArray.pop() ?? '';
  const freeClassListingFromPageConfig = await fetchFreeClassListingFromPageConfig(
    preview,
    fallbackLocale,
  );
  const freeClassFromPageConfig = freeClassListingFromPageConfig[classSlug];
  const freeClassDetailsFromAPI = await fetchClassContent(classSlug, fallbackLocale);
  const hasFreeClass = !!freeClassDetailsFromAPI && !!freeClassFromPageConfig;

  if (!hasFreeClass) {
    const message = `Error fetching free class data for slug ${slug}, locale ${locale}`;
    throw new NextNotFoundError({ message });
  }

  return {
    ...freeClassDetailsFromAPI,
    // this object needs to be spread last to ensure class data from contentful overwrites that of the api
    ...freeClassFromPageConfig,
    isFree: true,
  };
};
