import {
  ResponsiveImage,
  Label,
  Flex,
  Container,
  spacing,
  white,
  grey,
  data,
  Eyebrow,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { media } from '@peloton/styles';
import type { TypeCardListItem as CardListItem } from './types';

const CLICKED_CLASSES_CARD_EVENT_TYPE = 'Clicked Classes Card';

const colorMap = {
  beginner: data.teal,
  intermediate: data.darkYellow,
  advanced: data.orange,
};

export const TabCard: React.FC<React.PropsWithChildren<CardListItem>> = ({
  image,
  classDisplayName,
  link,
  instructorName,
  disciplineDisplayName,
  difficultyLevel,
}) => {
  const { trackEvent } = useTracking();

  const trackClickedClassesCard = () => {
    trackEvent({
      event: CLICKED_CLASSES_CARD_EVENT_TYPE,
      properties: {
        parent: 'Prospect Classes Page',
        linkTo: `${link.url}`,
        linkName: `${classDisplayName}`,
        unitName: 'TabsListing',
        parentType: 'Page',
      },
    });
  };
  return (
    <Link
      href={link.url}
      key={link.url}
      onClick={trackClickedClassesCard}
      hasUnderline={false}
    >
      <Card
        flexDirection="column"
        maxWidth={{
          mobile: `calc(100vw - ${spacing[64]})`,
          tablet: '310px',
          desktop: '392px',
        }}
        borderRadius="8px"
        data-test-id={classDisplayName}
      >
        <Container position="relative">
          <ClassImage {...image} />
        </Container>
        <ClassInfo flexDirection="column" flexGrow={1} justifyContent="space-between">
          <Flex flexDirection="column" gap={spacing[4]}>
            {difficultyLevel ? (
              <DifficultyContainer
                flexDirection="row"
                alignItems="center"
                gap={spacing[4]}
                data-test-id="difficulty-container"
              >
                <DifficultySquare difficulty={difficultyLevel.value!} />
                <Eyebrow textColor={white} size="extraSmall">
                  {difficultyLevel.display}
                </Eyebrow>
              </DifficultyContainer>
            ) : null}
            <BreakpointSwitchCSS
              breakpoints={{
                mobile: (
                  <StyledTruncatedLabel textColor={white} size="medium">
                    {classDisplayName}
                  </StyledTruncatedLabel>
                ),
                tablet: (
                  <StyledTruncatedLabel textColor={white} size="extraLarge">
                    {classDisplayName}
                  </StyledTruncatedLabel>
                ),
              }}
            />
            <Label textColor={grey[50]} size="medium">
              {instructorName} • {disciplineDisplayName}
            </Label>
          </Flex>
        </ClassInfo>
      </Card>
    </Link>
  );
};

const ClassInfo = styled(Flex)`
  padding: ${spacing[24]} 0;
  color: ${grey[50]};
  background: none;
  gap: ${spacing[16]};
  height: fit-content;

  ${media.tablet`
    padding: ${spacing[24]} ${spacing[40]} ${spacing[24]} ${spacing[24]};
    height: auto;
    background: ${grey[80]};
  `};

  ${media.tabletXLarge`
    height: 114px;
    justify-content: center;
  `}
`;

const ClassImage = styled(ResponsiveImage)`
  width: 216px;
  height: 120px;
  ${media.tabletXLarge`
      width: 310px;
      height: 160px;
  `};
  ${media.desktopXLarge`
      width: 392px;
      height: 216px;
  `};
  img {
    display: block;
    border-radius: 6px;
    ${media.tablet`
      border-radius: 6px 6px 0px 0px;
    `}
    width: 216px;
    height: 120px;
    ${media.tabletXLarge`
      width: 310px;
      height: 160px;
  `};
    ${media.desktopXLarge`
      width: 392px;
      height: 216px;
  `};
  }
`;

const StyledTruncatedLabel = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 98%;
`;

const DifficultyContainer = styled(Flex)`
  height: 18px;
`;

const DifficultySquare = styled.div<{
  difficulty: string;
}>`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 2px;
  transform: translateY(-1px);
  background-color: ${({ difficulty }) => colorMap[difficulty] || data.orange};
`;

const Card = styled(Flex)`
  overflow: hidden;
  height: 100%;

  ${media.tablet`
    height: auto;
  `}
`;
