import { Container, spacing, grey, Label } from '@pelotoncycle/design-system';
import Chevron from '@pelotoncycle/design-system/icons/arrows/Chevron';
import type { GenericCtaData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import { TrackingEvent } from '@ecomm/analytics/models';

export const BREADCRUMBS_HEIGHT = 40;

export type Props = {
  breadcrumbs: GenericCtaData[] | undefined;
};

/**
 * Renders two levels of breadcrumbs for the PDP pages.
 * @param breadcrumbs - List of two CTAs (Explore and Shop) used to render the breadcrumbs. This should be ordered so
 *                      that the Explore CTA is the first element and the Shop CTA is the second element in the list.
 */
const Breadcrumbs: React.FC<React.PropsWithChildren<Props>> = ({
  breadcrumbs,
}: Props) => {
  const { trackEvent } = useTracking();
  if (!breadcrumbs || breadcrumbs.length < 2) return null;

  const [exploreBreadcrumb, shopBreadcrumb] = breadcrumbs;

  return (
    <BreadcrumbsContainer
      height={`${BREADCRUMBS_HEIGHT}px`}
      verticalPadding={{
        desktop: `0 ${spacing[16]}`,
      }}
      position={{ desktop: `relative` }}
    >
      {exploreBreadcrumb.url && (
        <>
          <Label size="medium">
            <ExploreLink
              href={exploreBreadcrumb.url}
              onClick={() => {
                trackEvent({
                  event: TrackingEvent.ClickedLink,
                  properties: {
                    parentType: 'Component: Gallery NEW PDP UI',
                    parent: 'Gallery Container',
                    unitName: 'Component: Breadcrumbs',
                    linkName: exploreBreadcrumb.text,
                    linkTo: exploreBreadcrumb.url,
                  },
                });
              }}
            >
              {exploreBreadcrumb.text}
            </ExploreLink>
          </Label>
          <StyledIcon
            name="chevron"
            data-test-id="breadcrumb-icon"
            height={8}
            primaryColor={grey[70]}
            titleId="breadcrumbs-chevron"
          />
        </>
      )}
      {shopBreadcrumb && <ShopLabel size="medium">{shopBreadcrumb.text}</ShopLabel>}
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const ExploreLink = withI18n(styled.a`
  color: ${grey[90]};
  text-decoration: none !important;

  &:hover {
    color: ${grey[70]};
  }
`);

const ShopLabel = styled(Label)`
  color: ${grey[70]};
`;

// We use the Chevron icon directly so that it does not get loaded asynchronously and does not cause the breadcrumbs to shift when it loads in
const StyledIcon = styled(Chevron)`
  overflow: visible;
  padding: 0 ${spacing[8]};
  transform: rotate(180deg);
`;

export default Breadcrumbs;
