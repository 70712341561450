import { ErrorMsg } from '@pelotoncycle/design-system';
import React from 'react';
import { toInputWithError } from '@peloton/forms';
import Select from './Select';
import { errorStyles } from './styles';

const StyledErrorMsg: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ErrorMsg marginTop="8px">{children}</ErrorMsg>
);

export default toInputWithError(Select, errorStyles, StyledErrorMsg);
