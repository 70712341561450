import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { toDollarsCeil } from '@ecomm/models';
import type { PriceOrPriceRange } from '@ecomm/product-recommendations/models/Product';
import { isSinglePrice } from '@ecomm/product-recommendations/utils/productUtils';

const SINGLE_PRICE_FORMAT = '{price, number, currency}';
const PRICE_RANGE_FORMAT = '{low, number, currency} – {high, number, currency}';

const usePriceTextWithCurrency = (price: PriceOrPriceRange) => {
  const toFormattedText = useGetTextFormatter();

  if (isSinglePrice(price)) {
    return toFormattedText(SINGLE_PRICE_FORMAT, { price: toDollarsCeil(price) });
  }

  const [low, high] = price;
  return toFormattedText(PRICE_RANGE_FORMAT, {
    low: toDollarsCeil(low),
    high: toDollarsCeil(high),
  });
};

export default usePriceTextWithCurrency;
