import {
  Headline,
  Support,
  spacing,
  ResponsiveImage,
  Flex,
  Button,
  Badge,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys, toImageProps } from '@page-builder/utils/helpers';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';

const mobileAndTabletOnly = {
  mobile: 'contents',
  desktop: 'none',
};

const desktopOnly = {
  mobile: 'none',
  desktop: 'contents',
};

const ContainedPromoBanner: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ name, text, ctas, media, theme = 'Grey 30' }) => {
  const { trackLinkClick } = useTrackLinkClick();

  const { backgroundColor, textColor, headlineColor, buttonColor } = themes[theme];
  const { headline, support, eyebrow } = getTextFieldsWithRequiredKeys(
    ['headline', 'support'],
    text,
  );
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const image = toImageProps(media);

  if (!primaryCta?.url) {
    throw new Error('Primary Cta Missing');
  }

  const onLinkClick = () => {
    trackLinkClick({
      href: primaryCta.url!,
      parent: 'Contained Promo Banner',
      additionalProps: {
        unitName: name,
      },
    });
  };

  return (
    <Flex
      id={nameToModuleId(name)}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[64],
        desktop: spacing[40],
      }}
      verticalPadding={{
        mobile: spacing[24],
        desktop: `${spacing[8]} ${spacing[40]}`,
      }}
      justifyContent="center"
    >
      <LinkCard onClick={onLinkClick} href={primaryCta.url}>
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor={backgroundColor}
          verticalPadding={{
            mobile: `${spacing[16]} ${spacing[32]}`,
            desktop: spacing[32],
          }}
          horizontalPadding={spacing[12]}
          borderRadius={spacing[12]}
          flexDirection={{
            mobile: 'column',
            desktop: 'row',
          }}
          gap={{
            mobile: spacing[16],
            desktop: spacing[32],
          }}
          textAlign="center"
          centered
        >
          {image && (
            <Flex justifyContent="center" minWidth="192px">
              <Image mobile={image.mobile} alt={image.alt} loading="lazy" />
            </Flex>
          )}
          <Flex
            flexDirection="column"
            gap={spacing[16]}
            maxWidth={{
              mobile: '358px',
              tablet: '392px',
            }}
          >
            {eyebrow && (
              <Flex justifyContent="center">
                <Badge variant="horizontal" theme="accent">
                  {eyebrow}
                </Badge>
              </Flex>
            )}
            <Headline is="h2" size="extraSmall" textColor={headlineColor}>
              <Markdown content={headline} />
            </Headline>
            <Support is="h3" size="small" textColor={textColor} display={desktopOnly}>
              <Markdown content={support} />
            </Support>
          </Flex>
          <Flex
            justifyContent="center"
            minWidth="192px"
            verticalPadding={{
              mobile: spacing[8],
              desktop: 0,
            }}
          >
            <Button
              size="medium"
              color={primaryCta.color || buttonColor}
              variant={primaryCta.variant || 'outline'}
              width="adaptive"
              is="div"
            >
              {primaryCta.text}
            </Button>
          </Flex>
          <Support
            is="h3"
            size="small"
            textColor={textColor}
            display={mobileAndTabletOnly}
          >
            <Markdown content={support} />
          </Support>
        </Flex>
      </LinkCard>
    </Flex>
  );
};

const Image = styled(ResponsiveImage)`
  img {
    max-width: 160px;
  }
`;

const LinkCard = withI18n(styled.a`
  display: block;
  max-width: 1224px;
  width: 100%;
`);

export default ContainedPromoBanner;
