import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import type { Class, FreeClass } from '@peloton/models/PelotonClass';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { getPagePath } from '@peloton/next/utils/getPagePath';
import {
  checkHasPaginationSlug,
  getPageFromSlug,
  transformPaginatedPath,
} from '@peloton/next/utils/pagination';
import { fetchClassListingData } from '@ecomm/classes/utils/fetchClassListingData';
import { CLASS_LISTING_LIMIT } from '@ecomm/classes/utils/fetchDisciplineDataApi';
import { getPagefromQuery } from '@ecomm/classes/utils/getPageFromQuery';
import { getPageFromPath, isPageHigherThanTotal } from '@ecomm/classes/utils/helpers';
import { useLocalStorage } from '@ecomm/hooks';
import type { PaginationState } from '@ecomm/pagination/LoadMore/LoadMoreContext';
import { LoadMoreProvider } from '@ecomm/pagination/LoadMore/LoadMoreContext';
import { useCacheKey } from '@page-builder/hooks/useCustomPageData';
import type {
  ClassesGridComponent,
  ClassesGridComponentWithClasses,
} from '../ClassesGrid';

interface CustomPageData {
  classes: Class[];
  totalClasses: number;
  freeClass?: FreeClass;
  paginationState?: PaginationState;
}

export interface StoredPaginatedClasses {
  highestPage: number;
  lowestPage: number;
  classes: Class[];
  path: string;
  freeClass?: FreeClass;
  totalClasses: number;
}

const STORED_PAGINATED_CLASSES = 'storedPaginatedClasses';

export const withPagination = (
  Component: ClassesGridComponentWithClasses,
): ClassesGridComponent => {
  const ClassesGridWithPagination: ClassesGridComponent = props => {
    const router = useRouter();
    const locale = useLocale();
    const { mutate } = useSWRConfig();
    const cacheKey = useCacheKey();
    const cacheKeyWithoutPage = cacheKey.split('?')[0];
    const [frozenCacheKey, setFrozenCacheKey] = useState(cacheKey);
    const paginationStateRef = useRef<PaginationState | null>(null);
    const { data } = useSWR<CustomPageData>(frozenCacheKey, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });
    const [
      storedPaginatedClasses,
      setStoredPaginatedClasses,
    ] = useLocalStorage<StoredPaginatedClasses>(STORED_PAGINATED_CLASSES, null);

    const page = router.asPath.includes('page=')
      ? getPageFromPath(router.asPath)
      : getPagefromQuery(router.query);
    const {
      classes: initialClasses,
      totalClasses: initialTotalClasses,
      paginationState,
      freeClass: initialFreeClass,
      ...additionalData
    } = data || {
      classes: [],
      totalClasses: 0,
    };

    if (paginationState && !paginationStateRef.current) {
      paginationStateRef.current = paginationState;
    }

    const [classes, setClasses] = useState(initialClasses || []);
    const [freeClass, setFreeClass] = useState(initialFreeClass);
    const [totalClasses, setTotalClasses] = useState(initialTotalClasses || 0);
    const [totalPages, setTotalPages] = useState(
      Math.ceil(totalClasses / CLASS_LISTING_LIMIT),
    );

    const getPageHref = (pageIndex: number, includeBasePath = true) => {
      const hrefPath = getPagePath(router, { includeBasePath });
      const hrefQueryParams = new URLSearchParams(router.asPath.split('?')[1] || '');
      hrefQueryParams.set('page', `${pageIndex}`);
      const hrefQueryString = hrefQueryParams.toString();
      const href = `${hrefPath}?${hrefQueryString}`;
      return href;
    };

    const setPage = async (
      pageIndex: number,
      previousPageIndex: number,
      state: PaginationState,
    ) => {
      if (!isPageHigherThanTotal(pageIndex, totalPages) || totalPages === 0) {
        paginationStateRef.current = state;
        const insertBefore = pageIndex < previousPageIndex;
        const { newClassList, newTotalClasses } = await fetchPaginatedClasses({
          query: { ...router.query, page: `${pageIndex}` },
          insertBefore,
        });
        const destination = getPageHref(pageIndex, false);
        router.replace(destination, undefined, { shallow: true, scroll: false });
        setClasses(newClassList);
        updateTotalClassesAndPages(newTotalClasses);
        setStoredPaginatedClasses({
          highestPage: state.highestLoadedPage,
          lowestPage: state.lowestLoadedPage,
          classes: newClassList,
          path: destination,
          freeClass,
          totalClasses: newTotalClasses,
        });
      }
    };

    const updateTotalClassesAndPages = (newTotalClasses: number): void => {
      if (newTotalClasses) {
        setTotalClasses(newTotalClasses);
        setTotalPages(Math.ceil(newTotalClasses / CLASS_LISTING_LIMIT));
      }
    };

    const loadClassesFromStorage = (): void => {
      if (storedPaginatedClasses) {
        paginationStateRef.current = {
          highestLoadedPage: storedPaginatedClasses.highestPage,
          lowestLoadedPage: storedPaginatedClasses.lowestPage,
        };
        updateTotalClassesAndPages(storedPaginatedClasses.totalClasses);
        setFreeClass(storedPaginatedClasses.freeClass);
        setClasses(storedPaginatedClasses.classes);
      }
    };

    useEffect(() => {
      const pathWithoutParams = router.asPath.split('?')[0];
      if (checkHasPaginationSlug(pathWithoutParams)) {
        const slugPage = getPageFromSlug(pathWithoutParams);
        if (!isPageHigherThanTotal(slugPage, totalPages)) {
          router.replace(transformPaginatedPath(pathWithoutParams), undefined, {
            shallow: true,
            scroll: false,
          });
        }
      }
    }, []);

    useEffect(() => {
      const frozenCacheKeyWithoutPage = frozenCacheKey.split('?')[0];
      const isDifferentRoute = cacheKeyWithoutPage !== frozenCacheKeyWithoutPage;

      if (isDifferentRoute) {
        setFrozenCacheKey(cacheKey);
      } else {
        mutate(cacheKey, {
          classes,
          freeClass,
          totalClasses,
          paginationState: paginationStateRef.current,
          ...additionalData,
        });
      }
    }, [cacheKey]);

    useEffect(() => {
      setClasses(data?.classes || []);
      setTotalClasses(data?.totalClasses || 0);
    }, [frozenCacheKey]);

    useEffect(() => {
      const getClassesForPageOnLoad = async () => {
        if (classes?.length === 0) {
          const newPage = isPageHigherThanTotal(page, totalPages)
            ? totalPages
            : page > 0
            ? page
            : 1;
          if (storedPaginatedClasses?.path === router.asPath) {
            loadClassesFromStorage();
          } else {
            const state: PaginationState = {
              highestLoadedPage: newPage,
              lowestLoadedPage: newPage,
            };
            await setPage(newPage, newPage - 1, state);
          }
        }
      };
      getClassesForPageOnLoad();
    }, [page, totalPages, classes]);

    const fetchPaginatedClasses = async ({
      query,
      insertBefore,
    }: {
      query: NextParsedUrlQuery;
      insertBefore: boolean;
    }) => {
      const classListingData = await fetchClassListingData({
        slug: getPagePath(router),
        locale: locale as string,
        query,
      });
      const newClassList = insertBefore
        ? classListingData.classes.concat(classes)
        : classes.concat(classListingData.classes);
      return { newClassList, newTotalClasses: classListingData.totalClasses };
    };

    return (
      <LoadMoreProvider
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        getPageHref={getPageHref}
        initialState={paginationStateRef.current}
        listingKey={cacheKeyWithoutPage}
      >
        <Component
          {...props}
          freeClass={freeClass}
          classes={classes}
          totalClasses={totalClasses}
          isPaginated={totalClasses > CLASS_LISTING_LIMIT}
        />
      </LoadMoreProvider>
    );
  };

  return ClassesGridWithPagination;
};
