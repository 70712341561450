import {
  Container,
  Flex,
  Label,
  spacing,
  Support,
  BreakpointWidths,
  Toast,
  Icon,
  white,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import { MODAL_CLOSE_TRANSITION_TIME } from '@ecomm/modal/ScrollFromBottomModalStyles';
import { isProductSparePart } from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { CtaType } from '@ecomm/product-recommendations/models/TileComponentProps';
import type { TileComponentProps } from '@ecomm/product-recommendations/models/TileComponentProps';
import { getItemInCart } from '@ecomm/product-recommendations/utils/cartUtils';
import type { QuickAddProductFunction } from '@ecomm/product-recommendations/utils/quick-add/types';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import type { ButtonDisplayLocation } from '@ecomm/product-recommendations/utils/toTileButtons';
import { useCartResponse } from '@ecomm/product-recommendations/utils/useCart';
import useDetailsUrl from '@ecomm/product-recommendations/utils/useDetailsUrl';
import AddToCartMutationWrapper from '@ecomm/product-recommendations/Views/AddToCartMutationWrapper';
import FinancingText from '@ecomm/product-recommendations/Views/FinancingText';
import PackageContentsButton from '@ecomm/product-recommendations/Views/Grid/PackageContentsButton';
import {
  ImageContainer,
  TileMedia,
  TileButton,
  TileDescription,
  TileLink,
  TileWrapper,
} from '@ecomm/product-recommendations/Views/Grid/SharedTileComponents';
import PriceDisplay from '@ecomm/product-recommendations/Views/PriceDisplay';
import ProductPills from '@ecomm/product-recommendations/Views/ProductPills';
import { QuickViewModal } from '@ecomm/product-recommendations/Views/QuickViewModal';
import {
  setUrlQueryParams,
  deleteUrlQueryParam,
  getExistingQueryValueFor,
} from '@page-builder/utils/helpers/urlParams';
import Markdown from '@page-builder/utils/Markdown';
import GridTileCtas from './GridTileCtas';

export function replaceProductImage(product: Product) {
  if (!product.overrideImageUrl) {
    return product;
  }
  return {
    ...product,
    imageUrl: product.overrideImageUrl,
    images: [product.overrideImageUrl, ...(product.images?.slice(1) || [])],
  };
}

// This component is largely ported from Grid/GridProductTile
const GridTileComponent: React.FC<React.PropsWithChildren<TileComponentProps>> = ({
  product,
  ctaType,
  themeName,
}) => {
  const { isValidCartResponse, cart, isCTCart } = useCartResponse();
  const itemCountCopy = useMicroCopy('inYourCart');
  const toastNotificationCopy = useMicroCopy('addedToCart');
  const itemInCart = getItemInCart({ product, cart, isCTCart });
  const itemQuantityInCart: number = itemInCart ? itemInCart.quantity : 0;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState('closed');
  const {
    name,
    imageUrl,
    overrideImageUrl,
    factoid,
    altInCartDescription,
    packageContents = [],
  } = product;
  const [showToast, setShowToast] = useState(false);

  const showAltDescription =
    isValidCartResponse && Boolean(itemInCart) && altInCartDescription;

  const productUrl = useDetailsUrl(product);
  const productImageUrl = overrideImageUrl || imageUrl;
  const { imageBackground } = toGridTheme(themeName);

  const isSparePart = isProductSparePart(product);

  const isLink = !ctaType;
  const isMobile = useMedia(`(max-width: ${BreakpointWidths.tablet}px)`);
  const breakpoint = isMobile ? 'mobile' : 'desktop';

  const { trackEvent } = useTracking();

  const clickedLinkEventProps = (buttonText: string, linkTo: string = '') => {
    return {
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName: name,
        linkName: buttonText,
        linkTo,
      },
    };
  };

  // TODO: Move all spare parts and modal logic into a separate location
  React.useEffect(() => {
    const sparePart = getExistingQueryValueFor('sparePart');
    if (sparePart && product.slug === sparePart) {
      setModalOpen(true);
    }
  }, [product.slug]);

  const setUrlProductSlug = () => {
    setUrlQueryParams('sparePart', product.slug);
  };

  const resetUrlProductSlug = () => {
    deleteUrlQueryParam('sparePart');
  };

  const createModalEventProps = (
    eventName: 'Modal Opened' | 'Modal Closed',
    modalTrigger: string,
  ) => {
    return {
      event: eventName,
      properties: {
        productInterest: name,
        propertyType: 'Web',
        unitName: `${name} Quick View Modal`,
        unitPlacement: 'Modal',
        modalTrigger,
      },
    };
  };

  const openModal = (e?: React.MouseEvent, buttonText: string = 'Quick view') => {
    e?.preventDefault();

    if (isSparePart) {
      setUrlProductSlug();
    }

    setModalOpen(true);
    setModalKey('open');

    trackEvent(clickedLinkEventProps(buttonText));
    trackEvent(
      createModalEventProps('Modal Opened', `${name} '${buttonText}' CTA click`),
    );
  };

  const handleDefaultClick: React.MouseEventHandler = e => {
    if (isSparePart) {
      setUrlProductSlug();
    }

    if (ctaType) {
      const buttonText = e.currentTarget.textContent || 'Quick view';
      openModal(e, buttonText);
    } else {
      trackEvent({
        event: TrackingEvent.ClickedLink,
        properties: {
          unitName: name,
          linkName: '',
          linkTo: productUrl,
        },
      });
    }
  };

  const onQuickAdd = async (
    e: React.MouseEvent,
    quickAddProduct: QuickAddProductFunction,
  ) => {
    if (product.isConfigurable) {
      openModal(e, 'Quick Add');
    } else {
      try {
        await quickAddProduct([], {
          shouldOpenCartPanel: false,
        });
        setShowToast(true);
        trackEvent({
          event: TrackingEvent.ClickedLink,
          properties: {
            unitName: name,
            linkName: 'Quick Add',
            linkTo: productUrl,
          },
        });
      } catch (error) {
        console.error('Failed to add product to cart:', error);
      }
    }
  };

  const onCloseModal = () => {
    resetUrlProductSlug();
    setModalOpen(false);
    // The key change is used to reset the input elements in the
    // modal on closing / reopening the modal.
    // The timeout is required to prevent the modal from closing abruptly (leading to a flash)
    // which will happen if the key for the modal changes before the closing transition
    // animation completes
    setTimeout(() => setModalKey('closed'), MODAL_CLOSE_TRANSITION_TIME);
    trackEvent(
      createModalEventProps('Modal Closed', `User closed ${name} 'Quick view' modal`),
    );
  };

  const renderCtas = (displayLocation: ButtonDisplayLocation) => {
    return (
      <AddToCartMutationWrapper product={product}>
        {addToCartMutation => {
          const [quickAddProduct, { loading }] = addToCartMutation;
          return (
            <GridTileCtas
              ctaType={ctaType}
              breakpoint={breakpoint}
              isMobile={isMobile}
              loading={loading}
              handlers={{
                onQuickView: handleDefaultClick,
                onQuickAdd: e => onQuickAdd(e, quickAddProduct),
                onSeeDetails: handleDefaultClick,
              }}
              displayLocation={displayLocation}
            />
          );
        }}
      </AddToCartMutationWrapper>
    );
  };

  return (
    <>
      <TileWrapper data-test-id="grid-tile-component">
        {isLink ? (
          <TileLink href={productUrl} onClick={handleDefaultClick}>
            {name}
          </TileLink>
        ) : (
          <TileButton onClick={handleDefaultClick}>{name}</TileButton>
        )}
        <Flex flexDirection="column" gap={spacing[16]}>
          <ImageContainer
            background={imageBackground}
            borderRadius={spacing[4]}
            position="relative"
            flexDirection="column"
            justifyContent={isSparePart ? 'space-between' : 'flex-start'}
          >
            <Container position="relative" height="100%" width="100%">
              <ProductPills product={product} />
              <TileMedia
                width="100%"
                height="100%"
                objectFit="contain"
                media={{
                  mobile: toCloudinarySrc({ src: productImageUrl, height: 600 }),
                  alt: name,
                  type: 'image',
                  loading: 'lazy',
                }}
              />
              {isValidCartResponse && Boolean(itemInCart) && (
                <InCartCount data-test-id="in-cart-count">
                  <Markdown
                    content={itemCountCopy}
                    values={{ number: itemQuantityInCart }}
                    markdown={{
                      renderers: {
                        paragraph: ({ children }) => <Support>{children}</Support>,
                      },
                    }}
                  />
                </InCartCount>
              )}
            </Container>
            {renderCtas('imageContainer')}
            {packageContents.length > 0 && (
              <PackageContentsButton product={product} themeName={themeName} />
            )}
          </ImageContainer>
          <Flex
            flexDirection="column"
            gap={spacing[8]}
            flexGrow={1}
            justifyContent="space-between"
          >
            <Label size="large">{name}</Label>
            <Flex flexDirection="column" gap={spacing[8]}>
              <Flex flexDirection="column" gap={spacing[4]}>
                <Support size="large" data-test-id="grid-product-tile-price">
                  <PriceDisplay product={product} />
                </Support>
                {factoid && (
                  <TileDescription>
                    {showAltDescription ? altInCartDescription : factoid}
                  </TileDescription>
                )}
                <FinancingText product={product} />
              </Flex>
              {isSparePart && (
                <TileDescription truncated>{product.description}</TileDescription>
              )}
              {renderCtas('belowDescription')}
            </Flex>
          </Flex>
        </Flex>
      </TileWrapper>
      <AddToCartMutationWrapper product={product}>
        {addToCartMutation => (
          <QuickViewModal
            key={modalKey}
            product={replaceProductImage(product)}
            isOpen={modalOpen}
            onClose={onCloseModal}
            addToCartMutation={addToCartMutation}
            isInCart={false}
            showToastOnAdd={ctaType === CtaType.UpsellCtaGroup ? true : false}
          />
        )}
      </AddToCartMutationWrapper>
      <Toast
        content={
          <Flex gap={spacing[16]} alignItems="center">
            <Icon name="check" primaryColor={white} height={12} />
            {toastNotificationCopy}
          </Flex>
        }
        showToast={showToast}
        textColor={white}
        onClose={() => setShowToast(false)}
        data-test-id="quick-add-toast"
      />
    </>
  );
};

const InCartCount = styled(Flex)`
  position: absolute;
  bottom: ${spacing[8]};
  left: ${spacing[8]};
  z-index: 1;
  ${media.desktop`
    bottom: ${spacing[16]};
    left: ${spacing[16]};
  `}
`;

export default GridTileComponent;
