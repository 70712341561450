import type { CartFragment } from '@ecomm/cart-next/graphql/fragments/Cart.generated';
import type { Maybe } from '@ecomm/graphql/types.generated';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';

type GetItemInLegacyCartProps = {
  product: Product;
  legacyCart?: Maybe<{ __typename?: 'CartNext' } & CartFragment>;
};

export const getItemInLegacyCart = ({
  product,
  legacyCart,
}: GetItemInLegacyCartProps) => {
  if (!legacyCart || !legacyCart?.numberOfItems) {
    return false;
  }

  return legacyCart.items.find(item => {
    switch (item.__typename) {
      case 'AccessoryBundleItem': {
        return item.products.some(bundleItem => bundleItem.productId === product.id);
      }
      case 'ProductItem': {
        return item.productId === product.id;
      }
      case 'CfuBundleItem': {
        return item.products.some(bundleItem => bundleItem.productId === product.id);
      }
      case 'LeasingBundleItem': {
        return item.products.some(bundleItem => bundleItem.productId === product.id);
      }
      case 'MembershipItem': {
        return item.productId === product.id;
      }
      case 'PrepaidMembershipCreditsItem': {
        return item.productId === product.id;
      }
      default:
        return false;
    }
  });
};

type GetItemInCTCartProps = {
  product: Product;
  ctCart?: Maybe<{ __typename?: 'ShopCart' } & CtCartFragment>;
};

export const getItemInCTCart = ({ product, ctCart }: GetItemInCTCartProps) => {
  return ctCart?.lineItems.find(item => {
    if (!!item.bundleItemLineItems) {
      return item.bundleItemLineItems.find(
        bundleItem => bundleItem.productKey === product.slug,
      );
    } else {
      return item.productKey === product.slug;
    }
  });
};

type GetItemInCartProps = {
  product: Product;
  isCTCart: boolean;
  cart?:
    | Maybe<{ __typename?: 'CartNext' } & CartFragment>
    | Maybe<{ __typename?: 'ShopCart' } & CtCartFragment>;
};

export const getItemInCart = ({ product, isCTCart, cart }: GetItemInCartProps) => {
  if (isCTCart && cart?.__typename === 'ShopCart') {
    return getItemInCTCart({ product, ctCart: cart });
  } else if (cart?.__typename === 'CartNext') {
    return getItemInLegacyCart({ product, legacyCart: cart });
  } else {
    return false;
  }
};
