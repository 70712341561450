import React from 'react';
import styled from 'styled-components';
import { SPECIAL_PRICING_PRODUCT_TAG } from '@peloton/concessionary-pricing/constants';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { isProductCFU } from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { isSinglePrice } from '@ecomm/product-recommendations/utils/productUtils';
import usePriceTextWithCurrency from '@ecomm/product-recommendations/utils/usePriceTextWithCurrency';
import {
  PriceHighlightCopy,
  priceHighlightStyles,
  SmallPriceHighlightCopy,
  StrikeThrough,
  strikethroughStyles,
} from '@ecomm/product-recommendations/Views/MarkdownFormatters';
import { PromoPrice } from '@page-builder/modules/Promo';
import Markdown from '@page-builder/utils/Markdown';

export const FormattedPrice: React.FC<{
  content: string;
  values?: Record<string, string | React.ReactNode>;
}> = ({ content, values = {} }) => {
  return (
    <Markdown
      content={content}
      values={values}
      markdown={{
        renderers: {
          paragraph: PriceWrapper,
          delete: StrikeThrough,
          strong: PriceHighlightCopy,
          emphasis: SmallPriceHighlightCopy,
        },
      }}
    />
  );
};

export const StaticPriceDisplay: React.FC<{ price: number; priceDisplay?: string }> = ({
  price,
  priceDisplay,
}) => {
  const priceText = usePriceTextWithCurrency(price);

  if (priceDisplay) {
    return <FormattedPrice content={priceDisplay} values={{ price: priceText }} />;
  }

  return <>{priceText}</>;
};

const PriceDisplay: React.FC<{
  product: Product;
}> = ({ product }) => {
  const { price, priceDisplay, slug } = product;

  const shouldUseContentPromo = useIsToggleActive()('usePromoContentMgmt');
  const priceText = usePriceTextWithCurrency(price);

  const isSpecialPricingPage = Boolean(
    product.tags?.includes(SPECIAL_PRICING_PRODUCT_TAG),
  );

  if (priceDisplay) {
    return <FormattedPrice content={priceDisplay} values={{ price: priceText }} />;
  }

  if (isProductCFU(product) && isSinglePrice(price) && shouldUseContentPromo) {
    return (
      <PriceWrapper>
        <PromoPrice
          cfu={product.bundleType}
          bundleSlug={slug}
          price={price}
          specialPricingOptions={{
            ignorePromo: isSpecialPricingPage,
            requireVerification: !isSpecialPricingPage,
          }}
        />
      </PriceWrapper>
    );
  }

  return <>{priceText}</>;
};

const PriceWrapper = styled.span`
  display: inline-flex;
  gap: 6px;
  align-items: center;

  s {
    ${strikethroughStyles}
  }

  span {
    ${priceHighlightStyles}
  }
`;

export default PriceDisplay;
