import cuid from 'cuid';
import React from 'react';
import styled from 'styled-components';
import type { InputEvent, InputProps, Option } from '@peloton/forms';
import { labelStyles } from './styles';

type State = {
  userFocused: boolean;
  value: string;
};

export type Props = InputProps<HTMLSelectElement> & {
  options: Option[];
  showEmptyOption?: boolean;
  showAsterisk?: boolean;
};

export default class SelectInput extends React.Component<Props, State> {
  static displayName = 'SelectInput';

  state = {
    userFocused: false,
    value: '',
  };

  render() {
    const {
      getRef,
      isActive = false,
      label,
      onChange,
      options,
      required = false,
      touched,
      showEmptyOption = true,
      value,
      showAsterisk = true,
      ...props
    } = this.props;
    const uniqueId = cuid();

    return (
      <>
        <Label isActive={isActive} hasText={!!value} htmlFor={uniqueId}>
          {label}
          {showAsterisk && required && '*'}
        </Label>
        <select
          {...props}
          id={uniqueId}
          aria-label={label}
          aria-required={required}
          data-user-focused={this.state.userFocused}
          data-value={value || this.state.value}
          onChange={onChange || this.handleChange}
          onFocus={this.handleFocus}
          ref={getRef}
          required={required}
          value={value || this.state.value}
        >
          {showEmptyOption && <option value="" />}
          {options.map(({ label: optionLabel, ...option }) => (
            <option key={option.value} {...option}>
              {optionLabel}
            </option>
          ))}
        </select>
      </>
    );
  }

  private handleChange = (e: InputEvent) => {
    e.preventDefault();

    this.setState({ value: e.currentTarget.value });
  };

  private handleFocus = (e: InputEvent) => {
    if (this.props.onFocus) {
      this.props.onFocus(e as any);
    }

    this.setState({ userFocused: true });
  };
}

const Label = styled.label`
  ${labelStyles}
  pointer-events: none;
`;
