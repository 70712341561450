import {
  Headline,
  Support,
  spacing,
  ResponsiveImage,
  Flex,
} from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import useDigitalPrice from '@ecomm/subscription-plans/useDigitalPrice';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { usePriceToText } from '@page-builder/utils/helpers/priceToText';

const defaults = {
  theme: 'Grey 90',
} as const;

const DigitalPromo: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ text, ctas, media: mediaField, theme = defaults.theme }) => {
  const { backgroundColor, textColor, headlineColor, buttonColor } = themes[theme];

  const { trackEvent } = useTracking();
  const { headline, support } = getTextFieldsWithRequiredKeys(
    ['headline', 'support'],
    text,
  );
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);

  const image = toImageProps(mediaField);

  const appPrice = useDigitalPrice();
  const formattedPriceToText = usePriceToText(appPrice);
  const legalText = support
    ? support.replace('{membershipPrice}', formattedPriceToText)
    : '';

  return (
    <Container
      alignItems="stretch"
      justifyContent="center"
      backgroundColor={backgroundColor}
    >
      <Content alignItems="center" flexDirection="column">
        {image && <StyledImage mobile={image.mobile} alt={image.alt} loading="lazy" />}
        <TextContainer flexDirection="column">
          <StyledHeadline forwardedAs="h2" size="small" textColor={headlineColor}>
            {headline}
          </StyledHeadline>
          <DesktopSupport forwardedAs="h3" size="small" textColor={textColor}>
            {legalText}
          </DesktopSupport>
        </TextContainer>
        {primaryCta && (
          <LinkButton
            size="medium"
            color={buttonColor}
            to={primaryCta?.url}
            href={primaryCta?.url}
            text={primaryCta?.text || ''}
            variant="outline"
            onClick={() => trackCtaClick(trackEvent, primaryCta.url || '')}
          />
        )}
        <MobileSupport forwardedAs="h3" size="small" textColor={textColor}>
          {legalText}
        </MobileSupport>
      </Content>
    </Container>
  );
};

export default DigitalPromo;

const trackCtaClick = (track: (data: object) => void, linkTo: string) =>
  track({
    event: 'Clicked Link',
    properties: {
      page: window.location.pathname,
      parent: 'classes discipline module',
      unitName: 'classes discipline module CTA 1',
      linkTo,
    },
  });

const Container = styled(Flex)`
  margin: 0;
  max-width: 100%;
  padding: 60px 20px;
  width: 100%;

  ${media.desktop`
    height: 260px;
    padding: 0 30px;
  `}
`;

const Content = styled(Flex)`
  max-width: 900px;
  width: 100%;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const StyledImage = styled(ResponsiveImage)`
  margin-bottom: 30px;
  max-width: 170px;

  ${media.desktop`
    margin-bottom: 0;
  `}

  img {
    width: 170px;
    height: 127px;
  }
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  max-width: 316px;

  ${media.tablet`
    max-width: 480px;
  `}

  ${media.desktop`
    text-align: left;
    max-width: 400px;
`}
`;

const TextContainer = styled(Flex)`
  margin-bottom: ${spacing[24]};

  ${media.desktop`
    margin: 24px 55px 0;
  `}
`;

const DesktopSupport = styled(Support)`
  display: none;

  ${media.desktop`
    display: block;
    margin-top: ${spacing[16]};
  `}
`;

const MobileSupport = styled(Support)`
  margin-top: ${spacing[16]};

  ${media.desktop`
    display: none;
  `}
`;
