import { black, grey, spacing, Label } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { themes } from '@page-builder/themes';
import type { TOCItemProps } from './types';

const TOCItem: React.FC<React.PropsWithChildren<TOCItemProps>> = ({
  activeId,
  cta,
  handleClick,
  theme = 'Grey 90',
}) => {
  const sectionId = cta.link.url;
  const currentRef = React.useRef<HTMLAnchorElement>(null);
  const { backgroundColor, headlineColor } = themes[theme];

  useEffect(() => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activeId]);

  if (cta.variant === 'outline') {
    return (
      <StyledOutlineLink
        key={cta.text}
        onClick={e => handleClick(e, cta, sectionId)}
        href={sectionId}
        ref={activeId === sectionId ? currentRef : null}
        isActive={activeId === sectionId}
        textColor={headlineColor}
        backgroundColor={backgroundColor}
      >
        <Label as="span">{cta.text}</Label>
      </StyledOutlineLink>
    );
  }

  return (
    <StyledDefaultLink
      key={cta.text}
      ref={activeId === sectionId ? currentRef : null}
      onClick={e => handleClick(e, cta, sectionId)}
      href={sectionId}
      isActive={activeId === sectionId}
      textColor={headlineColor}
    >
      <Label as="span" size="large">
        {cta.text}
      </Label>
    </StyledDefaultLink>
  );
};

export default TOCItem;

const StyledOutlineLink = styled.a<{
  isActive: boolean;
  textColor: string;
  backgroundColor: string;
}>`
  box-shadow: 0 0 0 1px ${grey[50]};
  border-radius: ${spacing[8]};
  padding: ${spacing[8]} ${spacing[16]};
  height: ${spacing[40]};
  display: flex;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 1.5px ${black};
      transition: box-shadow 500ms ease-in-out;
    }
  }
  ${({ isActive, backgroundColor, textColor }) =>
    isActive && `color: ${backgroundColor}; background-color: ${textColor};`}
  transition: all 300ms ease-in-out;
`;

const StyledDefaultLink = styled.a<{ isActive: boolean; textColor: string }>`
  color: ${({ textColor }) => textColor};
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${grey[50]};
  }

  ::after {
    content: '';
    display: block;
    height: 1px;
    margin-top: -1px;
    transition: background-color 0.15s ease-in-out;
  }

  ${({ isActive, textColor }) =>
    isActive &&
    `
      &:hover::after {
        background-color: ${grey[50]};
      }

      ::after {
        background-color: ${textColor};
      }
    `}
`;
