import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';

export const useTrackingUtils = () => {
  const { trackEvent } = useTracking();

  const parentType = 'Component: JSON';
  const parent = 'MovementLibrary (Prospects)';

  const handleClickedVideoTracking = (movementName: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: parentType,
        parent: parent,
        unitName: 'Movement Video',
        linkName: 'Movement Video - ' + movementName,
        linkTo: '/',
      },
    });
  };

  const handleModalCloseTracking = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: parentType,
        parent: parent,
        unitName: 'Movement Video Modal',
        linkName: 'Exit Movement Library',
        linkTo: '/',
      },
    });
  };

  const handleStartedVideoTracking = () => {
    trackEvent({
      event: TrackingEvent.VideoContentStarted,
      properties: {
        parentType: parentType,
        parent: parent,
      },
    });
  };

  const handleTrackCTAClick = (linkName: string, linkTo: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: parentType,
        parent: parent,
        unitName: 'CTA',
        linkName: linkName,
        linkTo: linkTo,
      },
    });
  };

  return {
    handleClickedVideoTracking,
    handleModalCloseTracking,
    handleStartedVideoTracking,
    handleTrackCTAClick,
  };
};
