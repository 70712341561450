import { Container, Flex, Support, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ErrorBoundary, useErrorReporter } from '@peloton/error-reporting';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Partners } from '@ecomm/financing/models';
import { toEntryTags } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import CtaHandler from '../CtaHandler';
import FinancingCtaHandler from '../Financing/FinancingCtaHandler';
import toFinancingPartnerFromTags from '../Financing/toFinancingPartnerFromTags';
import { getFinancingDrawerExpandCta } from '../Financing/utils';
import { useDrawerContentContext } from './../../DrawerContentContext';

type Props = {
  product: string;
  parentName: string;
};

const BaseItem: React.FC<React.PropsWithChildren<Props>> = ({ product, parentName }) => {
  const { errorReporter } = useErrorReporter();
  const { pageBuilderData } = useDrawerContentContext();
  const { ctas, financingCtas, financingText, isOpen } = pageBuilderData;

  const isToggleActive = useIsToggleActive();
  const isTruemedEnabled = isToggleActive('truemed_enabled');
  const isCPayEnabled = isToggleActive('cpay_enabled');

  const filteredFinancingCtas = financingCtas?.filter(cta => {
    const tags = toEntryTags(cta);
    const partner = toFinancingPartnerFromTags(tags);

    if (partner === Partners.Truemed) {
      return isTruemedEnabled;
    }

    if (partner === Partners.Citizens) {
      return isCPayEnabled;
    }

    // with price merchandising in the drawers section, we do not want to show the ATC placement for klarna
    return partner !== Partners.Klarna;
  });

  const hasFinancingCtas = Boolean(
    filteredFinancingCtas && filteredFinancingCtas.length > 0,
  );
  const financingDrawer =
    filteredFinancingCtas && getFinancingDrawerExpandCta(filteredFinancingCtas);

  const FinancingStyledSupport: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => <StyledSupport hasFinancingCtas={hasFinancingCtas}>{children}</StyledSupport>;

  return (
    <Container style={{ width: '100%' }}>
      <Flex flexDirection="column">
        <ErrorBoundary
          renderError={() => <></>}
          reportError={errorReporter.reportError}
          errorContext={{ tags: { component: 'financingSection' } }}
        >
          <Container
            verticalPadding={financingText || hasFinancingCtas ? `0 ${spacing[32]}` : 0}
            data-test-id="financingSection"
          >
            {financingDrawer ? (
              <FinancingCtaHandler
                financingText={financingText}
                ctas={filteredFinancingCtas!}
                financingDrawerCta={financingDrawer}
                product={product}
                parentName={parentName}
                isOpen={isOpen}
              />
            ) : (
              <>
                {financingText && (
                  <Markdown
                    content={financingText}
                    values={{ placement: 'shopDrawer' }}
                    markdown={{ renderers: { paragraph: FinancingStyledSupport } }}
                  />
                )}
                <Flex flexDirection="column" gap={spacing[8]}>
                  {filteredFinancingCtas?.map(cta => {
                    return (
                      <CtaHandler
                        cta={cta}
                        product={product}
                        drawerName={parentName}
                        modalLayout="financing"
                        isActive={isOpen}
                        key={cta.fields.name}
                      />
                    );
                  })}
                </Flex>
              </>
            )}
          </Container>
        </ErrorBoundary>
        {/* ATC CTAs  */}
        <Container data-test-id="baseItemDrawer">
          {ctas?.map(cta => {
            return (
              <CtaHandler
                cta={cta}
                product={product}
                drawerName={parentName}
                isActive={isOpen}
                key={cta.fields.name}
              />
            );
          })}
        </Container>
      </Flex>
    </Container>
  );
};

const StyledSupport = styled(Support)<{ hasFinancingCtas: boolean }>`
  padding-bottom: ${props => props.hasFinancingCtas && spacing[8]};
`;

export default BaseItem;
