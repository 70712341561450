import type { IconNames } from '@pelotoncycle/design-system';
import {
  Flex,
  spacing,
  Icon,
  Media,
  grey,
  Label,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { Unpacked } from '@peloton/types';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import type {
  MediaReferenceType as MediaReference,
  ImageReferenceType as ImageReference,
} from '../../../../freeform/types/referenceTypes';
import type { CompareProps } from './CompareModule';

export type ValuePropsType = Unpacked<CompareProps['products']>['valueProps'];
export type ValuePropType = Unpacked<ValuePropsType>;

const ValueProps: React.FC<{
  valuePropSet: ValuePropsType;
}> = ({ valuePropSet }) => {
  const { textColor, headlineColor } = useTheme();

  if (!valuePropSet) {
    return null;
  }

  const setMediaProps = (media?: MediaReference, image?: ImageReference) => {
    if (media || image) {
      // Use the media entry over the image entry if media exists.
      return media
        ? {
            ...media,
            autoplay: true,
            progressTracked: false,
            playPauseButtonPositon: 'right',
          }
        : image;
    } else {
      // If a media nor an image entry is defined, then return undefined.
      return undefined;
    }
  };

  const valueProps = valuePropSet.map((valueProp, index) => {
    const { headline, media, image, icon, description } = valueProp;
    const mediaProps = setMediaProps(media, image);

    return (
      <ValueProp
        data-test-id="value-prop"
        key={index}
        flexDirection="column"
        gap={spacing[24]}
        justifyContent="start"
        alignContent="center"
        width="100%"
        maxWidth="400px"
        index={index}
      >
        {index !== 0 && <Divider />}
        <Flex justifyContent="center">
          {icon && (
            <Icon
              name={valueProp?.icon as IconNames}
              height={40}
              primaryColor={headlineColor}
            />
          )}
          {mediaProps && (
            <ValuePropImageContainer maxHeight="135px" borderRadius={spacing[12]}>
              <Media media={mediaProps} />
            </ValuePropImageContainer>
          )}
        </Flex>
        <Flex flexDirection="column" gap={spacing[8]} maxWidth="400px">
          <Label size="large" textColor={headlineColor} weight="medium">
            {headline}
          </Label>
          <Label size="large" textColor={textColor} weight="regular">
            {description}
          </Label>
        </Flex>
      </ValueProp>
    );
  });

  return valueProps;
};

export default ValueProps;

const Divider = styled.hr`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${grey[60]};
`;

const ValuePropImageContainer = styled(Flex)`
  aspect-ratio: 19 / 15;
  overflow: hidden;

  // Styling position of pause play button in videos
  div[class*='Video'][class*='IconContainer'] {
    bottom: 8px;
    right: 8px;
    opacity: 0.9;
  }

  // Styling for pause play button in videos
  svg {
    height: ${spacing[24]};
    fill: ${white};

    rect,
    path {
      fill: ${grey[90]};
    }
  }
`;

const ValueProp = styled(Flex)<{ index: number }>`
  // starts at index + 2 to account for the ProductCard's first row (img, rating, etc.)
  grid-row: ${({ index }) => `${index + 2} / ${index + 3}`};
  justify-self: center;
  width: 100%;
`;
