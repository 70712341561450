import { green, Flex, Icon, spacing, Label } from '@pelotoncycle/design-system';
import React from 'react';
import { getPersistedId, getPersistedSegment } from '@peloton/concessionary-pricing';
import { useMicroCopy } from '@content/client/microCopy';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

const VerifiedWithIdMeCheck: React.FC = () => {
  const isSpecialPricingPhaseTwoEnabled = useIsToggleActive()('special_pricing_phase_2');
  const verifiedWithIdMeLabel = useMicroCopy('verifiedWithIdMeLabel');
  const storedVerificationId = getPersistedId();
  const storedApplicableSpecialPricingSegment =
    getPersistedSegment() !== 'students' ? getPersistedSegment() : undefined;

  const shouldRenderVerifiedWithIdMeCheck =
    isSpecialPricingPhaseTwoEnabled &&
    storedVerificationId &&
    storedApplicableSpecialPricingSegment;

  if (!shouldRenderVerifiedWithIdMeCheck) {
    return null;
  }

  return (
    <Flex gap={spacing[4]} alignItems="center">
      <Icon height={16} primaryColor={green[50]} name="checkCircle" />
      <Label size="small" data-test-id="verifiedWithIdMeLabel" weight="medium">
        {verifiedWithIdMeLabel}
      </Label>
    </Flex>
  );
};

export default VerifiedWithIdMeCheck;
