import React from 'react';
import { useLocale } from '@peloton/next/hooks/useLocale';
import type { ProductGridProps } from '@ecomm/product-recommendations/models/ProductGridProps';
import { toCohortFromProductGrid } from '@ecomm/product-recommendations/utils/toCohortFromProductGrid';
import ProductRecommendationsGridV2 from '@ecomm/product-recommendations/Views/ProductRecommendationsGridV2';
import type { JSONComponentProps } from '../../../types/utilityTypes';

/**
 * The Product Grid module is used to display a grid of product cards with data sourced from CommerceTools.
 *
 * __Note:__ This documentation will refer to the options by the names they have in the code and the storybook examples. The actual field names in PBX will be the camel cased versions. (For example, `headline` in the code is `Headline` in PBX.)
 *
 * Though the module has many options, the only required ones are a `treatment` and at least one product within `products`. The `treatment` determines much of the layout and functionality of the module, and the `products` determine the actual cards displayed.
 *
 * ### Top Level Module Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __headline__  | Top level headline that is displayed above the product cards    |
 * | __subHeadline__ | Smaller headline copy that is displayed below the headline     |
 * | __eyebrow__    | Eyebrow that is displayed above the headline    |
 * | __treatment*__    | Treatment that determines module layout and features    |
 * | __theme__    | Sets the colors throughout the module; currently supports `White` and `Grey 90` |
 * | __products__    | List of products to display in the grid, fields documented below |
 * | __marketingTiles__    | List of non-product marketing tiles to display in the grid, fields documented below |
 * | __filterGroups__    | List of groups of filters for the module, currently only used to power the toggle on the `Toggled Deals Page Product Grid` treatment |
 *
 *
 * The following treatments are supported:
 *   - `Product Recommendations Upsell Grid`
 *   - `Deals Page Product Grid`
 *   - `Package Upsell In Overview`
 *   - `PLP Product Grid`
 *   - `Carousel Product Grid`
 *   - `Toggled Deals Page Product Grid`
 *   - `Nested Hero Product Grid`
 *
 *
 * ### Product Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __slug*__  | Localized slugs for this product; Since product slugs always vary by locale, we've added this localized option to avoid having to localize the entire module just to set different product slugs |
 * | __optionalFields.image__    | Image reference that will override the image set in CommerceTools. If omitted, the image from CommerceTools will be displayed instead |
 * | __optionalFields.shortDescription__    | Short description that will be displayed on the tile below the product name and price |
 * | __optionalFields.descriptionEyebrow__    | _Deprecated_ Eyebrow shown above the description in the quick view modal |
 * | __optionalFields.priceOverride__    | Will be displayed in place of the price from CommerceTools if set; Will override price configured in Promo Tool 2.0 if set; Supports formatting options documented below |
 * | __optionalFields.nameOverride__    | Will be displayed in place of the product name from CommerceTools if set. |
 * | __optionalFields.financingText__    | Will be displayed below the short description on the card if set; will override financing text configured in Promo Tool 2.0 if set |
 * | __optionalFields.pillText__    | Will be displayed in a badge on the card if set; will __not__ override Promo Tool 2.0 pill and instead will be shown next to it |
 * | __optionalFields.pillText__    | Sets the theme of the pill set using the pillText option; all design system badge themes are supported; defaults to `accent` if not set |
 * | __optionalFields.tags__    | A list of tags for the product, currently used for filtering |
 * | __optionalFields.packageContents__ | A list of items that are included in the package; when set, will display a "What's included" button on the cards; fields are documented below |
 * | __optionalFields.packageContentsDescription__ | Will be displayed in the package contents modal if set |
 *
 *
 * ### Package Contents Entry Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __image*__  | Image reference for the product within the package    |
 * | __description*__ | Description of the product in the package; supports Markdown formatting |
 *
 *
 * ### Marketing Tile Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __name*__  | Name of the tile; used for analytics    |
 * | __title*__ | Title displayed below image on tile; supports Markdown formatting     |
 * | __body__    | Body copy displayed below title when set; supports Markdown formatting  |
 * | __image*__    | Image reference that will be displayed on the tile |
 * | __link*__    | Link reference to navigate to when the tile is clicked; supports both internal and external links |
 *
 *
 * ### Filter Groups Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __title*__  | Title of the filter group; usage depends on style of filters set in the treatment, documented below    |
 * | __filters*__ | List of filters within the group; fields documented below     |
 *
 *
 * ### Filter Fields
 *
 * | Field    | Notes |
 * | -------- | ------- |
 * | __tag*__  | Tag to filter by when this filter is selected; must match tags on a product    |
 * | __displayName__    | Display name for the filter; if omitted, the tag will be displayed instead |
 * | __selectedByDefault__    | If set to true, this filter will be selected by default when the module first loads |
 *
 * Fields marked with an asterisk (*) are required.
 *
 * ### Filter functionality
 *
 * The [Toggled Deals Page Product Grid](/story/freeform-champ-ui-productgrid--toggled-deals-product-grid) treatment supports toggling between two subsets of the products contained in the module. This is done through the use of the `filterGroups` field, along with tags on each product.
 *
 * The first filter group in the list is used, and any others are ignored. Similarly, the first two filters in the first filter group are used as the two sides of the toggle, and any others are ignored. The first filter is set to be selecte dby default, so products that don't have that tag are filtered out. Switching the toggle changes which filter is selected, and thus the products displayed.
 *
 * A properly configured set of filter groups might look like the following:
 *
 * ```
 *   filterGroups: [
 *   {
 *     title: 'Filter by product',
 *     filters: [
 *       {
 *         tag: 'Bike',
 *         selectedByDefault: true,
 *       },
 *       {
 *         tag: 'Bike+',
 *       },
 *     ],
 *   },
 * ]
 * ```
 *
 * This would display as a toggle between Bike and Bike+, with Bike selected by default. Products in the grid would need to have either the `Bike` or `Bike+` tag to be displayed.
 *
 * In the future, we may add support for different styles of filters, such as the sidebar filters on the Spare Parts page.
 */
const ProductGrid: React.FC<JSONComponentProps<ProductGridProps>> = ({
  name,
  ...props
}) => {
  const locale = useLocale();
  const cohort = toCohortFromProductGrid(props, name, locale);

  return (
    <ProductRecommendationsGridV2
      cohort={cohort}
      themeName={props.theme}
      filterGroups={props.filterGroups}
    />
  );
};

export default ProductGrid;
