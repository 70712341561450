import { Label, red } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import toBundleType from '@ecomm/graphql-bridge/EquipmentType/toBundleType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { toDollars } from '@ecomm/models';
import useConcessionaryPricing from '@ecomm/pg-concessionary-pricing/useConcessionaryPricing';
import { isRentalSlug } from '@ecomm/rentals/models';
import { PromoPrice } from '@page-builder/modules/Promo';
import Markdown from '@page-builder/utils/Markdown';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';

type Props = {
  packageSlug: string;
  pageBuilderPrice?: string;
};

/*
  PriceWithPromo renders the price of a package in two different ways:
  - if there is price copy set on the drawer in Page Builder, it will be rendered as markdown, with the price of the package
    available as a variable (if there is build time product data for the package)
  - if there is *no* price copy set on the drawer, and there is build time product data for the package, it renders as
    PromoPrice component. This component will look at the active promo and display a strikethrough price if there is
    one configured for the package, otherwise just the base price.
  - if there is *no* price copy, and *no* build time product data, nothing is rendered. This should never happen in practice
    as all packages except rentals will be available in build time product data, and we will ensure that rental drawers
    have prices set in Page Builder.
 */
const PriceWithPromo: React.FC<React.PropsWithChildren<Props>> = ({
  packageSlug,
  pageBuilderPrice,
}) => {
  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);
  const { errorReporter } = useErrorReporter();
  const { reportError } = errorReporter;
  const { isConcessionaryPricingEnabled } = useConcessionaryPricing(true);

  const concessionaryPricingEligible =
    isConcessionaryPricingEnabled && !isRentalSlug(packageSlug);

  useEffect(() => {
    if (!pageBuilderPrice && !buildTimeProductData) {
      reportError(
        new Error(
          `PDP Error: No price configured in page builder for ${packageSlug} and no build time data available`,
        ),
      );
    }
  }, [buildTimeProductData, reportError, packageSlug, pageBuilderPrice]);

  if (pageBuilderPrice && !concessionaryPricingEligible) {
    const productPackagePriceCents = buildTimeProductData?.basePrice;
    /*
      productPackagePrice is used as another variable when rendering the price copy as markdown.
      This is needed because the built-in price variables only include the "basics" packages (the package with just
      the CFU), but we need the price available for all types of packages.
     */
    const productPackagePrice = productPackagePriceCents
      ? toDollars(productPackagePriceCents)
      : undefined;

    return (
      <Markdown
        content={pageBuilderPrice}
        values={{
          productPackagePrice,
        }}
        markdown={{
          renderers: {
            emphasis: ({ node, ...props }) => (
              <Label size="medium" style={{ color: red[50] }}>
                {props.children}
              </Label>
            ),
            strong: ({ node, ...props }) => (
              <span style={{ color: red[50] }}>{props.children}</span>
            ),
          },
        }}
      />
    );
  }

  if (buildTimeProductData) {
    const { equipmentType, slug, basePrice } = buildTimeProductData;
    const bundleType = toBundleType(equipmentType as EquipmentType)!;

    return (
      <PromoPrice
        cfu={bundleType}
        bundleSlug={slug}
        price={basePrice}
        specialPricingOptions={{ ignorePromo: false, requireVerification: true }}
      />
    );
  }

  return null;
};

export default PriceWithPromo;
