import { Support, Flex, spacing } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import AddToCartCta from '@page-builder/modules/Overview/ShopDrawers/AddToCartCta';
import { toCtaTrackingProperties } from '@page-builder/modules/Overview/utils';
import { themes } from '@page-builder/themes';
import Markdown from '@page-builder/utils/Markdown';
import type { Theme } from '../../../../freeform/types/referenceTypes';
import type { HeadbandCTAProps } from './CTA';
import { CTA } from './CTA';

export const HeadbandCtaWithInfo: React.FC<{
  ctas?: HeadbandCTAProps[];
  theme?: Theme;
  financingText?: string;
  disclaimerText?: string;
  parentTitle?: string;
  addToCartCta?: TypeComponentCta;
}> = ({
  parentTitle,
  ctas,
  financingText,
  disclaimerText,
  theme = 'Grey 90',
  addToCartCta,
}) => {
  const { trackEvent } = useTracking();
  const { headlineColor } = themes[theme];

  const trackLinkClicked = () => {
    if (addToCartCta) {
      trackEvent({
        event: TrackingEvent.ClickedSubnavATC,
        properties: toCtaTrackingProperties(addToCartCta.fields, 'Headband'),
      });
    }
  };

  return (
    <StyledFlex justifyContent="flex-end" alignItems="center">
      {(financingText || disclaimerText) && (
        <Flex
          flexDirection="column"
          minWidth="196px"
          maxWidth="262px"
          horizontalPadding={`0 ${spacing[16]}`}
          textAlign="right"
          alignItems="flex-end"
          display={{ mobile: 'none', desktop: 'flex' }}
          flexShrink={1}
        >
          {financingText && (
            <Support is="p" size="medium" textColor={headlineColor}>
              <Markdown content={financingText} />
            </Support>
          )}
          {disclaimerText && (
            <Support is="p" size="small" textColor={headlineColor}>
              <Markdown content={disclaimerText} />
            </Support>
          )}
        </Flex>
      )}
      <Flex gap={spacing[16]} flexShrink={0} alignItems="center">
        {ctas?.map(cta => (
          <CTA key={cta.text} text={cta.text} link={cta.link} parentTitle={parentTitle} />
        ))}
        {addToCartCta && (
          <Flex flexShrink={0} minWidth="134px">
            <AddToCartCta
              cta={addToCartCta.fields}
              size="small"
              onClick={trackLinkClicked}
            />
          </Flex>
        )}
      </Flex>
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)`
  flex-shrink: 0;
  flex-grow: 0;
`;
