import {
  Container,
  Flex,
  Eyebrow,
  white,
  Icon,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';
import type { LibraryVideoCardProps as LibraryVideoCard } from './types';

type MovementCardProps = {
  videoCard: LibraryVideoCard;
  videoRef: (el: HTMLVideoElement | null) => void;
  onMouseEnter: () => void;
  onClick: () => void;
  handleVideoPause: (index: number) => void;
  shouldAutoPlay: boolean;
  index: number;
  videoIndexActive: { index: number; showPause: boolean };
  hasModalOpened: boolean;
  isOnCDP?: boolean;
};

export const MovementCard = ({
  videoCard,
  videoRef,
  onMouseEnter,
  onClick,
  handleVideoPause,
  shouldAutoPlay,
  index,
  videoIndexActive,
  hasModalOpened,
  isOnCDP = false,
}: MovementCardProps) => {
  const isMobile = useMedia(`(max-width: ${BreakpointWidth.tablet}px)`);
  const isDesktop = useMedia(`(min-width: ${BreakpointWidth.desktopLarge}px)`);

  const showIconContainer =
    (isDesktop && videoIndexActive.index === index) ||
    (index === 0 && !isMobile && !isDesktop);

  return (
    <Container width={'fit-content'} position="relative" data-test-id={`movement-card`}>
      <StyledVideo
        ref={videoRef}
        src={videoCard?.video?.src}
        poster={videoCard?.video?.poster}
        autoPlay={shouldAutoPlay}
        loop={true}
        muted={true}
        playsInline={true}
        preload="metadata"
        onMouseEnter={!videoIndexActive.showPause ? undefined : onMouseEnter}
        tabIndex={0}
        onClick={onClick}
        data-test-id={`movement-card-video`}
        isOnCDP={isOnCDP}
      />
      <Flex
        position="absolute"
        style={{
          bottom: isOnCDP ? spacing[8] : spacing[12],
          left: isOnCDP ? spacing[8] : spacing[12],
          zIndex: 2,
        }}
      >
        <StyledEyebrow
          size={isOnCDP ? 'extraSmall' : 'small'}
          textColor={white}
          isOnCDP={isOnCDP}
        >
          {videoCard?.movementName}
        </StyledEyebrow>
      </Flex>
      {showIconContainer && !hasModalOpened && (
        <IconContainer
          onClick={() => {
            videoIndexActive.showPause ? handleVideoPause(index) : onMouseEnter();
          }}
          data-test-id={`movement-card-icon`}
          isOnCDP={isOnCDP}
        >
          <Icon
            name={videoIndexActive.showPause ? 'pauseOutline' : 'playOutline'}
            primaryColor="white"
            height={isOnCDP ? 16 : 24}
          />
        </IconContainer>
      )}
      <StyledOverlay />
    </Container>
  );
};

const StyledOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 1;
  pointer-events: none;
`;

const StyledVideo = styled.video<{ isOnCDP?: boolean }>`
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 287/398;
  min-width: ${({ isOnCDP }) => (isOnCDP ? '135px' : '145px')};
  min-height: 201px;
  max-height: 398px;
  cursor: pointer;
  z-index: 0;
  border-radius: ${spacing[4]};
`;

const IconContainer = styled(Flex)<{ isOnCDP?: boolean }>`
  position: absolute;
  bottom: ${spacing[0]};
  right: ${spacing[0]};
  cursor: pointer;
  padding: ${spacing[8]};
  z-index: 2;

  ${media.desktopLarge<{ isOnCDP?: boolean }>`
    padding: ${({ isOnCDP }) => (isOnCDP ? spacing[8] : spacing[16])};
  `}
`;

const StyledEyebrow = styled(Eyebrow)<{ isOnCDP?: boolean }>`
  max-width: 110px;
  ${media.tablet`
    max-width: 140px;
  `}
  line-height: ${({ isOnCDP }) => isOnCDP && spacing[16]};
  ${media.desktopLarge`
    max-width: ${isOnCDP => (isOnCDP ? '270px' : '160px')};
  `}
`;
