import { spacing } from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import FullWidthMediaModuleContainer from '@page-builder/modules/FullWidthMediaModule';
import useCollapsiblePanel from '@page-builder/modules/PosterWithContentPanel/useCollapsiblePanel';
import { themes } from '@page-builder/themes';
import { nameToModuleId } from '@page-builder/utils/helpers';
import PanelContent from './PanelContent';
import ToggleCta from './ToggleCta';

const PosterWithContentPanel: React.FC<TypeComponentGenericTextWithMediaFields> = ({
  ctas,
  ctaExpanded,
  ...genericTextWithoutCtas
}) => {
  const { isExpanded, toggleExpanded, outerContentRef } = useCollapsiblePanel(
    ctaExpanded,
  );
  const panelIsExpanded = ctaExpanded || isExpanded;

  const { name, text, media, theme = 'Grey 90' } = genericTextWithoutCtas;

  const { Track } = useTracking({
    properties: {
      parentName: name,
    },
  });

  if (!text || !media || !ctas?.[0].fields.modal) {
    return null;
  }

  const [
    {
      fields: { modal: panelContent },
    },
  ] = ctas;

  const moduleId = nameToModuleId(name);

  const { backgroundColor } = themes[theme];

  return (
    <Track>
      <OuterContainer
        theme={theme}
        backgroundColor={backgroundColor}
        horizontalPadding="0"
        flexDirection="column"
        maxWidth="100%"
        verticalPadding={{
          mobile: '0',
          desktop: spacing[80],
        }}
      >
        <div ref={outerContentRef} data-test-id="outer-content">
          <FullWidthMediaModuleContainer
            {...genericTextWithoutCtas}
            verticalPadding={{
              mobile: '0',
              desktop: panelIsExpanded ? `0 ${spacing[80]}` : '0',
            }}
          >
            <ToggleCta
              moduleId={moduleId}
              cta={ctas![0]}
              isExpanded={panelIsExpanded}
              toggleExpanded={() => toggleExpanded(true)}
              preventDisabled={true}
            />
          </FullWidthMediaModuleContainer>
        </div>
        <div data-test-id="panel-content">
          <PanelContent
            moduleId={moduleId}
            panelContent={panelContent!}
            isExpanded={panelIsExpanded}
            toggleExpanded={toggleExpanded}
            ctaExpanded={ctaExpanded}
          />
        </div>
      </OuterContainer>
    </Track>
  );
};

export default PosterWithContentPanel;
