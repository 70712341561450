import { useRouter } from 'next/router';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { useTracking } from 'react-tracking';
import logAction from '@ecomm/logging/logAction';
import type { TypeQuiz } from '@page-builder/lib/types';
import type { Progress } from '../models/progress';
import getWinningSlug from '../utils/getWinningSlug';

type Props = {
  progress: Progress;
  quiz: TypeQuiz;
};

const MINIMUM_LOADING_TIME = 3000;

const QuizRedirect: React.FC<React.PropsWithChildren<Props>> = ({ progress, quiz }) => {
  const { trackEvent } = useTracking();
  const isRedirecting = useRef(false);
  const router = useRouter();

  const redirect = async (url: string) => {
    const pathName = router.asPath;
    const prefix = pathName.includes('/sandbox/') ? '/sandbox' : '';
    await router.replace(`${prefix}${url}`);
  };

  const trackResultsViewed = () => {
    const inputs = Object.values(progress).reduce((acc, value, index) => {
      acc[`input${index + 1}`] = value.join(',');
      return acc;
    }, {} as Record<string, string>);
    trackEvent({
      event: 'Quiz Results Viewed',
      properties: {
        quiz: quiz.fields.quizName,
        ...inputs,
      },
    });
  };

  useEffect(() => {
    if (router.isReady && progress && !isRedirecting.current) {
      const winningSlug = getWinningSlug(progress, quiz);

      if (!winningSlug) {
        if (Object.keys(progress).length === quiz.fields.questions.length) {
          logAction(`${quiz.fields.quizName} error, no redirect`, {
            quiz: quiz.fields.quizName,
            progress,
          });
        }
        return;
      }

      isRedirecting.current = true;

      setTimeout(async () => {
        await redirect(winningSlug);
        trackResultsViewed();
      }, MINIMUM_LOADING_TIME);
    }
  }, [progress, quiz, router.isReady, trackResultsViewed]);

  return null;
};

export default QuizRedirect;
