import { Flex, Label, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';
import type { ProductRecommendationsMarketingTile } from '@content/client/www/product-recommendations/models';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type { PageBuilderTheme } from '@ecomm/product-recommendations/utils/theme';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import useMarketingTileUrl from '@ecomm/product-recommendations/utils/useMarketingTileUrl';
import ContentPills from '@ecomm/product-recommendations/Views/ContentPills';
import { FormattedPrice } from '@ecomm/product-recommendations/Views/PriceDisplay';
import { imageFieldsToMediaImageProps } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { ImageContainer, TileMedia, TileLink, TileWrapper } from './SharedTileComponents';

type Props = {
  marketingTile: ProductRecommendationsMarketingTile;
  themeName?: PageBuilderTheme;
};

const MarketingTile: React.FC<React.PropsWithChildren<Props>> = ({
  marketingTile,
  themeName,
}) => {
  const { trackLinkClick } = useTrackLinkClick();
  const { name, title, body, image, priceDisplay, pills = [] } = marketingTile;
  const { imageBackground } = toGridTheme(themeName);

  const tileLinkUrl = useMarketingTileUrl(marketingTile);

  const onLinkClick = () => {
    trackLinkClick({ href: tileLinkUrl, parent: name });
  };

  return (
    <TileWrapper>
      <TileLink href={tileLinkUrl} onClick={onLinkClick}>
        <Markdown content={title} />
      </TileLink>
      <Flex flexDirection="column" gap={spacing[16]}>
        <ImageContainer
          background={imageBackground}
          borderRadius={spacing[4]}
          position="relative"
        >
          <TileMedia
            width="100%"
            height="auto"
            objectFit="contain"
            media={imageFieldsToMediaImageProps(image)}
          />
        </ImageContainer>
        {pills.length > 0 && <ContentPills pills={pills} />}
        <Flex flexDirection="column" gap={spacing[8]}>
          <Label size="large">
            <Markdown content={title} />
          </Label>
          {priceDisplay && (
            <Support size="large" data-test-id="grid-product-tile-price">
              <FormattedPrice content={priceDisplay} />
            </Support>
          )}
          {body && (
            <Support size="small">
              <Markdown content={body} />
            </Support>
          )}
        </Flex>
      </Flex>
    </TileWrapper>
  );
};

export default MarketingTile;
