import type { Class } from '../../../../models/PelotonClass';
import { ProspectsDiscipline } from '../../../../models/PelotonClass';
import type { Locale } from '../../../models/locale';
import type { LocaleLanguagePrefix } from '../../models';

const DISCIPLINE_LABELS: Record<
  ProspectsDiscipline,
  Record<LocaleLanguagePrefix, string>
> = {
  [ProspectsDiscipline.STRENGTH]: { en: 'Strength', de: 'Kraft' },
  [ProspectsDiscipline.CYCLING]: { en: 'Cycling', de: 'Cycling' },
  [ProspectsDiscipline.RUNNING]: { en: 'Running', de: 'Laufen' },
  [ProspectsDiscipline.ROWING]: { en: 'Rowing', de: 'Rudern' },
  [ProspectsDiscipline.YOGA]: { en: 'Yoga', de: 'Yoga' },
  [ProspectsDiscipline.MEDITATION]: { en: 'Meditation', de: 'Meditation' },
  [ProspectsDiscipline.STRETCHING]: { en: 'Stretching', de: 'Stretching' },
  [ProspectsDiscipline.WALKING]: { en: 'Walking', de: 'Walking' },
  [ProspectsDiscipline.OUTDOOR]: { en: 'Outdoor', de: 'Outdoor' },
  [ProspectsDiscipline.CARDIO]: { en: 'Cardio', de: 'Cardio' },
};

export const mapDisciplineToDisplayName = (
  discipline: ProspectsDiscipline,
  locale: Locale,
): Class['fitnessDisciplineDisplayName'] => {
  const languageLocale = locale.split('-')[0] as LocaleLanguagePrefix;
  return DISCIPLINE_LABELS[discipline]?.[languageLocale];
};
