import { useRouter } from 'next/router';
import React from 'react';
import { Locale, useLocale } from '@peloton/internationalize';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';
import type { TypeQuiz } from '@page-builder/lib/types';
import type { AlgorithmInstructor } from '@page-builder/modules/MWYIQuiz/models/AlgorithmInstructor';
import { toResultsPageUrl } from '@page-builder/modules/MWYIQuiz/utils/instructors';
import { getIntructorMatches } from '@page-builder/modules/MWYIQuiz/utils/matchingAlgorithm';
import {
  getQueryParams,
  useFormProgress,
} from '@page-builder/modules/Quiz/utils/progress';
import { writeProgressToSession } from '@page-builder/modules/Quiz/utils/session';

type Props = {
  algorithmInstructors: AlgorithmInstructor[];
  quiz: TypeQuiz;
};

const MINIMUM_LOADING_TIME = 3000;

const MatchingInstructorRedirect: React.FC<React.PropsWithChildren<Props>> = ({
  algorithmInstructors,
  quiz,
}) => {
  const router = useRouter();
  const { progress, setProgress, quizSessionKey } = useFormProgress();
  const locale = useLocale();

  React.useEffect(() => {
    // next does not hydrate query on first load
    if (router.isReady) {
      // on load, slug field in query isn't populated
      const slugs = router.pathname.split('/').slice(1, 2);
      const queryParams = getQueryParams(quiz.fields.questions, {
        ...router.query,
        slugs: slugs,
      });
      const hasQueryParams = Object.keys(queryParams).length !== 0;

      // prevents loop from setting progress
      if (JSON.stringify(queryParams) !== JSON.stringify(progress)) {
        if (hasQueryParams) {
          setProgress(prevProgress => ({ ...prevProgress, ...queryParams }));
          writeProgressToSession(queryParams, quizSessionKey);
        }
      }

      const { matchedInstructors } = getIntructorMatches(
        hasQueryParams ? queryParams : progress,
        quiz,
        algorithmInstructors,
      );

      if (matchedInstructors.length > 0) {
        const [{ instructor: topMatchingInstructor }] = matchedInstructors;

        setTimeout(() => {
          // Replace instead of assign (push) so that the results loading page is not in the browser's history
          const isSubpathLocalePresent =
            LOCALE_TOGGLE &&
            Object.values(Locale).find(subpath => window.location.href.includes(subpath));
          const url = isSubpathLocalePresent
            ? `/${locale}${toResultsPageUrl(topMatchingInstructor)}`
            : toResultsPageUrl(topMatchingInstructor);

          window.location.replace(url);
        }, MINIMUM_LOADING_TIME);
      }
    }
  }, [algorithmInstructors, progress, quiz, quizSessionKey, router, setProgress]);

  return null;
};

export default MatchingInstructorRedirect;
