import { Flex, spacing, grey, white } from '@pelotoncycle/design-system';
import type { TypeComponentLeadGen } from '@pelotoncycle/page-builder';
import { LeadGen } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { designPaddings } from '../utils/layout';
import { useTheme } from '../utils/theme';

type QuizLeadGenProps = {
  leadGen: TypeComponentLeadGen;
  onLeadGenSuccess?: () => void;
};

const QuizLeadGen: React.FC<QuizLeadGenProps> = ({ leadGen, onLeadGenSuccess }) => {
  const { questionBackground } = useTheme();

  return (
    <Content background={questionBackground} flexDirection="column">
      <StyledLeadGen
        className="devFreeLeadGen"
        onSuccess={onLeadGenSuccess}
        {...leadGen}
      />
    </Content>
  );
};

export default QuizLeadGen;

const Content = styled(Flex)<{ background: string }>`
  background: ${props => props.background};
  flex: 1;
  align-items: center;
  text-align: center;
  padding-top: ${spacing[40]};
  padding-bottom: ${spacing[40]};
  min-height: 100vh;

  ${media.desktopLarge`
    padding-top: ${spacing[80]};
    padding-bottom: ${spacing[80]};
  `}

  ${designPaddings}
`;

const StyledLeadGen = styled(LeadGen)`
  background-color: unset;
  p {
    color: ${white};
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
  button {
    background-color: black !important;
    border-color: black !important;

    &:hover {
      background-color: ${grey[70]} !important;
      border-color: ${grey[70]} !important;
      color: ${white} !important;
    }
  }
`;
