import {
  Container,
  Flex,
  Layout,
  LinkButton,
  Media,
  spacing,
} from '@pelotoncycle/design-system';
import {
  UpperTextBlock,
  LowerTextBlock,
  OuterContainer,
} from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import useScrollOpacity from '@page-builder/hooks/useScrollOpacity';
import { themes } from '@page-builder/themes';
import { nameToModuleId } from '@page-builder/utils/helpers';
import type {
  Theme as ThemeReference,
  MediaReferenceType as MediaReference,
} from '../../../types/referenceTypes';
import type { JSONComponentProps } from '../../../types/utilityTypes';
import type { FullWidthMediaCta as FullWidthMediaCtaReference } from './types';

export type FullWidthMediaProps = {
  ctas?: FullWidthMediaCtaReference[];
  shouldScroll?: boolean;
  theme?: ThemeReference;
  lightboxMedia: MediaReference;
  lightboxHeight?: string;
  topMedia?: MediaReference;
  eyebrowText?: string;
  headlineText?: string;
  bodyText?: string;
  supportText?: string;
  labelText?: string;
};

export const defaults = {
  theme: 'Grey 90',
  shouldScroll: true,
  MAX_OPACITY: 0.5,
} as const;

const LOGO_DESKTOP_WIDTH = 116;
const LOGO_MOBILE_WIDTH = 80;

/**
 * FullWidthMedia is a migration of a Page Builder component that displays a full-width media element with text and CTAs.
 *
 * It is composed of two Media components, an UpperTextBlock component, a LowerTextBlock component, and a LinkButtons component.
 *
 * The lightboxMedia component is a full-width image or video.
 *
 * The topMedia component is an optional image that appears above the UpperTextBlock.
 *
 * When the `shouldScroll` prop is set to `true`, the Media component will be sticky and will scroll with the content.
 *
 */

const FullWidthMedia: React.FC<JSONComponentProps<FullWidthMediaProps>> = props => {
  const {
    name,
    ctas,
    theme = defaults.theme,
    shouldScroll = defaults.shouldScroll,
    lightboxMedia,
    lightboxHeight,
    topMedia,
    eyebrowText,
    headlineText,
    bodyText,
    supportText,
    labelText,
  } = props;
  const { trackEvent } = useTracking();
  const { backgroundColor, headlineColor, buttonColor } = themes[theme];
  const { opacity } = useScrollOpacity(defaults.MAX_OPACITY);
  const ctasWithTheme = ctas?.map(button => {
    return {
      ...button,
      color: button.color || buttonColor,
    };
  });
  const handleOnClick = (text: string, url: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: JSON',
        parent: 'FullWidthMedia (CHAMP)',
        unitName: 'FullWidthMedia CTA',
        linkName: text,
        linkTo: url,
      },
    });
  };

  return (
    <Container
      id={nameToModuleId(name)}
      position={shouldScroll ? undefined : 'relative'}
      backgroundColor={backgroundColor}
    >
      <MediaContainer shouldScroll={shouldScroll} minHeight={lightboxHeight}>
        <Media media={lightboxMedia} />
        <StyledOverlay opacity={shouldScroll ? opacity : defaults.MAX_OPACITY} />
      </MediaContainer>
      <Layout
        horizontalAlignment="center"
        horizontalPadding={spacing[16]}
        minHeight={lightboxHeight || (shouldScroll ? '100vh' : '80vh')}
        verticalAlignment="center"
      >
        <TextContainer horizontalPadding={0}>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            removeOuterMargins
          >
            {topMedia && (
              <Container
                textAlign="center"
                margin={{
                  mobile: `0 auto ${spacing[12]}`,
                  desktop: `0 auto ${spacing[16]}`,
                }}
              >
                <StyledTopMedia media={topMedia} />
              </Container>
            )}
            <>
              <UpperTextBlock
                eyebrowText={eyebrowText}
                headlineText={headlineText}
                bodyText={bodyText}
                textColor={headlineColor}
                margin={{
                  mobile: `0 auto ${spacing[24]}`,
                  desktop: `0 auto ${spacing[32]}`,
                }}
                hasDropShadow
              />
              {ctasWithTheme && (
                <Flex
                  flexDirection={{ mobile: 'column', tablet: 'row' }}
                  justifyContent="center"
                  gap={spacing[16]}
                >
                  {ctasWithTheme.map(cta => (
                    <LinkButton
                      key={cta.text}
                      href={cta.link.url}
                      color={cta.color}
                      variant={cta.variant}
                      size="medium"
                      onClick={() => handleOnClick(cta.text, cta.link.url)}
                    >
                      {cta.text}
                    </LinkButton>
                  ))}
                </Flex>
              )}
              {(supportText || labelText) && (
                <LowerTextBlock
                  supportText={supportText}
                  labelText={labelText}
                  textColor={headlineColor}
                  margin={{
                    mobile: `${ctas ? spacing[32] : 0} 0 0`,
                    desktop: `${ctas ? spacing[40] : 0} 0 0`,
                  }}
                  hasDropShadow
                />
              )}
            </>
          </Flex>
        </TextContainer>
      </Layout>
    </Container>
  );
};

export default FullWidthMedia;

const scrollableStyles = css`
  position: sticky;
  top: 0;
  height: 100vh;
`;

const nonScrollableStyles = css`
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const MediaContainer = styled(Container)<{ shouldScroll: boolean }>`
  ${({ shouldScroll }) => (shouldScroll ? scrollableStyles : nonScrollableStyles)};
`;

const StyledOverlay = styled.div<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ opacity }) => `background: rgba(0, 0, 0, ${opacity});`};
`;

const TextContainer = styled(OuterContainer)`
  position: relative;
  z-index: 1;
`;

const StyledTopMedia = styled(Media)`
  width: ${LOGO_MOBILE_WIDTH}px;
  height: auto;

  ${media.desktopLarge`
      width: ${LOGO_DESKTOP_WIDTH}px;
    `}
`;
