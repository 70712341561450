import { Display, spacing, grey, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import toBundleType from '@ecomm/graphql-bridge/EquipmentType/toBundleType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { isRentalSlug } from '@ecomm/rentals/models';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import { PromoPills } from '@page-builder/modules/Promo';
import Markdown from '@page-builder/utils/Markdown';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';

export type Props = {
  ribbon?: string;
  title: string;
};

const Header: React.FC<React.PropsWithChildren<Props>> = ({ ribbon, title }) => {
  const { packageSlug, productPackage, productPackageLoading } = useShopContext();
  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);

  // as of now, we don't have build time data for FaaS
  // we can check if the package is a rental, and pull it from the context when ready
  const isRentalReady = isRentalSlug(packageSlug) && !productPackageLoading;
  const rentalPackage = isRentalReady && productPackage;

  const packageData = buildTimeProductData || rentalPackage;

  return (
    <Flex
      flexDirection="column"
      data-test-id="hero-overview-header"
      alignItems="flex-start"
      gap={spacing[12]}
    >
      {packageData && (
        <PromoPills
          cfu={toBundleType(packageData.equipmentType as EquipmentType)!}
          bundleSlug={packageData.slug}
          theme="accent"
          pillFallback={ribbon}
        />
      )}
      <Markdown
        content={title}
        markdown={{
          renderers: {
            paragraph: ({ node, ...props }) => (
              <Display as="h2" size="small" color={grey[90]}>
                {props.children}
              </Display>
            ),
          },
        }}
      />
    </Flex>
  );
};

export default Header;
