import { Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

type PlusIconProps = {
  isHovered: boolean;
  primaryColor: string;
  filledPrimaryColor: string;
  filledSecondaryColor: string;
};

const PlusIcon = ({
  isHovered,
  primaryColor,
  filledPrimaryColor,
  filledSecondaryColor,
}: PlusIconProps) => (
  <IconWrapper>
    {!isHovered ? (
      <Icon
        name="plus"
        primaryColor={primaryColor}
        aria-hidden="true"
        data-test-id="plus-icon"
      />
    ) : (
      <Icon
        name="plusFilled"
        primaryColor={filledPrimaryColor}
        secondaryColor={filledSecondaryColor}
        data-test-id="plus-filled-icon"
        aria-hidden="true"
      />
    )}
  </IconWrapper>
);

export default PlusIcon;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;
