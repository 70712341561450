import type { TypeBundlePromo } from '@pelotoncycle/page-builder';
import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import { useDateTriggerContext } from '@ecomm/cms-promos/DateTriggerProvider';
import { createDateSlug } from '../promos';
import {
  PB_PROMO_BUNDLE_API_ENDPOINT,
  PB_PROMO_BUNDLE_PREVIEW_API_ENDPOINT,
} from '../utils/constants';

export const fetcher = (url: string) => fetch(url).then(r => r.json());

const useBundlePromo = (cfu: string, bundleSlug: string) => {
  const { overrideToday } = useDateTriggerContext();

  const formattedDate = createDateSlug(overrideToday);

  const preview = useIsPreviewCopyEnabled();
  const locale = useLocale();
  const endpoint = `${locale}/${cfu}/bundles/${bundleSlug}`;
  const dateOverride = formattedDate && preview ? `?today=${formattedDate}` : '';

  return useSWR<TypeBundlePromo>(
    `${
      preview ? PB_PROMO_BUNDLE_PREVIEW_API_ENDPOINT : PB_PROMO_BUNDLE_API_ENDPOINT
    }/${endpoint}${dateOverride}`,
    fetcher,
  );
};

export default useBundlePromo;
