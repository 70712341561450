import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import type {
  TypeComponentGenericListFields,
  TypeComponentGenericTextWithMediaFields,
} from '@page-builder/lib/types';
import { toMediaProps, toImageProps } from '@page-builder/utils/helpers';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import FeatureCards from './FeatureCards';
import type { Card } from './types';

const FeatureCardsContentHelper: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ text, items, ctas, name: parentName }) => {
  const { eyebrow, body } = getTextFieldsWithRequiredKeys(['eyebrow'], text);

  const cards: Card[] = items.map(item => {
    const mediaFields = toImageProps(item.fields.media);
    const { eyebrow: title } = getTextFieldsWithRequiredKeys(
      ['eyebrow'],
      item.fields.text,
    );
    const modal =
      item.fields.ctas &&
      (item.fields.ctas?.map(cta => ctaExtractData(cta)).find(cta => cta.modal)
        ?.modal as TypeComponentGenericTextWithMediaFields);
    const modalHeader =
      item.fields.ctas &&
      item.fields.ctas?.map(cta => ctaExtractData(cta)).find(cta => cta.modal)?.text;

    if (!modal) {
      throw new Error(`Missing modal at ${item.fields.name}`);
    }
    if (!modalHeader) {
      throw new Error(`Missing modal header at ${item.fields.name}`);
    }
    if ('media' in modal === false) {
      throw new Error(`Missing modal media at ${item.fields.name}`);
    }

    const modalPrimaryMedia = modal.media && toMediaProps(modal.media);
    const modalSecondaryMedia =
      modal.secondaryMedia && toMediaProps(modal.secondaryMedia);

    const { body: modalText } = getTextFieldsWithRequiredKeys(['body'], modal.text);

    return {
      images: mediaFields,
      title,
      modal: {
        media: {
          primaryMedia: modalPrimaryMedia,
          secondaryMedia: modalSecondaryMedia,
        },
        body: modalText,
        name: modal.name,
        header: modalHeader,
      },
    };
  });

  const modalContent = cards.map(card => card.modal);

  const extractedCtas = ctas && ctas?.map(cta => ctaExtractData(cta));
  const moreButton = extractedCtas?.find(cta => cta.name?.toLowerCase().includes('more'));
  const lessButton = extractedCtas?.find(cta => cta.name?.toLowerCase().includes('less'));

  if (!moreButton || !lessButton) {
    throw new Error('Missing cta for show more/less button');
  }

  return (
    <FeatureCards
      headerTitle={eyebrow}
      headerBody={body}
      cards={cards}
      parentName={parentName}
      modalContent={modalContent}
      buttons={{
        moreButton,
        lessButton,
      }}
    />
  );
};

export default FeatureCardsContentHelper;
