import { Flex } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import React from 'react';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { ErrorBoundary, ErrorMessage } from '@page-builder/layout/error-handling';
import HeadbandContainer from '@page-builder/modules/Headband/HeadbandContainer';
import HeadbandTitle from '@page-builder/modules/Headband/HeadbandTitle';
import { filterFalsyValues } from '@page-builder/utils/unions';
import type {
  ExternalLinkReferenceType as ExternalLinkReference,
  WwwLinkReferenceType as WwwLinkReference,
  Theme as ThemeType,
} from '../../../types/referenceTypes';
import { TOCItems, useSectionObserver } from '../TableOfContents';
import type { TOCItemLinkProps as TypeTOCItemLink } from '../TableOfContents';
import { treatmentMap } from '../TableOfContents/utils';
import { HeadbandCtaWithInfo } from './HeadbandCtaWithInfo';
import { MobileCTABanner } from './MobileCTABanner';

export type HeadbandProps = {
  title?: string;
  tableOfContents?: TypeTOCItemLink[];
  ctas?: {
    text: string;
    link?: WwwLinkReference | ExternalLinkReference;
    iosLink?: ExternalLinkReference;
  }[];
  financingText?: string;
  disclaimerText?: string;
  stackHeadband?: boolean;
  theme?: ThemeType;
  treatment?: 'Original' | 'Mobile Carousel';
};

/**
 * Headband component that displays a title, table of contents, and a list of call to actions.
 *
 * - `title` : The title of the headband.
 * - `tableOfContents` : The table of contents items to display.
 *    - `text` : The text of the table of contents item.
 *    - `link` : The link of the table of contents item.
 *     - `url` : The URL of the link.
 *     - `target` : The target of the link.
 *     - `rel` : The rel of the link.
 *  - `variant` : The variant of the link.
 * - `ctas` : Optional list of CTA buttons.
 * - `financingText` : The financing text to display.
 * - `disclaimerText` : The disclaimer text to display.
 * - `stackHeadband` : Whether to stack the headband on mobile.
 * - `theme` : The theme of the headband.
 * - `treatment` : The treatment of the headband. Currently supports Original and Mobile Carousel.
 *
 * **Note**: In storybook `Docs` view the bottom of the Headband is cut off due to a negative margin set on the component. This is to ensure the Headband is flush with the bottom of the viewport when used in the context of a page. The negative margin is not applied in the context of a page.
 *
 * @param {string} title - The title of the headband.
 * @param {TypeTOCItemLink[]} tableOfContents - The table of contents items to display.
 * @param {HeadbandProps['ctas']} ctas - The call to actions to display.
 * @param {string} financingText - The financing text to display.
 * @param {string} disclaimerText - The disclaimer text to display.
 * @param {boolean} stackHeadband - Whether to stack the headband on mobile.
 * @param {ThemeType} theme - The theme of the headband.
 * @param {'Original' | 'Mobile Carousel'} treatment - The treatment of the headband. Currently supports Original and Mobile Carousel.
 * @returns {React.ReactElement} The headband component.
 * @example
 * <Headband
 *   title="Headband Title"
 *   tableOfContents={[
 *     {
 *       text: 'Table of Contents Item 1',
 *       link: {
 *         url: '#table-of-contents-item-1',
 *       },
 *     },
 *     {
 *       text: 'Table of Contents Item 2',
 *       link: {
 *         url: '#table-of-contents-item-2',
 *       },
 *     },
 *   ]}
 *   ctas={[
 *     {
 *       text: 'CTA 1',
 *       link: {
 *         url: 'https://www.example.com',
 *       },
 *     },
 *     {
 *       text: 'CTA 2',
 *       link: {
 *         url: 'https://www.example.com',
 *       },
 *     },
 *   ]}
 *   financingText="Financing text"
 *   disclaimerText="Disclaimer text"
 *   stackHeadband={false}
 * />
 */

const Headband: React.FC<HeadbandProps & { addToCartCta?: TypeComponentCta }> = ({
  title,
  tableOfContents,
  ctas,
  financingText,
  disclaimerText,
  stackHeadband,
  theme = 'Grey 90',
  treatment = 'Original',
  addToCartCta,
}) => {
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const sectionIds = React.useMemo(
    () =>
      (tableOfContents || []).map(tocCta => tocCta.link.url).filter(filterFalsyValues),
    [tableOfContents],
  );
  const { activeId } = useSectionObserver(sectionIds, {
    observerOptions: {
      // An anchor section is considered in-view when it's halfway up the viewport (50%)
      rootMargin: '50% 0px -50% 0px',
    },
  });

  const MobileComponent = treatmentMap[treatment]['mobileComponent'];
  const OverflowContainer = treatmentMap[treatment]['overflowContainer'];

  return (
    <ErrorBoundary
      renderError={({ error }) => (
        <ErrorMessage error={error} module="Freeform Headband" />
      )}
    >
      <HeadbandContainer
        stackHeadband={stackHeadband}
        theme={theme}
        treatment={treatment}
      >
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: tableOfContents?.length ? (
              <>
                <OverflowContainer>
                  <Flex>
                    <HeadbandTitle title={title} theme={theme} />
                  </Flex>
                  <MobileComponent
                    activeId={activeId}
                    ctas={tableOfContents}
                    headbandRef={headbandRef}
                    parentName={title}
                    theme={theme}
                    treatment={treatment}
                  />
                </OverflowContainer>
                <MobileCTABanner
                  ctas={ctas}
                  title={title}
                  theme={theme}
                  addToCartCta={addToCartCta}
                />
              </>
            ) : (
              <HeadbandCtaWithInfo
                parentTitle={title}
                ctas={ctas}
                financingText={financingText}
                disclaimerText={disclaimerText}
                addToCartCta={addToCartCta}
              />
            ),
            desktop: (
              <>
                <HeadbandTitle title={title} theme={theme} />
                {tableOfContents?.length && (
                  <TOCItems
                    ctas={tableOfContents}
                    headbandRef={headbandRef}
                    parentName={title}
                    activeId={activeId}
                    theme={theme}
                    treatment={treatment}
                  />
                )}
                <HeadbandCtaWithInfo
                  parentTitle={title}
                  ctas={ctas}
                  financingText={financingText}
                  disclaimerText={disclaimerText}
                  theme={theme}
                  addToCartCta={addToCartCta}
                />
              </>
            ),
          }}
        />
      </HeadbandContainer>
    </ErrorBoundary>
  );
};

export default Headband;
