import type { GenericCtaData } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import type { BannerLink } from '@content/client/promos';
import type {
  TypeComponentBannerFields,
  TypeComponentCta,
} from '@page-builder/lib/types';

export type HybridBanner = {
  name?: string;
  key?: string;
  text: string;
  theme?: TypeComponentBannerFields['theme'];
  links?: GenericCtaData[];
};

/**
 * Extract peloContent or Page Builder CTA data into a common link object
 * @param data peloContent: CTAWithURL or Page Builder: CTA
 * @returns GenericCtaData
 */
export const bannerCtaExtractData = (
  data?: TypeComponentCta | Entry<BannerLink>,
): GenericCtaData => {
  const fields = data?.fields;

  if (fields && 'key' in fields) {
    const { link, text, driftCta, targetBlank } = fields;
    return {
      url: link?.fields.url,
      text: text || driftCta?.fields.text,
      isExternalLink: targetBlank,
      driftInteractionId: driftCta && `${driftCta?.fields.interactionId}`,
    };
  }

  return {
    url: fields?.link?.fields.url,
    text: fields?.text,
    isExternalLink: fields?.link?.sys.contentType.sys.id === 'componentExternalLink',
    driftInteractionId: fields?.driftInteractionId,
  };
};

export const toBannerLinks = (
  data: TypeComponentCta[] | Entry<BannerLink>[] = [],
): GenericCtaData[] => data.map(link => bannerCtaExtractData(link));
