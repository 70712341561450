import type { FlexProps } from '@pelotoncycle/design-system';
import { Container, Eyebrow, Flex, spacing } from '@pelotoncycle/design-system';
import Chevron from '@pelotoncycle/design-system/icons/arrows/Chevron';
import type { TypeComponentGenericList } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import { useShopContext } from '../ShopContext';
import { useUpsellAccessoryContext } from '../UpsellAccessoryContext';
import ComparePricingModalCta from '../WaysToShopIntro/ComparePricingModalCta';
import SectionTitle from '../WaysToShopIntro/SectionTitle';
import Drawer from './Drawer';
import SoloDrawer from './SoloDrawer';

type DrawersProps = {
  product: string;
  parentName: string;
  shopTitle?: TypeComponentGenericTextWithMedia;
};

const Drawers: React.FC<React.PropsWithChildren<DrawersProps>> = ({
  product,
  shopTitle,
  parentName,
}) => {
  const { setShouldFocusError } = useShopContext();
  const { clearSelectedVariants } = useUpsellAccessoryContext();

  const { trackEvent } = useTracking();
  const moreWaysToShopLabel = useMicroCopy('moreWaysToShopLabel');

  const {
    activeShopDrawer,
    setActiveShopDrawer,
    visibleShopDrawers,
    availableShopDrawers,
    expandDynamicDrawers,
    dynamicDrawersExpanded,
    hasDynamicDrawers,
  } = useDrawerSelectionContext();

  const handleDrawerChange = (drawer: TypeComponentGenericList) => {
    setActiveShopDrawer(drawer);

    // resets the accessory state
    setShouldFocusError(false);
    clearSelectedVariants();
  };

  const handleExpandOptions = () => {
    expandDynamicDrawers();
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Overview',
        parent: parentName,
        unitName: `${product} Overview Drawers expand`,
        linkName: moreWaysToShopLabel,
        linkTo: null,
      },
    });
  };

  const shouldShowComparePricingModalCta =
    shopTitle && (!hasDynamicDrawers || dynamicDrawersExpanded);

  if (!visibleShopDrawers?.length || !availableShopDrawers?.length) {
    return null;
  }

  // Need to use available drawers here because a subset might be visible due to dynamic drawer logic
  if (availableShopDrawers.length === 1) {
    return (
      <DrawersContainer>
        <SoloDrawer
          drawer={availableShopDrawers[0]}
          parentName={parentName}
          product={product}
        />
      </DrawersContainer>
    );
  }

  return (
    <>
      {shopTitle?.fields.text && !hasDynamicDrawers && (
        <Container data-test-id="shop-section-title-container">
          <SectionTitle title={shopTitle} />
        </Container>
      )}

      <DrawersContainer>
        {visibleShopDrawers.map((drawer, index) => (
          <Drawer
            drawer={drawer}
            onChange={() => handleDrawerChange(drawer)}
            isDrawerOpen={activeShopDrawer === drawer}
            index={index}
            product={product}
            parentName={parentName}
            key={index}
          />
        ))}
      </DrawersContainer>
      {hasDynamicDrawers && !dynamicDrawersExpanded && (
        <Flex justifyContent="center" margin={`${spacing[16]} auto 0`}>
          <ExpandButton label={moreWaysToShopLabel} onClick={handleExpandOptions} />
        </Flex>
      )}

      {shouldShowComparePricingModalCta && (
        <ComparePricingModalCta title={shopTitle} product={product} />
      )}
    </>
  );
};

export default Drawers;

const DrawersContainer: React.FC<React.PropsWithChildren<FlexProps>> = props => (
  <Flex
    data-test-id="overview-drawers"
    gap={spacing[16]}
    flexDirection="column"
    {...props}
  />
);

type ExpandButtonProps = {
  label: string;
} & React.ComponentPropsWithoutRef<'button'>;

const ExpandButton: React.FC<React.PropsWithChildren<ExpandButtonProps>> = ({
  label,
  ...remainingProps
}) => (
  <StyledButton type="button" {...remainingProps}>
    <StyledButtonLabel size="extraSmall" is="span">
      {label}
    </StyledButtonLabel>
    <StyledChevron primaryColor="black" aria-hidden="true" />
  </StyledButton>
);

const StyledButton = styled.button`
  display: flex;
  align-items: center;
`;

const StyledButtonLabel = styled(Eyebrow)`
  margin-right: ${spacing[8]};
  background-image: linear-gradient(180deg, currentColor, currentColor);
  background-size: 100% 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
`;

const StyledChevron = styled(Chevron)`
  transform: rotate(-90deg);
`;
