import { Grid, spacing, Label } from '@pelotoncycle/design-system';
import type {
  TypeComponentGenericListFields,
  TypeComponentCtaFields,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toCtaFields } from '@page-builder/utils/helpers';
import HighlightReelCard from './HighlightReelCard';
import useTrackCardClick from './useTrackCardClick';

type Props = {
  items: TypeComponentGenericListFields['items'];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onVideoEnded: () => void;
  isInView: boolean;
  handleJumpScroll: (
    event: React.MouseEvent<Element, MouseEvent>,
    cta: TypeComponentCtaFields,
    sectionId: string,
  ) => void;
  headlineColor: string;
};

const DesktopHighlightReel: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  activeIndex,
  setActiveIndex,
  onVideoEnded,
  isInView,
  handleJumpScroll,
  headlineColor,
}) => {
  const onCardClick = useTrackCardClick();
  const columns = items.map((item, idx) => (idx === activeIndex ? '2fr' : '1fr'));

  return (
    <DesktopContainer
      gap={spacing[24]}
      columnSizes={columns}
      data-test-id="desktop-highlight-reel"
    >
      {items.map((item, idx) => {
        const active = activeIndex === idx;
        const { eyebrow } = getGenericTextNodes(item.fields.text);
        const ctas = item.fields.ctas && toCtaFields(item.fields.ctas);
        const secondaryCta = ctas && ctas[1];
        const sectionId = secondaryCta?.link?.fields.url || '';
        const ctaText = secondaryCta?.text;

        return (
          <DesktopCardWrapper
            onClick={() => {
              if (!active) {
                onCardClick('Desktop Highlight Reel', item.fields.ctas?.[0]);
                setActiveIndex(idx);
              }
            }}
            key={idx}
            as={active ? 'div' : 'button'}
            data-test-id={`desktop-highlight-reel-card-wrapper${
              active ? '--active' : ''
            }`}
            aria-label={`desktop-highlight-reel-card-${idx + 1}`}
          >
            <HighlightReelCard
              {...item.fields}
              active={active}
              onVideoEnded={onVideoEnded}
              position={idx}
              isInView={isInView}
            />
            {eyebrow && (
              <CardInfoContainer active={active}>
                <Label size="large" textColor={headlineColor} weight="regular">
                  {eyebrow}
                </Label>
                {ctaText && (
                  <StyledLink
                    hasUnderline
                    size="small"
                    href={sectionId}
                    onClick={e => handleJumpScroll(e, secondaryCta, sectionId)}
                    textColor={headlineColor}
                  >
                    <Label size="large" style={{ fontWeight: 500 }}>
                      {ctaText}
                    </Label>
                  </StyledLink>
                )}
              </CardInfoContainer>
            )}
          </DesktopCardWrapper>
        );
      })}
    </DesktopContainer>
  );
};

export default DesktopHighlightReel;

const DesktopContainer = styled(Grid)`
  width: 100%;
  transition: 400ms all;
  align-items: start;
`;

const DesktopCardWrapper = styled.button`
  display: block;
  width: 100%;
  overflow: hidden;
`;

const CardInfoContainer = styled.div`
  display: ${(props: { active: boolean }) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: ${spacing[16]};

  ${media.desktopLarge`
    min-width: 448px;
`}

  ${media.desktopXLarge`
    min-width: 588px;
`}
`;

const StyledLink = styled(Link)`
  color: ${(props: { textColor: string }) => props.textColor};
  width: fit-content;
`;
