import { Body, Headline } from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import ScrolledIntoView from '@peloton/scroll/ScrolledIntoView';
import { media } from '@peloton/styles';
import { cascadingContent } from '@ecomm/styles';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';

const TryClasses: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ text, ctas }) => {
  const { trackEvent } = useTracking();
  const { headline, body } = getGenericTextNodes(text, ['headline', 'body']);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const secondaryCta = ctas && ctas[1] && ctaExtractData(ctas[1]);

  return (
    <Container>
      <CascadingContent speed={0.95}>
        <TextContainer>
          <Headline as="h2" marginBottom="24px" data-test-id="membership_block_copy1">
            {headline}
          </Headline>
          <Body as="h3" size="medium" data-test-id="membership_block_copy2">
            {body}
          </Body>
        </TextContainer>
      </CascadingContent>
      <CascadingContent speed={0.95}>
        <CtaContainer>
          {primaryCta && (
            <LinkButton
              variant="solid"
              color="primary"
              size="small"
              to={primaryCta?.url}
              href={primaryCta?.url}
              onClick={() => trackClassesCtaClick(trackEvent, primaryCta.url || '')}
              text={primaryCta.text || ''}
            />
          )}
          {secondaryCta && (
            <LinkButton
              variant="outline"
              color="dark"
              size="small"
              to={secondaryCta.url}
              href={secondaryCta?.url}
              onClick={() => trackMembershipCtaClick(trackEvent, secondaryCta.url || '')}
              data-test-id="membership_block_link"
              text={secondaryCta.text || ''}
            />
          )}
        </CtaContainer>
      </CascadingContent>
    </Container>
  );
};

export default TryClasses;

const trackClassesCtaClick = (track: (data: object) => void, linkTo: string) =>
  track({
    event: 'Clicked Link',
    properties: {
      page: window.location.pathname,
      parent: 'classes discipline module',
      unitName: 'classes discipline module CTA 1',
      linkTo,
    },
  });

const trackMembershipCtaClick = (track: (data: object) => void, linkTo: string) =>
  track({
    event: 'Clicked Link',
    properties: {
      page: window.location.pathname,
      parent: 'classes discipline module',
      unitName: 'classes discipline module CTA 2',
      linkTo,
    },
  });

const CascadingContent = styled(ScrolledIntoView)`
  ${cascadingContent};
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 55px 0 60px;

  ${media.tabletXLarge`
    padding: 80px 0;
  `}

  ${media.desktopLarge`
    padding: 100px 0;
  `};
`;

const TextContainer = styled.div`
  padding: 0 8%;
  text-align: center;

  ${media.desktopLarge`
    padding: 0;
    max-width: 700px;
  `}
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  a:last-child {
    margin-top: 20px;

    ${media.tablet`
      margin-top: 0px;
    `}
  }

  ${media.tablet`
    flex-direction: row;

    a:first-child {
      margin-right: 16px;
    }
  `}
`;
