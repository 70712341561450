import {
  Flex,
  Body,
  Container,
  spacing,
  Icon,
  Label,
  green,
  Support,
  red,
  grey,
  Eyebrow,
  FlexChild,
  white,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { ctaExtractData, LinkButtons } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';

export const checkUrl = (url: string, userId: string) => {
  return url.endsWith('/') ? `${url}${userId}` : `${url}/${userId}`;
};

const AppMembershipCard: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ text, ctas }) => {
  const { eyebrow, headline, body, label } = getGenericTextNodes(text);
  const userId = useMicroCopy('userId');
  const appliedAppDiscount = useMicroCopy('appliedAppDiscount');
  const appRetailCost = useMicroCopy('retailCost');
  const billingFrequency = useMicroCopy('billingFrequency');
  const thirdPartyName = useMicroCopy('thirdPartyName');

  const defaultDescription =
    text && text.fields.support && text.fields.support.split('|');

  const monthlyBillingDescription = defaultDescription && defaultDescription[0];
  const yearlyBillingDescription = defaultDescription && defaultDescription[1];
  const thirdPartyDescription = defaultDescription && defaultDescription[2];

  const priceToDisplay =
    billingFrequency !== 'norender' && billingFrequency === 'monthly'
      ? monthlyBillingDescription
      : yearlyBillingDescription;

  const totalAppCost =
    appRetailCost !== 'norender' &&
    priceToDisplay &&
    priceToDisplay.replace('{price}', appRetailCost);

  const updatedThirdPartyDescription =
    thirdPartyName &&
    thirdPartyName !== 'norender' &&
    thirdPartyDescription &&
    thirdPartyDescription.replace('{thirdPartyProviderName}', thirdPartyName);

  const parsedCtas =
    ctas &&
    ctas?.map(cta => {
      const parsedCta = ctaExtractData(cta);
      const { isExternalLink, url } = parsedCta;
      const updatedUrl =
        isExternalLink && url && url.includes('lululemon') ? checkUrl(url, userId) : url;

      return { ...parsedCta, url: updatedUrl };
    });

  return (
    <Container
      data-test-id="app-membership-card"
      maxWidth={CONTAINER_MAXWIDTH}
      verticalMargin={`${spacing[48]}`}
      centered
    >
      <Eyebrow size="large" textAlign="center">
        {eyebrow}
      </Eyebrow>
      <Container
        backgroundColor={`${white}`}
        verticalMargin={`${spacing[24]}`}
        padding={`${spacing[40]}`}
        maxWidth="90%"
        hasShadow
        hasShadowHover
        centered
      >
        <Flex
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <FlexChild
            alignSelf="center"
            margin={{
              mobile: 0,
              tablet: `0 ${spacing[32]} 0 0`,
            }}
          >
            <Icon height={40} name="devices" primaryColor={grey[60]} />
          </FlexChild>

          <FlexChild
            flexGrow={1}
            textAlign={{ mobile: 'center', tablet: 'left' }}
            maxWidth="65%"
          >
            <Body size="small">{headline} </Body>
            {updatedThirdPartyDescription ? (
              <Support>{updatedThirdPartyDescription}</Support>
            ) : (
              <>
                {userId === 'norender' && body ? (
                  <>
                    {/* this displays text for members with more than one benefit */}
                    <Support> {body} </Support>
                  </>
                ) : (
                  <>
                    <StyledSupport
                      size="large"
                      strikethrough={appliedAppDiscount !== 'norender'}
                    >
                      {/* this determines if totalAppCost price has strikethrough */}
                      {appliedAppDiscount !== 'norender' ? (
                        <>
                          {appliedAppDiscount} <s>{totalAppCost}</s>
                        </>
                      ) : (
                        <>{totalAppCost}</>
                      )}
                    </StyledSupport>
                  </>
                )}
              </>
            )}

            {label && appliedAppDiscount !== 'norender' && (
              <Flex
                flexDirection="row"
                justifyContent={{ mobile: 'center', tablet: 'left' }}
                verticalMargin={spacing[8]}
              >
                <Icon
                  name="checkCircle"
                  height={16}
                  primaryColor={green[50]}
                  style={{ marginRight: spacing[8] }}
                />
                <Label size="small" style={{ color: grey[70] }}>
                  {label}
                </Label>
              </Flex>
            )}
          </FlexChild>

          {ctas && (
            <Flex
              textAlign="right"
              flexGrow={1}
              justifyContent="flex-end"
              maxWidth={ctas?.length > 1 ? 'min-content' : ''}
            >
              <LinkButtons
                linkVariant="body"
                linkSize="small"
                ctas={parsedCtas}
                hasDropShadow={false}
                style={{ justifyContent: 'center' }}
              />
            </Flex>
          )}
        </Flex>
      </Container>
    </Container>
  );
};

export default AppMembershipCard;

const StyledSupport = styled(Support)<{ strikethrough: boolean }>`
  color: ${({ strikethrough }) => (strikethrough ? `${red[50]}` : `${grey[80]}`)};

  s {
    color: ${grey[70]};
  }
`;
