import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { State } from '@ecomm/product-recommendations/models/State';
import useCanCohortBeBuildTimeRendered from '@ecomm/product-recommendations/utils/useCanCohortBeBuildTimeRendered';

type Args = {
  cohort: ProductRecommendationsCohort;
  products: Product[];
  error?: Error;
};

const useModuleState = ({ cohort, products, error }: Args) => {
  const canBeBuildTimeRendered = useCanCohortBeBuildTimeRendered(cohort);
  const areProductsLoaded = products.length > 0;

  if (areProductsLoaded) {
    return State.ProductDataLoaded;
  }

  if (canBeBuildTimeRendered) {
    return State.CanBeBuildTimeRendered;
  }

  if (error) {
    return State.Error;
  }

  return State.Loading;
};

export default useModuleState;
