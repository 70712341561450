import type { FetchPolicy } from '@apollo/client';
import { toCurrency } from '@peloton/internationalize';
import type { Country } from '@peloton/internationalize/models/country';
import { toCountryFromLocale } from '@peloton/internationalize/models/country';
import { Locale } from '@peloton/internationalize/models/locale';
import type { CommercetoolsClient } from '../apollo';
import { AccessoryKeys, SPARE_PARTS } from '../constants';
import { mapProductsToSparePartsList } from '../mappers/mapProductsToSparePartsList';
import { toChannelAvailability } from '../models/channelAvailability';
import type { ProductProjectionType } from '../models/main';
import { GetCategoryByKeyDocument } from '../queries/GetCategoryByKey.generated';
import type {
  GetCategoryByKeyQuery,
  GetCategoryByKeyQueryVariables,
} from '../queries/GetCategoryByKey.generated';
import { ProjectionSearchAllAccessoriesDocument } from '../queries/ProjectionSearchAllAccessories.generated';
import type {
  ProjectionSearchAllAccessoriesQuery,
  ProjectionSearchAllAccessoriesQueryVariables,
} from '../queries/ProjectionSearchAllAccessories.generated';

type Options = {
  client: CommercetoolsClient;
  locale: Locale;
  fetchPolicy?: FetchPolicy;
};

type QueryOptions = {
  country: Country;
  acceptLanguage?: Locale[];
  fetchPolicy?: FetchPolicy;
};

type CategoryQueryOptions = {
  acceptLanguage?: Locale[];
  fetchPolicy?: FetchPolicy;
  key: string;
};

export const queryProductCategory = async (
  client: CommercetoolsClient,
  params: CategoryQueryOptions,
) => {
  const { acceptLanguage, fetchPolicy, key } = params;

  const category = await client.query<
    GetCategoryByKeyQuery,
    GetCategoryByKeyQueryVariables
  >({
    query: GetCategoryByKeyDocument,
    fetchPolicy,
    variables: {
      acceptLanguage,
      key: key,
    },
  });

  return category;
};

export const queryCommerceToolsProducts = async (
  client: CommercetoolsClient,
  params: QueryOptions,
) => {
  const { acceptLanguage, country, fetchPolicy } = params;
  const category = await queryProductCategory(client, {
    acceptLanguage,
    key: SPARE_PARTS,
  });
  const { data } = await client.query<
    ProjectionSearchAllAccessoriesQuery,
    ProjectionSearchAllAccessoriesQueryVariables
  >({
    query: ProjectionSearchAllAccessoriesDocument,
    fetchPolicy,
    variables: {
      limit: 100,
      acceptLanguage,
      currency: toCurrency(country),
      country,
      filters: [
        {
          model: {
            value: {
              path: `variants.attributes.${AccessoryKeys.CHANNEL_AVAILABILITY}.key`,
              values: toChannelAvailability(),
            },
          },
        },
        {
          model: {
            value: {
              path: 'categories.id',
              values: [category.data?.category?.id || ''],
            },
          },
        },
      ],
    },
  });
  return data;
};

const fetchSpareParts = async ({ client, locale, fetchPolicy }: Options) => {
  const country = toCountryFromLocale(locale);
  const acceptLanguage = [locale, Locale.Default];

  const data = await queryCommerceToolsProducts(client, {
    country,
    acceptLanguage,
    fetchPolicy,
  });

  return mapProductsToSparePartsList(
    data.productProjectionSearch.results as ProductProjectionType[],
    locale,
  );
};

export default fetchSpareParts;
