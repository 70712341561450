import { Flex, Media, Label, Body, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media as mediaBreakpoints } from '@peloton/styles';
import { CARD_MEDIA_HEIGHT } from '@page-builder/modules/VideoCarousel/constants';
import { themes, ThemeValues } from '@page-builder/themes';
import Markdown from '@page-builder/utils/Markdown';
import type { Theme as TypeTheme } from '../../../../types/referenceTypes';
import type { MediaCarouselCardProps } from '../types';

type ActiveProps = {
  isActive: boolean;
  isInView?: boolean;
};

/**
 * This component maps to one `item` in the MediaCarousel.
 * - The `headline` field is *optional*. It is the bolded text that is displayed right below the rendered media.
 * - The `subtext` field is *optional*. It is the text that is displayed right below the optional headline.
 * - The `media` field is *required*. It is the media asset that is rendered in the carousel card item.
 *
 * @param headline optional bolded text that is displayed right below the rendered media.
 * @param subtext optional subtext that is displayed right below the optional headline.
 * @param media the media asset that is rendered in the carousel card item.
 * @param cta the optional cta entry that will display below the subtext. If the optional `color` and/or `variant` fields are defined in this entry, it will override the default theme styling for the cta.
 * @param theme is the same theme passed down from the MediaCarousel component. It defaults to 'Grey 90' when it is not defined for the MediaCarousel.
 */
const MediaCarouselCard = ({
  headline,
  subtext,
  media,
  isActive,
  isInView,
  cta,
  theme = 'Grey 90',
}: MediaCarouselCardProps & ActiveProps & { theme?: TypeTheme }) => {
  const showText = Boolean(headline || subtext);
  const mediaProps = {
    ...media,
    autoplay: isActive,
    progressTracked: true,
    playPauseButtonPosition: isActive ? 'right' : 'hide',
  } as const;
  const { headlineColor, textColor, buttonColor } = themes[theme];

  // Sets the styling of the CTA based on the MediaCarousel theme when the `color` or the `variant` field(s) are not defined.
  const setDefaultCtaColorStyles = () => {
    if (isActive) {
      // If the card is active, set the CTA color to be the buttonColor from the theme.
      return buttonColor;
    } else {
      // If inactive, set the CTA color to the toggled value of the buttonColor.
      return buttonColor === 'light' ? 'dark' : 'light';
    }
  };

  return (
    <Card flexDirection="column" gap={spacing[16]} isActive={isActive} theme={theme}>
      {isInView ? (
        <CardMedia key={`${isActive}`} media={mediaProps} />
      ) : (
        <PlaceholderMedia data-test-id="placeholder-media" />
      )}
      {showText && (
        <Flex flexDirection="column" gap={spacing[8]} style={{ whiteSpace: 'normal' }}>
          {headline && (
            <Label size="extraLarge" textColor={headlineColor}>
              {headline}
            </Label>
          )}
          {subtext && (
            <Body size="small" textColor={textColor}>
              <Markdown content={subtext} />
            </Body>
          )}
          {cta && (
            <Flex verticalMargin={spacing[16]}>
              <LinkButton
                key={cta.text}
                href={cta.link.url}
                color={cta.color ?? setDefaultCtaColorStyles()}
                variant={cta.variant ?? 'solid'}
                isDisabled={!isActive}
                size="small"
                width="adjustable"
              >
                {cta.text}
              </LinkButton>
            </Flex>
          )}
        </Flex>
      )}
    </Card>
  );
};

export default MediaCarouselCard;

const Card = styled(Flex)<ActiveProps & { theme: TypeTheme }>`
  opacity: ${({ isActive, theme }) =>
    theme === ThemeValues.White ? 1 : isActive ? 1 : 0.5};
  transition: opacity 400ms ease;
`;

const CardMedia = styled(Media)`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  scroll-snap-align: center;
  img {
    object-fit: contain;
  }
`;

const PlaceholderMedia = styled.div`
  height: ${CARD_MEDIA_HEIGHT.mobile};
  ${mediaBreakpoints.desktopLarge`
      height: ${CARD_MEDIA_HEIGHT.desktop};
  `}
  border-radius: 12px;
  background: ${grey[60]};
`;
