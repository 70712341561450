import {
  Button,
  Container,
  Flex,
  Label,
  Support,
  spacing,
} from '@pelotoncycle/design-system';
import type {
  TypeComponentCtaFields,
  TypeComponentReviewsFields,
} from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { RatingStats } from '@ecomm/reviews/data/utils';
import type { ReviewQuery } from '@ecomm/reviews/models/ReviewQuery';
import AIReviewCard from './AIReviewCard';
import { REVIEWS_MAXIMUM_SCORE, REVIEWS_MODAL, REVIEWS_TRACKING } from './constants';
import { themes } from './themes';

export type ReviewsModalContentType = {
  reviews: ReviewQuery[];
  totalReviewCount: number;
  activeRating: number | undefined;
  theme: TypeComponentReviewsFields['theme'];
  setActiveRating: (rating: number | undefined) => void;
  handleFetchMoreReviews: () => void;
  activeReviewId?: string;
  cta?: TypeComponentCtaFields;
  ratingsDistribution: RatingStats[];
};

const ReviewsModalContent: React.FC<ReviewsModalContentType> = ({
  reviews,
  totalReviewCount,
  activeRating,
  theme,
  setActiveRating,
  handleFetchMoreReviews,
  activeReviewId,
  cta,
  ratingsDistribution,
}) => {
  const { trackEvent } = useTracking();
  const {
    cardBackgroundColor,
    modalButtonBackgroundColor,
    textColor,
    starsColor,
    itemTextColor,
    totalReviewCountColor,
    moreReviewsButtonColor,
    allReviewsCtaColor,
    modalBackgroundColor,
  } = themes[theme];
  const reviewCardStyles = {
    textColor: textColor,
    starsColor: starsColor,
    itemTextColor: itemTextColor,
    cardBackgroundColor: cardBackgroundColor,
  };

  const [opacity, setOpacity] = useState<number>(1);
  const previousActiveRating = useRef<number | undefined>(activeRating);
  const toFormattedText = useGetTextFormatter();
  const mostRecent = useMicroCopy('reviewsMostRecent');
  const stars = useMicroCopy('reviewsStars');

  const activeButtonRef = useRef<HTMLButtonElement>(null);

  const reviewsShownOfTotalMicrocopy = useMicroCopy('reviewsShownOfTotal');
  const seeMoreReviewsCtaCopy = useMicroCopy('seeMoreReviewsCta');
  const seeAllReviewsCtaFallbackCopy = useMicroCopy('seeAllReviewsCta');
  const reviewsShownCount = reviews.length;
  const reviewsShownOfTotalCaption = toFormattedText(reviewsShownOfTotalMicrocopy, {
    reviewsShownCount,
    totalReviewCount: totalReviewCount.toLocaleString(),
  });

  const activeRatingReviewsTotal = activeRating
    ? ratingsDistribution[REVIEWS_MAXIMUM_SCORE - activeRating].count
    : totalReviewCount;

  useEffect(() => {
    if (previousActiveRating.current !== activeRating) {
      setOpacity(0);
      const timer: NodeJS.Timeout = setTimeout(() => {
        setOpacity(1);
        previousActiveRating.current = activeRating;
      }, REVIEWS_MODAL.transitionDuration);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [activeRating]);

  useEffect(() => {
    if (activeButtonRef.current) {
      activeButtonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [activeRating]);

  const trackFilterReviews = (rating: number | undefined, text: string) => {
    setActiveRating(rating);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: REVIEWS_TRACKING.parentType,
        parent: REVIEWS_TRACKING.parent,
        unitName: `Review Modal Content ${text} Button`,
        linkName: text,
        linkTo: `Filter reviews by ${text}`,
      },
    });
  };

  const trackSeeMoreReviews = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: REVIEWS_TRACKING.parentType,
        parent: REVIEWS_TRACKING.parent,
        unitName: `${seeMoreReviewsCtaCopy} CTA`,
        linkName: seeMoreReviewsCtaCopy,
        linkTo: `Load more reviews - ${
          reviewsShownCount + REVIEWS_MODAL.defaultNumberOfReviews
        } reviews shown`,
      },
    });
  };

  const trackSeeAllReviews = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: REVIEWS_TRACKING.parentType,
        parent: REVIEWS_TRACKING.parent,
        unitName: cta?.name,
        linkName: cta?.text || seeAllReviewsCtaFallbackCopy,
        linkTo: cta?.link?.fields.url,
      },
    });
  };

  const resetModalPosition = () => {
    const innerContent = document.querySelector('.ReactModal__Overlay--after-open');
    if (innerContent) {
      setTimeout(() => {
        innerContent.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, REVIEWS_MODAL.transitionDuration);
    }
  };

  const createFilterButtons = () => {
    const firstButton = (
      <RatingButton
        key={mostRecent}
        onClick={() => {
          trackFilterReviews(undefined, mostRecent);
          resetModalPosition();
        }}
        borderColor={textColor}
        buttonBackgroundColor={modalButtonBackgroundColor}
        isActive={activeRating === undefined}
        as="button"
        data-test-id="star-rating-filter-most-recent"
        ref={activeRating === undefined ? activeButtonRef : null}
      >
        <Label textColor={textColor}>{mostRecent}</Label>
      </RatingButton>
    );

    const starButtons = ratingsDistribution.map(({ count, ratingValue: starNumber }) => {
      if (count === 0) {
        return null;
      }

      const starsText = toFormattedText(stars, {
        rating: starNumber,
        isPlural: starNumber !== 1,
      });
      return (
        <RatingButton
          key={starNumber}
          onClick={() => {
            trackFilterReviews(starNumber, starsText as string);
            resetModalPosition();
          }}
          borderColor={textColor}
          buttonBackgroundColor={modalButtonBackgroundColor}
          isActive={activeRating === starNumber}
          as="button"
          data-test-id={`star-rating-filter-${starNumber}`}
          ref={starNumber === activeRating ? activeButtonRef : null}
        >
          <Label textColor={textColor}>{starsText}</Label>
        </RatingButton>
      );
    });

    return [firstButton, ...starButtons];
  };

  return (
    <Flex flexDirection="column" data-test-id="reviews-modal-content">
      <ButtonContainer
        padding={{
          mobile: spacing[16],
          desktop: `${spacing[16]} ${spacing[24]}`,
        }}
        gap={spacing[8]}
        backgroundColor={modalBackgroundColor}
      >
        {createFilterButtons()}
      </ButtonContainer>
      <ContentContainer
        flexDirection="column"
        alignItems="center"
        gap={spacing[40]}
        padding={{
          mobile: `0 ${spacing[16]} ${spacing[40]} `,
          desktop: `0 ${spacing[24]} ${spacing[40]}`,
        }}
        opacity={opacity}
      >
        <Flex
          flexDirection="column"
          gap={{ mobile: spacing[16], desktop: spacing[24] }}
          style={{ width: '100%' }}
        >
          {reviews.map((review, index) => (
            <Container
              key={`review-${activeRating}-${review.id}-modal-${index}`}
              data-test-id={`review-card-${review.id}-modal`}
              className={`review-card-${review.id}`}
            >
              <AIReviewCard
                review={review}
                setActiveRating={rating => {
                  setActiveRating(rating);
                }}
                activeReviewId={activeReviewId}
                {...reviewCardStyles}
              />
            </Container>
          ))}
        </Flex>
        <Flex flexDirection="column" alignItems="center" gap={spacing[16]} width="100%">
          <TotalReviewCount
            size="small"
            data-test-id="total-review-count"
            totalReviewCountColor={totalReviewCountColor}
          >
            {reviewsShownOfTotalCaption}
          </TotalReviewCount>
          {reviews.length >= 100 || reviews.length >= activeRatingReviewsTotal ? (
            <StyledLink
              hasUnderline
              data-test-id="seeMoreReviewsLink"
              to={cta?.link?.fields.url}
              href={cta?.link?.fields.url}
              size="medium"
              allReviewsCtaColor={allReviewsCtaColor}
              onClick={trackSeeAllReviews}
            >
              {cta?.text || seeAllReviewsCtaFallbackCopy}
            </StyledLink>
          ) : (
            <Button
              variant="outline"
              color={moreReviewsButtonColor}
              size="medium"
              data-test-id="seeMoreReviewsButton"
              onClick={() => {
                trackSeeMoreReviews();
                handleFetchMoreReviews();
              }}
            >
              {seeMoreReviewsCtaCopy}
            </Button>
          )}
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

const ButtonContainer = styled(Flex)<{ backgroundColor: string }>`
  overflow-x: auto;
  z-index: 1000;
  background-color: ${({ backgroundColor }) => rgba(backgroundColor, 0.9)};
  backdrop-filter: blur(${spacing[12]});
  top: 56px;
  position: sticky;
  height: ${spacing[72]};
  ${media.tabletLarge`
    height: ${spacing[80]};
    top: 64px;
  `}

  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;
`;

const TotalReviewCount = styled(Support)<{
  totalReviewCountColor: string;
}>`
  color: ${({ totalReviewCountColor }) => totalReviewCountColor};
`;

const StyledLink = styled(Link)<{ allReviewsCtaColor: string }>`
  color: ${({ allReviewsCtaColor }) => allReviewsCtaColor};
`;

const RatingButton = styled(Flex)<{
  borderColor: string;
  buttonBackgroundColor: string;
  isActive: boolean;
}>`
  height: ${spacing[40]};
  padding: ${spacing[8]} ${spacing[16]};
  justify-content: center;
  align-items: center;
  background-color: ${({ buttonBackgroundColor }) => buttonBackgroundColor};

  flex-shrink: 0;

  // todo: fix hover color for light theme?
  &:hover {
    background-color: ${({ buttonBackgroundColor, isActive }) =>
      !isActive && rgba(buttonBackgroundColor, 0.5)};
  }

  transition: border-color ${REVIEWS_MODAL.transitionDuration}ms ease-in-out,
    background-color ${REVIEWS_MODAL.transitionDuration}ms ease-in-out;
  border: 1.5px solid transparent;
  border-radius: ${spacing[8]};
  ${({ isActive, borderColor }) => isActive && `border-color: ${borderColor}`};
`;

const ContentContainer = styled(Flex)<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity ${REVIEWS_MODAL.transitionDuration}ms ease-in-out;
`;

export default ReviewsModalContent;
