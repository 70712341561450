import { throttle } from 'frame-throttle';
import { useCallback, useEffect, useRef, useState } from 'react';

const useScrollOpacity = (maxOpacity: number) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(0);

  const handleScroll = useCallback(
    () => setOpacity(calculateOpacity(contentRef.current, opacity, maxOpacity)),
    [opacity, maxOpacity],
  );

  useEffect(() => {
    const throttled = throttle(handleScroll);
    window.addEventListener('scroll', throttled);
    return () => {
      window.removeEventListener('scroll', throttled);
    };
  }, [handleScroll]);
  return { contentRef, opacity };
};

export default useScrollOpacity;

export const calculateOpacity = (
  ref: HTMLDivElement | null,
  opacity: number,
  maxOpacity: number,
) => {
  if (ref) {
    const { height, top } = ref.getBoundingClientRect();
    const calculatedOpacity = ((height - Math.max(0, top)) / height) * maxOpacity;
    return Math.max(0, Number(calculatedOpacity.toFixed(2)));
  }
  return opacity;
};
