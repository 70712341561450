import { black, grey, Icon, Label, spacing, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { defaultTransition } from '@peloton/styles/animation';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { PageBuilderTheme } from '@ecomm/product-recommendations/utils/theme';
import { DEFAULT_THEME_NAME } from '@ecomm/product-recommendations/utils/theme';
import PackageContentsModal from '@ecomm/product-recommendations/Views/Grid/PackageContentsModal';
import { media } from '@page-builder/utils/helpers';

type Props = {
  product: Product;
  themeName?: PageBuilderTheme;
};

type ButtonTheme = {
  backgroundColor: string;
  backgroundBlur: Record<'mobile' | 'desktop', number>;
  textColor: string;
  hoverTextColor: string;
};

const buttonThemes: Partial<Record<PageBuilderTheme, ButtonTheme>> = {
  ['White']: {
    backgroundColor: rgba(white, 0.85),
    backgroundBlur: { mobile: 4, desktop: 8 },
    textColor: grey[90],
    hoverTextColor: grey[70],
  },
  ['Grey 90']: {
    backgroundColor: rgba(black, 0.33),
    backgroundBlur: { mobile: 12, desktop: 12 },
    textColor: white,
    hoverTextColor: rgba(white, 0.75),
  },
};

const PackageContentsButton: React.FC<Props> = ({
  product,
  themeName = DEFAULT_THEME_NAME,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { trackEvent } = useTracking();
  const text = useMicroCopy('whatsIncluded');

  const { name } = product;

  const buttonTheme = buttonThemes[themeName] || buttonThemes[DEFAULT_THEME_NAME];

  const baseModalAnalyticsProps = {
    productInterest: name,
    propertyType: 'Web',
    unitName: text,
    unitPlacement: 'Modal',
  };

  const onClick = () => {
    trackEvent({
      event: TrackingEvent.ModalOpened,
      properties: {
        ...baseModalAnalyticsProps,
        modalTrigger: 'Clicked package contents button',
      },
    });
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName: name,
        linkName: text,
        linkTo: '',
      },
    });
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    trackEvent({
      event: TrackingEvent.ModalClosed,
      properties: {
        ...baseModalAnalyticsProps,
        modalTrigger: 'Closed package contents modal',
      },
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <Button {...buttonTheme!} onClick={onClick} aria-label={text}>
        <Icon name="plus" role="presentation" primaryColor="currentcolor" />
        <Label
          textColor="currentcolor"
          size="small"
          display={{
            mobile: 'none',
            tablet: 'unset',
          }}
        >
          {text}
        </Label>
      </Button>
      <PackageContentsModal
        product={product}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
};

const Button = styled.button<ButtonTheme>`
  position: absolute;
  bottom: ${spacing[8]};
  left: ${spacing[8]};
  z-index: 2;
  cursor: pointer;
  display: flex;
  gap: ${spacing[8]};
  align-items: center;
  border-radius: 128px;
  padding: 11px;
  box-shadow: 0 ${spacing[4]} ${spacing[4]} 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(${props => props.backgroundBlur.mobile}px);
  ${defaultTransition('color')}

  background: ${props => props.backgroundColor};
  color: ${props => props.color};

  &:hover {
    color: ${props => props.hoverTextColor};
  }

  svg {
    height: 22px;
  }

  ${media.tablet`
    padding: ${spacing[8]} ${spacing[12]};
    bottom: ${spacing[16]};
    left: ${spacing[16]};
    backdrop-filter: blur(${(props: ButtonTheme) => props.backgroundBlur.desktop}px);

    svg {
      height: 16px;
    }
  `}
`;

export default PackageContentsButton;
