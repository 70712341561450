import {
  brand,
  grey,
  black,
  Container,
  Headline,
  spacing,
} from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { ieHideScroll, media } from '@peloton/styles';
import { usePrograms } from '@ecomm/programs/Context';
import { hb6, hb8 } from '@ecomm/typography';
import type {
  TypeComponentGenericTextWithMedia,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import { toMediaProps } from '@page-builder/utils/helpers/media';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';
import type { CardData } from './BellyBandCard';
import BellyBandCard from './BellyBandCard';

type StyleProps = {
  cardCount: number;
  showTryBanner: boolean;
};

const CARD_LIST_SIDE_PADDING_DESKTOP = 40;
const CARD_LIST_SIDE_PADDING_MOBILE = 25;
const MAXIMUM_BELLY_BAND_WIDTH = 1050;

const PRODUCT_PREFIX = 'Belly Band Card: ';

const BellyBand: React.FC<React.PropsWithChildren<TypeComponentGenericListFields>> = ({
  name,
  items,
  text,
  ctas,
  theme: themeKey,
}) => {
  const { apparel } = usePrograms();
  const { trackEvent } = useTracking();
  const moduleId = nameToModuleId(name);
  const headingId = `${moduleId}-heading`;

  const cfus = [
    'Bike+',
    'BikePlus',
    'Bikes',
    'Bike',
    'Tread+',
    'TreadPlus',
    'Treads',
    'Tread',
    'Row',
    'Guide',
    'Apparel',
    'App',
    'Accessories',
    'Strength+',
  ];
  const nameToProduct = (fieldName: string) => {
    for (const cfu of cfus) {
      if (fieldName.startsWith(`${PRODUCT_PREFIX}${cfu}`)) {
        return cfu;
      }
    }
    return '';
  };

  const cardCount = items.length;
  const headline = text?.fields?.headline;
  const support = text?.fields?.support;
  const cta = ctas?.[0];
  const defaultTheme = 'Grey 30';
  const theme = themes[themeKey || defaultTheme];

  return (
    <>
      <NavContainer
        backgroundColor={theme.backgroundColor}
        hasHeading={!!headline}
        aria-labelledby={headline ? headingId : undefined}
      >
        {headline && (
          <StyledFixedContainer
            verticalPadding={{
              mobile: `${spacing[32]} 0`,
              desktop: `${spacing[64]} 0`,
            }}
            horizontalPadding={{
              mobile: spacing[16],
              tablet: spacing[32],
              desktop: spacing[88],
            }}
            maxWidth={`${1400 / 16}rem`}
            margin={'auto'}
          >
            <Headline
              size="extraSmall"
              is="h2"
              id={headingId}
              style={{ color: theme.headlineColor }}
            >
              {headline}
            </Headline>
          </StyledFixedContainer>
        )}
        <ListContainer cardCount={cardCount} showTryBanner={!!cta}>
          {items.map(
            (
              { fields, metadata = { tags: [] } }: TypeComponentGenericTextWithMedia,
              index,
            ) => {
              // do not render with missing image/cta
              if (!fields.media || !fields.ctas) {
                return null;
              }
              if (nameToProduct(fields.name) === 'Apparel' && apparel === false) {
                return null;
              }
              const card: CardData = {
                link: ctaExtractData(fields.ctas[0]),
                image: toMediaProps(fields.media, true),
              };
              const ribbonText = fields.text?.fields.label
                ? fields.text.fields.label
                : undefined;
              return (
                <BellyBandCard
                  card={card}
                  tags={metadata.tags}
                  productName={nameToProduct(fields.name)}
                  key={`BellyBandProduct${index + 1}`}
                  testId={`BellyBandProduct${index + 1}`}
                  ribbonText={ribbonText}
                  themeKey={themeKey}
                />
              );
            },
          )}
        </ListContainer>
      </NavContainer>

      {cta && (
        <TryContainer backgroundColor={brand.light}>
          <TryBanner backgroundColor={brand.lightest} textAlign="center" borderRadius={4}>
            {support && <Markdown content={support} />}
            <TryLink
              children={cta.fields.text}
              href={cta.fields.link?.fields.url}
              onClick={() =>
                trackEvent({
                  event: 'Clicked Link',
                  properties: {
                    parentType: 'Component: Generic List',
                    parent: name,
                    unitName: cta.fields.name,
                    linkName: cta.fields.text,
                    linkTo: cta.fields.link?.fields.url,
                  },
                })
              }
            />
          </TryBanner>
        </TryContainer>
      )}
    </>
  );
};

export default BellyBand;

const NavContainer = styled.nav<{ backgroundColor: string; hasHeading: boolean }>`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }

  ${ieHideScroll}
`;

const StyledFixedContainer = styled(Container)`
  position: sticky;
  left: 0;
`;

const ListContainer = styled.ul<StyleProps>`
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: ${({ showTryBanner }) => (showTryBanner ? '30px' : '35px')};
  padding-left: ${CARD_LIST_SIDE_PADDING_MOBILE}px;
  padding-right: ${CARD_LIST_SIDE_PADDING_MOBILE}px;
  padding-top: 35px;
  width: ${({ cardCount }) => `${cardCount * 45}vw`};

  @media (min-width: 550px) {
    width: ${({ cardCount }) => `${cardCount * 30}vw`};
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
    justify-content: ${({ cardCount }) => (cardCount >= 5 ? 'flex-start' : 'center')};
  }

  ${media.tabletXLarge`
    max-width: ${MAXIMUM_BELLY_BAND_WIDTH}px;
  `}

  ${media.desktop<StyleProps>`
    padding-bottom: ${({ showTryBanner }) => (showTryBanner ? '30px' : '60px')};
    padding-left: ${CARD_LIST_SIDE_PADDING_DESKTOP}px;
    padding-right:${CARD_LIST_SIDE_PADDING_DESKTOP}px;
    padding-top: 60px;
  `}
`;

const TryContainer = styled(Container)`
  ${hb8};
  color: ${grey[70]};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 25px 32px 25px;

  ${media.tablet`
  ${hb6}
`}

  ${media.desktopLarge`
  padding: 0 40px 48px 40px;
`}
`;

const TryBanner = styled(Container)`
  box-shadow: 5px 3px 15px 0 ${rgba(black, 0.03)};
  padding: 20px 8px 18px 8px;
  width: 100%;

  ${media.tabletXLarge`
  max-width: calc(${MAXIMUM_BELLY_BAND_WIDTH}px - ${CARD_LIST_SIDE_PADDING_MOBILE}px * 2);
`}

  ${media.desktop`
  max-width: calc(${MAXIMUM_BELLY_BAND_WIDTH}px - ${CARD_LIST_SIDE_PADDING_DESKTOP}px * 2);
`}
`;

const TryLink = styled(Link)`
  padding-left: 5px;
  color: inherit;

  > span {
    border-bottom: 1px solid ${grey[70]};
    display: inline-block;
    letter-spacing: inherit;
  }

  &::after {
    display: none;
  }
`;
