import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { themes } from '@page-builder/themes';
import type { Themes } from '@page-builder/themes';
import Markdown from '@page-builder/utils/Markdown';

const HeadbandTitle: React.FC<
  React.PropsWithChildren<{ title?: string; theme?: Themes }>
> = ({ title, theme = 'Grey 90' }) => {
  if (!title) return null;
  const { headlineColor } = themes[theme];

  return (
    <StyledEyebrow textColor={headlineColor}>
      <Markdown content={title} />
    </StyledEyebrow>
  );
};

export default HeadbandTitle;

const StyledEyebrow = styled(Eyebrow)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
