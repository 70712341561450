import { Flex, Label, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { BuildTimeTileComponentProps } from '@ecomm/product-recommendations/models/BuildTimeTileComponentProps';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import useBuildTimeDataForSlug from '@ecomm/product-recommendations/utils/useBuildTimeDataForSlug';
import { BaseFinancingText } from '@ecomm/product-recommendations/Views/FinancingText';
import {
  ImageContainer,
  TileDescription,
  TileMedia,
  TileWrapper,
} from '@ecomm/product-recommendations/Views/Grid/SharedTileComponents';
import { StaticPriceDisplay } from '@ecomm/product-recommendations/Views/PriceDisplay';

const BuildTimeGridTileComponent: React.FC<BuildTimeTileComponentProps> = ({
  tile,
  themeName,
}) => {
  const { slug, overrideImageUrl, factoid, priceDisplay, financingText } = tile;

  const productData = useBuildTimeDataForSlug(slug);

  if (!productData) {
    return null;
  }

  const { imageUrl, name, basePrice } = productData;

  const { imageBackground } = toGridTheme(themeName);
  const productImageUrl = overrideImageUrl || imageUrl;

  return (
    <TileWrapper>
      <Flex flexDirection="column" gap={spacing[16]}>
        <ImageContainer
          background={imageBackground}
          borderRadius={spacing[4]}
          position="relative"
          flexDirection="column"
        >
          <TileMedia
            width="100%"
            height="100%"
            objectFit="contain"
            media={{
              mobile: toCloudinarySrc({ src: productImageUrl, height: 600 }),
              alt: name,
              type: 'image',
              loading: 'lazy',
            }}
          />
        </ImageContainer>
        <Flex
          flexDirection="column"
          gap={spacing[8]}
          flexGrow={1}
          justifyContent="space-between"
        >
          <Label size="large">{name}</Label>
          <Flex flexDirection="column" gap={spacing[4]}>
            <Support size="large" data-test-id="grid-product-tile-price">
              <StaticPriceDisplay price={basePrice} priceDisplay={priceDisplay} />
            </Support>
            {factoid && <TileDescription>{factoid}</TileDescription>}
            <BaseFinancingText>{financingText}</BaseFinancingText>
          </Flex>
        </Flex>
      </Flex>
    </TileWrapper>
  );
};

export default BuildTimeGridTileComponent;
