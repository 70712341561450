import { ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { useTrackWithDriftAndSegment } from '@peloton/drift/utils';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import {
  useOpenCartPanel,
  useSetHasItemJustBeenAdded,
} from '@ecomm/cart-next/context/CartContext';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import type { CreateCartItemsBody } from '@ecomm/cart/api/createCartItems';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import { getAddToCartErrorCode } from '@ecomm/cart/models';
import { getAccessoryProductOptions } from '@ecomm/commercetools/utils/getAccessoryBundleInfo';
import type { ProductSelection } from '../models';
import type { AddToCartItem } from './addToCartUtils';
import { createAddToCartEvent, getLegacyItemId } from './addToCartUtils';

export type AtcResult = {
  loading: boolean;
  data?: any;
  error?: any;
};

type UseAddProductToCartMonolithHook = {
  addToCart: (
    selectedItem: AddToCartItem,
    productSelections?: ProductSelection[],
  ) => Promise<void>;
  atcResult: AtcResult;
};

const getCartBody = (
  selectedItem: AddToCartItem,
  productSelections?: ProductSelection[],
): CreateCartItemsBody => {
  const legacyItemId = getLegacyItemId(selectedItem);

  if ('legacyOptionId' in selectedItem) {
    return {
      products: [
        {
          productOptionId: legacyItemId,
          quantity: 1,
        },
      ],
    };
  }

  if (!productSelections) {
    throw new ApolloError({ errorMessage: 'Product selection not provided' });
  }

  const productOptions = getAccessoryProductOptions(selectedItem, productSelections);

  return {
    bundles: [
      {
        bundleId: legacyItemId,
        productOptions,
      },
    ],
  };
};

export const useAddProductToCartMonolith = (): UseAddProductToCartMonolithHook => {
  const restClient = useClient();
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const { track } = useTrackWithDriftAndSegment();
  const { data: currentCart } = useCartWithVariables();
  const [atcResult, setAtcResult] = useState<AtcResult>({ loading: false });

  const openCartPanel = useOpenCartPanel();
  const setHasItemJustBeenAddedToCart = useSetHasItemJustBeenAdded();

  const addToCart = useCallback(
    async (selectedItem: AddToCartItem, productSelections?: ProductSelection[]) => {
      setAtcResult({
        ...atcResult,
        loading: true,
        error: undefined,
      });

      try {
        const nextCart = await addItemsToCart(
          restClient,
          getCartBody(selectedItem, productSelections),
        );

        setAtcResult({
          ...atcResult,
          loading: false,
          data: nextCart,
        });
        if (!nextCart) return;

        const numberOfItems = nextCart.items.reduce(
          (sum, item) => sum + item.quantity,
          0,
        );

        if (
          currentCart?.cart?.total.amount === nextCart.total ||
          currentCart?.cart?.numberOfItems === numberOfItems
        ) {
          throw new ApolloError({ errorMessage: 'Unknown cart error' });
        }

        track(createAddToCartEvent(selectedItem, nextCart));
        setHasItemJustBeenAddedToCart(true);
        openCartPanel();
      } catch (e) {
        const errorCode = e.networkError?.response?.data?.errorCode;
        const errorMsg = getAddToCartErrorCode(errorCode);
        console.log(e, errorMsg, errorCode);
        setAtcResult({
          ...atcResult,
          loading: false,
          error: new ApolloError({ errorMessage: errorMsg }),
        });
        reportError(e, { errorMsg });
        throw e;
      }
    },
    [currentCart, atcResult],
  );

  return { addToCart, atcResult };
};
