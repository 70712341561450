import React from 'react';
import styled from 'styled-components';
import type { InputComponent } from '@peloton/forms';
import { styleInput } from '@peloton/forms';
import { Chevron } from '@ecomm/icons';
import type { Props as SelectInputProps } from './SelectWithInnerLabel';
import SelectInputWithInnerLabel from './SelectWithInnerLabel';
import { selectStyles, selectContainerStyles, selectArrowStyles } from './styles';

const BaseSelect = styleInput(SelectInputWithInnerLabel)(
  selectStyles,
) as InputComponent<SelectInputProps>;

const Container = styled.div`
  ${selectContainerStyles}
`;

const DropDownArrow = styled.div`
  ${selectArrowStyles}
`;

const Select: React.FC<React.PropsWithChildren<SelectInputProps>> = ({
  className,
  ...props
}) => (
  <Container className={className}>
    <BaseSelect {...props} />
    <DropDownArrow>
      <Chevron width="12px" height="7px" role="presentation" />
    </DropDownArrow>
  </Container>
);

export default Select;
