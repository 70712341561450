import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useNextPath from '@content/client/hooks/useNextPath';
import type {
  TypeComponent_overviewFields,
  TypeComponentJson,
} from '@page-builder/lib/types';
import type { ShopDrawer } from '@page-builder/modules/Overview/DrawerSelectionContext';
import { DrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import {
  setProductInterestParamForDrawer,
  toReorderedDynamicDrawers,
  useDynamicDrawers,
  useIsRentDrawerDisabled,
} from '@page-builder/modules/Overview/ShopDrawers/utils';
import {
  getDefaultIndex,
  useToggledComponents,
} from '@page-builder/modules/Overview/utils';

export const ShopDrawersContextProvider: React.FC<
  React.PropsWithChildren<Pick<TypeComponent_overviewFields, 'shopDrawers' | 'product'>>
> = ({ shopDrawers, product, children }) => {
  const pathname = useNextPath();
  const availableShopDrawers = useToggledComponents(shopDrawers) as
    | TypeComponent_overviewFields['shopDrawers']
    | undefined;

  const rentDrawerDisabled = useIsRentDrawerDisabled(product);

  // We can't include the query params in the initial state because we end up with hydration errors, as the server rendered
  // page doesn't actually have the query params, so the first render could return a different index.
  const defaultIndexWithoutQueryParams = getDefaultIndex({
    drawers: availableShopDrawers,
    pathname,
    includeQueryParams: false,
    rentDrawerDisabled,
  });

  const {
    hasDynamicDrawers,
    dynamicDrawersExpanded,
    expandDynamicDrawers,
    visibleDynamicDrawersCount,
  } = useDynamicDrawers(pathname);

  const [activeShopDrawer, setActiveDrawerState] = useState<ShopDrawer | undefined>(
    availableShopDrawers?.[defaultIndexWithoutQueryParams],
  );
  const [defaultDrawerIndex, setDefaultDrawerIndexState] = useState<number>(
    defaultIndexWithoutQueryParams,
  );
  const [activeDrawerHeadband, setActiveDrawerHeadband] = useState<
    TypeComponentJson | undefined
  >(activeShopDrawer?.fields.modules?.[0]);

  const setActiveShopDrawer = useCallback(
    (newActiveDrawer: ShopDrawer) => {
      if (!hasDynamicDrawers) {
        setProductInterestParamForDrawer(newActiveDrawer);
      }

      setActiveDrawerState(newActiveDrawer);
      setActiveDrawerHeadband(newActiveDrawer.fields.modules?.[0]);
    },
    [hasDynamicDrawers],
  );

  // Whenever available shop drawers change (such as preview copy or optimizely bucketing), sync with state
  useEffect(() => {
    // Since this effect fires on the client after the initial render pass, we can include the query params to
    // switch to the drawer with the product interest tag if it exists.
    // Since the pages with dynamic drawers should not support the product interest query param, we only include the
    // query params in the default index calculation if the page does not have dynamic drawers
    const defaultIndexWithQueryParams = getDefaultIndex({
      drawers: availableShopDrawers,
      pathname,
      includeQueryParams: !hasDynamicDrawers,
      rentDrawerDisabled,
    });
    setActiveDrawerState(availableShopDrawers?.[defaultIndexWithQueryParams]);
    setActiveDrawerHeadband(
      availableShopDrawers?.[defaultIndexWithQueryParams].fields.modules?.[0],
    );
    setDefaultDrawerIndexState(defaultIndexWithQueryParams);
  }, [availableShopDrawers, hasDynamicDrawers, pathname, rentDrawerDisabled]);

  const visibleShopDrawers = useMemo(() => {
    if (!availableShopDrawers) {
      return undefined;
    }

    if (!hasDynamicDrawers) {
      return availableShopDrawers;
    }

    return toReorderedDynamicDrawers(
      availableShopDrawers,
      defaultDrawerIndex,
      dynamicDrawersExpanded,
      visibleDynamicDrawersCount,
    );
  }, [
    availableShopDrawers,
    defaultDrawerIndex,
    dynamicDrawersExpanded,
    hasDynamicDrawers,
    visibleDynamicDrawersCount,
  ]);

  const value = useMemo(
    () => ({
      visibleShopDrawers,
      availableShopDrawers,
      activeShopDrawer,
      setActiveShopDrawer,
      hasDynamicDrawers,
      dynamicDrawersExpanded,
      expandDynamicDrawers,
      activeDrawerHeadband,
      setActiveDrawerHeadband,
    }),
    [
      visibleShopDrawers,
      availableShopDrawers,
      activeShopDrawer,
      setActiveShopDrawer,
      hasDynamicDrawers,
      dynamicDrawersExpanded,
      expandDynamicDrawers,
      activeDrawerHeadband,
      setActiveDrawerHeadband,
    ],
  );

  return (
    <DrawerSelectionContext.Provider value={value}>
      {children}
    </DrawerSelectionContext.Provider>
  );
};
