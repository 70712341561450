import {
  grey,
  Body,
  spacing,
  Support,
  Flex,
  Eyebrow,
  Container,
} from '@pelotoncycle/design-system';
import React, { useRef, useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { BreakpointWidth } from '@peloton/styles';
import { MovementCard } from './MovementCard';
import { useTrackingUtils } from './trackingUtils';
import type {
  ModalVideoCardProps as ModalVideoCard,
  LibraryVideoCardProps as LibraryVideoCard,
  CtaProps as Cta,
} from './types';
import { VideoPlayerModal } from './VideoPlayerModal';

export type MovementLibraryProps = {
  headline: string;
  subtitle: string;
  modalHeadline: string;
  libVideos: LibraryVideoCard[];
  modalVideos: ModalVideoCard[];
  cta?: Cta;
  isOnCDP?: boolean;
};

const MovementLibrary = ({
  headline,
  subtitle,
  modalHeadline,
  libVideos,
  modalVideos,
  cta,
  isOnCDP = false,
}: MovementLibraryProps) => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const observerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [videoIndexActive, setVideoIndexActive] = useState({
    index: 0,
    showPause: true,
  });
  const [hasModalOpened, setHasModalOpened] = useState(false);

  const { handleClickedVideoTracking, handleModalCloseTracking } = useTrackingUtils();
  const isMobile = useMedia(`(max-width: ${BreakpointWidth.tablet}px)`);
  const isTablet = useMedia(`(max-width: ${BreakpointWidth.desktop}px)`);
  useEffect(() => {
    if (isMobile) {
      const observer = createMobileIntersectionObserver(videoRefs, observerRefs);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [isMobile, isModalOpen]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setHasModalOpened(true);
    setVideoIndexActive({ index: videoIndexActive.index, showPause: true });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleModalCloseTracking();
  };

  const handleVideoPlay = (index: number) => {
    videoRefs.current.forEach((videoRef, i) => {
      if (videoRef) {
        if (i === index) {
          videoRef.play();
          setVideoIndexActive({ index: i, showPause: true });
        } else {
          videoRef.pause();
          videoRef.currentTime = 0;
        }
      }
    });
  };

  const handleVideoPause = (index: number) => {
    videoRefs.current[index]?.pause();
    setVideoIndexActive({ index, showPause: false });
  };

  const resetVideoRefs = () => {
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.pause();
        videoRef.currentTime = 0;
      }
    });
  };

  const onClick = (index: number) => {
    handleClickedVideoTracking(libVideos[index].movementName);
    setSelectedVideoIndex(index);
    handleModalOpen();
    resetVideoRefs();
  };

  const handleMouseEnter = (index: number) => {
    handleVideoPlay(index);
    setHasModalOpened(false);
  };

  return (
    <>
      <VideoPlayerModal
        isOpen={isModalOpen}
        onAfterOpen={handleModalOpen}
        closeHandler={handleModalClose}
        selectedVideoIndex={selectedVideoIndex}
        setSelectedVideoIndex={setSelectedVideoIndex}
        headline={headline}
        subtitle={subtitle}
        modalTitle={modalHeadline}
        modalVideos={modalVideos}
        cta={cta}
      />
      <Flex
        backgroundColor={isOnCDP ? grey[80] : grey[90]}
        padding={{
          mobile: `${isOnCDP ? spacing[24] : spacing[32]} ${spacing[0]} ${spacing[24]} `,
          tablet: `${spacing[32]} ${spacing[32]} ${isOnCDP ? spacing[32] : spacing[24]} `,
          desktop: `${spacing[40]} auto`,
        }}
        flexDirection="column"
        alignItems={{ mobile: 'flex-start', tablet: 'center' }}
        justifyContent="center"
        width="100%"
      >
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          maxWidth={isMobile || isTablet ? '100%' : '1220px'}
        >
          <Container
            padding={{
              mobile: `0 ${spacing[24]}`,
              tablet: '0',
              desktop: '0',
            }}
          >
            {isOnCDP ? (
              <Eyebrow size="small" textColor={grey[50]} marginBottom={spacing[8]}>
                {headline}
              </Eyebrow>
            ) : (
              <Body size="medium" textColor="white">
                {headline}
              </Body>
            )}
            <Support size="medium" textColor="white" marginBottom={spacing[24]}>
              {subtitle}
            </Support>
          </Container>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent={{
              mobile: 'flex-start',
              tablet: 'center',
            }}
            gap={{
              mobile: spacing[12],
              tablet: spacing[12],
              desktop: isOnCDP ? spacing[12] : spacing[24],
            }}
            maxWidth={'100%'}
            style={{
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollBehavior: 'smooth',
              WebkitOverflowScrolling: 'touch',
              scrollbarWidth: 'none',
              paddingLeft: isMobile ? spacing[24] : 0,
              paddingRight: isMobile ? spacing[24] : 0,
            }}
          >
            {libVideos.map((videoCard, index) => (
              <div
                key={index}
                ref={el => {
                  observerRefs.current[index] = el;
                }}
              >
                <MovementCard
                  index={index}
                  videoCard={videoCard}
                  videoRef={el => (videoRefs.current[index] = el)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onClick={() => onClick(index)}
                  shouldAutoPlay={!isMobile && index === 0}
                  handleVideoPause={handleVideoPause}
                  videoIndexActive={videoIndexActive}
                  hasModalOpened={hasModalOpened}
                  isOnCDP={isOnCDP}
                />
              </div>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const createMobileIntersectionObserver = (
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>,
  observerRefs: React.MutableRefObject<(HTMLDivElement | null)[]>,
) => {
  let isSecondVisible = false;
  let isThirdVisible = false;

  const playVideos = (playIndices: number[]) => {
    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        if (playIndices.includes(index)) {
          videoRef.play();
        } else {
          videoRef.pause();
        }
      }
    });
  };

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      const index = observerRefs.current.indexOf(entry.target as HTMLDivElement);
      if (index === 1) {
        isSecondVisible = entry.isIntersecting;
      }
      if (index === 2) {
        isThirdVisible = entry.isIntersecting;
      }
    });

    if (isThirdVisible && !isSecondVisible) {
      playVideos([2, 3]);
    } else if (isSecondVisible && !isThirdVisible) {
      playVideos([0, 1]);
    } else if (!isSecondVisible && !isThirdVisible) {
      playVideos([0, 1]);
    } else {
      playVideos([1, 2]);
    }
  };

  const observer = new IntersectionObserver(observerCallback, {
    threshold: 0.75,
  });

  observerRefs.current.forEach(ref => {
    if (ref) {
      observer.observe(ref);
    }
  });

  return observer;
};

export default MovementLibrary;
