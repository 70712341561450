import { grey, white } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';

type Theme = {
  primaryTextColor: string;
  secondaryTextColor: string;
  backgroundColor: string;
  arrowColor: string;
  arrowBorderColor: string;
  categoryBackgroundColor: string;
  categoryBorderColor: string;
  categoryHoverColor: string;
  iconColor: string;
  classOptionHoverColor: string;
  loadingColor0?: string;
  loadingColor100?: string;
};

type DisciplineTilesWithModalTheme = { [name: string]: Theme };

const defaultTheme: Theme = {
  primaryTextColor: white,
  secondaryTextColor: grey[40],
  backgroundColor: grey[90],
  arrowColor: white,
  arrowBorderColor: grey[70],
  categoryBackgroundColor: 'rgba(105, 113, 128, 0.25)',
  categoryBorderColor: white,
  categoryHoverColor: transparentize(0.5, grey[70]),
  iconColor: white,
  classOptionHoverColor: transparentize(0.5, grey[70]),
  loadingColor0: grey[75],
  loadingColor100: grey[70],
};

export const THEMES: DisciplineTilesWithModalTheme = {
  'Grey 90': {
    ...defaultTheme,
  },
  White: {
    backgroundColor: white,
    primaryTextColor: grey[90],
    secondaryTextColor: grey[70],
    arrowColor: grey[50],
    arrowBorderColor: grey[50],
    categoryBackgroundColor: grey[30],
    categoryBorderColor: grey[90],
    categoryHoverColor: grey[40],
    iconColor: grey[90],
    classOptionHoverColor: grey[30],
    loadingColor0: grey[45],
    loadingColor100: grey[40],
  },
};

export const themes: DisciplineTilesWithModalTheme = {
  White: { ...THEMES.White },
  'Grey 90': { ...THEMES['Grey 90'] },
};
