import {
  Container,
  Eyebrow,
  Headline,
  Body,
  Flex,
  Media,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import { useNearestShowroom } from '@ecomm/geolocation/hooks/useNearestShowroomNext';
import Map from '@ecomm/geolocation/Map';
import { googleMapsCommonProps } from '@ecomm/google-maps';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toImageProps } from '@page-builder/utils/helpers/media';
import type { MarkdownChildrenType } from '@page-builder/utils/Markdown';
import Markdown, { toMarkdownChildrenString } from '@page-builder/utils/Markdown';

const toBooleanParam = (value: boolean) => (value ? 'yes' : 'no');

const toBookingLink = (location: string = '') => {
  const queryString = `/?location=${location.replace(/ /g, '-').toLowerCase()}`;
  return queryString;
};

const NearestShowroom: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ text, ctas, media: image }) => {
  const showroom = useNearestShowroom();
  const imageProps = toImageProps(image);
  const ctaProps = ctas && ctaExtractData(ctas[0]);
  const { eyebrow, headline, body } = getTextFieldsWithRequiredKeys(
    ['eyebrow', 'headline', 'body'],
    text,
  );
  const { trackEvent } = useTracking();

  if (!imageProps || !ctaProps) {
    throw new Error('Missing media or cta');
  }

  const ctaLink =
    ctaProps.url && showroom
      ? ctaProps.url.concat(toBookingLink(showroom.name))
      : ctaProps.url;

  return (
    showroom && (
      <Flex
        flexDirection={{ mobile: 'column-reverse', desktop: 'row' }}
        justifyContent={{ desktop: 'center' }}
        data-test-id="ecomm-geolocation-nearest-showroom"
      >
        <Flex
          justifyContent={{ desktop: 'center' }}
          style={{ flexBasis: 0 }}
          margin={{ desktop: '0px 135px 0px 0px' }}
        >
          <TextWrapper>
            <Eyebrow size="small" as="h2" style={{ marginBottom: spacing[16] }}>
              {eyebrow}
            </Eyebrow>
            <Headline size="small" as="h3" style={{ marginBottom: spacing[16] }}>
              <Markdown
                content={headline}
                values={{ showroomName: showroom.displayName }}
              />
            </Headline>
            <Body size="small">
              <Markdown
                content={body}
                values={{
                  showroomGoogleMapsUrl: showroom.googleMapsUrl,
                  apparelAvailable: toBooleanParam(!showroom.isMicrostore),
                }}
                markdown={{
                  renderers: {
                    link: TrackingLink,
                  },
                }}
              />
            </Body>
            <LinkButton
              data-test-id="showroom-tracking-link"
              text={ctaProps.text}
              href={ctaLink}
              color={ctaProps.color || 'primary'}
              variant={ctaProps.variant || 'solid'}
              target="_blank"
              width="adaptive"
              size="small"
              onClick={() =>
                trackEvent({
                  event: 'Clicked Link',
                  properties: {
                    linkTo: ctaLink,
                    linkName: ctaProps.text,
                    // to-do: discuss potentially updating unit names w/ PAs to use entry key name
                    unitName: 'geolocation.nearestShowroomModule.testRideCta',
                    parent: 'NearestShowroom',
                    parentType: 'nearestShowroomModule',
                  },
                })
              }
            />
          </TextWrapper>
        </Flex>
        <Flex justifyContent={{ desktop: 'center' }} flexBasis={0}>
          <MapWithImageWrapper>
            <Map
              {...googleMapsCommonProps}
              containerElement={<MapContainerElement />}
              mapElement={<div style={{ height: `100%` }} />}
              {...showroom}
            />
            <Container
              className="image-container"
              width={{
                mobile: 174,
                tablet: 248,
                desktop: 234,
              }}
            >
              <Media
                data-test-id="storefront-image"
                media={{
                  alt: 'Storefront Image',
                  mobile: imageProps.mobile,
                  loading: 'lazy',
                  type: 'image',
                }}
                width="100%"
              />
            </Container>
          </MapWithImageWrapper>
        </Flex>
      </Flex>
    )
  );
};

export default NearestShowroom;

type LinkRendererProps = {
  children: MarkdownChildrenType;
  href: string;
};

const TrackingLink: React.FC<React.PropsWithChildren<LinkRendererProps>> = ({
  children,
  href,
}) => {
  const { trackEvent } = useTracking();
  return (
    <StyledLink
      data-test-id="showroom-map-link"
      href={href}
      target="_blank"
      variant="body"
      size="large"
      onClick={() =>
        trackEvent({
          event: 'Clicked Link',
          properties: {
            linkTo: href,
            linkName: toMarkdownChildrenString(children),
            // to-do: discuss potentially updating unit names w/ PAs to use entry key name
            unitName: 'geolocation.nearestShowroom',
            parent: 'NearestShowroom',
            parentType: 'nearestShowroomModule',
          },
        })
      }
    >
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  &::after {
    display: none;
  }
  color: ${grey[70]};
`;
const MapContainerElement = styled.div`
  height: 500px;

  ${media.desktopLarge`
    width: 500px;
  `}

  ${media.desktopXLarge`
    height: 608px;
    width: 608px;
  `}
`;

const TEXT_WRAPPER_WIDTH_TABLET = 512;
const TEXT_WRAPPER_SIDE_PADDING_MOBILE = 24;
const TextWrapper = styled.div`
  padding: 140px ${TEXT_WRAPPER_SIDE_PADDING_MOBILE}px 48px;

  ${media.tablet`
    margin: 0 auto;

    width: ${TEXT_WRAPPER_WIDTH_TABLET}px;
    padding: 194px 0 80px;
    box-sizing: content-box;
  `}

  ${media.desktop`
    margin: unset;
    padding: 0px 24px;
    width: 287px;
    align-self: center;
  `}

  ul {
    list-style: disc;
    margin-bottom: 24px;
    color: ${grey[70]};
  }

  li {
    margin-left: 24px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    a {
      text-decoration: underline;
    }
  }
`;

const MapWithImageWrapper = styled.div`
  flex: 1;

  ${media.desktopLarge`
    margin: 120px 0px 232px 0px;
    min-height: 512px;
    max-width: 512px;
  `}

  ${media.desktopXLarge`
    max-width: unset;
  `}

  position: relative;

  > .image-container {
    width: 174px;
    position: absolute;
    left: ${TEXT_WRAPPER_SIDE_PADDING_MOBILE}px;
    bottom: 0;

    border-radius: 4px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));

    transform: translate3d(0, 50%, 0);

    ${media.tablet`
      width: 248px;
      left: calc((100vw - ${TEXT_WRAPPER_WIDTH_TABLET}px) / 2);
    `}

    /* don't show when map and content is displayed inline on smaller desktop screens */
    ${media.desktop`
      display: none;
    `}

    ${media.desktopLarge`
      display: block;
      width: 210px;
      left: -40px;
    `}

    ${media.desktopXLarge`
      width: 234px;
      left: -48px;
    `}
  }
`;
