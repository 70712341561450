import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import type { CSSProperties } from 'styled-components';
import type { BaseModalProps } from '@ecomm/modal/BaseModal';
import getRootElement from '@ecomm/modal/getRootElement';
import ModalGlobalStyles from '@ecomm/modal/ModalGlobalStyles';
import CloseButton from '@ecomm/modal/shared/CloseButton';
import AdditionalModalStyles from './ModalGlobalStyles';

export type NextModalProps = BaseModalProps & {
  id?: string;
  className?: string;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  darkMode?: boolean;
  role?: string;
  closeTimeoutMS?: number;
  style?: CSSProperties;
  defaultCloseButton?: boolean;
  shouldApplySavedScrollPosition?: boolean;
};

const NextModal: React.FC<React.PropsWithChildren<NextModalProps>> = ({
  isOpen = true,
  contentLabel,
  closeHandler,
  openHandler,
  className = '',
  onAfterOpen = () => {},
  onRequestClose = () => {},
  darkMode,
  role = 'dialog',
  closeTimeoutMS = 400,
  style,
  defaultCloseButton = true,
  children,
  id,
  shouldApplySavedScrollPosition = true,
  ...props
}) => {
  const [savedScrollPosition, setSavedScrollPosition] = useState<number | undefined>();

  const applySavedScrollPosition = useCallback(() => {
    requestAnimationFrame(() => window.scrollTo({ top: savedScrollPosition }));
    document.body.style.width = '';
    document.body.style.position = '';
    document.body.style.top = '';
  }, [savedScrollPosition]);

  const handleAfterOpen = () => {
    // Apply the saved scroll position to the #root element when the modal is opened
    const rootElement = getRootElement();
    if (rootElement) {
      setSavedScrollPosition(rootElement.scrollTop);
    }

    // Run any custom functions (e.g. video event listeners for video modal)
    onAfterOpen();

    if (openHandler) openHandler();
  };

  const handleRequestClose = () => {
    // Run any custom functions (e.g. video event listeners for video modal)
    onRequestClose();

    if (shouldApplySavedScrollPosition) {
      applySavedScrollPosition();
    }

    if (closeHandler) closeHandler();
  };
  if (isOpen) {
    // http://reactcommunity.org/react-modal/accessibility/#app-element
    const root = getRootElement();
    ReactModal.setAppElement(root as HTMLElement);
  }

  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      role={role}
      aria-modal="true"
      contentLabel={contentLabel}
      className={className}
      closeTimeoutMS={closeTimeoutMS}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      style={{ content: style }}
    >
      <ModalGlobalStyles darkMode={darkMode} />
      <AdditionalModalStyles />
      {defaultCloseButton && (
        <CloseButton
          contentLabel={contentLabel}
          onClick={handleRequestClose}
          darkMode={darkMode}
          {...props}
        />
      )}
      {children}
    </ReactModal>
  );
};

export default NextModal;
