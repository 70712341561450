export enum State {
  Error,
  Loading,
  CanBeBuildTimeRendered,
  ProductDataLoaded,
}

const RENDERABLE_STATES = [State.CanBeBuildTimeRendered, State.ProductDataLoaded];

export const canModuleBeRendered = (state: State): boolean => {
  return RENDERABLE_STATES.includes(state);
};
