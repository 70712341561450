import type { FC, PropsWithChildren } from 'react';
import type { VariationReferenceType } from '../types/referenceTypes';

export type Props = {
  test: VariationReferenceType<'componentJson', ['componentJson', 'componentJson']>;
};

const TestComponent: FC<PropsWithChildren<Props>> = () => null;

export default TestComponent;
