import {
  Container,
  Flex,
  grey,
  OverlaidChildren,
  spacing,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth, media as mediaQuery } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ModuleComponentProps } from '@ecomm/product-recommendations/models/ModuleComponentProps';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import { OBSERVER_HANDLER_DELAY } from '@page-builder/modules/VideoCarousel/constants';
import { getScrollToLeftPosition } from '@page-builder/modules/VideoCarousel/utils';

export const createObserver = (
  scrollContainerRef: React.MutableRefObject<HTMLUListElement | null>,
  cardElement: HTMLLIElement | null,
  handleActiveCardOnScroll: (entries: IntersectionObserverEntry[]) => void,
) => {
  if (!scrollContainerRef.current || !cardElement) {
    return null;
  }
  const itemWidth = cardElement.offsetWidth;
  const containerWidth = scrollContainerRef.current.offsetWidth;
  const totalMargin = containerWidth - itemWidth;

  const observerOptions: IntersectionObserverInit = {
    root: scrollContainerRef.current,
    threshold: 0.51,
    rootMargin: `0px -${totalMargin / 2}px 0px -${totalMargin / 2}px`,
  };

  return new IntersectionObserver(handleActiveCardOnScroll, observerOptions);
};

const NestedHeroModuleComponent: React.FC<
  React.PropsWithChildren<ModuleComponentProps>
> = ({ children, themeName }) => {
  const { backgroundColor, textColor } = toGridTheme(themeName);
  const { trackEvent } = useTracking();
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktop}px)`);

  const [isInitialPagination, setIsInitialPagination] = useState<boolean>(true);

  const [inViewRef, isModuleInView] = useInView({
    triggerOnce: true,
  });

  // Element Refs that help determine the scroll position of the carousel items.
  const scrollContainerRef = useRef<HTMLUListElement | null>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const childrenArray = useMemo(() => {
    return React.Children.toArray(children) as React.ReactElement[];
  }, [children]);
  const totalNumberOfItems = childrenArray.length;
  const childrenWithRefs = useMemo(() => {
    return childrenArray.map((child, index) => {
      return (
        <ProductTileWrapper
          key={child.key}
          ref={(el: HTMLLIElement | null) => {
            itemRefs.current[index] = el;
          }}
        >
          {child}
        </ProductTileWrapper>
      );
    });
  }, [childrenArray]);

  // Used for Intersection Observer when a user scrolls an inactive card into view.
  const timeoutIdsRef = useRef<Record<number, ReturnType<typeof setTimeout>>>({});
  const observerRefs = useRef<(IntersectionObserver | null)[]>([]);

  const sendTrackingEvent = useCallback(
    (initialPagination: boolean) => {
      trackEvent({
        event: TrackingEvent.ClickedCarouselSlide,
        properties: {
          parent: 'Nested Product Grid',
          parentType: 'Component: JSON',
          unitName: `Nested Product Grid: ${
            initialPagination ? 'First' : 'Second'
          } Pagination Dot`,
        },
      });
    },
    [trackEvent],
  );

  /**
   * Handles setting the inactive carousel section based on the scroll position.
   */
  const handleActiveSectionOnScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]; // Only one entry is expected.
      if (!entry) {
        return;
      }

      const isElementWithinRootBounds = entry.isIntersecting;
      const cardIndex = itemRefs.current.indexOf(entry.target as HTMLLIElement);
      const isCardInInitialPagination = cardIndex < totalNumberOfItems / 2;

      if (
        isElementWithinRootBounds &&
        isCardInInitialPagination !== isInitialPagination
      ) {
        timeoutIdsRef.current[cardIndex] = setTimeout(() => {
          setIsInitialPagination(isCardInInitialPagination);
          sendTrackingEvent(isCardInInitialPagination);
        }, OBSERVER_HANDLER_DELAY);
      } else {
        clearTimeout(timeoutIdsRef.current[cardIndex]);
      }
    },
    [isInitialPagination, sendTrackingEvent, totalNumberOfItems],
  );

  useEffect(() => {
    // Disconnect all current observers
    observerRefs.current.forEach(observer => observer?.disconnect());
    const observers = observerRefs.current;

    if (isModuleInView) {
      for (const [cardIndex, cardElement] of itemRefs.current.entries()) {
        if (scrollContainerRef.current && cardElement) {
          const newObserver = createObserver(
            scrollContainerRef,
            cardElement,
            handleActiveSectionOnScroll,
          );

          observers[cardIndex] = newObserver;
          observers[cardIndex]?.observe(cardElement);
        }
      }
    }

    return () => {
      observers.forEach(observer => observer?.disconnect());
    };
  }, [isModuleInView, handleActiveSectionOnScroll, totalNumberOfItems]);

  const scrollToActiveSection = (cardIndex: number) => {
    const scrollContainer = scrollContainerRef.current;
    const activeItem = itemRefs.current[cardIndex];
    if (activeItem && scrollContainer) {
      scrollContainer.scrollTo({
        left: getScrollToLeftPosition(
          activeItem,
          scrollContainer.offsetWidth,
          cardIndex,
          scrollContainer.children.length,
        ),
      });
    }
  };

  const handlePaginationClick = (index: 0 | 1) => {
    scrollToActiveSection(index === 0 ? 0 : totalNumberOfItems - 1);
  };

  return (
    <StyledContainer ref={inViewRef}>
      <ScrollContainer>
        <ProductGridTilesContainer
          data-test-id="nested-hero-module"
          ref={scrollContainerRef}
        >
          {childrenWithRefs}
        </ProductGridTilesContainer>
      </ScrollContainer>
      {!isMobileOrTablet && (
        <FadedEdgesContainer
          width="100vw"
          minWidth="100%"
          justifyContent="space-between"
          data-test-id="nested-hero-faded-edges"
        >
          <FadedEdge gradientDirection="right" gradientBase={backgroundColor} />
          <FadedEdge gradientDirection="left" gradientBase={backgroundColor} />
        </FadedEdgesContainer>
      )}
      {totalNumberOfItems > 3 && !isMobileOrTablet && (
        <Flex
          style={{ gridRow: '2/2' }}
          verticalMargin={spacing[16]}
          justifyContent="center"
          gap={spacing[8]}
        >
          <PaginationDot
            data-test-id="nested-hero-pagination-dot"
            isActive={isInitialPagination}
            onClick={() => handlePaginationClick(0)}
            activeColor={textColor}
          />
          <PaginationDot
            data-test-id="nested-hero-pagination-dot"
            isActive={!isInitialPagination}
            onClick={() => handlePaginationClick(1)}
            activeColor={textColor}
          />
        </Flex>
      )}
    </StyledContainer>
  );
};

export default NestedHeroModuleComponent;

const StyledContainer = styled(OverlaidChildren)`
  grid-template-rows: auto;
  overflow: hidden;
  ${mediaQuery.desktopLarge`
    width: 100vw;
    margin-inline: -${spacing[40]};
  `};
`;

const scrollDefinitions = `
overflow-x: scroll;
  width: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  // to hide the scrollbar
  box-sizing: content-box;

  // hide scrollbar on safari
  ::-webkit-scrollbar {
    display: none;
  }
`;

// Adding this was necessary to enable scrolling on mobile devices
// (only reproducable on actual devices, not in devtools)
// TODO: find a better solution
const ScrollContainer = styled(Container)`
  ${scrollDefinitions}
`;

const ProductGridTilesContainer = styled.ul`
  display: flex;
  flex-direction: row;
  height: ${spacing[88]};
  min-width: ${spacing[120]};
  position: relative;
  list-style-type: none;
  gap: ${spacing[12]};
  ${mediaQuery.desktopLarge`
    gap: ${spacing[16]};
  `}
  ${scrollDefinitions}

  li:first-child {
    padding-left: ${spacing[16]};
    ${mediaQuery.tabletXLarge`
      padding-left: ${spacing[64]};
    `}
    ${mediaQuery.desktopLarge`
      padding-left: ${spacing[40]};
    `}
  }

  li:last-child {
    padding-right: ${spacing[16]};
    ${mediaQuery.tabletXLarge`
      padding-right: ${spacing[64]};
    `}
    ${mediaQuery.desktopLarge`
      padding-right: ${spacing[40]};
    `}
  }
`;

const FadedEdgesContainer = styled(Flex)`
  div {
    width: ${spacing[40]};
    height: ${spacing[88]};
    opacity: 1;
  }
`;

const FadedEdge = styled(Container)<{
  gradientDirection: 'left' | 'right';
  gradientBase: string;
}>`
  background: linear-gradient(
    ${({ gradientDirection, gradientBase }) => `to ${gradientDirection},
        ${rgba(gradientBase, 1)} 5%,
        ${rgba(gradientBase, 0.7)} 50%,
        ${rgba(gradientBase, 0.3)} 75%,
        ${rgba(gradientBase, 0)} 100%
        `}
  );
`;

const ProductTileWrapper = styled.li`
  width: -webkit-fill-available;
`;

const PaginationDot = styled.button<{ isActive: boolean; activeColor: string }>`
  background-color: ${({ isActive, activeColor }) => (isActive ? activeColor : grey[70])};
  border-radius: 50%;
  height: ${spacing[8]};
  width: ${spacing[8]};
  border: none;
  cursor: pointer;
`;
