import {
  Flex,
  spacing,
  grey,
  Support,
  Icon,
  Label,
  Container,
  Eyebrow,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useBuilderQuery from '@content/client/hooks/useBuilderQuery';
import { useMicroCopy } from '@content/client/microCopy';
import ListRenderer from '@page-builder/layout/MarkdownPage/renderers/ListRenderer';
import Markdown from '@page-builder/utils/Markdown';
import DownloadLink from '../DownloadLink/DownloadLink';

const DOWNLOAD_TEXT_MIN_LEN = 1;

type InstructionsProps = {
  productName: string;
};

type Url = {
  fields: {
    url: string;
  };
};

type Cta = {
  fields: {
    link: Url;
    text: string;
  };
};

type BodyText = {
  fields: {
    body: string;
  };
};

type InstructionsData = {
  data?: {
    fields: {
      ctas: Cta[];
      text: BodyText;
      name: string;
    };
  };
};

const createDownloadText = (downloadText: string) => {
  if (downloadText.length <= DOWNLOAD_TEXT_MIN_LEN) {
    return '';
  }
  return downloadText;
};

// Create corresponding contentful entries with product name to dynamically pull links
const Instructions = ({ productName }: InstructionsProps) => {
  const requiredToolsHeader = useMicroCopy('requiredToolsHeader');
  const sparePartDeliveryMessage = useMicroCopy('sparePartsDelivery');

  const { data: instructionsData }: InstructionsData = useBuilderQuery({
    content_type: 'componentGenericTextWithMedia',
    'fields.name': productName,
  });
  const toolsList = instructionsData?.fields?.text?.fields?.body;
  const downloadLinks = instructionsData?.fields?.ctas;
  const dataProductName = instructionsData?.fields?.name;
  const doesNameMatchProp = productName === dataProductName; // Addresses intermittent incorrect netlify cache hits

  return (
    <Flex flexDirection="column" data-test-id="instructions">
      <Flex gap={spacing[12]}>
        <Icon height={24} name="delivery" />
        <Label size="large">{sparePartDeliveryMessage}</Label>
      </Flex>
      {downloadLinks && doesNameMatchProp && (
        <Container margin={`${spacing[12]} 0 0 0`}>
          {downloadLinks.map(cta => (
            <DownloadLink
              key={cta.fields?.link?.fields?.url}
              downloadLink={cta.fields?.link?.fields?.url}
              downloadText={createDownloadText(cta.fields?.text)}
            />
          ))}
        </Container>
      )}
      {toolsList && doesNameMatchProp && (
        <Flex
          backgroundColor={grey[30]}
          padding={spacing[24]}
          flexDirection="column"
          borderRadius={spacing[4]}
          margin={`${spacing[24]} 0 0 0`}
          textAlign="left"
        >
          <ToolsHeader size="medium">{requiredToolsHeader}</ToolsHeader>
          <Support size="medium" data-test-id="tools-list">
            <Markdown
              content={toolsList}
              markdown={{
                renderers: {
                  list: ListRenderer,
                },
              }}
            />
          </Support>
        </Flex>
      )}
    </Flex>
  );
};

export default Instructions;

const ToolsHeader = styled(Eyebrow)`
  padding-bottom: ${spacing[16]};
`;
