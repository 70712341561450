import {
  Flex,
  Stars,
  Eyebrow,
  Support,
  Label,
  Body,
  grey,
  spacing,
  Container,
  BreakpointWidths,
  Icon,
  ClampBox,
} from '@pelotoncycle/design-system';
import type { StarsProps } from '@pelotoncycle/design-system';
import React, { useRef } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';

export type ReviewCardProps = {
  rating?: number;
  title?: string;
  date?: string;
  heading?: string;
  moreLabel?: string;
  lessLabel?: string;
  reviewText?: string;
  verified?: boolean;
  textColor?: string;
  secondaryTextColor?: string;
  borderColor?: string;
  starsColor?: StarsProps['theme'];
};

const ReviewCard: React.FC<React.PropsWithChildren<ReviewCardProps>> = ({
  rating,
  title,
  date,
  heading,
  moreLabel = 'more',
  lessLabel = 'less',
  reviewText,
  textColor,
  borderColor,
  secondaryTextColor,
  starsColor,
  verified,
}) => {
  const { trackEvent } = useTracking();
  const seeMoreClicked = useRef(false);
  function handleClick() {
    seeMoreClicked.current = !seeMoreClicked.current;
    if (seeMoreClicked.current) {
      trackEvent({
        event: TrackingEvent.ClickedLink,
        properties: {
          parentType: 'Component: Reviews',
          parent: 'Component: ReviewCards',
          unitName: 'See more of review',
          linkName: moreLabel,
          linkTo: moreLabel,
        },
      });
    }
  }

  return (
    <StyledReviewCard borderColor={borderColor} flexDirection="column" gap={spacing[12]}>
      {!!rating && <Stars rating={rating} starSize={14} theme={starsColor} />}
      {!!title && (
        <Eyebrow size="large" textColor={textColor}>
          {title}
        </Eyebrow>
      )}
      {!!date && (
        <Support size="large" textColor={textColor}>
          {date}
        </Support>
      )}
      <Container verticalMargin={{ desktop: `${spacing[32]} -${spacing[4]}` }}>
        <Label size="extraLarge" textColor={textColor}>
          <Flex as="span" gap={spacing[8]} alignItems="center">
            {!!heading && heading}
            {!!verified && (
              <Icon name="checkCircleOutline" height={12} data-test-id="verified" />
            )}
          </Flex>
        </Label>
      </Container>
      {!!reviewText && (
        <StyledClampBox
          moreLabel={moreLabel}
          lessLabel={lessLabel}
          minClamp={5}
          maxClamp={7}
          textColor={textColor}
          onClick={handleClick}
        >
          <Body size="small" textColor={secondaryTextColor}>
            {reviewText}
          </Body>
        </StyledClampBox>
      )}
    </StyledReviewCard>
  );
};

export default ReviewCard;

const StyledReviewCard = styled(Flex)<{ borderColor?: string }>`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ borderColor }) => borderColor};
    padding-bottom: ${spacing[40]};
    @media (min-width: ${BreakpointWidths.desktop}px) {
      padding-bottom: 0;
      border: none;
    }
  }
`;

const StyledClampBox = styled(ClampBox)<{ textColor?: string }>`
  .clampBox__button {
    color: ${({ textColor }) => textColor};
    &:hover,
    &:focus {
      p {
        color: ${grey[70]};
      }
      &::after {
        border-color: ${grey[70]};
      }
    }
  }
`;
