import type { TypeComponentCta, TypeProduct } from '@pelotoncycle/page-builder';
import React from 'react';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { toDollars } from '@ecomm/models';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';
import ComponentJsonHandler from '../ComponentJsonHandler';
import { useDrawerSelectionContext } from './DrawerSelectionContext';
import { usePromoContext } from './PromoProvider';
import { useShopContext } from './ShopContext';
import { useHeadbandData } from './utils';

type OverviewHeadbandProps = {
  product: TypeProduct;
};

const OverviewHeadband: React.FC<OverviewHeadbandProps> = ({ product }) => {
  const { activeShopDrawer, activeDrawerHeadband } = useDrawerSelectionContext();
  const headbandData = useHeadbandData(product);

  const { bundlePromo } = usePromoContext();
  const { packageSlug } = useShopContext();

  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);
  const toFormattedText = useGetTextFormatter();

  if (!activeShopDrawer || !activeDrawerHeadband) {
    return null;
  }
  const cta = headbandData?.fields.cta as TypeComponentCta | undefined;

  const { basePrice } = buildTimeProductData;
  const couponDiscount = bundlePromo?.fields.couponDiscount;
  const totalPrice = basePrice - (couponDiscount || 0) * 100;

  const financingTextOverride = toFormattedText(
    activeDrawerHeadband.fields.payload.financingText,
    {
      price: toDollars(totalPrice),
    },
  );
  const disclaimerTextOverride = toFormattedText(
    activeDrawerHeadband.fields.payload.disclaimerText,
    {
      price: toDollars(totalPrice),
    },
  );

  const updatedPayload = {
    ...activeDrawerHeadband.fields.payload,
    addToCartCta: cta,
    financingText: financingTextOverride,
    disclaimerText: disclaimerTextOverride,
  };

  const updatedHeadband = {
    ...activeDrawerHeadband,
    fields: {
      ...activeDrawerHeadband.fields,
      payload: updatedPayload,
    },
  };

  return <ComponentJsonHandler {...updatedHeadband} />;
};

export default OverviewHeadband;
