import {
  Flex,
  Label,
  Icon,
  white,
  black,
  spacing,
  grey,
  ResponsiveImage,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { ImageReferenceType as ImageReference } from '../../../types/referenceTypes';

type MovementChipProps = {
  name: string;
  description: string;
  isActive: boolean;
  miniThumbnail?: ImageReference;
  onClick: () => void;
};

export const MovementChip = ({
  name,
  description,
  isActive,
  miniThumbnail,
  onClick,
}: MovementChipProps) => {
  return (
    <Flex
      height={spacing[72]}
      alignItems="center"
      style={{ width: '100%', cursor: isActive ? 'default' : 'pointer' }}
      backgroundColor={grey[75]}
      borderRadius={spacing[8]}
      border={`${spacing[1]} solid ${isActive ? grey[50] : 'transparent'}`}
      onClick={onClick}
      data-test-id="movement-chip"
    >
      {miniThumbnail && <ThumbnailImage {...miniThumbnail} />}
      <Flex
        flexDirection="column"
        gap={spacing[4]}
        padding={`${spacing[16]} 0`}
        justifyContent="center"
      >
        <Label textColor={white}>{name}</Label>
        <Label size="small" textColor={grey[40]}>
          {description}
        </Label>
      </Flex>
      {!isActive && (
        <IconContainer horizontalPadding={`0 ${spacing[16]}`}>
          <Icon name="play" primaryColor={white} secondaryColor={black} />
        </IconContainer>
      )}
    </Flex>
  );
};

const ThumbnailImage = styled(ResponsiveImage)`
  img {
    max-width: ${spacing[112]};
    display: block;
    aspect-ratio: 14/9;
    object-fit: cover;
    border-radius: ${spacing[8]} 0 0 ${spacing[8]};
    margin-right: ${spacing[16]};
  }
`;

const IconContainer = styled(Flex)`
  margin-left: auto;
`;
