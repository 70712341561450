import { useClient } from '@peloton/api/ClientContext';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import { useLocale } from '@peloton/internationalize';
import { toStoreSlug } from '@peloton/stores/Store';
import {
  useOpenCartPanel,
  useSetHasItemJustBeenAdded,
  useSetCartException,
} from '@ecomm/cart-next/context/CartContext';
import { getErrorCode as getAddToCartErrorCode } from '@ecomm/cart/models/AddToCartErrors';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo/toCommercetoolsClient';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { useLocalResolvers } from '@ecomm/graphql/useLocalResolvers';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import type {
  CfuCartModel,
  CfuCartAnalyticsModel,
} from '@ecomm/shop-configuration/models';
import { addToCartResolver } from '@ecomm/shop/graphql/addToCartResolver';
import { useAddBundleToCartAnalytics } from '@ecomm/shop/graphql/useAddBundleToCartAnalytics';
import { useAddPackageToCartMutation } from '@ecomm/shop/graphql/useAddPackageToCartMutation';
import type { Variables } from '@ecomm/shop/graphql/useAddPackageToCartMutation';
import useAddCfuToCartAnalytics from './useAddCfuToCartAnalytics';
type CfuVariables = Omit<
  Variables,
  'locale' | 'currency' | 'slug' | 'upsellIds' | 'upsellBundles'
>;

export const dataSourceCT = 'commercetools';

export const useAddCfuToCart = (
  { upsellIds, upsellBundles }: CfuCartModel,
  analyticsProperties: Omit<CfuCartAnalyticsModel, 'cartId'>,
) => {
  const baseLocale = useLocale();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const storeSlug = toStoreSlug();
  const restClient = useClient();
  const ctClient = toCommercetoolsClient();
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  useLocalResolvers(addToCartResolver);
  const [addPackageToCart, result] = useAddPackageToCartMutation();
  const openCartPanel = useOpenCartPanel();
  const setHasItemJustBeenAddedToCart = useSetHasItemJustBeenAdded();
  const setCartException = useSetCartException();
  const { trackAddBundleToCart } = useAddBundleToCartAnalytics();
  const trackCfuAnalytics = useAddCfuToCartAnalytics();
  const addToCart = async (
    variables: Omit<CfuVariables, 'locale' | 'currency' | 'slug'>,
    shouldOpenCart: boolean = true,
    bundleAnalyticsProperties?: Pick<BundleAccessoryType, 'id' | 'name' | 'price'>[],
  ) => {
    try {
      const { data } = await addPackageToCart({
        context: {
          restClient,
          ctClient,
        },
        variables: {
          ...variables,
          locale,
          currency,
          slug: storeSlug,
          upsellIds: upsellIds.map(([, poid]) => poid).filter(Boolean) as string[],
          upsellBundles,
        },
      });

      if (!data) return;
      if (bundleAnalyticsProperties && bundleAnalyticsProperties.length) {
        bundleAnalyticsProperties.forEach(({ id, name, price }) => {
          trackAddBundleToCart(
            {
              id,
              name,
              price,
            },
            data.addPackageToCart,
          );
        });
      }
      trackCfuAnalytics({ ...analyticsProperties, cartId: data.addPackageToCart.id });
      setHasItemJustBeenAddedToCart(true);
      if (shouldOpenCart) {
        openCartPanel();
      }
    } catch (e) {
      const networkError = e.networkError?.response?.data;
      const errorMsg = getAddToCartErrorCode(networkError?.errorCode);
      setCartException(errorMsg);
      const tags = {
        userAction: 'atc',
        dataSource: dataSourceCT,
      };
      reportError(e, { errorMsg, networkError, tags });
      throw e;
    }
  };

  return [addToCart, result] as const;
};
