import { BreakpointWidths } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ADA_BUTTON_SELECTOR, ADA_CHAT_SELECTOR } from '@peloton/chat';
import { driftSelector } from '@peloton/drift/ChatStyles';
import { fadeInAnimation } from '@ecomm/styles';

export const HideChatAlways = () => {
  return <HideChatsAlways />;
};

export const HideChatMobile = () => {
  return <HideChatsMobile />;
};

/**
 * Used when the 'Show Chat On Page Scroll Mobile' placeholder in contentful is added to a page.
 * This will hide the chat on the initial page load and show the chat once the page is scrolled.
 */
export const ShowChatOnPageScrollMobile = () => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    setHasScrolled(true);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { once: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <ToggleChatsMobileVisibility chatShouldBeVisible={hasScrolled} />;
};

export const HideDriftChatAlways = createGlobalStyle`
  body ${driftSelector} {
    display: none !important;
  }
`;

export const HideChatsAlways = createGlobalStyle`
  body ${ADA_BUTTON_SELECTOR}, ${ADA_CHAT_SELECTOR}  {
    display: none !important;
  }
`;

export const HideDriftChatMobile = createGlobalStyle`
  @media (max-width: ${BreakpointWidths.tablet}px) {
    body ${driftSelector} {
      display: none !important;
    }
  }
`;

export const HideChatsMobile = createGlobalStyle`
  @media (max-width: ${BreakpointWidths.tablet}px) {
    body ${ADA_BUTTON_SELECTOR}, ${ADA_CHAT_SELECTOR}  {
      display: none !important;
    }
  }
`;

const ToggleChatsMobileVisibility = createGlobalStyle<{ chatShouldBeVisible: boolean }>`
  @media (max-width: ${BreakpointWidths.tablet}px) {
    body ${ADA_BUTTON_SELECTOR}, ${ADA_CHAT_SELECTOR}  {
      ${fadeInAnimation};
      visibility: ${({ chatShouldBeVisible }) =>
        chatShouldBeVisible ? 'visible' : 'hidden'} !important;
    }
  }
`;
