import { Flex, white, grey, Label, spacing, Badge } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

type Props = {
  title: string;
  discipline: string;
  badgeLabel: string;
};

const FreeClassCardDescription: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  discipline,
  badgeLabel,
}) => {
  return (
    <Wrapper>
      <Flex flexDirection="column">
        <StyledBadge variant="horizontal" theme="accent">
          {badgeLabel}
        </StyledBadge>
        <StyledTitle size="large">{title}</StyledTitle>
        <StyledLabel size="medium">{discipline}</StyledLabel>
      </Flex>
    </Wrapper>
  );
};

export default FreeClassCardDescription;

const Wrapper = styled.div`
  color: ${white};
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: ${spacing[16]};
  ${media.desktop`
    padding-left: ${spacing[24]};
  `}
`;

const StyledBadge = styled(Badge)`
  align-self: flex-start;
`;

const StyledTitle = styled(Label)`
  margin-top: ${spacing[8]};
  margin-bottom: ${spacing[4]};
`;

const StyledLabel = styled(Label)`
  color: ${grey[50]};
`;
