import type {
  TypeComponentImageFields,
  TypeComponentMedia,
  TypeComponentGenericListFields,
  TypeComponentMediaFields,
} from '@pelotoncycle/page-builder';
import type * as Contentful from 'contentful';
import type {
  ProductRecommendationsCohort,
  ProductRecommendationsTile,
  ProductRecommendationsPillTheme,
  ProductRecommendationsMarketingTile,
} from '@content/client/www/product-recommendations/models';
import { PillThemes } from '@content/client/www/product-recommendations/models';
import type { SupportedTreatmentType } from '@ecomm/product-recommendations/models/treatment';
import { toCtaFields, toEntryTags } from '@page-builder/utils/helpers';

export const HEADLINE_FIELD = 'headline';
export const SUB_HEADLINE_FIELD = 'body';
export const EYEBROW_FIELD = 'eyebrow';

export const PILL_FIELD = 'eyebrow';
export const FACTOID_FIELD = 'body';
export const PRICE_DISPLAY_FIELD = 'label';
export const OVERRIDE_NAME_FIELD = 'headline';
export const FINANCING_TEXT_FIELD = 'support';

export const MARKETING_TILE_TAG = 'productGridMarketingTile';
export const MARKETING_TILE_TEXT_FIELD = 'headline';

const SEPARATOR = '::';

type TypeComponentMediaWithImage = Contentful.Entry<
  TypeComponentMediaFields & {
    media: Contentful.Entry<TypeComponentImageFields>;
  }
>;

const isImageMedia = (
  media?: TypeComponentMedia,
): media is TypeComponentMediaWithImage => {
  if (!media) {
    return false;
  }

  return media.fields.media.sys.contentType.sys.id == 'componentImage';
};

const toValuesWithSeparator = (
  value?: string,
): [string | undefined, string | undefined] => {
  const [first, second] = value?.split(SEPARATOR) || [];
  return [first, second];
};

const toCohortFromGenericList = (
  genericList: TypeComponentGenericListFields,
): ProductRecommendationsCohort => {
  const listTextFields = genericList.text?.fields || {};
  const headline = listTextFields[HEADLINE_FIELD];
  const subHeadline = listTextFields[SUB_HEADLINE_FIELD];
  const eyebrow = listTextFields[EYEBROW_FIELD];

  const products = genericList.items
    .filter(item => item.fields.ctas?.[0]?.fields.productSlug && item.fields.text)
    .map(item => {
      // We can assert that ctas and text are not null because we filtered out items without them above
      const { ctas, text, media } = item.fields;
      const [{ productSlug }] = toCtaFields(ctas!);
      const { fields } = text!;

      const [pill, maybePillTheme] = toValuesWithSeparator(fields[PILL_FIELD]);
      const [factoid, factoidEyebrow] = toValuesWithSeparator(fields[FACTOID_FIELD]);
      const priceDisplay = fields[PRICE_DISPLAY_FIELD];
      const overrideName = fields[OVERRIDE_NAME_FIELD];
      const financingText = fields[FINANCING_TEXT_FIELD];

      const pillTheme =
        maybePillTheme && isPillTheme(maybePillTheme) ? maybePillTheme : undefined;

      const overrideImageUrl = isImageMedia(media)
        ? media.fields.media.fields.mobile.fields.file.url
        : undefined;

      const tile: ProductRecommendationsTile = {
        slug: productSlug!,
        pill,
        pillTheme,
        factoid,
        factoidEyebrow,
        priceDisplay,
        financingText,
        overrideImageUrl,
        overrideName,
      };

      return tile;
    });

  const marketingTiles = genericList.items
    .filter(
      item =>
        // Must have a cta with a link...
        item.fields.ctas?.[0]?.fields.link?.fields.url &&
        // ...and a media component with an image (not a video)...
        isImageMedia(item.fields.media) &&
        // ...and a headline...
        item.fields.text?.fields[MARKETING_TILE_TEXT_FIELD] &&
        // ...and the correct tag
        toEntryTags(item).includes(MARKETING_TILE_TAG),
    )
    .map(item => {
      // We're able to assert that all of these fields are present because we've filtered out items that don't have them
      const { name, ctas, text, media } = item.fields;

      const [{ link }] = toCtaFields(ctas!);
      const { fields } = text!;

      const marketingTile: ProductRecommendationsMarketingTile = {
        name,
        url: link!.fields.url,
        image: media!.fields.media.fields as TypeComponentImageFields,
        title: fields[MARKETING_TILE_TEXT_FIELD]!,
      };

      return marketingTile;
    });

  const cohort: ProductRecommendationsCohort = {
    cohortKeyName: genericList.name,
    headline,
    subHeadline,
    eyebrow,
    position: 'top',
    products,
    marketingTiles,
    marketingTilesWithModal: [],
    treatment: genericList.treatment as SupportedTreatmentType,
  };

  return cohort;
};

const isPillTheme = (
  maybePillTheme: string,
): maybePillTheme is ProductRecommendationsPillTheme => {
  return PillThemes.includes((maybePillTheme as unknown) as any);
};

export default toCohortFromGenericList;
