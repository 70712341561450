import type { ContentfulClientApi, Entry } from 'contentful';
import { PageContentTypes, pageContentTypesArray } from '@page-builder/lib/constants';
import type { TypePageFields, TypeMicroCopyFields } from '@page-builder/lib/types';
import { parseContent } from './parseContent';

export const PageContentType = 'page';

export enum ContentfulTags {
  ALL_PAGES = 'pageAll',
  DISCIPLINE_PAGES = 'pageAllDisciplines',
}

export type GetPageParams = {
  locale: string;
  slug: string;
};

export type GetPagesParams = Omit<GetPageParams, 'slug'>;

export type GetPagesOfTypeParams = GetPagesParams & {
  pageType: string;
};

type GetPagesOfTypeOptions = {
  include?: number;
};

export const getPageQuery = (params: GetPageParams) => ({
  limit: 1,
  include: 10,
  locale: params.locale,
  'fields.slug': params.slug,
  content_type: PageContentType,
});

export const getPagesQuery = (params: GetPagesParams) => ({
  limit: 1000,
  include: 10,
  locale: params.locale,
  content_type: PageContentType,
  'fields.slug[nin]': 'norender',
});

export const getPagesOfTypeQuery = (
  params: GetPagesOfTypeParams,
  options: GetPagesOfTypeOptions = {},
) => ({
  limit: 1000,
  include: options.include ?? 10,
  locale: params.locale,
  content_type: PageContentType,
  'fields.content.sys.contentType.sys.id': params.pageType,
  'fields.slug[exists]': true,
  'fields.slug[nin]': 'norender',
});

export const getAllPages = async (
  client: ContentfulClientApi,
  params: GetPagesParams,
) => {
  const results = await Promise.all(
    pageContentTypesArray.map(pageType => {
      const query = getPagesOfTypeQuery({ ...params, pageType });

      return client.getEntries<TypePageFields>(query);
    }),
  );

  return results.map(result => result.items).flat();
};

export const getNextPageSlugs = async (
  client: ContentfulClientApi,
  params: GetPagesParams | GetPagesOfTypeParams,
) => {
  const results = await Promise.all(
    pageContentTypesArray.map(pageType => {
      // Include is set to 1 because we only need to grab the top level slug to generate the next-www paths
      const query = getPagesOfTypeQuery({ ...params, pageType }, { include: 1 });

      return client.getEntries<TypePageFields>(query);
    }),
  );

  return results.map(result => result.items).flat();
};

export const getPages = async (
  client: ContentfulClientApi,
  params: GetPagesParams | GetPagesOfTypeParams,
) => {
  const includesPageType = 'pageType' in params;

  if (includesPageType && params['pageType'] == PageContentTypes.EcommPage) {
    const query = getPagesOfTypeQuery(params);
    const { items: data1 } = await client.getEntries<TypePageFields>({
      ...query,
      limit: 200,
    });
    const { items: data2 } = await client.getEntries<TypePageFields>({
      ...query,
      skip: 200,
    });
    return [...data1, ...data2];
  } else if (includesPageType) {
    const query = getPagesOfTypeQuery(params);
    const { items } = await client.getEntries<TypePageFields>(query);
    return items;
  }

  return getAllPages(client, params);
};

export async function getPage<T = TypePageFields>(
  client: ContentfulClientApi,
  params: GetPageParams,
) {
  const query = getPageQuery(params);
  const {
    items: [page],
  } = await client.getEntries<T>(query);

  return page ? parseContent<Entry<T>>(page) : null;
}

export type MicroCopyParams = {
  name?: string;
  locale: string;
  tags?: ContentfulTags[];
  limit?: number;
};

const checkForHomepageName = (name?: string) => {
  if (name === 'SC Home') return 'Home';
  return name;
};

export const getPageMicroCopy = async (
  client: ContentfulClientApi,
  params: MicroCopyParams,
) => {
  const { locale, name, tags: tagsParam, limit } = params;
  const options = {
    locale,
    content_type: 'microCopy',
    limit: limit || 300,
  };
  const pageName = checkForHomepageName(name);
  const tags = tagsParam || [];

  if (tags?.length) {
    options['metadata.tags.sys.id[in]'] = tags.join(',');
  }

  // If name is specified, fetch page specific micro copy, otherwise fetch all micro copy entries
  if (pageName) {
    const pageNameWithoutSpace = pageName.replace(/\s/g, '');
    const tagsPrefix = tags.length ? `${tags.join(',')},` : '';
    options['metadata.tags.sys.id[in]'] = `${tagsPrefix}page${pageNameWithoutSpace}`;
  }

  const { items } = await client.getEntries<TypeMicroCopyFields>(options);

  return items.reduce((acc, curr) => {
    const { key, copy } = curr.fields;
    return {
      ...acc,
      [key]: copy,
    };
  }, {});
};
