import {
  Button,
  Flex,
  Label,
  Media,
  spacing,
  grey,
  black,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import { BreakpointWidth, media as mediaQuery } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { BuildTimeTileComponentProps } from '@ecomm/product-recommendations/models/BuildTimeTileComponentProps';
import type { TileComponentProps } from '@ecomm/product-recommendations/models/TileComponentProps';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import useBuildTimeDataForSlug from '@ecomm/product-recommendations/utils/useBuildTimeDataForSlug';
import useDetailsUrl from '@ecomm/product-recommendations/utils/useDetailsUrl';
import PriceDisplay, {
  StaticPriceDisplay,
} from '@ecomm/product-recommendations/Views/PriceDisplay';

const NestedHeroTileComponent: React.FC<React.PropsWithChildren<TileComponentProps>> = ({
  product,
  themeName,
}) => {
  const { name, imageUrl, overrideImageUrl } = product;
  const buttonText = useMicroCopy('shopCtaLabel');
  const productUrl = useDetailsUrl(product);
  const productImageUrl = overrideImageUrl || imageUrl;
  const { imageBackground } = toGridTheme(themeName);
  const { trackEvent } = useTracking();
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktop}px)`);
  const handleClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName: `${name} Product Tile`,
        linkName: buttonText,
        linkTo: productUrl,
        parentType: 'Component: JSON',
        parent: 'NestedHeroTileComponent',
      },
    });
  };

  return (
    <Tile>
      <LinkWrapper href={productUrl} onClick={handleClick}>
        <MediaContainer
          backgound={imageBackground}
          borderRadius={spacing[4]}
          position="relative"
          flexDirection="column"
        >
          <StyledMedia
            width="100%"
            height="100%"
            objectFit="contain"
            media={{
              mobile: toCloudinarySrc({ src: productImageUrl, height: 300 }),
              alt: name,
              type: 'image',
              loading: 'lazy',
            }}
          />
        </MediaContainer>
        <Flex flexDirection="column" justifyContent="center" flexGrow={1}>
          <ProductName size={isMobileOrTablet ? 'medium' : 'large'}>{name}</ProductName>
          <Label size="medium">
            <PriceDisplay product={product} />
          </Label>
        </Flex>
        <Button variant="outline" size="small" color="light" width="adjustable">
          {buttonText}
        </Button>
      </LinkWrapper>
    </Tile>
  );
};

export const BuildTimeNestedHeroTileComponent: React.FC<BuildTimeTileComponentProps> = ({
  tile,
  themeName,
}) => {
  const { slug, overrideImageUrl, priceDisplay } = tile;

  const productData = useBuildTimeDataForSlug(slug);

  const { imageUrl, name, basePrice } = productData;

  const buttonText = useMicroCopy('shopCtaLabel');
  const productImageUrl = overrideImageUrl || imageUrl;
  const { imageBackground } = toGridTheme(themeName);
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktop}px)`);

  if (!productData) {
    return null;
  }

  return (
    <Tile>
      <BaseLinkWrapper as="div">
        <MediaContainer
          backgound={imageBackground}
          borderRadius={spacing[4]}
          position="relative"
          flexDirection="column"
        >
          <StyledMedia
            width="100%"
            height="100%"
            objectFit="contain"
            media={{
              mobile: toCloudinarySrc({ src: productImageUrl, height: 300 }),
              alt: name,
              type: 'image',
              loading: 'lazy',
            }}
          />
        </MediaContainer>
        <Flex flexDirection="column" justifyContent="center" flexGrow={1}>
          <ProductName size={isMobileOrTablet ? 'medium' : 'large'}>{name}</ProductName>
          <Label size="medium">
            <StaticPriceDisplay price={basePrice} priceDisplay={priceDisplay} />
          </Label>
        </Flex>
        <Button
          variant="outline"
          size="small"
          color="light"
          width="adjustable"
          isDisabled
        >
          {buttonText}
        </Button>
      </BaseLinkWrapper>
    </Tile>
  );
};

const Tile = styled(Flex)`
  &:focus-visible {
    outline: none;
  }
`;
const BaseLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: ${spacing[12]};
  padding: ${spacing[12]};
  border-radius: ${spacing[12]};
  width: -webkit-fill-available;
  min-width: 298px;
  ${mediaQuery.tabletLarge`
    min-width: 300px;
`}
  ${mediaQuery.desktopLarge`
    min-width: 364px;
`}
  backdrop-filter: blur(12px);
  box-shadow: 0px 5px 32px ${rgba(black, 0.24)};
  transition: 0.4s background;
  background: linear-gradient(
      0deg,
      ${rgba(grey[30], 0.15)} 0%,
      ${rgba(grey[30], 0.15)} 100%
    ),
    ${rgba(grey[90], 0.75)};
  &:hover,
  &:focus,
  &:focus-visible {
    background: linear-gradient(
        0deg,
        ${rgba(199, 201, 209, 0.25)} 0%,
        ${rgba(199, 201, 209, 0.25)} 100%
      ),
      ${rgba(grey[90], 0.75)};
    outline: none;
  }
`;
const LinkWrapper = withI18n(BaseLinkWrapper);
const StyledMedia = styled(Media)`
  overflow: hidden;
  object-fit: cover;
`;

const MediaContainer = styled(Flex)<{ backgound: string }>`
  height: ${spacing[64]};
  width: ${spacing[64]};
  overflow: hidden;
  border-radius: ${spacing[8]};
  background: ${({ backgound }) => backgound};
`;

const ProductName = styled(Label)`
  white-space: break-spaces;
  font-weight: 500;
  ${mediaQuery.tabletLarge`
    white-space: nowrap;
  `}
  ${mediaQuery.desktopLarge`
    font-weight: 600;
 `}
`;
export default NestedHeroTileComponent;
