import type { QuickAddProductFunction } from '@ecomm/product-recommendations/utils/quick-add/types';

type Args = Parameters<QuickAddProductFunction>;

export const toQuickAddProductFunction = (
  callback: (...args: Args) => Promise<void>,
) => async (...args: Args) => {
  try {
    await callback(...args);

    return true;
  } catch {
    return false;
  }
};
