import { spacing, Support, Label, Flex } from '@pelotoncycle/design-system';
import { MarkdownWithDefaultRenderers } from '@pelotoncycle/page-builder';
import React from 'react';
import type { CSSProperties } from 'react';
import { useTracking } from 'react-tracking';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import { handleJumpLinks } from '../../../utils/linkUtils';
import type { CtaProps } from './types';

type Props = {
  ctas?: CtaProps[];
  labelText?: string;
  supportText?: string;
  primaryColor?: CSSProperties['color'];
  secondaryColor?: CSSProperties['color'];
};

const TextAndCTA: React.FC<React.PropsWithChildren<Props>> = ({
  ctas,
  labelText,
  supportText,
  primaryColor,
  secondaryColor,
}) => {
  const { trackEvent } = useTracking();
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const handleTracking = (text: string, url: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: JSON',
        parent: 'FiftyFiftyHero (CHAMP)',
        unitName: 'FiftyFiftyHero CTA',
        linkName: text,
        linkTo: url,
      },
    });
  };

  return (
    <Flex
      flexDirection="column"
      removeOuterMargins
      verticalPadding={{ desktop: `0 ${spacing[48]}` }}
      style={{ gridArea: 'cta', alignSelf: 'flex-end' }}
    >
      <Flex flexDirection={{ mobile: 'column', tablet: 'row' }} gap={spacing[16]}>
        {ctas &&
          ctas.map(cta => (
            <LinkButton
              key={cta.text}
              href={cta.link.url}
              color={cta.color}
              variant={cta.variant}
              size="medium"
              width="adjustable"
              onClick={e =>
                handleJumpLinks(e, headbandRef, cta.link, () =>
                  handleTracking(cta.text, cta.link.url),
                )
              }
            >
              {cta.text}
            </LinkButton>
          ))}
      </Flex>
      {labelText && (
        <Label
          size="extraLarge"
          weight="medium"
          textColor={secondaryColor}
          style={{ marginTop: spacing[16] }}
        >
          <MarkdownWithDefaultRenderers text={labelText} />
        </Label>
      )}
      {supportText && (
        <Support size="medium" textColor={primaryColor} style={{ marginTop: spacing[8] }}>
          <MarkdownWithDefaultRenderers text={supportText} />
        </Support>
      )}
    </Flex>
  );
};

export default TextAndCTA;
