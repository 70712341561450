import { Container, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Formatters from '@peloton/forms/formatters';
import { useLocale } from '@peloton/internationalize';
import { OTD_TREAD_PLUS_PREORDER } from '@content/client/www/otd/mapToView';
import DynamicOtdEstimate from '@ecomm/cart/summary/OtdEstimate/DynamicOtdEstimate';
import type { EstimateSuccessResp } from '@ecomm/cart/summary/OtdEstimate/DynamicOtdEstimate';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { Header, HeaderRow } from '@ecomm/otd/MessageWhenNotEmpty';
import { useOtdEstimate } from '@ecomm/otd/useOtdEstimates';
import { usePostalCodeContext } from '@ecomm/postal-code/PostalCodeContext';
import toPostalCodeLinkMarkdownRenderers from './toPostalCodeLinkMarkdownRenderers';

type Props = {
  productEquipmentType: EquipmentType;
  onTogglePostalCode: () => void;
  onOtdEstimateLoading: (isLoading: boolean) => void;
  isExpanded: boolean;
  onEstimateSuccess?: (data: EstimateSuccessResp) => void;
  cfuSku?: string;
};

const OverviewOtdEstimate: React.FC<React.PropsWithChildren<Props>> = ({
  productEquipmentType,
  onTogglePostalCode,
  onOtdEstimateLoading,
  isExpanded,
  onEstimateSuccess,
  cfuSku,
}) => {
  const locale = useLocale();
  const toFormattedZip = Formatters.zipCode[locale];

  const countryEstimate = useOtdEstimate(productEquipmentType);
  const { postalCode } = usePostalCodeContext();

  const isTreadPlus = productEquipmentType === 'TREADPLUS';
  const otdTreadPlusPreSaleKey =
    useIsToggleActive()('showTreadPlusPreOrderPage') && isTreadPlus
      ? OTD_TREAD_PLUS_PREORDER
      : undefined;

  return (
    <StyledContainer>
      <DynamicOtdEstimate
        cfuType={productEquipmentType}
        cfuSku={cfuSku}
        postalCode={postalCode}
        countryEstimate={countryEstimate}
        onLoading={onOtdEstimateLoading}
        additionalMarkdownRenderers={toPostalCodeLinkMarkdownRenderers(
          onTogglePostalCode,
          isExpanded,
        )}
        additionalValues={{
          // Turns the postalCode value into a link, which our link markdown renderer above will turn into a toggle button
          postalCode: `[${toFormattedZip(postalCode)}]()`,
        }}
        allowDynamicPostalCodeInput
        onEstimateSuccess={onEstimateSuccess}
        otdKey={otdTreadPlusPreSaleKey}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  ${HeaderRow} {
    margin-bottom: ${spacing[8]};
    ${Header} {
      font-weight: 400;
    }
  }
`;
export default OverviewOtdEstimate;
