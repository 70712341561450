import { Flex, spacing, Support, green, Label } from '@pelotoncycle/design-system';
import React from 'react';
import Markdown from '@page-builder/utils/Markdown';
import { useDrawerContentContext } from '../../../DrawerContentContext';
import { Divider } from './SharedComponents';
import UpsellItem from './UpsellItem';

const UpsellSectionWithHeading: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    pageBuilderData: { upsellSectionIntro, upsellItemContent },
  } = useDrawerContentContext();

  return (
    <>
      {upsellItemContent.length > 0 && (
        <div>
          <Divider topPadding={0} />
          {(upsellSectionIntro.heading || upsellSectionIntro.subheading) && (
            <Flex
              verticalPadding={`${spacing[32]} 0`}
              gap={spacing[8]}
              flexDirection="column"
            >
              <Label size="extraLarge" weight="medium">
                {upsellSectionIntro.heading}
              </Label>
              {upsellSectionIntro.subheading && (
                <Support size="large">
                  <Markdown
                    content={upsellSectionIntro.subheading}
                    markdown={{
                      renderers: {
                        strong: props => (
                          <Support size="large" textColor={green[50]} {...props} />
                        ),
                      },
                    }}
                  />
                </Support>
              )}
            </Flex>
          )}
          {upsellItemContent.map(item => (
            <UpsellItem key={item.key} upsellItemContent={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default UpsellSectionWithHeading;
