import { Flex, Toggle, grey, black, spacing } from '@pelotoncycle/design-system';
import type {
  TypeComponentProductCardFields,
  TypeProductFields,
} from '@pelotoncycle/page-builder';
import { useRouter } from 'next/router';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useSplitTestingContext } from '@peloton/split-testing/hooks';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { CFU } from '@ecomm/product-states';
import { getNameFromProduct } from '@ecomm/product-states/models/product';
import type { TypeComponentProductCard } from '@page-builder/lib/types';
import { toProductDisplayName } from '@page-builder/utils/helpers/regex';
import { getCrossShopUrl } from './crossShopToggleUtils';

export const TOGGLE_ANIMATION_TIME = 250;

export type Props = {
  crossShopToggle: TypeComponentProductCard[];
  currentProductId: TypeProductFields['productId'];
};

const CrossShopToggle: React.FC<React.PropsWithChildren<Props>> = ({
  crossShopToggle,
  currentProductId,
}) => {
  const router = useRouter();
  const { trackEvent } = useTracking();
  const [
    {
      fields: {
        product: { fields: leftProduct },
      },
    },
    {
      fields: {
        product: { fields: rightProduct },
      },
    },
  ] = crossShopToggle;
  const leftButtonActive = leftProduct.productId === currentProductId;
  const { refreshExperiments } = useSplitTestingContext();

  const handleChange = () => {
    const inactiveProduct = leftButtonActive ? rightProduct : leftProduct;
    const activeProduct = leftButtonActive ? leftProduct : rightProduct;
    const inactiveProductName = getNameFromProduct(inactiveProduct.productId as CFU);
    const activeProductName = getNameFromProduct(activeProduct.productId as CFU);
    if (window) {
      const crossShopLink = getCrossShopUrl(
        activeProduct,
        inactiveProduct,
        new URL(window.location.href),
      );

      const navigateToCrossShopLink = async () => {
        await router.push(crossShopLink!);
        refreshExperiments?.();
      };

      if (crossShopLink) {
        // wait for animation before routing
        setTimeout(navigateToCrossShopLink, TOGGLE_ANIMATION_TIME);
        trackEvent({
          event: TrackingEvent.ClickedLink,
          properties: {
            parentType: 'Component: Overview',
            parent: `${activeProductName} Overview Module`,
            unitName: `${inactiveProductName} Cross Shop Toggle`,
            linkName: inactiveProductName,
            linkTo: crossShopLink,
          },
        });
      }
    }
  };

  const getButtonText = (buttonFields: TypeComponentProductCardFields) => {
    return {
      eyebrowText: getNameFromProduct(buttonFields.product.fields.productId as CFU),
      labelText: buttonFields.ctaText ? toProductDisplayName(buttonFields.ctaText) : '',
    };
  };

  return (
    <Flex flexDirection="column">
      <StyledToggle
        backgroundColor={grey[30]}
        inactiveEyebrowColor={grey[90]}
        leftButtonActive={leftButtonActive}
        inactiveLabelColor={grey[70]}
        leftButton={getButtonText(crossShopToggle[0].fields)}
        rightButton={getButtonText(crossShopToggle[1].fields)}
        onChange={handleChange}
        data-test-id="overview-toggle-header"
      />
    </Flex>
  );
};

const StyledToggle = styled(Toggle)`
  div {
    padding: 0;
    height: ${spacing[64]};
    justify-content: center;

    :not(:active):hover {
      p {
        color: ${grey[70]} !important;
        -webkit-transition: color 0.1s ease-in-out 0.1s;
        transition: color 0.1s ease-in-out 0.1s;
      }
    }
  }
  :after {
    border: 2px solid ${black};
    border-radius: 3.125rem;
    box-shadow: unset;
  }
  margin-bottom: ${spacing[16]};
  border-radius: 3.125rem;
`;

export default CrossShopToggle;
