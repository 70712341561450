import {
  grey,
  Eyebrow,
  Headline,
  Flex,
  Grid,
  spacing,
  accessibility,
  Label,
} from '@pelotoncycle/design-system';
import type { LinkButtonProps } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import PlusIcon from '@page-builder/modules/FeatureCards/PlusIcon';
import { ThemeProvider, useTheme } from '@page-builder/themes/ThemeProvider';
import type {
  ImageReferenceType as ImageReference,
  ProductReferenceType as ProductReference,
  MediaReferenceType as MediaReference,
} from '../../../types/referenceTypes';
import { ProductCard } from './ProductCard';
import { RatingsProvider } from './RatingsProvider';
import { getGridRowCount, getValuePropsLength } from './utils';

export type CompareProps = {
  eyebrow?: string;
  headline?: string;
  products: {
    product: ProductReference;
    imageOverride?: ImageReference;
    description?: string;
    financing?: string;
    valueProps?: {
      icon?: string;
      image?: ImageReference;
      media?: MediaReference;
      headline: string;
      description: string;
    }[];
  }[];
  theme?: 'White' | 'Grey 90';
  expandButton?: string;
  maxValuePropAmount?: number;
};

export const ThemedCompareModule: React.FC<
  React.PropsWithChildren<CompareProps>
> = props => {
  const theme = props.theme || 'Grey 90';
  return (
    <ThemeProvider theme={theme}>
      <RatingsProvider products={props.products}>
        <CompareModule {...props} />
      </RatingsProvider>
    </ThemeProvider>
  );
};

/**
 * CompareModule
 *
 * The `CompareModule` component is used to display a comparison of multiple products.
 *
 * The `eyebrow` is optional
 *
 * The `headline` is optional
 *
 * The `products` prop is an array of objects that represent the products to compare.
 * Each product object should have the following properties:
 * - `product` (required): The product reference object
 * - `imageOverride` (optional): The image reference object to override the product image
 * - `description` (optional): The product description
 * - `financing` (optional): The product financing information
 * - `valueProps` (optional): An array of value prop objects
 *  - `icon` (optional): The icon name for the value prop
 * - `image` (optional): The image reference object for the value prop
 * - `media` (optional): The media reference object for the value prop
 * - `headline` (required): The headline for the value prop
 * - `description` (required): The description for the value prop
 * The `valueProps` array will be truncated to `maxValuePropAmount` before expanding
 * The `maxValuePropAmount` prop is optional and defaults to 2
 * The `expandButton` prop is optional and defaults to 'See More'
 * The `theme` prop is optional and defaults to 'Grey 90'
 *
 * @param eyebrow The optional eyebrow text
 * @param headline The optional headline text
 * @param products The array of product objects to compare
 * @param theme The optional theme
 * @param expandButton The optional expand button text
 * @param maxValuePropAmount The optional maximum number of value props to display
 *
 */

export const CompareModule: React.FC<CompareProps> = ({
  theme,
  products,
  eyebrow,
  headline,
  maxValuePropAmount = 2,
  expandButton,
}) => {
  const { trackLinkClick } = useTrackLinkClick();
  const { backgroundColor, headlineColor, buttonColor } = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const hasMoreValueProps = React.useMemo(
    () =>
      products.some(product => (product.valueProps?.length || 0) > maxValuePropAmount),
    [products, maxValuePropAmount],
  );
  const showExpandValuePropsButton = !isExpanded && hasMoreValueProps;

  const handleClick = () => {
    trackLinkClick({
      href: '',
      parent: headline || '',
      additionalProps: {
        parentType: 'CompareModule ValueProps',
        linkTo: '',
        page: window.location.pathname,
        unitName: 'Toggle CTA',
        linkName: expandButton,
      },
    });

    setIsExpanded(true);
  };

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      data-test-id="content-cards-container"
      verticalPadding={{
        mobile: spacing[48],
        tablet: spacing[80],
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={{ mobile: spacing[32], desktop: spacing[48] }}
      >
        <Flex flexDirection="column" textAlign="left" maxWidth="100%" gap={spacing[24]}>
          <Flex flexDirection="column" gap={{ desktop: spacing[8] }}>
            {eyebrow && (
              <Eyebrow size="small" textColor={headlineColor}>
                {eyebrow}
              </Eyebrow>
            )}
            {headline && (
              <Headline size="small" textColor={headlineColor}>
                {headline}
              </Headline>
            )}
          </Flex>
          <Grid
            columnCount={products.length}
            columnSize="minmax(0, 1fr)"
            rowCount={getGridRowCount(products, isExpanded, maxValuePropAmount)}
            textAlign="center"
            maxWidth="100%"
            gap={spacing[24]}
          >
            {products.map(product => {
              const valuePropLength = getValuePropsLength(
                isExpanded,
                product,
                maxValuePropAmount,
              );

              return (
                <ProductCard
                  {...product}
                  valueProps={product.valueProps?.slice(0, valuePropLength)}
                  key={product.product.bundleType}
                />
              );
            })}
          </Grid>
        </Flex>
        {showExpandValuePropsButton && (
          <StyledButton
            data-test-id="expand-value-props-btn"
            onClick={handleClick}
            disabled={isExpanded}
            aria-expanded={isExpanded}
            buttonColor={buttonColor}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            aria-label={expandButton}
          >
            <PlusIcon
              isHovered={isHovered}
              primaryColor={headlineColor}
              filledPrimaryColor={headlineColor}
              filledSecondaryColor={backgroundColor}
            />
            <Label size="large" textColor={headlineColor} style={{ fontWeight: 600 }}>
              {expandButton}
            </Label>
          </StyledButton>
        )}
      </Flex>
    </OuterContainer>
  );
};

const StyledButton = styled.button<{ buttonColor: LinkButtonProps['color'] }>`
  backdrop-filter: blur(12px);
  background: ${({ buttonColor }) =>
    buttonColor === 'light' ? grey[75] : 'transparent'};
  border-radius: 100px;
  display: flex;
  gap: ${spacing[8]};
  padding: ${spacing[12]} ${spacing[24]};
  transition: 400ms opacity;
  width: fit-content;
  outline: ${({ buttonColor }) =>
    buttonColor === 'dark' ? `2px solid ${grey[70]}` : 'none'};

  &:hover {
    svg {
      border-radius: 50%;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  [data-whatintent='keyboard'] &:focus {
    outline: 2px solid ${accessibility.outline};
    outline-offset: 4px;
  }
`;
