import { Button, Flex } from '@pelotoncycle/design-system';
import type {
  TypeComponentCta,
  TypeComponentGenericList,
} from '@pelotoncycle/page-builder';
import { toVariationCopy } from '@pelotoncycle/page-builder';
import React, { useState } from 'react';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { useCtaCopyTestToggle } from '@ecomm/feature-toggle/CtaCopyTest';
import type { ModalProps } from '@page-builder/modules/FullWidthMediaModule/FullWidthMediaModuleModal';
import { toCtaFields } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import { scrollToSection } from '../../../../apps/freeform/utils/linkUtils';
import StickyCta from './StickyCta';
import useStickyProps from './useStickyProps';

type CTAProps = {
  cta: TypeComponentCta;
  Modal?: React.FC<ModalProps>;
  handleOnClick?: () => void;
  parent: { parentName: string; parentType: string };
};

const LinkOrModalCTA: React.FC<CTAProps> = ({ cta, Modal, handleOnClick, parent }) => {
  const { isExperimentActive, variables, activeVariation } = useCtaCopyTestToggle();
  const updatedCtaText = isExperimentActive
    ? toVariationCopy({
        originalCopy: cta.fields.text,
        ctaLink: cta.fields.link?.fields.url ?? '',
        variables,
        activeVariation,
      })
    : undefined;
  const [ctaFields] = toCtaFields([cta], updatedCtaText);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const { activeCtaRef, isStickyCta, showStickyCta } = useStickyProps(cta);

  const addSmoothScroll = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    linkTo?: string,
  ) => {
    const isJumpLink = linkTo?.startsWith('#');
    if (isJumpLink) {
      event.preventDefault();
      scrollToSection(linkTo, headbandRef);
    }
    handleOnClick && handleOnClick();
  };

  if (ctaFields.link) {
    return (
      <Flex ref={activeCtaRef}>
        <LinkButton
          href={ctaFields.link.fields.url}
          color={ctaFields.color}
          variant={ctaFields.variant}
          size="medium"
          width="adjustable"
          onClick={e => addSmoothScroll(e, ctaFields?.link?.fields.url)}
        >
          {ctaFields.text}
        </LinkButton>
        {isStickyCta && (
          <StickyCta
            cta={cta}
            showStickyCta={showStickyCta}
            handleOnClick={handleOnClick}
          />
        )}
      </Flex>
    );
  }

  if (ctaFields.modal) {
    return (
      <>
        <Flex ref={activeCtaRef}>
          <Button
            onClick={() => setIsModalOpen(true)}
            color={ctaFields.color}
            variant={ctaFields.variant}
            size="medium"
            width="adjustable"
          >
            {ctaFields.text}
          </Button>
          {isStickyCta && <StickyCta cta={cta} showStickyCta={showStickyCta} />}
        </Flex>

        {isEntryType<TypeComponentGenericList>(ctaFields.modal, 'text') && Modal && (
          <Modal
            isModalOpen={isModalOpen}
            onModalClose={() => setIsModalOpen(false)}
            modalContent={ctaFields.modal}
            linkName={ctaFields.text}
            parent={{ ...parent }}
          />
        )}
      </>
    );
  }

  return null;
};

export default LinkOrModalCTA;
