import { Icon, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import useAppDownloadRedirect from './useAppDownloadRedirect';

const DownloadRedirect: React.FC<React.PropsWithChildren<unknown>> = () => {
  useAppDownloadRedirect();

  return (
    <Flex minHeight="700px" alignItems="center" justifyContent="center">
      <Icon name="spinner" height={32} />
    </Flex>
  );
};

export default DownloadRedirect;
