import { grey, white, red, black, brand, green } from '@pelotoncycle/design-system';
import React, { createContext } from 'react';

export const LIGHT: 'Light' = 'Light';
export const DARK: 'Dark' = 'Dark';

// Themes defined from the Panel field
export const GREY90: 'Grey 90' = 'Grey 90';

export type ThemeProps = {
  name: typeof LIGHT | typeof DARK | typeof GREY90;

  modalBackgroundColor: string;
  modalTextColor: string;
  modalCtaTextColor: string;
  modalIconPrimaryColor: string;
  modalIconSecondaryColor: string;
  modalDividerColor: string;

  productCardBackgroundColor: string;
  productCardAndHeaderTextColor: string;
  productCardActiveBorderColor: string;
  productCardInactiveBorderColor: string;

  toggleBorderColor: string;
  toggleActiveBorderColor: string;
  toggleInactiveBackgroundColor: string;
  toggleInactivePrimaryColor: string;
  toggleInactiveSecondaryColor: string;

  secondaryTextColor: string;
  secondaryBackgroundColor: string;
  badgeColor: string;
  highlightColor: string;
  checkoutCtaColor: string;
  promoBackgroundColor: string;
  outerContainerStyles?: {
    maxWidth?: string | number;
    horizontalPadding?: string | number;
  };
};
type Themes = Record<typeof LIGHT | typeof DARK | typeof GREY90, ThemeProps>;

export const themeMap: Themes = {
  Light: {
    name: LIGHT,

    modalBackgroundColor: white,
    modalTextColor: grey[90],
    modalCtaTextColor: grey[90],
    modalIconPrimaryColor: grey[80],
    modalIconSecondaryColor: white,
    modalDividerColor: grey[40],

    productCardBackgroundColor: white,
    productCardAndHeaderTextColor: grey[90],
    productCardInactiveBorderColor: grey[50],
    productCardActiveBorderColor: black,

    toggleBorderColor: grey[40],
    toggleActiveBorderColor: grey[90],
    toggleInactiveBackgroundColor: grey[30],
    toggleInactivePrimaryColor: grey[90],
    toggleInactiveSecondaryColor: grey[70],

    secondaryTextColor: grey[80],
    secondaryBackgroundColor: grey[30],
    badgeColor: brand.accent,
    highlightColor: green[50],
    checkoutCtaColor: red[80],
    promoBackgroundColor: grey[30],
    outerContainerStyles: {
      horizontalPadding: 0,
    },
  },
  Dark: {
    name: DARK,

    modalBackgroundColor: grey[90],
    modalTextColor: white,
    modalCtaTextColor: white,
    modalIconPrimaryColor: white,
    modalIconSecondaryColor: grey[90],
    modalDividerColor: grey[70],

    productCardBackgroundColor: black,
    productCardAndHeaderTextColor: white,
    productCardInactiveBorderColor: grey[60],
    productCardActiveBorderColor: white,

    toggleBorderColor: 'transparent',
    toggleActiveBorderColor: grey[40],
    toggleInactiveBackgroundColor: `rgba(255, 255, 255, 0.15)`,
    toggleInactivePrimaryColor: grey[40],
    toggleInactiveSecondaryColor: brand.accent,

    secondaryTextColor: grey[50],
    secondaryBackgroundColor: grey[50],
    badgeColor: brand.accent,
    highlightColor: green[20],
    checkoutCtaColor: red[80],
    promoBackgroundColor: grey[80],
    outerContainerStyles: {
      maxWidth: '100%',
      horizontalPadding: 0,
    },
  },
  'Grey 90': {
    name: GREY90,

    modalBackgroundColor: grey[90],
    modalTextColor: white,
    modalCtaTextColor: white,
    modalIconPrimaryColor: white,
    modalIconSecondaryColor: grey[90],
    modalDividerColor: grey[70],

    productCardBackgroundColor: grey[90],
    productCardAndHeaderTextColor: white,
    productCardInactiveBorderColor: grey[60],
    productCardActiveBorderColor: white,

    toggleBorderColor: grey[40],
    toggleActiveBorderColor: white,
    toggleInactiveBackgroundColor: grey[90],
    toggleInactivePrimaryColor: grey[40],
    toggleInactiveSecondaryColor: brand.accent,

    secondaryTextColor: grey[50],
    secondaryBackgroundColor: grey[50],
    badgeColor: brand.accent,
    highlightColor: green[20],
    checkoutCtaColor: red[80],
    promoBackgroundColor: grey[80],
    outerContainerStyles: {
      horizontalPadding: 0,
    },
  },
};

export const ThemeContext = createContext(themeMap.Light);
export const ThemeProvider: React.FC<
  React.PropsWithChildren<{ theme: typeof LIGHT | typeof DARK | typeof GREY90 }>
> = ({ theme, children }) => {
  return (
    <ThemeContext.Provider value={themeMap[theme]}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
