import { useMemo } from 'react';
import type { Taggable } from '@content/client/www/product-recommendations/models';

const EMPTY_ARRAY = [] as const;
const useFilteredItems = <T extends Taggable>(
  items: T[],
  enabledFilterTags: string[],
) => {
  const filteredItems = useMemo(() => {
    if (enabledFilterTags.length === 0) {
      return items;
    }

    return items.filter(item => {
      const itemTags: readonly string[] = item.tags || EMPTY_ARRAY;
      return enabledFilterTags.every((tag: string) => itemTags.includes(tag));
    });
  }, [items, enabledFilterTags]);

  return filteredItems;
};

export default useFilteredItems;
