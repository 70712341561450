import { white, spacing, Container, Display, brand } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import { media } from '@peloton/styles/breakpoints';

const HeroText: FC<{ staticText: string; highlightedText: string[] }> = ({
  staticText,
  highlightedText,
}) => {
  return (
    <Container
      height={{ mobile: spacing[104], tablet: '192px', desktop: '208px' }}
      width={{ mobile: '270px', tablet: '260px', desktop: '299px' }}
      margin={{ mobile: `0 0 0 ${spacing[16]}`, tablet: '0', desktop: '0' }}
    >
      <Display textColor={white} size="large">
        {staticText}
      </Display>
      <DynamicTextWrapper>
        <Display textColor={brand.accent} size="large">
          <Typewriter
            options={{
              strings: highlightedText,
              autoStart: true,
              loop: true,
              delay: 100,
            }}
          />
        </Display>
      </DynamicTextWrapper>
    </Container>
  );
};

const DynamicTextWrapper = styled.span`
  display: inline-flex;
  position: relative;

  ${media.tabletXLarge`
    width: 310px;
  `}

  ${media.desktopLarge`
    width: 420px;
  `}
`;

export default HeroText;
