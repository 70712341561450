import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import type { TypeComponentFormFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import {
  BUYING_OR_SELLING_FIELD,
  EMAIL_INPUT_FIELD,
  MARKETING_CONSENT_FIELD,
  SERIAL_NUMBER_INPUT_FIELD,
  TERMS_AND_CONDITIONS,
  extractHistoryFormFields,
} from '@page-builder/utils/helpers/historyReportForm';
import HistoryReport from './HistoryReport';
import HistoryReportContainer from './HistoryReportContainer';
import HistoryReportHeadband from './HistoryReportHeadband';

type Props = TypeComponentFormFields;

const HistoryReportEntry: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  formFields,
  submit,
  text: textField,
  metadata,
  failureMessage,
}) => {
  const { eyebrow, headline, body } = getGenericTextNodes(textField, [
    'eyebrow',
    'headline',
    'body',
    'support',
  ]);

  const primaryCta = ctaExtractData(submit);
  const { ref, inView: formInView, entry } = useInView();
  const {
    emailInputField,
    serialNumberInputField,
    buyingOrSellingInputField,
    termsAndConditionsInputField,
    marketingConsentInputField,
  } = extractHistoryFormFields(formFields);

  if (
    !emailInputField ||
    !buyingOrSellingInputField ||
    !serialNumberInputField ||
    !termsAndConditionsInputField ||
    !marketingConsentInputField ||
    !metadata
  ) {
    console.error(
      `Malformed Contentful Entry for ${name}. This entry requires the following input field names:
       - ${EMAIL_INPUT_FIELD} (text input type)
       - ${SERIAL_NUMBER_INPUT_FIELD} (text input type)
       - ${BUYING_OR_SELLING_FIELD} (select input type) 
       - ${TERMS_AND_CONDITIONS} (radio input type) 
       - ${MARKETING_CONSENT_FIELD} (checkbox input type)
       - Leadgen Metadata for email capture
      `,
    );
    return <div data-test-id="form-error"></div>;
  }

  const { text, url } = primaryCta;

  if (!text || !url) {
    console.error(
      `Malformed Contentful Entry for ${name}. Ensure you have a cta with text and url`,
    );
    return <></>;
  }

  return (
    <HistoryReportContainer emailCaptureMetaData={metadata}>
      <HistoryReportHeadband formInView={formInView} cta={submit} entry={entry} />
      <HistoryReport
        headline={headline}
        body={body}
        emailInputField={emailInputField}
        serialNumberInputField={serialNumberInputField}
        buyingOrSellingInputField={buyingOrSellingInputField}
        cta={{ text: text, url: url }}
        termsAndConditionsInputField={termsAndConditionsInputField}
        marketingConsentInputField={marketingConsentInputField}
        serialNumberLinkText={eyebrow}
        formRef={ref}
        failureMessage={failureMessage}
      />
    </HistoryReportContainer>
  );
};

export default HistoryReportEntry;
