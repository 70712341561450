export const checkHasPaginationSlug = (path: string): boolean => {
  const hasPagination = /\/\d+$/.test(path);
  return hasPagination;
};

export const excludePaginationSlug = (path: string): string => {
  const hasPagination = checkHasPaginationSlug(path);
  const pathWithoutPagination = hasPagination
    ? path.split('/').slice(0, -1).join('/')
    : path;
  return pathWithoutPagination;
};

export const transformPaginatedPath = (path: string, ignoreFirstPage = false): string => {
  const hasPagination = checkHasPaginationSlug(path);

  if (!hasPagination) {
    return path;
  }

  const pathWithoutPagination = excludePaginationSlug(path);
  const page = path.split('/').pop();
  const pageQueryParam = !ignoreFirstPage || Number(page) > 1 ? `?page=${page}` : '';

  return pathWithoutPagination + pageQueryParam;
};

export const getPageFromSlug = (path: string): number =>
  checkHasPaginationSlug(path) ? Number(path.split('/').pop()) : 1;
