import type { IconNames } from '@pelotoncycle/design-system';
import {
  Label,
  Flex,
  Container,
  grey,
  spacing,
  Checkbox,
  Support,
  Icon,
} from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { useMicroCopy } from '@content/client/microCopy';
import { AccessoryOptions } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/AccessoryOptions';
import { useUpsellAccessoryContext } from '@page-builder/modules/Overview/UpsellAccessoryContext';
import {
  isConfigurable,
  isAccessoryBundle,
  toVariantForAccessory,
  getUserAccessorySelections,
  isWarranty,
} from '@page-builder/modules/Overview/utils';
import Markdown from '@page-builder/utils/Markdown';
import {
  HeightTransition,
  Price,
  StyledList,
  StyledMedia,
  Divider,
} from './SharedComponents';
import type { Props } from './SharedComponents';

const UpsellItemDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  accessory,
  accessoryVariants,
  setVariantForAccessory,
  setAccessorySelected,
  isExpanded,
  setIsExpanded,
  isAnimating,
  handleAnimation,
  handleTracking,
  image,
  isSelected,
  showErrorMessage,
  setShowErrorMessage,
  upsellItemContent,
  upsellSectionIntro,
}) => {
  const toFormattedText = useGetTextFormatter();

  const singleVariant = !isAccessoryBundle(accessory)
    ? toVariantForAccessory(accessory, accessoryVariants)
    : undefined;
  const bundleVariants = isAccessoryBundle(accessory)
    ? accessoryVariants[accessory.slug]
    : undefined;
  const accessoryVariant = singleVariant || bundleVariants;

  const configSelection = getUserAccessorySelections(
    singleVariant,
    bundleVariants,
    isAccessoryBundle(accessory) ? accessory : undefined,
  );

  const { clearSelectedVariants } = useUpsellAccessoryContext();

  const [isChecked, setIsChecked] = React.useState(isExpanded);

  useEffect(() => {
    const accIsBundle = isAccessoryBundle(accessory);
    const accIsConfigurable = isConfigurable(accessory);

    if (isChecked && accIsBundle && !accIsConfigurable && !accessoryVariant) {
      // if an upsell bundle is checked by default but is unconfigurable (ex. guide upsell), to allow ATC we must also set variant in state
      accessory.products?.forEach((product, index) => {
        if (!isConfigurable(product)) {
          setVariantForAccessory(product.variants[0], product, index.toString());
        }
      });
    }
    setAccessorySelected(accessory.slug, isChecked);
    if (!isChecked && !isExpanded) {
      accIsBundle
        ? clearSelectedVariants(accessory.slug)
        : setVariantForAccessory(undefined);
    }
    if (!isExpanded && !accessoryVariant) {
      setIsChecked(false);
    }
  }, [
    isExpanded,
    isChecked,
    accessoryVariant,
    setVariantForAccessory,
    setAccessorySelected,
    accessory,
    clearSelectedVariants,
  ]);

  const onHandleOnChange = (e: React.SyntheticEvent) => {
    // blocks other events while drawer is animating
    if (isAnimating) return;

    handleAnimation();
    // click event type happens on the configuration edit button, otherwise it's a change event type
    if (e.type === 'click') {
      setIsExpanded(true);
      setIsChecked(true);
    } else {
      if (!isExpanded && Boolean(accessoryVariant)) {
        setIsChecked(false);
        setIsExpanded(false);
        setVariantForAccessory(undefined);
      } else {
        setIsChecked(!isChecked);
        setIsExpanded(!isExpanded);
      }

      // this means the accessory does not have multiple variants (no configurations)
      // so we can set the variant to the only one that exists
      if (isAccessoryBundle(accessory) && accessory.products) {
        accessory.products.forEach((product, index) => {
          if (!isConfigurable(product)) {
            setVariantForAccessory(product.variants[0], product, index.toString());
          }
        });
      } else if (!isAccessoryBundle(accessory) && !isConfigurable(accessory)) {
        setVariantForAccessory(accessory.variants[0]);
      }
    }

    // The tracking event is triggered and sent before the states are correctly updated,
    // so pass in the negated value of isSelected (which will be the updated state).
    handleTracking(!isSelected);
  };

  const accessoryName = accessory.displayName || accessory.name;
  const dropdownLabel = upsellItemContent.text?.configDropdownLabel;
  const warrantyUpsellHeadline = useMicroCopy('warrantyUpsellHeadline');
  const isWarrantyItem = isWarranty(accessory);
  const headline = isWarrantyItem ? warrantyUpsellHeadline : accessoryName;

  const configSelectionSection = configSelection?.map(config => (
    <Flex key={`configuration-${config.attribute}-${config.option}`} gap={spacing[2]}>
      <ConfigAttributeName size="medium">
        {accessory.sizeLabel || config.attribute}:
      </ConfigAttributeName>
      <ConfigAttributeValue size="medium" textColor={grey[90]}>
        {config.option}
      </ConfigAttributeValue>
    </Flex>
  ));

  const accessoryOptionsSection = (isAccessoryBundle(accessory)
    ? accessory.products
    : [accessory]
  ).map((acc, index) => {
    const dropdownAttribute = acc.attributes[0]?.name;
    const fullLabelWithMarkdown = toFormattedText(dropdownLabel, {
      attributeUpperCase: dropdownAttribute,
      attributeLowerCase: dropdownAttribute?.toLowerCase(),
    }).toString();

    return (
      <AccessoryOptions
        key={`${acc.name}-${index}`}
        accessory={acc}
        dropdownSupportText={fullLabelWithMarkdown}
        setAccessoryVariant={setVariantForAccessory}
        variantsForMainAccessory={accessoryVariants[accessory.slug]}
        isAnimating={isAnimating}
        setShowErrorMessage={setShowErrorMessage}
        showErrorMessage={showErrorMessage}
        onClickAdditions={setIsExpanded}
        bundleAccessory={isAccessoryBundle(accessory) ? accessory : undefined}
        indexOfProduct={index}
      />
    );
  });

  return (
    <Container data-test-id={`upsell-${accessory.slug}`}>
      <UpsellDrawerContainer
        key={upsellItemContent?.itemName}
        borderRadius={spacing[4]}
        verticalMargin={`${upsellSectionIntro ? spacing[16] : spacing[40]} 0`}
        padding={`${spacing[8]} ${spacing[16]} ${spacing[12]}`}
        isExpanded={isExpanded}
        style={{
          outline: Boolean(isSelected)
            ? `2px solid ${grey[90]}`
            : `1px solid ${grey[70]}`,
        }}
      >
        <StyledFlex
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Checkbox
            name={headline}
            handleChange={e => onHandleOnChange(e)}
            label={upsellItemContent?.key}
            checked={isSelected}
            data-test-id="upsell-checkbox"
            labelText={
              <Flex
                flexDirection="column"
                style={{ width: '100%' }}
                horizontalPadding={`${spacing[4]} 0`}
              >
                <Label size="large">{headline}</Label>
                {configSelectionSection &&
                configSelectionSection.length > 0 &&
                !isWarrantyItem ? (
                  configSelectionSection
                ) : (
                  <Support size="medium">
                    {upsellItemContent?.text?.productSubhead}
                  </Support>
                )}
                {!isExpanded && configSelection ? (
                  <EditConfigButton onClick={e => onHandleOnChange(e)}>
                    <Support>{upsellItemContent?.text?.editConfigText}</Support>
                  </EditConfigButton>
                ) : (
                  <Price
                    includedValueText={upsellItemContent.text?.isIncludedText}
                    accessory={accessory}
                  />
                )}
              </Flex>
            }
          />
          {isWarrantyItem ? (
            <Container padding={spacing[16]} width="unset">
              <Icon name={'warrantyCheck' as IconNames} height={40} />
            </Container>
          ) : (
            <StyledMedia
              media={{
                mobile: toCloudinarySrc({ src: image.mobile, height: 80 }),
                alt: image.alt,
                type: 'image',
                loading: 'lazy',
              }}
              height={spacing[80]}
              width={spacing[80]}
            />
          )}
        </StyledFlex>
        <HeightTransition $isExpanded={isExpanded} $isAnimating={isAnimating}>
          <Container height={isExpanded ? 'fit-content' : '0'}>
            {(isConfigurable(accessory) || isWarrantyItem) && <Divider topPadding={8} />}
            {upsellItemContent.text?.productDescription && (
              <Container verticalPadding={`${spacing[12]}`}>
                <Markdown
                  content={upsellItemContent.text?.productDescription}
                  markdown={{
                    renderers: {
                      paragraph: ({ children }) => (
                        <Support size="large" as="p">
                          {children}
                        </Support>
                      ),
                      list: ({ node, ...props }) => (
                        <StyledList
                          listStyle="disc"
                          size="medium"
                          items={props.children.map(
                            (child: any) => child?.props?.children,
                          )}
                          gap="0"
                        />
                      ),
                    },
                  }}
                />
              </Container>
            )}
            {upsellItemContent.text?.configDropdownLabel && (
              <Flex gap={spacing[12]} flexDirection="column">
                {accessoryOptionsSection}
              </Flex>
            )}
          </Container>
        </HeightTransition>
      </UpsellDrawerContainer>
    </Container>
  );
};

export default UpsellItemDrawer;

const EditConfigButton = styled.button`
  border-bottom: 1px solid ${grey[90]};
  max-width: fit-content;
  &:hover {
    color: ${grey[70]};
    border-bottom: 1px solid ${grey[70]};
  }
`;

const ConfigAttributeName = styled(Support)`
  text-transform: capitalize;
  flex-shrink: 0;
`;

const ConfigAttributeValue = styled(Support)`
  font-weight: 400;
  // The below is to support single-line truncation with ellipses
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

const UpsellDrawerContainer = styled(Container)<{ isExpanded: boolean }>`
  &:hover {
    border-color: ${grey[90]};
  }
`;

const StyledFlex = styled(Flex)`
  label > span > input {
    margin: 0px;
  }
`;
