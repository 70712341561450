import { Badge } from '@pelotoncycle/design-system';
import React from 'react';
import { isProductCFU } from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import usePill from '@ecomm/product-recommendations/utils/usePill';
import { BundleType } from '@ecomm/shop/models';
import { PromoPill } from '@page-builder/modules/Promo';
import PillsWrapper from './PillsWrapper';

type Props = {
  product: Product;
  floating?: boolean;
};

const ProductPills: React.FC<Props> = ({ product, floating = true }) => {
  const contentPill = usePill({ isInCart: false, product });
  const bundleType = isProductCFU(product) ? product.bundleType : BundleType.Accessories;

  return (
    <PillsWrapper floating={floating} dataTestId="grid-product-tile-pill">
      {isProductCFU(product) && <PromoPill cfu={bundleType} bundleSlug={product.slug} />}
      {contentPill && (
        <Badge variant="horizontal" theme={contentPill.theme}>
          {contentPill.text}
        </Badge>
      )}
    </PillsWrapper>
  );
};

export default ProductPills;
