import {
  Flex,
  Media,
  spacing,
  Headline,
  Body,
  Support,
  white,
  grey,
} from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/internationalize-ui';
import { media } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import usePlatform, { Platform } from '@ecomm/app-download/usePlatform';
import type {
  ImageReferenceType as ImageReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from '../../../types/referenceTypes';

export type AppStoreModuleProps = {
  appStoreImage: ImageReference;
  appStoreHeadline: string;
  appStoreLink: ExternalLinkReference;
  appStoreLinkText: string;
  appStoreSupportText?: string;
  qrCode: ImageReference;
  qrHeadline: string;
  qrBody?: string;
  qrSupportText?: string;
};

const AppStoreModule: React.FC<AppStoreModuleProps> = ({
  appStoreImage,
  appStoreHeadline,
  appStoreLink,
  appStoreLinkText,
  appStoreSupportText,
  qrCode,
  qrHeadline,
  qrBody,
  qrSupportText,
}) => {
  const { trackEvent } = useTracking();
  const platform = usePlatform();
  const showAppContent = platform === Platform.iOS;

  const handleTracking = (text: string, url: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: JSON',
        parent: 'AppStoreModule (CHAMP)',
        unitName: 'AppStoreModule CTA',
        linkName: text,
        linkTo: url,
      },
    });
  };

  const supportText = showAppContent ? appStoreSupportText : qrSupportText;
  return (
    <OuterContainer
      backgroundColor="black"
      verticalPadding={{
        mobile: `${spacing[32]}`,
        tablet: `${spacing[80]}`,
        desktop: `${spacing[80]}`,
      }}
      horizontalPadding={{
        mobile: `${spacing[16]}`,
        tablet: `${spacing[80]}`,
        desktop: `${spacing[80]}`,
      }}
    >
      <Flex
        flexDirection={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        gap={{
          mobile: `${spacing[24]}`,
          tablet: `${spacing[72]}`,
          desktop: `${spacing[72]}`,
        }}
        alignItems="center"
      >
        <StyledMedia
          media={showAppContent ? appStoreImage : qrCode}
          objectFit="contain"
          data-test-id="appStoreMedia"
        />
        <Flex
          flexDirection="column"
          gap={{
            mobile: `${spacing[24]}`,
            tablet: `${spacing[12]}`,
            desktop: `${spacing[12]}`,
          }}
          textAlign={{ mobile: 'center', tablet: 'left' }}
          alignItems={{ mobile: 'center', tablet: 'flex-start' }}
        >
          <Flex
            flexDirection="column"
            gap={{ mobile: `${spacing[16]}`, tablet: `${spacing[8]}` }}
            maxWidth="450px"
          >
            <Headline size="small" textColor={white}>
              {showAppContent ? appStoreHeadline : qrHeadline}
            </Headline>
            {!showAppContent && qrBody && (
              <Body size="medium" textColor={white}>
                {qrBody}
              </Body>
            )}
          </Flex>
          {showAppContent && (
            <LinkButton
              href={appStoreLink.url}
              variant="outline"
              color="light"
              data-test-id="appStoreLinkButton"
              onClick={() => handleTracking(appStoreLinkText, appStoreLink.url)}
            >
              {appStoreLinkText}
            </LinkButton>
          )}
          {supportText && (
            <Support size="medium" textColor={grey[40]}>
              {supportText}
            </Support>
          )}
        </Flex>
      </Flex>
    </OuterContainer>
  );
};

const StyledMedia = styled(Media)`
  width: 120px;

  ${media.tabletLarge`
    width: 176px
  `}
`;

export default AppStoreModule;
