import { brand, red, white, grey } from '@pelotoncycle/design-system';
import { shade } from 'polished';

export type Theme = keyof typeof THEMES;

const defaultTheme = {
  backgroundColor: brand.light,
  color: brand.darkest,
  emphasisColor: brand.primary,
  hoverColor: shade(0.95, brand.light),
};

export const THEMES = {
  red: {
    backgroundColor: brand.primary,
    color: white,
    emphasisColor: white,
    hoverColor: red[80],
  },
  black: {
    backgroundColor: brand.dark,
    color: white,
    emphasisColor: white,
    hoverColor: grey[70],
  },
  white: {
    ...defaultTheme,
  },
  defaultTheme,
};
