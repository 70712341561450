import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import { PB_QUERY_API_ENDPOINT, PB_QUERY_PREVIEW_API_ENDPOINT } from '../utils/constants';

export const fetcher = (url: string) => fetch(url).then(r => r.json());

// content_type is required in queries
export type Query = Record<string, string> & {
  content_type: string;
};

const useBuilderQuery = <T>(query: Query) => {
  const locale = useLocale();
  const previewCopy = useIsPreviewCopyEnabled();
  const queryString = new URLSearchParams(query).toString();

  return useSWR<T>(
    `${
      previewCopy ? PB_QUERY_PREVIEW_API_ENDPOINT : PB_QUERY_API_ENDPOINT
    }/${locale}?${queryString}`,
    fetcher,
  );
};

export default useBuilderQuery;
