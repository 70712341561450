import { grey, spacing } from '@pelotoncycle/design-system';
import type { Entry } from 'contentful';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from '@peloton/error-reporting';
import { media } from '@peloton/styles';
import type { TypeComponentJsonFields } from '@page-builder/lib/types';
import { useBlockComponent } from '@page-builder/modules';

type LibrarySectionProps = {
  modules: Entry<TypeComponentJsonFields>[];
};

const LibrarySection: React.FC<LibrarySectionProps> = ({ modules }) => {
  const Block = useBlockComponent();

  return (
    <>
      {modules?.map((block, index) => (
        <ErrorBoundary key={index} renderError={() => <></>}>
          <Section>{Block && <Block block={block} />}</Section>
        </ErrorBoundary>
      ))}
    </>
  );
};

const Section = styled.section`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${rgba(grey[70], 0.4)};
    margin: 0 ${spacing[16]};
    ${media.tablet`
    margin: 0 ${spacing[32]};
  `}
  }
`;

export default LibrarySection;
