import { Media, spacing } from '@pelotoncycle/design-system';
import { MarkdownWithDefaultRenderers, OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useMedia } from 'react-use';
import { BreakpointWidth } from '@peloton/styles/breakpoints';
import {
  containedProps,
  FiftyFiftyContainer,
  MediaContainer,
} from '@page-builder/modules/FiftyFiftyMedia/FiftyFiftyMedia';
import HeaderText from '@page-builder/modules/FiftyFiftyMedia/HeaderText';
import { themes } from '@page-builder/themes';
import { nameToModuleId } from '@page-builder/utils/helpers';
import type {
  MediaReferenceType as MediaReference,
  Theme as ThemeReference,
} from '../../../types/referenceTypes';
import type { JSONComponentProps } from '../../../types/utilityTypes';
import ProductGrid from '../ProductGrid/ProductGrid';
import TextAndCTA from './TextAndCTA';
import type {
  CtaProps as CtaPropsType,
  ProductGridProps as ProductGridType,
} from './types';

export type FiftyFiftyHeroProps = {
  ctas?: CtaPropsType[];
  eyebrow?: string;
  headline?: string;
  body?: string;
  support?: string;
  label?: string;
  media?: MediaReference;
  theme?: ThemeReference;
  productGrid?: ProductGridType;
};

/**
 * This is the `FiftyFiftyHero` module that reflects the PageBuilder [Generic Text with Media: Fifty Fifty Media](https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/42582999579/Generic+Text+with+Media+Fifty+Fifty+Media)
 * module with an addidional, optional field, `productGrid`.
 * - `eyebrow`, `headline`, and `body` are all optional text fields and rendered in that order on the left side of the module.
 * - `ctas` is an optional list of CTAs that will be rendered below the above mentioned text fields.
 * - `support` and `label` are optional text fields that will be rendered below the CTAs.
 * - `media` is an optional media asset that will be rendered on the right side of the module.
 * - `theme` is an optional field that will determine the color scheme of the module.
 * - `productGrid` is an optional field that will render a `ProductGrid` module directly below the text and media sections of the module.
 * - *All text fields are markdown enabled.*
 * - *On mobile and tablet, when `productGrid` is present, the `ctas`, `support` and `label` will be hidden.*
 * - *For more information on the `ProductGrid` props, see the `ProductGrid` component.*
 *
 * @param ctas list of CTAs that will be rendered below the eyebrow, headline, and body fields.
 * @param eyebrow the optional text that will be rendered above the headline.
 * @param headline the optional text that will be rendered below the eyebrow.
 * @param body the optional text that will be rendered below the headline.
 * @param support the optional text that will be rendered below the CTAs.
 * @param label the optional text that will be rendered below the support text.
 * @param media the optional media asset that will be rendered on the right side of the module.
 * @param theme the optional theme that will determine the color scheme of the module.
 * @param productGrid the optional product grid that will be rendered below the text and media sections of the module.
 */

const FiftyFiftyHero: React.FC<JSONComponentProps<FiftyFiftyHeroProps>> = ({
  name,
  ctas,
  eyebrow,
  headline,
  body,
  support,
  label,
  media,
  theme = 'Grey 90',
  productGrid,
}) => {
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);
  const textAndCtasHidden = isMobileOrTablet && productGrid;
  const { backgroundColor, textColor, headlineColor } = themes[theme];

  const mediaProps = media && {
    ...media,
    autoplay: true,
    progressTracked: true,
    loop: false,
  };

  return (
    <>
      <OuterContainer
        id={nameToModuleId(name)}
        backgroundColor={backgroundColor}
        theme={theme}
        verticalPadding={{
          mobile: `${spacing[32]} 0`,
          tablet: `${spacing[64]} 0`,
          desktop: `${spacing[80]} 0`,
        }}
        horizontalPadding={containedProps.horizontalPadding}
      >
        <FiftyFiftyContainer
          maxWidth={containedProps.maxWidth}
          backgroundColor={backgroundColor}
          gap={containedProps.gap}
          removeOuterMargins
        >
          <HeaderText
            eyebrowText={<MarkdownWithDefaultRenderers text={eyebrow ?? ''} />}
            headlineText={<MarkdownWithDefaultRenderers text={headline ?? ''} />}
            bodyText={<MarkdownWithDefaultRenderers text={body ?? ''} />}
            primaryColor={headlineColor}
            secondaryColor={textColor}
          />
          {!textAndCtasHidden && (
            <TextAndCTA
              ctas={ctas}
              labelText={label}
              supportText={support}
              primaryColor={headlineColor}
              secondaryColor={textColor}
            />
          )}
          <MediaContainer style={{ gridArea: 'media' }}>
            {mediaProps && <Media media={mediaProps} />}
          </MediaContainer>
        </FiftyFiftyContainer>
      </OuterContainer>
      <OuterContainer
        id={`${nameToModuleId(name)}-product-grid`}
        backgroundColor={backgroundColor}
        theme={theme}
        verticalPadding={{
          mobile: `0 ${spacing[32]}`,
          tablet: `0 ${spacing[64]}`,
          desktop: `0 ${spacing[80]}`,
        }}
        horizontalPadding={isMobileOrTablet ? 0 : containedProps.horizontalPadding}
        flexDirection="column"
      >
        {productGrid && (
          <ProductGrid
            {...productGrid}
            treatment="Nested Hero Product Grid"
            name={`${name} Nested Product Grid`}
            theme={theme}
          />
        )}
      </OuterContainer>
    </>
  );
};

export default FiftyFiftyHero;
