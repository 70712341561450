import { Flex, Media } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { MediaReferenceType } from '../../../types/referenceTypes';

type Props = {
  media?: MediaReferenceType;
};

const PreviewVideo = ({ media }: Props) => {
  // TODO CHAMP-1977: Segment events
  const onEnded = () => {};
  const onPause = () => {};

  if (!media) {
    return null;
  }

  const mediaProps = {
    ...media,
    onEnded,
    onPause,
  };

  return (
    <Flex>
      <StyledMedia media={mediaProps} />
    </Flex>
  );
};

export default PreviewVideo;

const StyledMedia = styled(Media)`
  max-width: 776px;

  div {
    height: auto; // video icons travel with video
  }

  video {
    height: auto; // always shrink to match browser width
    border-radius: 12px;
  }
`;
