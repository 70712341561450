import React from 'react';
import type { AlgorithmInstructor } from '@page-builder/modules/MWYIQuiz/models/AlgorithmInstructor';

type ContextInterface = {
  hasAlgorithmInstructors: boolean;
  algorithmInstructors: AlgorithmInstructor[];
};

type Props = {
  algorithmInstructors: AlgorithmInstructor[];
};

const defaultContext: ContextInterface = {
  hasAlgorithmInstructors: false,
  algorithmInstructors: [],
};

export const InstructorsContext = React.createContext<ContextInterface>(defaultContext);

const InstructorsProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  algorithmInstructors = [],
}) => {
  const hasAlgorithmInstructors = algorithmInstructors.length > 0;
  const contextValue = React.useMemo(() => {
    return {
      hasAlgorithmInstructors,
      algorithmInstructors,
    };
  }, [hasAlgorithmInstructors, algorithmInstructors]);

  return (
    <InstructorsContext.Provider value={contextValue}>
      {children}
    </InstructorsContext.Provider>
  );
};

export default InstructorsProvider;
