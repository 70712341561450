import {
  Container,
  Radio,
  grey,
  black,
  spacing,
  Flex,
  Label,
  red,
} from '@pelotoncycle/design-system';
import React, { useMemo } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import NextContentSizedDiv from '@peloton/content-sized-div/NextContentSizedDiv';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import shouldHideContent from '@ecomm/copy/helpers/shouldHideContent';
import useIsFaasOutOfStock from '@ecomm/feature-toggle/FaasOutOfStock';
import type { TypeComponentGenericList } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { TRANSITION_SETTINGS } from '../animations';
import { toPrimaryDrawerCta } from '../utils';
import DrawerContent from './DrawerContent/DrawerContent';
import DrawerFinancingSection from './DrawerContent/DrawerFinancingSection';
import RentDrawerCtaBox from './DrawerContent/RentDrawerCtaBox';
import { strikethroughTreatment } from './priceUtils';
import PriceWithPromo from './PriceWithPromo';

type DrawerProps = {
  drawer: TypeComponentGenericList;
  index: number;
  onChange: (drawer: TypeComponentGenericList) => void;
  isDrawerOpen: boolean;
  isFirstDisplayed?: boolean;
  product: string;
  parentName: string;
};

const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  drawer,
  index,
  onChange,
  isDrawerOpen,
  isFirstDisplayed = false,
  product,
  parentName,
}) => {
  const {
    fields: { text },
  } = drawer;
  const {
    eyebrow,
    headline: price,
    body: priceSubhead = 'norender',
  } = getTextFieldsWithRequiredKeys([], text);
  const { trackEvent } = useTracking();
  const notAvailableCopy = useMicroCopy('notAvailable');

  const showSubhead = !shouldHideContent(priceSubhead);

  const productSlug = toPrimaryDrawerCta(drawer)?.fields.productSlug;

  const isRentalOutOfStock = useIsFaasOutOfStock();
  const isBikeRental = useMemo(() => productSlug?.match(/opc-pkg-[\d]+/), [productSlug]);
  const drawerIsDisabled = !!(isRentalOutOfStock && isBikeRental);

  const handleOnClick = () => {
    onChange(drawer);

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Generic List',
        parent: `${product} Overview Drawer`,
        unitName: `${product} Overview ${eyebrow} Drawer`,
        linkName: eyebrow,
        linkTo: window.location.href,
      },
    });
  };

  const drawerSubhead = drawerIsDisabled ? notAvailableCopy : priceSubhead;

  return (
    <StyledContainer
      drawerIsDisabled={drawerIsDisabled}
      isDrawerOpen={isDrawerOpen}
      isFirstDisplayed={isFirstDisplayed}
      borderRadius={spacing[4]}
      data-test-id="overview-drawer"
    >
      <HeightTransition>
        <Container padding={`${spacing[16]}`} position="relative">
          <DrawerTitle justifyContent="space-between" alignItems="center">
            <Radio
              labelText={eyebrow}
              name="Overview Radio Buttons"
              checked={isDrawerOpen}
              onClick={() => handleOnClick()}
              disabled={drawerIsDisabled}
            />
            <Flex
              flexDirection="column"
              alignItems="flex-end"
              style={{ lineHeight: 'normal' }}
              textAlign="right"
            >
              <Price size="large" data-test-id="overview-drawer-price">
                {productSlug && !drawerIsDisabled && (
                  <PriceWithPromo packageSlug={productSlug} pageBuilderPrice={price} />
                )}
              </Price>
              {showSubhead && (
                <Label textColor={grey[70]}>
                  <Markdown
                    content={drawerSubhead}
                    markdown={{
                      renderers: {
                        emphasis: ({ node, ...props }) => (
                          <span style={{ color: black }}>{props.children}</span>
                        ),
                        strong: ({ node, ...props }) => (
                          <span style={{ color: red[50] }}>{props.children}</span>
                        ),
                      },
                    }}
                  />
                </Label>
              )}
            </Flex>
          </DrawerTitle>
          {productSlug && (
            <DrawerFinancingSection
              productSlug={productSlug}
              product={product}
              isDrawerOpen={isDrawerOpen}
              index={index}
              parentName={parentName}
            />
          )}
          {drawerIsDisabled && <RentDrawerCtaBox />}
          <DrawerContent isOpen={isDrawerOpen} />
        </Container>
      </HeightTransition>
    </StyledContainer>
  );
};

export default Drawer;

// This is to increase the clickable area for the radio button,
// while still keeping the DrawerContent links clickable.
const DrawerTitle = styled(Flex)`
  input {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  label {
    flex-shrink: 0;
  }
`;

type ContainerProps = {
  isDrawerOpen: boolean;
  drawerIsDisabled: boolean;
  isFirstDisplayed: boolean;
};
const StyledContainer = styled(Container)<ContainerProps>`
  ${props => `
    &:hover {
      border-color: ${grey[90]};
    }
    outline: ${props.isDrawerOpen ? `2px solid ${black}` : `1px solid ${grey[70]}`};
    order: ${props.isFirstDisplayed ? 1 : 2};
  
    ${
      props.drawerIsDisabled &&
      `
      outline: 1px solid ${grey[40]};
      background-color: ${grey[30]};
      color: ${grey[70]};
    `
    }
  `}
`;

const HeightTransition = styled(NextContentSizedDiv)`
  transition: ${TRANSITION_SETTINGS};
`;

const Price = styled(Label)`
  ${strikethroughTreatment}
`;
