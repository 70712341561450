import React from 'react';
import type { FC } from 'react';
import { useMedia } from 'react-use';
import { BreakpointWidth } from '@peloton/styles';
import DesktopTabletHeroCarousel from './DesktopTabletHeroCarousel';
import MobileHeroCarousel from './MobileHeroCarousel';
import type { VideoCard as VideoCardProps } from './types';

const HeroCarousel: FC<{
  videoCards: VideoCardProps[];
}> = ({ videoCards }) => {
  const isMobile = useMedia(`(max-width: ${BreakpointWidth.tabletXLarge}px)`, false);

  return isMobile ? (
    <MobileHeroCarousel videoCards={videoCards} />
  ) : (
    <DesktopTabletHeroCarousel videoCards={videoCards} />
  );
};

export default HeroCarousel;
