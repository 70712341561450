import { useEffect, useState } from 'react';
import { getPersistedSegment } from '@peloton/concessionary-pricing';
import useContentAggregate from '@content/client/www/aggregates/useContentAggregate';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { BundleType } from '@ecomm/shop/models/BundleType';
import type { ConcessionaryPricing } from './content';
import { CONCESSIONARY_PRICING_ID } from './content';

type BundleTypeToContentfulDiscountMap = {
  [key in BundleType]?: string;
};

const CFUBundleToContentfulDiscountMap: BundleTypeToContentfulDiscountMap = {
  [BundleType.Bike]: 'bikeDiscount',
  [BundleType.RefurbishedBike]: 'refurbBikeDiscount',
  [BundleType.BikePlus]: 'bikePlusDiscount',
  [BundleType.RefurbishedBikePlus]: 'refurbBikePlusDiscount',
  [BundleType.Tread]: 'treadDiscount',
  [BundleType.TreadPlus]: 'treadPlusDiscount',
  [BundleType.Row]: 'rowDiscount',
};

const useConcessionaryPricing = (shouldVerifyCohort: boolean) => {
  const [
    isConcessionaryPricingEnabled,
    setIsConcessionaryPricingEnabled,
  ] = useState<boolean>(false);
  const [group, setGroup] = useState<string>('');
  const isSpecialPricingPhaseTwoEnabled: boolean = useIsToggleActive()(
    'special_pricing_phase_2',
  );
  const contentfulContent = useContentAggregate<ConcessionaryPricing>(
    CONCESSIONARY_PRICING_ID,
  );

  useEffect(() => {
    const verifiedGroup = getPersistedSegment();
    setGroup(verifiedGroup);
  }, []);

  useEffect(() => {
    setIsConcessionaryPricingEnabled(
      isSpecialPricingPhaseTwoEnabled &&
        (shouldVerifyCohort ? !!group : true) &&
        group !== 'students',
    );
  }, [group, isSpecialPricingPhaseTwoEnabled, shouldVerifyCohort]);

  const toDiscountForCFU = (bundleType: BundleType | string): number => {
    if (!isConcessionaryPricingEnabled) {
      return 0;
    }
    if (!Object.keys(CFUBundleToContentfulDiscountMap).includes(bundleType)) {
      return 0;
    }

    const discountKey = CFUBundleToContentfulDiscountMap[bundleType];
    if (!discountKey) {
      return 0;
    }
    if (!contentfulContent.content) {
      return 0;
    }
    const contentDiscount =
      contentfulContent.content[discountKey as keyof ConcessionaryPricing];
    return parseInt(contentDiscount, 10);
  };

  return {
    isConcessionaryPricingEnabled,
    toDiscountForCFU: toDiscountForCFU,
  };
};

export default useConcessionaryPricing;
