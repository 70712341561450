import { Eyebrow, Flex, spacing, Stars } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import { useLocale } from '@peloton/internationalize';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import type { Language } from '@ecomm/graphql/types.generated';
import type { DeviceType } from '@ecomm/models/DeviceType';
import { useStatsQuery } from '@ecomm/reviews/data/reviews/Stats.generated';
import { toEquipmentType } from '@ecomm/reviews/data/utils/toEquipmentType';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import { useRatings } from './RatingsProvider';
import { formatRating } from './utils';

export const Rating: React.FC<{
  productName: string;
  link: string;
}> = ({ productName, link }) => {
  const { type, headlineColor, textColor } = useTheme();
  const { trackEvent } = useTracking();
  const { updateShouldShowRatings, shouldShowRatings } = useRatings();

  const locale = useLocale();
  const displayLang = locale.split('-')[0];
  const lang = displayLang as Language;
  const { errorReporter } = useErrorReporter();
  const { loading, error, data } = useStatsQuery({
    throwError: false,
    suspend: false,
    variables: {
      equipmentType: toEquipmentType(productName as DeviceType),
      lang: lang,
      excludeFamily: true,
    },
    reportSwallowedError: errorReporter.reportError,
  });

  if (!data || error || loading) {
    return null;
  }

  // update Ratings Context with the data from the query
  const ratingData = data.reviewsByEquipmentType;
  updateShouldShowRatings(productName, ratingData);

  if (!shouldShowRatings) {
    return null;
  }

  const rating = ratingData.averageOverallRating;
  const formattedRating = `${formatRating(rating)}/5`;

  const handleClick = () => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        parentType: 'CompareModule (CHAMP)',
        parent: `${productName} Section of CompareModule`,
        unitName: `${productName} Rating`,
        linkName: formattedRating,
      },
    });
  };

  return (
    <ReviewLink
      as={I18nLink}
      href={`${link}#reviews`}
      baseColor={headlineColor}
      hoverColor={textColor}
      onClick={handleClick}
    >
      <StarsWrapper alignItems="center">
        <Stars rating={rating} starSize={16} theme={type as 'light' | 'dark'} />
      </StarsWrapper>
      <RatingScore size="small">{formattedRating}</RatingScore>
    </ReviewLink>
  );
};

const RatingScore = styled(Eyebrow)`
  line-height: ${spacing[16]};
  text-underline-offset: ${spacing[4]};
  text-decoration: underline;
`;

const ReviewLink = styled.a<{ baseColor: string; hoverColor: string }>`
  display: flex;
  align-items: center;
  width: auto;
  gap: ${spacing[8]};
  transition: color 250ms ease 0s;
  color: ${({ baseColor }) => baseColor};

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const I18nLink = withI18n(styled.a``);

const StarsWrapper = styled(Flex)`
  &:focus {
    outline: none;
  }
`;
