import { grey, white, data, brand, green } from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';

export type PageBuilderTheme = NonNullable<TypeComponentGenericListFields['theme']>;

type ThemeKey =
  | 'backgroundColor'
  | 'textColor'
  | 'strikethroughColor'
  | 'priceHighlightColor'
  | 'descriptionHighlightColor'
  | 'dividerColor'
  | 'imageBackground';

export type GridTheme = Record<ThemeKey, string>;

const themeMap: Partial<Record<PageBuilderTheme, GridTheme>> = {
  'Grey 90': {
    backgroundColor: grey[90],
    textColor: white,
    strikethroughColor: grey[50],
    priceHighlightColor: brand.accent,
    descriptionHighlightColor: rgba(data.purple, 0.4),
    dividerColor: grey[80],
    imageBackground: `radial-gradient(221.66% 110.18% at 18.7% 35.4%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[80]}`,
  },
  White: {
    backgroundColor: white,
    textColor: grey[90],
    strikethroughColor: grey[70],
    priceHighlightColor: green[50],
    descriptionHighlightColor: '#EEE6FF', // TODO: Replace this when the color is added to design system
    dividerColor: grey[40],
    imageBackground: grey[30],
  },
};

export const DEFAULT_THEME_NAME: PageBuilderTheme = 'White';

export const defaultGridTheme = themeMap[DEFAULT_THEME_NAME]!;

export const toGridTheme = (
  themeName: PageBuilderTheme = DEFAULT_THEME_NAME,
): GridTheme => {
  return themeMap[themeName] || defaultGridTheme;
};

export const toThemeValue = (key: ThemeKey) => (props: any) =>
  ((props.theme as GridTheme) || defaultGridTheme)[key];
