import type {
  WwwLinkReferenceType as WwwLinkReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from '../types/referenceTypes';

// When neighboring modules share the same theme, we reduce top-padding in OuterContainer component.
// So when computing height, we should re-add that padding as buffer.

// relatedSection represents the TOC div, so we must compare its neighbors.
const getSharedThemeBuffer = (relatedSection: HTMLElement) => {
  const previousModule = relatedSection.previousElementSibling;
  const prevModuleTheme = previousModule?.getAttribute('data-theme');

  const nextModule = relatedSection.nextElementSibling;
  const nextModuleTheme = nextModule?.getAttribute('data-theme');
  const prevModuleOuterContainerSection = previousModule?.firstElementChild;

  if (
    prevModuleTheme &&
    prevModuleTheme === nextModuleTheme &&
    prevModuleOuterContainerSection
  ) {
    const bottomPadding = window.getComputedStyle(prevModuleOuterContainerSection)
      .paddingBottom;
    return parseInt(bottomPadding) || 0;
  }

  return 0;
};

export const scrollToSection = (
  sectionId?: string,
  headbandRef?: HTMLDivElement | HTMLElement | null,
  options?: {
    scrollBehavior?: ScrollBehavior;
  },
) => {
  if (!sectionId) {
    return;
  }

  const jumpScrollId = sectionId.split('#')[1];
  const relatedSection = document.getElementById(jumpScrollId);

  if (relatedSection) {
    const tocHeight = headbandRef ? headbandRef.offsetHeight : 0;
    const sharedThemeBuffer = getSharedThemeBuffer(relatedSection);
    const offset = tocHeight + sharedThemeBuffer;

    const y = relatedSection.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: y, behavior: options?.scrollBehavior ?? 'smooth' });
  }
};

export const handleJumpLinks = (
  e: React.MouseEvent<Element, MouseEvent>,
  headbandRef: HTMLElement | null,
  link?: WwwLinkReference | ExternalLinkReference,
  tracking?: () => void,
) => {
  const isJumpLink = link?.url.startsWith('#');
  if (isJumpLink) {
    e.preventDefault();
    scrollToSection(link?.url, headbandRef);
  }
  tracking?.();
};
