import ReactSlick from '@dior/react-slick';
import {
  Container,
  grey,
  Icon,
  Media,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { Options } from '@peloton/images-ui';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { defaultTransition, hoverTransition, media } from '@peloton/styles';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import 'slick-carousel/slick/slick.css';

type Props = {
  isSparePart: boolean;
  product: Product;
};

// Map of product slugs to array of indices of images that need to be auto cropped
const IMAGES_WITH_CROPS = {
  ['bike-mat-v2']: [3],
  ['dumbbells']: [3, 4],
  ['peloton-weights']: [3],
  ['sp-47-tool-kit-spare-part']: [0],
};

const BASE_IMAGE_SIZE = 448;
const NON_MOBILE_IMAGE_PADDING = 56;

const ImageCarousel: React.FC<React.PropsWithChildren<Props>> = ({
  isSparePart,
  product,
}) => {
  const { images, name, slug } = product;
  const { trackEvent } = useTracking();

  const trackPagination = (slideIndex: number) => {
    trackEvent({
      event: 'Clicked Carousel Slide',
      properties: {
        productInterest: name,
        unitName: `${name} Quick View Modal Carousel Slide ${slideIndex}`,
        parentType: 'Modal',
        parent: `${name} Quick View Modal`,
      },
    });
  };

  return (
    <SlickCarousel
      infinite={true}
      variableWidth={false}
      draggable={false}
      arrows
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      dots={true}
      customPaging={() => <Dot />}
      appendDots={dots => <ul aria-hidden="true">{dots}</ul>}
      afterChange={trackPagination}
      slidesToShow={1}
      slidesToScroll={1}
      centerPadding={0}
      centerMode
    >
      {images.map((imgSrc, i) => {
        const isCropped = Boolean(IMAGES_WITH_CROPS[slug]?.includes(i));
        const cloudinaryOptions: Options = isCropped
          ? {
              height: BASE_IMAGE_SIZE + NON_MOBILE_IMAGE_PADDING * 2,
              width: BASE_IMAGE_SIZE,
              gravity: 'auto',
              crop: 'fill',
            }
          : {
              height: BASE_IMAGE_SIZE,
            };
        const isSparePartsStyle = isSparePart && i === 0;
        return (
          <Container
            key={i}
            height={{
              mobile: '100%',
              desktop: '560px',
            }}
          >
            <StyledMedia
              width="100%"
              height="100%"
              objectFit="cover"
              media={{
                mobile: toCloudinarySrc({
                  src: imgSrc,
                  ...cloudinaryOptions,
                }),
                alt: `${name} Image ${i + 1}`,
                type: 'image',
                loading: 'lazy',
              }}
              isSparePartsStyle={isSparePartsStyle}
            />
          </Container>
        );
      })}
    </SlickCarousel>
  );
};

export default ImageCarousel;

const StyledMedia = styled(Media)<{ isSparePartsStyle: boolean }>`
  & > picture > img {
    height: ${props => (props.isSparePartsStyle ? '80%' : '100%')};
    margin-top: ${props => (props.isSparePartsStyle ? 'calc(25% / 2)' : 'inherit')};
  }
`;

const Arrow = styled.button`
  position: absolute;
  z-index: 2;
  height: 32px;
  width: 32px;
  text-align: center;
  top: 50%;
  transform: translateY(calc(-50% - ${spacing[8]}));
  opacity: 0;
  display: none !important;
  color: ${grey[90]};

  &:hover {
    color: ${grey[70]};
  }

  ${defaultTransition('opacity,color')}

  ${media.desktopLarge`
    display: block !important;
  `}
  
  circle {
    fill: currentColor;
    stroke: none;
  }
`;

const NextArrow = styled(Arrow).attrs({
  children: <Icon name="carouselArrow" primaryColor={white} height={32} rotate={180} />,
  'aria-label': 'Next Image',
})`
  right: ${spacing[16]};
`;

const PrevArrow = styled(Arrow).attrs({
  children: <Icon name="carouselArrow" primaryColor={white} height={32} />,
  'aria-label': 'Previous Image',
})`
  left: ${spacing[16]};
`;

const SlickCarousel = styled(ReactSlick)<{ children: React.ReactNode }>`
  font-size: 0;
  line-height: 0;
  min-width: 0;
  top: 0;
  width: 100%;

  ${media.desktopLarge`
  max-width: 448px;
  `}

  ${media.desktopLarge`
    position: sticky;
  `}

  .slick-bottom {
    margin-top: ${spacing[16]};
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    line-height: 0;
  }

  .slick-list {
    background-color: ${grey[30]};
    border-radius: ${spacing[4]};
  }

  &:hover ${Arrow} {
    opacity: 1;
  }

  [data-whatintent='keyboard'] &:focus-within ${Arrow} {
    opacity: 1;
  }
`;

const Dot = styled.button`
  background-color: ${grey[50]};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: ${spacing[8]};
  margin: 0 ${spacing[4]};
  width: ${spacing[8]};

  .slick-active & {
    background-color: ${grey[70]};
  }

  ${hoverTransition({
    property: 'background-color',
    to: grey[70],
  })}
`;
