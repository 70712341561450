import type { ProductRecommendationsCopyKey } from '@content/client/www/product-recommendations/models';
import useProductRecommendations from '@content/client/www/product-recommendations/useProductRecommendations';

export const useProductRecommendationsCopyKey = (
  key: ProductRecommendationsCopyKey,
): string => {
  const { content } = useProductRecommendations();

  // This will only be called in a situation where the content has been loaded, so we can assert that content is non-null
  return content!.copy[key];
};
