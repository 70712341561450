import { Support } from '@pelotoncycle/design-system';
import React from 'react';
import { isProductCFU } from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import useFinancingTextForProduct from '@ecomm/product-recommendations/utils/useFinancingTextForProduct';
import WithMarkdown from '@page-builder/modules/WithMarkdown';

type Props = {
  product: Product;
};

export const BaseFinancingText: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (React.Children.count(children) === 0) {
    return null;
  }

  return (
    <Support size="small">
      <WithMarkdown text={children as string} />
    </Support>
  );
};

const PromoFinancingText: React.FC<React.PropsWithChildren<Props>> = ({ product }) => {
  const financingText = useFinancingTextForProduct(product);

  return <BaseFinancingText>{financingText}</BaseFinancingText>;
};

const FinancingText: React.FC<React.PropsWithChildren<Props>> = ({ product }) => {
  const { financingText } = product;
  const hasProductCFU = isProductCFU(product);

  if (hasProductCFU) {
    return <PromoFinancingText product={product} />;
  }

  return <BaseFinancingText>{financingText}</BaseFinancingText>;
};

export default FinancingText;
