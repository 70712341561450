import {
  BreakpointWidths,
  Container,
  Flex,
  FlexChild,
  spacing,
} from '@pelotoncycle/design-system';
import type { GenericCtaData } from '@pelotoncycle/page-builder';
import React, { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import styled, { createGlobalStyle } from 'styled-components';
import { ADA_BUTTON_SELECTOR, ADA_CHAT_SELECTOR } from '@peloton/chat';
import { driftSelector } from '@peloton/drift/ChatStyles';
import { media } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import { useIsReviewsRemovalExperimentActive } from '@ecomm/feature-toggle/ReviewsRemoval';
import { PostalCodeProvider } from '@ecomm/postal-code/PostalCodeContext';
import { BundleType, stripDashesFromId } from '@ecomm/shop/models';
import type {
  TypeComponent_overviewFields,
  TypeComponentCta,
  TypeComponentFormFields,
  TypeComponentGenericList,
  TypeComponentGenericText,
  TypeComponentGenericTextWithMedia,
  TypeComponentLeadGenFields,
  TypeComponentProductCard,
  TypeProduct,
} from '@page-builder/lib/types';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import Header from '@page-builder/modules/Overview/Header/Header';
import NearestShowroomCard from '@page-builder/modules/Overview/NearestShowroom/NearestShowroomCard';
import PackageUpsell from '@page-builder/modules/Overview/PackageUpsell';
import Reviews from '@page-builder/modules/Overview/Reviews/Reviews';
import useCanAddToCart from '@page-builder/modules/Overview/ShopDrawers/useCanAddToCart';
import {
  useActiveDrawerRibbon,
  useToggledComponents,
} from '@page-builder/modules/Overview/utils';
import { toProductDisplayName } from '@page-builder/utils/helpers/regex';
import AccordionWrapper from './AccordionWrapper/AccordionWrapper';
import Breadcrumbs, { BREADCRUMBS_HEIGHT } from './Breadcrumbs/Breadcrumbs';
import CrossShopSection from './CrossShopToggle/CrossShopSection';
import LoadingWrapper from './Loading';
import Gallery from './MultiMediaGallery/Gallery';
import { MobileCarousel } from './MultiMediaGallery/MobileCarousel';
import TextCards from './MultiMediaGallery/TextCards';
import { toGalleryData } from './MultiMediaGallery/utils';
import ProductAvailabilityController from './ProductAvailability';
import useRequiresPostalCode from './ProductAvailability/useRequiresPostalCode';
import ProductConfigurations from './ProductConfigurations/ProductConfigurations';
import ReferralCodes from './ReferralCodes';
import ShopATCBands from './ShopATCBands/ShopATCBands';
import { useShopContext } from './ShopContext';
import AddToCartCtaWithErrorTracking from './ShopDrawers/AddToCartCtaWithErrorTracking';
import BaseItem from './ShopDrawers/DrawerContent/BaseItem';
import { Divider } from './ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import UpsellSectionWithHeading from './ShopDrawers/DrawerContent/UpsellContent/UpsellSectionWithHeading';
import Drawers from './ShopDrawers/Drawers';
import { useUpsellAccessoryContext } from './UpsellAccessoryContext';
import {
  useIsTreadPromoOverviewVariation1Active,
  useIsTreadPromoOverviewVariation2Active,
} from './utils/useIsTreadPromoOverviewExperimentActive';
import ValuePropsContainer from './ValueProps/ValuePropsContainer';

export type OverviewProps = {
  name: string;
  product: TypeProduct;
  gallery?: TypeComponentGenericList;
  ribbon?: string;
  reviews?: TypeComponentGenericText;
  shopSectionTitle?: TypeComponentGenericTextWithMedia;
  shopDrawers?: TypeComponentGenericList[];
  tabs?: TypeComponentGenericText[];
  valueProps?: TypeComponentCta[];
  nearestShowroom?: TypeComponentGenericTextWithMedia;
  breadcrumbs?: GenericCtaData[];
  postalCodeInputFields?: TypeComponentFormFields;
  emailLeadGenFields?: TypeComponentLeadGenFields;
  crossShopToggle?: TypeComponentProductCard[];
  soldOutLeadGenFields?: TypeComponentLeadGenFields;
  pbxPackageUpsell?: TypeComponent_overviewFields['pbxPackageUpsell'];
};

const Overview: React.FC<React.PropsWithChildren<OverviewProps>> = ({
  name,
  reviews,
  gallery,
  ribbon,
  product,
  shopSectionTitle,
  valueProps,
  tabs,
  nearestShowroom,
  breadcrumbs,
  postalCodeInputFields,
  emailLeadGenFields,
  crossShopToggle,
  soldOutLeadGenFields,
  pbxPackageUpsell,
}) => {
  const { trackEvent } = useTracking();

  const formattedGallery = gallery && toGalleryData(gallery);

  const {
    productBundleType,
    productEquipmentType,
    productPackage,
    productPackageLoading,
    isPostalCodeEligible,
  } = useShopContext();
  const {
    activeShopDrawer,
    availableShopDrawers,
    visibleShopDrawers,
    activeDrawerHeadband,
  } = useDrawerSelectionContext();

  const canAddToCart = useCanAddToCart();

  useEffect(() => {
    if (!productPackageLoading && productPackage) {
      const productId = stripDashesFromId(productPackage.id);
      trackEvent({
        event: TrackingEvent.ProductViewed,
        properties: {
          category: product.fields.bundleType,
          productId,
          name: productPackage.name,
          sku: productPackage.slug,
          price: productPackage.price.amount,
        },
      });
    }
  }, [productPackageLoading, productPackage, product.fields.bundleType, trackEvent]);

  const {
    fields: { name: productTitle, bundleType },
  } = product;

  const { upsellAccessoryLoading } = useUpsellAccessoryContext();

  const activeDrawerRibbon = useActiveDrawerRibbon();

  const isTreadPromoOverviewVariationActive = {
    variation1: useIsTreadPromoOverviewVariation1Active(),
    variation2: useIsTreadPromoOverviewVariation2Active(),
  };

  const isTreadOrTreadPlus =
    product.fields.bundleType === BundleType.Tread ||
    product.fields.bundleType === BundleType.TreadPlus;

  /**
   * This is used to handle the rendering of the upsell section based on the 'Tread Promo Overview' test.
   * If the product is either Tread or Tread Plus AND the user is bucketed into either variations,
   * then the original upsell section component (UpsellSectionWithHeading) will not show.
   * Instead, the products recommendations grid component will be rendered below the zip availability.
   * Else, the original upsell section component (UpsellSectionWithHeading) will be rendered.
   */
  const shouldShowTreadPromoOverviewContent =
    isTreadOrTreadPlus &&
    (isTreadPromoOverviewVariationActive.variation1 ||
      isTreadPromoOverviewVariationActive.variation2);

  const availableTabs = useToggledComponents(tabs) as
    | TypeComponent_overviewFields['tabs']
    | undefined;

  const shouldShowReviews = !useIsReviewsRemovalExperimentActive();
  const isSoloDrawer = availableShopDrawers?.length === 1;
  const headingGap = !shouldShowReviews && isSoloDrawer ? spacing[16] : spacing[32];

  // Do not render any ATC button for the user if:
  // they are in a restricted zip code (doesRequirePostalCode(productBundleType) is true)
  // and the product is not available for that zip code (isPostalCodeEligible is false)
  const doesRequirePostalCode = useRequiresPostalCode();
  const isUnavailableForRestrictedPostalCode = productBundleType
    ? doesRequirePostalCode(productBundleType) && !isPostalCodeEligible
    : false;

  return (
    <>
      <ReferralCodes />
      <HideChatWidgetsGlobalStyle />
      {!activeDrawerHeadband && <ShopATCBands product={product} />}
      <GalleryContainer>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {formattedGallery && (
          <FlexChild flexGrow={{ desktop: 1 }}>
            <Gallery gallery={formattedGallery} product={productTitle} />
            {formattedGallery.media && (
              <MobileCarousel
                mediaArray={formattedGallery.media}
                parentName={name}
                product={productTitle}
              />
            )}
          </FlexChild>
        )}
      </GalleryContainer>
      <TextContainer
        // Add padding-top to the desktop view when breadcrumbs exist
        // to offset height of the breadcrumbs and align the two columns.
        verticalPadding={
          breadcrumbs && breadcrumbs.length > 1
            ? { desktop: `${BREADCRUMBS_HEIGHT}px 0` }
            : undefined
        }
        margin="0 auto"
      >
        <Flex flexDirection="column" gap={headingGap}>
          <Container>
            {crossShopToggle && crossShopToggle.length > 1 && (
              <CrossShopSection
                crossShopToggle={crossShopToggle}
                currentProductId={product.fields.productId}
              />
            )}
            <Flex flexDirection="column" gap={spacing[12]}>
              <Header
                title={toProductDisplayName(productTitle)}
                ribbon={activeDrawerRibbon || ribbon}
              />
              {reviews && shouldShowReviews && (
                <Reviews totalReviewsText={reviews.fields.headline} device={bundleType} />
              )}
            </Flex>
          </Container>
          <Container>
            {visibleShopDrawers && (
              <Drawers
                product={productTitle}
                parentName={name}
                shopTitle={shopSectionTitle}
              />
            )}
            <LoadingWrapper
              isLoading={productPackageLoading || upsellAccessoryLoading}
              key={activeShopDrawer?.sys.id || ''}
            >
              {canAddToCart && (
                <UpsellAndCTAContainer>
                  <ProductConfigurations />
                  {!shouldShowTreadPromoOverviewContent && <UpsellSectionWithHeading />}
                  <Divider topPadding={0} />
                  <BaseItem product={productTitle} parentName={name} />
                </UpsellAndCTAContainer>
              )}
              {!canAddToCart && !isUnavailableForRestrictedPostalCode && (
                <UpsellAndCTAContainer>
                  <AddToCartCtaWithErrorTracking
                    bundleType={bundleType as BundleType}
                    bundleName={toProductDisplayName(productTitle)}
                  />
                </UpsellAndCTAContainer>
              )}

              {!productPackageLoading && productBundleType && productEquipmentType && (
                <PostalCodeProvider>
                  <ProductAvailabilityController
                    connectedFitnessUnitSlug={productPackage?.connectedFitnessUnit?.slug}
                    productBundleType={productBundleType}
                    productEquipmentType={productEquipmentType}
                    postalCodeInputFields={postalCodeInputFields}
                    emailLeadGenFields={emailLeadGenFields}
                    soldOutLeadGenFields={soldOutLeadGenFields}
                  />
                </PostalCodeProvider>
              )}
            </LoadingWrapper>
          </Container>
        </Flex>
        {pbxPackageUpsell && <PackageUpsell upsellModuleData={pbxPackageUpsell} />}
        {valueProps && (
          <Container verticalMargin={`0 ${spacing[40]}`}>
            <ValuePropsContainer
              valueProps={valueProps}
              parentName={name}
              variant="body"
              size="large"
              product={bundleType as BundleType}
            />
          </Container>
        )}
        {formattedGallery?.textItems && (
          <Container display={{ desktop: 'none' }} verticalMargin={`0 ${spacing[40]}`}>
            <TextCards textItems={formattedGallery.textItems} />
          </Container>
        )}
        {/* flex without gap for inner components */}
        <Flex flexDirection="column">
          {availableTabs && (
            <AccordionWrapper items={availableTabs} product={productTitle} />
          )}
          {nearestShowroom && (
            <NearestShowroomCard
              nearestShowroom={nearestShowroom.fields}
              product={productTitle}
            />
          )}
        </Flex>
      </TextContainer>
    </>
  );
};

const UpsellAndCTAContainer = styled(Flex)`
  flex-direction: column;
  gap: ${spacing[32]};
  padding-top: ${spacing[32]};
`;

const GalleryContainer = styled.div`
  bottom: ${spacing[16]};
  position: sticky;
  align-self: flex-end;
`;

const TextContainer = styled(Container)`
  position: relative;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    min-width: 392px;
    max-width: 392px;
  }
`;

const HideChatWidgetsGlobalStyle = createGlobalStyle`	
  ${driftSelector}, ${ADA_BUTTON_SELECTOR}, ${ADA_CHAT_SELECTOR} {	
    display: none !important;	
    ${media.tablet`	
        display: block !important;	
      `}	
  }	
`;

export default Overview;
