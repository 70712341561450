import { map } from 'ramda';
import { parseEntry } from './parseEntry';
import { truncateComponentJsonEntry } from './truncateComponentJsonEntry';
import type { ContentfulEntry, TruncatedEntry } from './types';

type Field = string | ContentfulEntry<any> | Array<ContentfulEntry<any>>;

const shouldTruncate = (field: Field) =>
  Array.isArray(field) || (typeof field === 'object' && field.sys !== undefined);

export const truncateEntry = (entry: ContentfulEntry<any>): TruncatedEntry<any> => {
  if (entry.sys.contentType.sys.id === 'componentJson') {
    return truncateComponentJsonEntry(entry);
  }
  const { sysId, id, fields, tagLinks = [], spaceId } = parseEntry(entry);

  return {
    sys: {
      id: sysId,
      contentType: {
        sys: { id },
      },
      space: {
        sys: { id: spaceId },
      },
    },
    metadata: {
      tags: tagLinks.map(({ sys: { id: tagId } }) => tagId),
    },
    fields: {
      ...map((field: Field) => {
        if (!shouldTruncate(field)) {
          return field;
        }

        // Multi-reference field - referenced entries recursively truncated
        if (Array.isArray(field)) {
          /**
           *  Rich Text fields should not be truncated - they do not have any 'sys' properties and hold their copy in a `content` array
           */
          return field.map(reference =>
            !!reference.sys ? truncateEntry(reference) : reference,
          );
        }

        /**
         * Single reference field
         * referenced entry is truncated
         * asset are flattened to fields only
         * */
        if (typeof field === 'object' && field.sys.type) {
          if (field.sys.type === 'Entry') {
            return truncateEntry(field);
          }
          if (field.sys.type === 'Asset') {
            return { fields: field.fields };
          }
        }

        // Standard text field - returned as is
        return field;
      }, fields),
    },
  };
};
