import type { Configuration, SelectedOption } from '../../graphql/types.generated';
import type {
  AccessoryCommerceTools,
  VariantCommerceTools,
} from '../../pg-shop-accessories-display/models';
import type { CommerceToolsSelectedItem } from '../models/commerceToolsSelectedItem';

const findBySelectedOption = (
  accessory: AccessoryCommerceTools,
  variant: VariantCommerceTools,
  option: SelectedOption,
): CommerceToolsSelectedItem | undefined => {
  const configuration:
    | (Configuration & {
        legacyOptionId: string;
      })
    | undefined = variant.configurations.find(
    c => c.attribute === option.attribute && c.option === option.option,
  );

  if (configuration) {
    return {
      configuration,
      price: variant.price,
      slug: accessory.slug,
      legacyOptionId: configuration.legacyOptionId,
      sku: variant.sku,
    };
  }

  return undefined;
};

export const mapSelectionToLegacyOptions = (
  accessory: AccessoryCommerceTools,
  selections: Array<SelectedOption | undefined>,
): Array<CommerceToolsSelectedItem> => {
  const { variants } = accessory;
  const legacyOptionId = variants[0]?.legacyOptionId;
  const selectedOptions = selections.filter(Boolean) as Array<SelectedOption>;

  if (!selectedOptions.length && legacyOptionId) {
    return [
      {
        legacyOptionId,
        slug: accessory.slug,
        price: variants[0]?.price,
        sku: variants[0]?.sku,
      },
    ];
  }

  const result: Array<CommerceToolsSelectedItem> = [];

  for (let i = 0; i < selectedOptions.length; i++) {
    for (let j = 0; j < variants.length; j++) {
      const item = findBySelectedOption(accessory, variants[j], selectedOptions[i]);

      if (item) {
        result.push(item);
      }
    }
  }

  return result;
};
