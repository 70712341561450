import {
  BreakpointWidths,
  Eyebrow,
  Flex,
  Icon,
  Label,
  Media,
  Support,
  spacing,
} from '@pelotoncycle/design-system';
import type {
  MediaImageProps,
  WwwLinkReferenceType as WwwLinkReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from 'apps/freeform/types/referenceTypes';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { Link } from '@peloton/next/components/Link';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import { ClassDetailsModal } from './ClassDetailsModal';
import { classImageStyles, skeletonAnimationStyles } from './styles';
import { themes } from './theme';
import type {
  Discipline as DisciplineType,
  ParsedClass as ParsedClassType,
} from './types';

type Props = {
  activeDiscipline?: DisciplineType;
  classes: ParsedClassType[];
  isLoadingClassData: boolean;
  modalCta?: {
    text: string;
    link: WwwLinkReference | ExternalLinkReference;
  };
  theme?: 'White' | 'Grey 90';
};

const ClassOptions = ({
  activeDiscipline,
  classes = [],
  isLoadingClassData,
  modalCta,
  theme = 'Grey 90',
}: Props) => {
  const { trackEvent } = useTracking();
  const [isClassModalOpen, setIsClassModalOpen] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<ParsedClassType | undefined>(
    undefined,
  );

  const onModalClosed = () => {
    setIsClassModalOpen(false);
    trackEvent({
      event: TrackingEvent.ModalClosed,
      properties: {
        pageName: window.location.pathname,
        productInterest: 'App',
        propertyType: 'Web',
        unitPlacement: 'Modal',
        modalTrigger: "X'd out",
        unitName: selectedClass?.title,
      },
    });
  };

  const { previewClassesHeadline, seeMoreLink } = activeDiscipline || {};

  const seeMoreCopy = useMicroCopy('seeMoreClasses');
  const modalContentLabel = useMicroCopy('app.classModalLabel.contentLabel');

  const hasNoClasses = !isLoadingClassData && !classes?.length;

  const { primaryTextColor } = themes[theme];

  const onClassOptionClick = (classOption: ParsedClassType) => {
    setSelectedClass(classOption);
    setIsClassModalOpen(true);

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: 'DisciplineTilesWithModal (CHAMP)',
        parentType: 'Component: JSON',
        unitName: '',
        linkTo: `Class Details Modal: ${classOption?.title}`,
        linkName: classOption?.title,
      },
    });

    trackEvent({
      event: TrackingEvent.ModalOpened,
      properties: {
        pageName: window.location.pathname,
        productInterest: 'App',
        propertyType: 'Web',
        unitPlacement: 'Modal',
        modalTrigger: 'Click',
        unitName: classOption?.title,
      },
    });
  };

  const onSeeMoreLinkClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: 'DisciplineTilesWithModal (CHAMP)',
        parentType: 'Component: JSON',
        unitName: 'See more classes link',
        linkTo: seeMoreLink?.url,
        linkName: seeMoreCopy,
      },
    });
  };

  return (
    <Flex flexDirection="column" gap={{ mobile: spacing[16], desktop: spacing[24] }}>
      <Eyebrow textColor={primaryTextColor} size="medium">
        {previewClassesHeadline}
      </Eyebrow>
      <Flex flexDirection="column" gap={spacing[16]}>
        {isLoadingClassData ? (
          new Array(4).fill(0).map((_, i) => <PlaceholderOption key={i} theme={theme} />)
        ) : hasNoClasses ? (
          <ClassesError theme={theme} />
        ) : (
          classes.map((classInfo, i) => (
            <ClassOption
              key={`${i}_${classInfo.id}`}
              {...classInfo}
              onClick={() => onClassOptionClick(classInfo)}
              theme={theme}
            />
          ))
        )}
      </Flex>
      {seeMoreLink &&
        (!isLoadingClassData ? (
          <div>
            <StyledLink
              href={seeMoreLink.url}
              onClick={onSeeMoreLinkClick}
              variant="body"
              theme={theme}
            >
              <Label size="large">{seeMoreCopy}</Label>
            </StyledLink>
          </div>
        ) : (
          <TextPlaceholder theme={theme} />
        ))}
      <ClassDetailsModal
        isOpen={isClassModalOpen}
        closeHandler={() => onModalClosed()}
        classDetails={selectedClass}
        contentLabel={modalContentLabel}
        cta={modalCta}
      />
    </Flex>
  );
};

const ClassesError = ({ theme = 'Grey 90' }: { theme?: 'White' | 'Grey 90' }) => {
  const errorCopy = useMicroCopy('errorLoadingClasses');
  const { primaryTextColor, backgroundColor } = themes[theme];
  return (
    <Flex gap={spacing[8]}>
      <Icon
        height={16}
        primaryColor={primaryTextColor}
        secondaryColor={backgroundColor}
        name="exclamation"
      />
      <Label textColor={primaryTextColor} size="medium" weight="regular">
        {errorCopy}
      </Label>
    </Flex>
  );
};

const PlaceholderOption = ({ theme = 'Grey 90' }: { theme?: 'White' | 'Grey 90' }) => (
  <Flex gap={spacing[16]} data-test-id="class-option-placeholder">
    <ImagePlaceholder theme={theme} />
    <ClassRightColumn alignItems="center">
      <ClassTextContainer flexDirection="column" gap={spacing[8]}>
        <TextPlaceholder theme={theme} />
        <TextPlaceholder theme={theme} />
      </ClassTextContainer>
    </ClassRightColumn>
  </Flex>
);

const ClassOption = ({
  fitnessDisciplineDisplayName,
  instructorName,
  imageUrl,
  title,
  onClick,
  theme = 'Grey 90',
}: ParsedClassType & {
  onClick: () => void;
  theme?: 'White' | 'Grey 90';
}) => {
  const mediaProps = {
    alt: `Class: ${title}`,
    mobile: toCloudinarySrc({ src: imageUrl, width: 112, devicePixelRatio: 2 }),
    type: 'image',
  } as MediaImageProps;
  const { primaryTextColor, secondaryTextColor } = themes[theme];

  return (
    <ClickableClass onClick={onClick} theme={theme}>
      <Flex gap={spacing[16]} data-test-id="class-option">
        <ClassImage media={mediaProps} width="auto" />
        <ClassRightColumn alignItems="center">
          <ClassTextContainer flexDirection="column" gap={spacing[4]}>
            <Label textColor={primaryTextColor} weight="medium">
              {title}
            </Label>
            <Support textColor={secondaryTextColor}>
              {instructorName} · {fitnessDisciplineDisplayName}
            </Support>
          </ClassTextContainer>
        </ClassRightColumn>
        <ChevronContainer>
          <StyledChevron name="chevron" theme={theme} />
        </ChevronContainer>
      </Flex>
    </ClickableClass>
  );
};

export default ClassOptions;

const ImagePlaceholder = styled.div<{ theme?: 'White' | 'Grey 90' }>`
  background-color: grey;
  ${classImageStyles}
  ${({ theme }) => skeletonAnimationStyles({ theme })};
`;

const TextPlaceholder = styled.div<{ theme?: 'White' | 'Grey 90' }>`
  border-radius: 6px;
  height: 14px;
  width: 220px;
  max-width: 100%;
  background-color: grey;
  ${({ theme }) => skeletonAnimationStyles({ theme })};
`;

const ClickableClass = styled.button<{ theme?: 'White' | 'Grey 90' }>`
  border-radius: 8px;
  position: relative;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    :hover {
      background-color: ${({ theme }) => themes[theme].classOptionHoverColor};
    }
  }
`;

const ClassRightColumn = styled(Flex)`
  position: relative;
  gap: ${spacing[4]};
  padding: ${spacing[16]} ${spacing[40]} ${spacing[16]} 0;
  min-width: 0;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    width: 264px;
  }
`;

const ClassTextContainer = styled(Flex)`
  overflow: hidden;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ClassImage = styled(Media)`
  img {
    ${classImageStyles}
  }
`;

const ChevronContainer = styled.div`
  align-self: center;
  position: absolute;
  right: ${spacing[8]};
  @media (min-width: ${BreakpointWidths.tablet}px) {
    right: ${spacing[16]};
  }
`;

const StyledChevron = styled(Icon)<{ theme: 'White' | 'Grey 90' }>`
  path {
    fill: ${({ theme }) => themes[theme].primaryTextColor};
    transform: scaleX(-1) translateX(-8px);
  }
`;

const StyledLink = styled(Link)<{ theme: 'White' | 'Grey 90' }>`
  color: ${({ theme }) => themes[theme].primaryTextColor};
`;
