import {
  ResponsiveImage,
  OverlaidChildren,
  Container,
  Flex,
  brand,
  spacing,
  Eyebrow,
  Layout,
} from '@pelotoncycle/design-system';
import type { MediaImageProps } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import Plus from './PlusIcon';

type Props = {
  title: string;
  images: MediaImageProps | undefined;
  testId: string;
  onClick?: () => void;
  isVisible?: boolean;
};

const FeatureCard: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  images,
  testId,
  onClick,
  isVisible,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  if (!images) {
    throw new Error('Missing media');
  }

  return (
    <CtaWrapper
      onClick={onClick}
      data-test-id={testId}
      role="button"
      tabIndex={isVisible ? 0 : -1}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          onClick && onClick();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledImage {...images} loading="lazy" className="bg-image" />
      <DropShadow />
      <Container>
        <Layout verticalAlignment="bottom" minHeight="100%">
          <Flex
            padding={{ mobile: spacing[16], desktop: spacing[24] }}
            gap={{ mobile: spacing[12], desktop: spacing[16] }}
            alignItems="center"
            data-test-id="feature-card-cta"
          >
            <Plus
              isHovered={isHovered}
              primaryColor={brand.lightest}
              filledPrimaryColor={brand.lightest}
              filledSecondaryColor={brand.darkest}
            />
            <Eyebrow textColor={brand.lightest} size="large" hasDropShadow={true}>
              {title}
            </Eyebrow>
          </Flex>
        </Layout>
      </Container>
    </CtaWrapper>
  );
};

export default FeatureCard;

const CtaWrapper = styled(OverlaidChildren)`
  cursor: pointer;
  &:hover {
    svg {
      fill: ${brand.lightest};
      & rect {
        fill: ${brand.darkest};
      }
    }

    ${media.tabletXLarge`
      .bg-image img {
        transform: scale(1.03);
        cursor: pointer;
      }
    `}
  }
`;

const StyledImage = styled(ResponsiveImage)`
  & > img {
    transition: transform 0.4s ease 0s;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const DropShadow = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    6deg,
    rgba(0, 0, 0, 0.6) 0%,
    transparent 30%,
    transparent 100%
  );
`;
