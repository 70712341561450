import { Container, spacing } from '@pelotoncycle/design-system';
import type { TypeComponent_overviewFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useTagHandler from '@page-builder/hooks/useTagHandler';
import { ErrorBoundary } from '@page-builder/layout/error-handling';
import { useBlockComponent } from '@page-builder/modules';
import { toEntryTags } from '@page-builder/utils/helpers';

type Props = {
  upsellModuleData: Exclude<TypeComponent_overviewFields['pbxPackageUpsell'], undefined>;
};

const PackageUpsell: React.FC<Props> = ({ upsellModuleData }) => {
  // A Block is a React component that can render a page builder module based on its content type
  const Block = useBlockComponent();

  const { resolvedStatus } = useTagHandler(toEntryTags(upsellModuleData));

  if (!resolvedStatus || !Block) {
    return null;
  }

  return (
    <ErrorBoundary renderError={() => <></>}>
      <PackageUpsellContainer>
        <Block block={upsellModuleData} />
      </PackageUpsellContainer>
    </ErrorBoundary>
  );
};

const PackageUpsellContainer = styled(Container)`
  [data-test-id='grid-product-tile-pill'] {
    ${media.desktopLarge`
      top: ${spacing[12]};
      left: ${spacing[12]};
    `}
  }
`;

export default PackageUpsell;
