import { Button, Flex, spacing } from '@pelotoncycle/design-system';
import type { ButtonProps } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import {
  useCartException,
  useSetCartException,
} from '@ecomm/cart-next/context/CartContext';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { toCtaFields } from '@page-builder/utils/helpers';
import { useDrawerContentContext } from '../DrawerContentContext';
import { useShopContext } from '../ShopContext';
import AddToCartError from './AddToCartError';

export type AddToCartCtaWithErrorProps = {
  bundleType?: BundleType;
  bundleName?: string;
  size?: ButtonProps['size'];
  width?: ButtonProps['width'];
};

/**
 * This component is an Add to Cart CTA that is intended to work as a "dummy" CTA.
 * This CTA will render on the overview when something goes wrong with fetching product data (from Commercetools or CMS) or if the user is in some state where they are not able to add the product to the cart.
 * When clicked on, it will send two tracking events to denote the error and it will prompt an error message to the user.
 */
const AddToCartCtaWithErrorTracking: React.FC<AddToCartCtaWithErrorProps> = ({
  bundleType,
  bundleName,
  size = 'medium',
  width = 'adaptive',
}: AddToCartCtaWithErrorProps) => {
  const { trackEvent } = useTracking();
  const [isActive, setIsActive] = useState<boolean>(false);

  // For setting the cart exception and rendering the error message when the CTA is clicked on.
  const cartException = useCartException();
  const setCartException = useSetCartException();
  const { setShouldFocusError, activeCtaRef } = useShopContext();
  const errorMessageOverride = useMicroCopy(
    'addToCartWithErrorHandling.errorMessageOverride',
  );

  // Use the ATC CTA entry from page builder so it will look consistent with the actual CTA.
  const { pageBuilderData } = useDrawerContentContext();
  const { ctas } = pageBuilderData;
  const [addToCartCta] = toCtaFields(ctas);

  const handleOnClick = () => {
    setCartException(errorMessageOverride);
    setIsActive(true);
    setShouldFocusError(true);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName: 'Add to Cart Button Error',
      },
    });

    trackEvent({
      event: 'Added Product Error',
      properties: {
        category: bundleType,
        name: bundleName,
      },
    });
  };

  return (
    <Flex flexDirection="column" gap={spacing[16]}>
      <Button
        data-test-id="addToCartCtaWithErrorTracking"
        {...addToCartCta}
        onClick={handleOnClick}
        size={size}
        width={width}
        ref={isActive ? activeCtaRef : undefined}
      />
      {cartException && isActive && (
        <AddToCartError
          errorMessageOverride={errorMessageOverride}
          errorCode={cartException}
        />
      )}
    </Flex>
  );
};

export default AddToCartCtaWithErrorTracking;
