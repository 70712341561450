import type { TypeComponentCtaFields } from '@pelotoncycle/page-builder';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import type { TryClassProps } from '@page-builder/modules/ClassesGrid/Modal/TryClassCTA';
import type { ModalItems } from '@page-builder/modules/ClassesGrid/utils/mapModalLabelsFromContentfulToProps';

export type ModalLabelPropsFromContentful = {
  fields: {
    ctas: [
      {
        fields: {
          modal: {
            fields: {
              items: ModalItems[];
              ctas: ModalCtaPropsFromContentful;
            };
          };
        };
      },
    ];
  };
};
type ModalCtaPropsFromContentful = {
  fields: {
    color?: 'dark' | 'light' | 'primary';
    variant: 'solid' | 'outline';
    text: string;
    link: { fields: { url?: string } };
    name: string;
  };
};

export type ContentfulItem = {
  fields: {
    name: string;
    media?: {
      fields: {
        media?: {
          fields: {
            mobile?: {
              fields: {
                file?: {
                  url: string;
                };
              };
            };
          };
        };
      };
    };
    text?: {
      fields: {
        headline?: string;
        support?: string;
      };
    };
    ctas?: [
      {
        fields: {};
      },
    ];
  };
};

export const mapModalLabelPropsFromContentful = (
  items: ModalLabelPropsFromContentful[],
) => {
  return items[0]?.fields?.ctas[0]?.fields?.modal?.fields || { items: [], ctas: [] };
};

export const mapModalCtaPropsFromContentful = (
  fields: ModalLabelPropsFromContentful[],
): TryClassProps => {
  const items = mapModalLabelPropsFromContentful(fields);

  return {
    color: items?.ctas[0]?.fields?.color,
    text: items?.ctas[0]?.fields?.text,
    link: items?.ctas[0]?.fields?.link?.fields?.url,
    variant: items?.ctas[0]?.fields?.variant,
    name: items?.ctas[0]?.fields?.name,
  };
};

export const mapCFUClassCardsFromContentful = (items: ContentfulItem[]) =>
  items.filter(item => item.fields.name.includes('CFU Class Card'));

export const mapCFUClassCardPropsFromContentful = (
  items: ContentfulItem[],
  cfuClassCardKey: string,
) => {
  const classCards = mapCFUClassCardsFromContentful(items);

  const cfuClassCard = classCards.find(
    item => item.fields.name === `${cfuClassCardKey} CFU Class Card`,
  );

  if (cfuClassCard) {
    const ctaData = cfuClassCard.fields.ctas
      ? ctaExtractData(cfuClassCard.fields.ctas[0] as Entry<TypeComponentCtaFields>)
      : undefined;

    return {
      media: cfuClassCard.fields?.media?.fields?.media?.fields?.mobile?.fields?.file?.url,
      headline: cfuClassCard.fields?.text?.fields?.headline,
      support: cfuClassCard.fields?.text?.fields?.support,
      cta: ctaData,
    };
  }

  return null;
};
