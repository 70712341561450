import {
  Container,
  Media,
  OverlaidChildren,
  white,
  Eyebrow,
  Display,
  Flex,
  spacing,
  brand,
  Label,
  black,
  grey,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth, media as mediaQuery } from '@peloton/styles';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import Markdown from '@page-builder/utils/Markdown';
import { scrollToSection } from '../../../../utils/linkUtils';
import type { HeroTileProps } from '../types';

/**
 * This is the `HeroTile` which is the individual tile component that gets rendered within the `HeroTiles` component.
 * - `backgroundMedia` and `headlineText` are required.
 * - If `headlineMedia` is provided, it will be rendered instead of `headlineText`.
 * - `subhead` is optional and will be rendered below the headline.
 * - `link` is optional and will wrap the entire tile if provided.
 * - The `underThreeTiles` prop is passed in from `HeroTiles` and determines the margin of the container that wraps all of the text.
 *
 * @param backgroundMedia the media asset that gets rendered as the background image in the tile.
 * @param headlineMedia the svg media asset that gets rendered as the headline text for the tile.
 * @param headlineText the fallback headline text that gets rendered when the headlineMedia is not defined.
 * @param subhead the text that gets rendered below either the headlineMedia or the headlineText.
 * @param link the optional jump scroll link that gets wrapped around the entire tile.
 * @param underThreeTiles passed in boolean from HeroTiles that determines the margin of the text container.
 */

const HeroTile: React.FC<HeroTileProps & { underThreeTiles: boolean }> = ({
  backgroundMedia,
  headlineMedia,
  headlineText,
  subhead,
  link,
  underThreeTiles,
}) => {
  const { trackEvent } = useTracking();
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const shouldShowFallbackHeadline = !headlineMedia && headlineText;

  const textContainerMargin = underThreeTiles
    ? {
        mobile: `0 ${spacing[12]} ${spacing[8]} ${spacing[12]}`,
        tablet: `0 ${spacing[16]} ${spacing[12]} ${spacing[16]}`,
        desktop: `0 ${spacing[32]} ${spacing[24]} ${spacing[32]}`,
      }
    : {
        mobile: `0 ${spacing[8]} ${spacing[8]} ${spacing[8]}`,
        tablet: `0 ${spacing[12]} ${spacing[12]} ${spacing[12]}`,
        desktop: `0 ${spacing[24]} ${spacing[24]} ${spacing[24]}`,
      };

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);

  const SubHeading: React.FC<React.PropsWithChildren<unknown>> = ({ children }) =>
    isMobileOrTablet ? (
      <Label textColor={white} size="small" style={{ fontWeight: 600 }} as="h2">
        {children}
      </Label>
    ) : (
      <Eyebrow textColor={white} size="large">
        {children}
      </Eyebrow>
    );

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>, linkTo: string) => {
    const isJumpLink = linkTo.startsWith('#');
    if (isJumpLink) {
      event.preventDefault();
      scrollToSection(linkTo, headbandRef);
    }
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        linkName: headlineText,
        linkTo: linkTo,
        parentType: 'Component: JSON',
        parent: 'HeroTiles (CHAMP)',
        unitName: `${headlineText} Hero Tile`,
      },
    });
  };

  const Tile: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    if (link) {
      return (
        <LinkTile
          data-test-id="hero-tile-link"
          key={link.url}
          as="a"
          href={link.url}
          onClick={e => handleClick(e, link.url)}
        >
          {children}
        </LinkTile>
      );
    } else {
      return (
        <OverlaidChildren data-test-id="hero-tile" key={headlineText}>
          {children}
        </OverlaidChildren>
      );
    }
  };

  return (
    <Tile>
      <BackgroundMedia media={backgroundMedia} className="backgroundMedia" />
      <TileGradient />
      <Flex flexDirection="column" justifyContent="end" margin={textContainerMargin}>
        {headlineMedia && (
          <Container
            height={{ mobile: spacing[48], desktop: spacing[112] }}
            position="relative"
          >
            <HeadlineMedia media={headlineMedia} height="auto" width="auto" />
          </Container>
        )}
        {shouldShowFallbackHeadline && (
          <Display textColor={white} size={isMobileOrTablet ? 'small' : 'large'}>
            {headlineText}
          </Display>
        )}
        {subhead && (
          <SubheadContainer>
            <Markdown
              content={subhead}
              markdown={{
                renderers: {
                  paragraph: SubHeading,
                  strong: ({ children }) => (
                    <strong style={{ color: brand['accent'], fontWeight: 600 }}>
                      {children}
                    </strong>
                  ),
                },
              }}
            />
          </SubheadContainer>
        )}
      </Flex>
    </Tile>
  );
};

export default HeroTile;

const LinkTile = styled(OverlaidChildren)`
  // Desktop-only hover state
  border-radius: ${spacing[12]};
  overflow: hidden;
  ${mediaQuery.desktopLarge`
    transition: all 0.4s ease-in-out 0s;
    // specifying background media to avoid hover state on headline media
    .backgroundMedia > picture > img, video {
      transition: all 0.4s ease-in-out 0s;
    }
    &:hover, &:focus-within {
      cursor: pointer;
      box-shadow: ${rgba(black, 0.24)} 0px 5px 32px;
      .backgroundMedia > picture > img, video {
        transform: scale(1.03);
        }
      }
    `}
`;

const TileGradient = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    192deg,
    ${rgba(black, 0)} 45.49%,
    ${rgba(grey[90], 115.04)} 100%
  );
  border-radius: ${spacing[12]};
`;

const BackgroundMedia = styled(Media)`
  height: 144px;
  > picture > img,
  video {
    border-radius: ${spacing[12]};
  }
  ${mediaQuery.tabletLarge`
    height: 150px;
  `}
  ${mediaQuery.desktopLarge`
    height: 312px;
  `}
`;

const HeadlineMedia = styled(Media)`
  position: absolute;
  bottom: 0;
  height: 100%;
  > picture > img,
  video {
    width: auto;
  }
`;

const SubheadContainer = styled(Container)`
  > h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
