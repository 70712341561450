import { useMemo } from 'react';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import useProductsByKeys from '@ecomm/commercetools/hooks/useProductsByKeys';
import { toRecommendedProductsForCohort } from '@ecomm/product-recommendations/utils/toRecommendedProductsCommerceTools';
import useFilteredItems from '@ecomm/product-recommendations/utils/useFilteredItems';
import useModuleState from '@ecomm/product-recommendations/utils/useModuleState';

const useProductsForCohort = (
  cohort: ProductRecommendationsCohort,
  enabledFilterTags: string[],
) => {
  const { error, products: bucketedProducts } = useProductsByKeys(
    cohort.products.map(p => p.slug),
  );

  const allProducts = useMemo(() => {
    if (!bucketedProducts) {
      return [];
    }

    return toRecommendedProductsForCohort(cohort, bucketedProducts);
  }, [cohort, bucketedProducts]);

  const products = useFilteredItems(allProducts, enabledFilterTags);

  const state = useModuleState({
    cohort,
    products,
    error,
  });

  return {
    products,
    state,
  };
};

export default useProductsForCohort;
