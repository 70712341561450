import { Flex, Label, spacing, Icon, grey } from '@pelotoncycle/design-system';
import React from 'react';
import { Link } from '@peloton/next/components/Link';
import { useMicroCopy } from '@content/client/microCopy';

type DownloadLinkProps = {
  downloadLink: string;
  downloadText?: string;
};

const DownloadLink = ({ downloadLink, downloadText }: DownloadLinkProps) => {
  const downloadInstructionsCTA = useMicroCopy('sparePartsDownloadCTA');

  return (
    <Flex
      data-test-id="download-link"
      gap={spacing[12]}
      alignItems="flex-end"
      margin={`0 0 ${spacing[16]} 0`}
    >
      <Icon name="instructions" primaryColor={grey[60]} height={24} />
      <Link href={downloadLink} variant="body" target="_blank">
        <Label style={{ lineHeight: '20px' }} size="large">
          {downloadText || downloadInstructionsCTA}
        </Label>
      </Link>
    </Flex>
  );
};

export default DownloadLink;
