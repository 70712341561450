import {
  Flex,
  grey,
  white,
  spacing,
  Headline,
  Support,
  Video,
  LinkButton,
} from '@pelotoncycle/design-system';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { media, BreakpointWidth } from '@peloton/styles';
import { LiftedModal } from '@page-builder/modules/NextModal';
import { MovementChip } from './MovementChip';
import { useTrackingUtils } from './trackingUtils';
import type { ModalVideoCardProps as ModalVideoCard, CtaProps as Cta } from './types';

type VideoPlayerModalProps = {
  isOpen: boolean;
  onAfterOpen: () => void;
  closeHandler: () => void;
  selectedVideoIndex: number;
  setSelectedVideoIndex: (index: number) => void;
  headline: string;
  subtitle: string;
  modalTitle: string;
  modalVideos: ModalVideoCard[];
  cta?: Cta;
};

export const VideoPlayerModal = ({
  isOpen,
  onAfterOpen,
  closeHandler,
  selectedVideoIndex,
  setSelectedVideoIndex,
  headline,
  subtitle,
  modalTitle,
  modalVideos,
  cta,
}: VideoPlayerModalProps) => {
  const {
    handleStartedVideoTracking,
    handleClickedVideoTracking,
    handleTrackCTAClick,
  } = useTrackingUtils();

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);

  useEffect(() => {
    if (isOpen) {
      handleStartedVideoTracking();
    }
  }, [isOpen]);

  return (
    <>
      <LiftedModal
        isOpen={isOpen}
        contentLabel={modalTitle}
        onAfterOpen={onAfterOpen}
        closeHandler={closeHandler}
        backgroundColor={grey[80]}
        textColor={white}
      >
        <SectionContainer
          margin="auto"
          padding={{
            mobile: `${spacing[32]} ${spacing[16]}`,
            tablet: `${spacing[32]} ${spacing[24]}`,
            desktop: `${spacing[80]} ${spacing[24]}`,
          }}
          flexDirection={{
            mobile: 'column',
            desktop: `row`,
          }}
          gap={spacing[24]}
          className={`pos-${headline}`}
        >
          <Flex
            maxWidth={{ desktop: '395px' }}
            minWidth={{ desktop: '355px' }}
            flexDirection="column"
            justifyContent="center"
          >
            <Headline size="extraSmall" textColor="white">
              {headline}
            </Headline>
            <Support size="medium" textColor="white">
              {subtitle}
            </Support>
            <Flex flexDirection="column" gap={spacing[16]} margin={`${spacing[24]} 0 0`}>
              {modalVideos.map((video, idx) => (
                <>
                  <AnimatePresence>
                    {idx === selectedVideoIndex && isMobileOrTablet && (
                      <AnimatedVideoContainer
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{ display: 'flex' }}
                      >
                        <VideoContainer
                          src={modalVideos[selectedVideoIndex].video.src}
                          poster={modalVideos[selectedVideoIndex].video.poster}
                          autoplay={true}
                          loop={true}
                          controls={false}
                          playPauseButtonPosition="right"
                          muteButtonPosition="hide"
                          progressTracked={true}
                        />
                      </AnimatedVideoContainer>
                    )}
                  </AnimatePresence>
                  <MovementChip
                    key={idx}
                    name={video.movementName || ''}
                    description={video.movementSubtitle || ''}
                    isActive={idx === selectedVideoIndex}
                    miniThumbnail={video.miniThumbnail}
                    onClick={() => {
                      setSelectedVideoIndex(idx);
                      handleClickedVideoTracking(modalVideos[idx].movementName);
                    }}
                  />
                </>
              ))}
              {cta && isMobileOrTablet && (
                <LinkButton
                  key={cta.text}
                  text={cta.text}
                  href={cta.link.url}
                  color={cta.color}
                  variant={cta.variant}
                  width="adjustable"
                  style={{ marginTop: spacing[16] }}
                  onClick={() => handleTrackCTAClick(cta.text, cta.link.url)}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            display={{ mobile: 'none', desktop: 'flex' }}
            maxWidth="776px"
            style={{ alignSelf: 'center' }}
          >
            <VideoContainer
              src={modalVideos[selectedVideoIndex].video.src}
              poster={modalVideos[selectedVideoIndex].video.poster}
              autoplay={true}
              loop={true}
              controls={false}
              playPauseButtonPosition="right"
              muteButtonPosition="hide"
              progressTracked={true}
            />
          </Flex>
        </SectionContainer>
        {cta && !isMobileOrTablet && (
          <LinkButton
            key={cta.text}
            text={cta.text}
            href={cta.link.url}
            color={cta.color}
            variant={cta.variant}
            width={'fixed'}
            style={{
              width: '350px',
              position: 'absolute',
              left: '50%',
              transform: `translate(-50%, ${spacing[24]})`,
            }}
            onClick={() => handleTrackCTAClick(cta.text, cta.link.url)}
          />
        )}
      </LiftedModal>
    </>
  );
};

const SectionContainer = styled(Flex)`
  ${media.desktopXLarge`
    gap: ${spacing[56]};
    padding: ${spacing[80]} ${spacing[56]};
  `}
`;

const VideoContainer = styled(Video)`
  height: auto;
  width: auto;
  video,
  picture > img {
    aspect-ratio: 16/9;
    border-radius: ${spacing[4]};
  }
`;

const AnimatedVideoContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
`;
