import React, { createContext, useContext, useMemo, useState } from 'react';
import type { FilterGroupsType } from '@ecomm/product-recommendations/models/ProductGridPropsSubTypes';

type FiltersContextType = {
  hasAnyFilterGroups: boolean;
  filterGroups: FilterGroupsType[];
  enabledFilterTags: string[];
  setEnabledFilterTags: (tags: string[]) => void;
};

export const FiltersContext = createContext<FiltersContextType>({
  hasAnyFilterGroups: false,
  filterGroups: [],
  enabledFilterTags: [],
  setEnabledFilterTags: () => {},
});

type Props = {
  filterGroups?: FilterGroupsType[];
};

const toInitialState = (filterGroups: FilterGroupsType[]) => {
  return filterGroups.flatMap(filterGroup =>
    filterGroup.filters
      .filter(filter => filter.selectedByDefault)
      .map(filter => filter.tag),
  );
};

export const useFiltersState = (filterGroups: FilterGroupsType[] = []) => {
  const [enabledFilterTags, setEnabledFilterTags] = useState<string[]>(
    toInitialState(filterGroups),
  );

  const value = useMemo(() => {
    const hasAnyFilterGroups = Boolean(
      filterGroups.find(group => group.filters.length > 0),
    );

    return {
      hasAnyFilterGroups,
      filterGroups,
      enabledFilterTags,
      setEnabledFilterTags,
    };
  }, [enabledFilterTags, filterGroups]);

  return value;
};

export const FiltersContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  filterGroups = [],
  children,
}) => {
  const value = useFiltersState(filterGroups);

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export const useFiltersContext = () => useContext(FiltersContext);
