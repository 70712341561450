import {
  Container,
  Eyebrow,
  black,
  white,
  Label,
  Headline,
  Body,
  spacing,
  Badge,
  Flex,
  Media,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { TypeComponentProductCard } from '@page-builder/lib/types';
import Markdown from '@page-builder/utils/Markdown';
import { useTheme } from './themes';

export type Props = {
  card: TypeComponentProductCard;
  isCardActive: boolean;
  isMonthlyToggleSelected: boolean;
};

const EyebrowTextSmall: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Eyebrow size="small">{children}</Eyebrow>
);

const EyebrowTextSmallStrong: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Eyebrow size="small" style={{ fontWeight: 600 }}>
    {children}
  </Eyebrow>
);

const HeadlineTextExtraSmall: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <Headline size="extraSmall">{children}</Headline>;

const ProductComparisonCard: React.FC<React.PropsWithChildren<Props>> = ({
  card,
  isCardActive,
  isMonthlyToggleSelected,
}) => {
  const {
    productCardAndHeaderTextColor,
    productCardActiveBorderColor,
    productCardInactiveBorderColor,
    promoBackgroundColor,
    highlightColor,
  } = useTheme();

  const {
    modalText: priceSupportText,
    priceInfo: description,
    monthlyPricing,
    totalPricing: annualPrice,
    disclaimer: annualPriceBillingDisclaimer,
    product,
    ribbon,
    annualOffer,
    monthlyOffer,
  } = card.fields;
  const { image } = product.fields;

  const offerText = isMonthlyToggleSelected ? monthlyOffer : annualOffer;

  const hasImage = image && image.fields.file.details.image && isMonthlyToggleSelected;

  return (
    <StyledCardContainer
      position="relative"
      borderRadius={spacing[12]}
      activeBorderColor={productCardActiveBorderColor}
      inactiveBorderColor={productCardInactiveBorderColor}
      isCardActive={isCardActive}
      padding={{ mobile: spacing[16], desktop: spacing[24] }}
      width={{ mobile: '100%', desktop: '464px' }}
      height="100%"
    >
      {ribbon && (
        <Badge
          variant="horizontal"
          theme="accent"
          top={spacing[12]}
          right={spacing[12]}
          position="absolute"
        >
          {ribbon}
        </Badge>
      )}
      <Wrapper color={productCardAndHeaderTextColor} height="100%">
        <Flex
          height="100%"
          gap={spacing[24]}
          justifyContent="space-between"
          flexDirection="column"
        >
          <Container
            padding={{
              mobile: `${spacing[16]} ${spacing[16]} 0`,
              desktop: `${spacing[24]} ${spacing[24]} 0`,
            }}
          >
            <Container verticalMargin={`0 ${spacing[16]}`}>
              <Markdown
                content={product.fields.name.split('(')[0].trim()}
                markdown={{
                  renderers: {
                    paragraph: EyebrowTextSmall,
                    strong: EyebrowTextSmallStrong,
                  },
                }}
              />
            </Container>
            {description && (
              <Container maxWidth={{ desktop: '336px' }}>
                <Markdown
                  content={description}
                  markdown={{
                    renderers: {
                      paragraph: HeadlineTextExtraSmall,
                    },
                  }}
                />
              </Container>
            )}
            {priceSupportText && (
              <Flex
                verticalMargin={{ mobile: `${spacing[4]} 0`, desktop: `${spacing[8]} 0` }}
              >
                <Label size="small">{priceSupportText}</Label>
              </Flex>
            )}
          </Container>
          <Flex
            horizontalPadding={{
              mobile: spacing[16],
              desktop: spacing[24],
            }}
            verticalPadding={{
              mobile: `0px ${spacing[16]}`,
              tablet: priceSupportText
                ? `0px ${spacing[16]}`
                : `${spacing[24]} ${spacing[16]}`,
              desktop: priceSupportText
                ? `0px ${spacing[24]}`
                : `${spacing[24]} ${spacing[24]}`,
            }}
          >
            {isMonthlyToggleSelected && monthlyPricing ? (
              <Markdown
                content={monthlyPricing}
                markdown={{
                  renderers: {
                    paragraph: props => <Headline size="extraSmall" {...props} />,
                  },
                }}
              />
            ) : (
              <Flex gap={spacing[8]} alignItems="baseline">
                {annualPrice && (
                  <Markdown
                    content={annualPrice}
                    markdown={{
                      renderers: {
                        paragraph: props => <Headline size="extraSmall" {...props} />,
                      },
                    }}
                  />
                )}
                {annualPriceBillingDisclaimer && (
                  <Markdown
                    content={annualPriceBillingDisclaimer}
                    markdown={{
                      renderers: {
                        paragraph: props => <Label size="small" {...props} />,
                        emphasis: props => (
                          <Label size="small" textColor={highlightColor} {...props} />
                        ),
                      },
                    }}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        {offerText && (
          <StyledContainer
            data-test-id="product-card-promo-box"
            backgroundColor={promoBackgroundColor}
            borderRadius={spacing[8]}
            padding={{
              mobile: spacing[16],
              desktop: spacing[24],
            }}
            height="100%"
            width={{ mobile: 'unset', desktop: '100%' }}
          >
            <Flex
              flexBasis={hasImage ? '60%' : '100%'}
              minWidth={{
                mobile: '200px',
                desktop: '249px',
              }}
            >
              <Markdown
                content={offerText.toString()}
                markdown={{
                  renderers: {
                    paragraph: props => (
                      <Body size="small" {...props}>
                        {props.children}
                      </Body>
                    ),
                    emphasis: props => (
                      <Body
                        display="inline"
                        size="small"
                        textColor={highlightColor}
                        {...props}
                      />
                    ),
                  },
                }}
              />
            </Flex>
            {image && image.fields.file.details.image && isMonthlyToggleSelected && (
              <Flex
                flexBasis="40%"
                height="100%"
                justifyContent="right"
                maxHeight={{
                  mobile: '100px',
                  tablet: 'unset',
                  desktop: '120px',
                }}
              >
                <Media
                  media={{
                    mobile: toCloudinarySrc({
                      src: image.fields.file.url,
                      height: image.fields.file.details.image.height,
                    }),
                    alt: image.fields.description,
                    type: 'image',
                    loading: 'lazy',
                  }}
                  height={image.fields.file.details.image.height}
                  width={image.fields.file.details.image.width}
                />
              </Flex>
            )}
          </StyledContainer>
        )}
      </Wrapper>
    </StyledCardContainer>
  );
};

const StyledCardContainer = styled(Container)<{
  activeBorderColor: string;
  inactiveBorderColor: string;
  isCardActive: boolean;
}>`
  align-items: center;
  outline: ${({ isCardActive, activeBorderColor, inactiveBorderColor }) =>
    isCardActive
      ? `2px solid ${activeBorderColor};`
      : `1px solid ${inactiveBorderColor};`};
  transition: all 0.1s ease-in-out 0.1s;
  :hover {
    cursor: pointer;
    box-shadow: ${({ activeBorderColor }) =>
      activeBorderColor === black ? `rgba(0, 0, 0, 0.05) 5px 10px 25px` : ''};
    background-color: ${({ activeBorderColor }) =>
      activeBorderColor === white ? `rgba(24, 26, 29, 0.5)` : ''};
  }
`;

const Wrapper = styled(Container)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledContainer = styled(Container)`
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: row;
`;
export default ProductComparisonCard;
