import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import { PB_ENTRY_API_ENDPOINT, PB_ENTRY_PREVIEW_API_ENDPOINT } from '../utils/constants';

export const fetcher = (url: string) => fetch(url).then(r => r.json());

const useBuilderEntry = <T>(id: string) => {
  const locale = useLocale();
  const previewCopy = useIsPreviewCopyEnabled();
  return useSWR<T>(
    `${
      previewCopy ? PB_ENTRY_PREVIEW_API_ENDPOINT : PB_ENTRY_API_ENDPOINT
    }/${locale}/${id}`,
    fetcher,
  );
};

export default useBuilderEntry;
