import { spacing } from '@pelotoncycle/design-system';
import styled, { css } from 'styled-components';
import { toThemeValue } from '@ecomm/product-recommendations/utils/theme';

export const strikethroughStyles = css`
  color: ${toThemeValue('strikethroughColor')};

  /*
    - for strikethrough element a11y
  */

  &::before,
  &::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &::before {
    content: ' [deletion start] ';
  }

  &::after {
    content: ' [deletion end] ';
  }
`;

export const priceHighlightStyles = css`
  color: ${toThemeValue('priceHighlightColor')} !important;
  font-weight: 400;
`;

export const PriceHighlightCopy = styled.span`
  ${priceHighlightStyles}
`;

export const SmallPriceHighlightCopy = styled(PriceHighlightCopy)`
  font-size: 12px;
`;

export const StrikeThrough = styled.del`
  ${strikethroughStyles}
`;

export const DescriptionHighlightCopy = styled.span`
  display: inline-block;
  background-color: ${toThemeValue('descriptionHighlightColor')};
  font-weight: 600;
  padding: 0 ${spacing[4]};
`;
