import {
  DISCIPLINE_SLUGS,
  DISCIPLINE_VARIANTS,
  PROSPECTS_DISCIPLINE_SLUGS,
} from '@ecomm/classes/models/constants';
import type { CtaWithUrlData } from '@ecomm/copy';
import { getClassDetailPageSegments } from './getClassDetailPageSegments';
import { getClassListingPageSegments } from './getClassListingPageSegments';

const DISCIPLINE_PAGE_REGEX = /classes\/(?<discipline>.*)/;

export const transformCtas = (ctas: Required<CtaWithUrlData>[]) =>
  ctas.map(cta => {
    return { name: cta.text!, icon: cta.text!.toLowerCase(), slug: cta.url! };
  });

export const getActiveCategory = (path: string) => {
  const pathMatch = path.match(DISCIPLINE_PAGE_REGEX);
  const { discipline } = pathMatch!.groups!;
  return discipline;
};

export const isDisciplineSlug = (discipline: string) => {
  const disciplines = Object.values(PROSPECTS_DISCIPLINE_SLUGS);
  const discipline_variants = Object.values(DISCIPLINE_VARIANTS);

  return disciplines.includes(discipline) || discipline_variants.includes(discipline);
};

export const getDataBySlug = (path: string, classes: any) => {
  const getSlug = path.split('/').pop();
  return classes.find((item: any) => item.slug === getSlug) || classes[0];
};

export const createDisciplinePageHref = (
  pathname: string,
  previousRoute: string | null,
): string => {
  const {
    isDiscipline,
    isFiltered,
    disciplineOrLanding,
    duration,
    classType,
  } = getClassListingPageSegments(previousRoute || '');

  if (isDiscipline && isFiltered) {
    const composedPath =
      `/classes/` + [disciplineOrLanding, duration, classType].filter(Boolean).join('/');
    return composedPath;
  }

  const { discipline } = getClassDetailPageSegments(pathname);
  return `/classes/${discipline}`;
};

const stringMatch = (key: string) => (slug: string) => slug === key;

export const isDisciplineReferrer = (url?: string | null) => {
  if (!url) return false;
  const disciplineValues = Object.values(DISCIPLINE_SLUGS);

  try {
    const { pathname, origin } = new URL(url);
    const disciplineOrLanding = pathname.split('/')[2];

    return (
      origin.includes('peloton') &&
      disciplineValues.some(stringMatch(disciplineOrLanding))
    );
  } catch {
    const prevUrl = url.substring(1).split('/')[1];
    const isFromDiscipline = disciplineValues.some(stringMatch(prevUrl));

    return isFromDiscipline;
  }
};

export const getPageFromPath = (path: string): number => {
  const pageParam = path.split('page=')[1];
  return pageParam ? Number(pageParam.match(/\d+/)?.[0]) : 1;
};

export const getPreviousPaginationPage = (
  lowestLoadedPage: number,
  totalPages: number,
): number =>
  lowestLoadedPage > totalPages
    ? totalPages
    : lowestLoadedPage > 1
    ? lowestLoadedPage - 1
    : 1;

export const getNextPaginationPage = (highestLoadedPage: number): number =>
  highestLoadedPage < 0 ? 1 : highestLoadedPage + 1;

export const isPageHigherThanTotal = (page: number, totalPages: number) =>
  !!totalPages && page > totalPages;
