import type { MediaImageProps, MediaVideoProps } from '@pelotoncycle/design-system';
import type { GenericCtaData } from '@pelotoncycle/page-builder';

export type Card = {
  title: string;
  images: MediaImageProps | undefined;
  modal: {
    body: string;
    media: ModalMediaProps;
    name: string;
    header: string;
  };
};

export type ShowButtons = {
  moreButton: GenericCtaData;
  lessButton: GenericCtaData;
};

export type ModalMediaProps = {
  primaryMedia: MediaImageProps | MediaVideoProps | undefined;
  secondaryMedia: MediaImageProps | MediaVideoProps | undefined;
};

export const DEFAULT_DISPLAY_CARDS_LENGTH = 4;
