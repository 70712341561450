import { Container, brand, spacing } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { toVariationCopy } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media as mediaBreakpoints } from '@peloton/styles';
import { useCtaCopyTestToggle } from '@ecomm/feature-toggle/CtaCopyTest';
import { mobileScrollHeadbandStyles } from '@page-builder/modules/Headband/styles';
import type { ATCBandProps } from '@page-builder/modules/Headband/styles';

type Props = {
  cta: TypeComponentCta;
  showStickyCta: boolean;
  hideInView?: boolean;
  handleOnClick?: () => void;
};

const StickyCta: React.FC<Props> = ({
  cta,
  showStickyCta,
  handleOnClick,
  hideInView = false,
}) => {
  const { isExperimentActive, variables, activeVariation } = useCtaCopyTestToggle();
  const mobileBandUrl = cta?.fields.link?.fields.url;
  const ctaText = isExperimentActive
    ? toVariationCopy({
        originalCopy: cta.fields.text,
        ctaLink: mobileBandUrl ?? '',
        variables,
        activeVariation,
      })
    : cta.fields.text;

  return (
    <MobileATCBandWrapper
      bottom={showStickyCta ? '0' : '-100px'}
      aria-hidden={!showStickyCta || hideInView}
      data-test-id="mobile-band-cta"
      hideInView={hideInView}
    >
      <LinkButton
        href={mobileBandUrl}
        size="small"
        width="adaptive"
        onClick={handleOnClick}
      >
        {ctaText}
      </LinkButton>
    </MobileATCBandWrapper>
  );
};

export default StickyCta;

const MobileATCBandWrapper = styled(Container)<ATCBandProps & { hideInView?: boolean }>`
  ${mobileScrollHeadbandStyles}

  background-color: ${rgba(brand.darkest, 0.9)};
  backdrop-filter: blur(12px);
  border-top: 1px solid ${rgba(brand.light, 0.15)};
  padding: 20px ${spacing[16]};
  transition: bottom 0.3s ease-in-out, transform 0.3s ease-in-out;

  transform: translateY(${props => (props.hideInView ? '100%' : '0')});

  a {
    width: 100%;
  }

  ${mediaBreakpoints.desktop`
    display: none;
  `}
`;
