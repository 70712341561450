import React from 'react';
import { createGlobalStyle } from 'styled-components';

const NAV_SELECTOR = '#globalHeader';

export const HideNav = () => {
  return <HideNavStyles />;
};

export const HideNavStyles = createGlobalStyle`
  ${NAV_SELECTOR} {
    display: none !important;
  }
`;
