import { useEffect, useState } from 'react';
import {
  generateIdMeAuthorizationLink,
  getStoredVerificationData,
} from '@peloton/concessionary-pricing';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import type { ProductRecommendationsMarketingTile } from '@content/client/www/product-recommendations/models';
import { isSpecialPricingComponent } from './productUtils';

const useMarketingTileUrl = (marketingTile: ProductRecommendationsMarketingTile) => {
  const externalLinks = useExtLinkEnv();

  const [tileLinkUrl, setTileLinkUrl] = useState<string>('');

  useEffect(() => {
    // builds link used when clicking on Marketing Tile
    if (externalLinks && isSpecialPricingComponent(marketingTile)) {
      const credentials = getStoredVerificationData();

      if (credentials) {
        setTileLinkUrl(
          `${marketingTile.url}?verification_id=${credentials.id}&group=${credentials.segment}`,
        );
      } else {
        setTileLinkUrl(generateIdMeAuthorizationLink(externalLinks, marketingTile.name));
      }
    } else {
      setTileLinkUrl(marketingTile.url);
    }
  }, [externalLinks, marketingTile]);

  return tileLinkUrl;
};

export default useMarketingTileUrl;
