import { Support, white, Flex, spacing, Badge, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

type Props = {
  description: string;
  isExplicit?: boolean;
  captions?: string[] | null;
  moreInfoLabel: string;
  contentSensitivityLabel?: string;
  explicitLabel?: string;
  subtitlesLabel?: string;
  withBorder?: boolean;
};

type MetadataBadgeProps = {
  type?: string;
  value: string;
};

const MetadataBadge: React.FC<React.PropsWithChildren<MetadataBadgeProps>> = ({
  type,
  value,
  ...rest
}) => (
  <StyledBadge
    variant="horizontal"
    theme="grey"
    {...rest}
    aria-label={type ? `${type}: ${value}` : undefined}
  >
    {value}
  </StyledBadge>
);

/*
 1. Casts as string because TS doesn't acknowledge the condition properly.
 */
export const ClassDetailsMoreInfo: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  isExplicit,
  captions,
  moreInfoLabel,
  contentSensitivityLabel,
  explicitLabel,
  subtitlesLabel,
  withBorder = true,
}) => {
  const showExplicitBadge = isExplicit && explicitLabel;
  const showBadges = showExplicitBadge || captions?.length;
  const transformedCaptions = captions
    ?.map(caption => caption?.split('-')?.[0]?.toUpperCase())
    ?.join(', ');

  return (
    <ClassDetailsCardSection withBorder={withBorder} title={moreInfoLabel} isTextOnly>
      <Flex flexDirection="column" gap={spacing[16]}>
        <Support size="large" textColor={white} as="span">
          {description}
        </Support>

        {showBadges ? (
          <StyledBadgeList>
            {/* Explicit */}
            {showExplicitBadge && (
              <p>
                <MetadataBadge
                  type={contentSensitivityLabel}
                  value={explicitLabel as string} /* [1] */
                />
              </p>
            )}

            {/* Subtitles */}
            {captions?.length ? (
              <p>
                <MetadataBadge value={`${subtitlesLabel}: ${transformedCaptions}`} />
              </p>
            ) : null}
          </StyledBadgeList>
        ) : null}
      </Flex>
    </ClassDetailsCardSection>
  );
};

const StyledBadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[8]};
`;

const StyledBadge = styled(Badge)`
  display: block;
  background-color: ${rgba(grey[70], 0.25)};
`;
