import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { PageBuilderTheme } from '@ecomm/product-recommendations/utils/theme';

export enum CtaType {
  QuickView = 'quickView',
  UpsellCtaGroup = 'upsellCtaGroup',
}

export type TileComponentProps = {
  product: Product;
  ctaType?: CtaType;
  themeName?: PageBuilderTheme;
};
