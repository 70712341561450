import type { BadgeProps } from '@pelotoncycle/design-system';
import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import { PillComponent } from '../PromoPill/PromoPill';
import type { CfuAndBundlePromoProps } from '../types';

type ThemeProps = { theme?: BadgeProps['theme'] };

type PromoPillsProps = ThemeProps & CfuAndBundlePromoProps & { pillFallback?: string };

/**
 * This is the `PromoPills` component that is used to render the `Pill Messaging` and/or `Secondary Pill Messaging` promo pills for a `Bundle Promo`.
 * The `Pill Messaging` content will be rendered as the primary (green accent) pill, unless explicitly passed a different theme as the optional theme prop.
 * The `Secondary Pill Messaging` content will be rendered as the secondary (purple) pill.
 * As of now, the `Secondary Pill Messaging` content is only used for the `Bundle Promo` and not for the `CFU Promo`.
 * Likewise, this `PromoPills` component is currently only rendered in the `Overview` module used on the PDPs.
 * If there is no `Pill Messaging` or `Secondary Pill Messaging` content, the `Primary Pill Fallback` content will be rendered as the primary pill.
 **/

const PromoPills: React.FC<React.PropsWithChildren<PromoPillsProps>> = ({
  cfu,
  bundleSlug,
  theme,
  pillFallback,
}) => {
  const { data } = useBundlePromo(cfu, bundleSlug);
  const pillMessaging = data?.fields.pillMessaging;
  const secondaryPillMessaging = data?.fields.secondaryPillMessaging;

  if (!pillMessaging && !secondaryPillMessaging && !pillFallback) {
    return null;
  }

  //  Only render the pillFallback if there is no pill/secondary pill messaging from the Bundle Promo
  const primaryPillMessaging =
    pillMessaging || secondaryPillMessaging ? pillMessaging : pillFallback;

  return (
    <Flex gap={spacing[8]} alignItems="center">
      {primaryPillMessaging && (
        <PillComponent text={primaryPillMessaging} theme={theme} />
      )}
      {secondaryPillMessaging && (
        <PillComponent text={secondaryPillMessaging} theme="purple" />
      )}
    </Flex>
  );
};

export default PromoPills;
